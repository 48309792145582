/**
 * ModelError
 * @description Throw errors on model, print the correct stack and also the correct instanceof
 * @param message
 * @constructor
 */
var ModelError = function( message ){
    this.message = (message || '');
};

ModelError.prototype = new Error();
ModelError.prototype.name = "ModelError";

module.exports = ModelError;