module.exports = {
    //REF_TYPE_PROGRAMMING_MODE
    MODE: {
        ABSENCE: 5,
        ONOFF: 6,
        PROGRAMME: 7
    },
    ABSENCE: {
        NB_MAX_HOURS: 192
    },
    MAX: {
        MAX_MODES: 5,
        MAX_SCHEDULERS: 8
    },
};