var $appScope = require('../appScope'),
    logger = require('logger'),
    _ = require("underscore"),
    async = require('async');
var tools = require('tools').tools;

module.exports = ['$scope', '$http', '$timeout', '$i18next', 'immediateConsumptionTools', function($scope, $http, $timeout, $i18next, immediateConsumptionTools) {

    $scope.isUpdating = false;

    $scope.onOff = {};
    $scope.onOff.lastConsumptions = {};
    $scope.onOff.lastConsumptionsError = null;
    // immediateConsumptionTools.refreshLoopPowerBar($scope, 'onOff');
    immediateConsumptionTools.fetchPowerBarData($scope, 'onOff');

    $scope.changeState = function(){
        if($scope.isUpdating === false){
            $scope.isUpdating = true;
            // $$$ LSN TODO This timeout is wrong as f, if we want to prevent the user from spamming the button -> use _.debounce
            //              if we do this because the button value has not been updated yet, find another way
            $timeout(function(){
                //update dans base
                var data = {
                    csLinkList: _.filter($scope.availableCsLinkForControl, function(csLink){
                        return csLink.isProgrammable;
                    })
                };

                $http({
                    method: 'POST',
                    url: '/programmationEvent/updateOnOffEvent',
                    data: data
                })
                    .then(function onSuccess(response){
                        logger.debug("Update OnOff Event", "success")
                    })
                    .catch(function onError(response){
                        logger.debug("Update OnOff Event", response.data.message)
                    });
                $scope.isUpdating = false
            }, 1000) //1 seconds
        }
    };

    function getOnOffState(csLinkId, currentCsLink,cb){
        var params = {
            "csLinkId" : csLinkId
        };
        $http.get("/programmationEvent/getOnOffState.json",{"params":params})
            .then(function onSuccess(response){
                currentCsLink.status = response.data.onOffState;
                cb();
            })
            .catch(function onError(response){
                logger.debug('error OnOffState', response.data.message);
                cb(response.message);
            });
    }

    function getModulationStateFromCsLinkId(csLinkId, currentCsLink, cb){
        var params = {
            "csLinkId" : csLinkId
        };
        $http.get("/modulator/getModulatorState.json", {"params":params})
          .then(function onSuccess(response){
              currentCsLink.modulation = response.data.modulationStatus;
              cb();
          })
          .catch(function onError(response) {
              logger.debug('error Modulation State', response.data.message);
              cb(response.data.message);
          });
    }

    $scope.availableCsLinkForControl = [];
    $scope.csLinkList = $appScope.get('user').getSelectedSiteCsLinkList();
    _.each($scope.csLinkList,function(csLink){
       var currentCsLink = {};
       currentCsLink.name = csLink.name ? csLink.name : $i18next( csLink.defaultName );
       currentCsLink.csLinkId = csLink.csLinkId;
       currentCsLink.csLinkToCutId = csLink.csLinkToCutId;
       async.parallel([
           function(callback){
               getModulationStateFromCsLinkId(csLink.csLinkToCutId, currentCsLink,callback);
           },
           function(callback){
               getOnOffState(csLink.csLinkToCutId, currentCsLink,callback);
           }
       ], function(err){
           if (err){
               logger.debug("error in async", err);
               return;
           }
           currentCsLink.isProgrammable = tools.isProgrammable(csLink);
           $scope.availableCsLinkForControl.push(currentCsLink);
           $scope.availableCsLinkForControl = _.sortBy($scope.availableCsLinkForControl,'csLinkId');
       });
    });
}];

