var logger = require('logger'),
    ref = require('enum'),
    config = require('config');

module.exports = function(){
    return {
        restrict: 'E',
        template : '<div class="annualConsumptionDetailChart" style="height: inherit"><loader></loader></div>',
        link: function(scope){
            logger.debug('annualConsumptionDetailChart', 'link');

            scope.$watch("annualConsumptionDetailChart.chartDataType", function(){
                logger.debug('annualConsumptionDetailChart', 'switch chartDataType to', scope.annualConsumptionDetailChart.chartDataType.name);
                scope.annualConsumptionDetailChart.beforeRender();
            });

            scope.$on( 'selectedSiteHasChanged', function(event, userSiteId){
                logger.debug('annualConsumptionDetailChart', 'receiving event', 'selectedSiteHasChanged');
                scope.annualConsumptionDetailChart.fetch();
            });

            scope.annualConsumptionDetailChart.fetch();
        },
        controller: ['$rootScope','$scope', '$http', '$i18next', 'tooltipFormatter',
            function($rootScope, $scope, $http, $i18next, tooltipFormatter){

            var STATUS_PENDING = {},
                STATUS_ERROR = {},
                STATUS_OK = {};

            $scope.CHART_DATA_TYPES = ref.CHART_DATA_TYPES;

            // directive attributes
            $scope.annualConsumptionDetailChart = {};
            $scope.annualConsumptionDetailChart.charts = null;
            $scope.annualConsumptionDetailChart.status = null;
            $scope.annualConsumptionDetailChart.chartDataType = ref.CHART_DATA_TYPES.KWH;
            $scope.annualConsumptionDetailChart.fetch = fetch;
            $scope.annualConsumptionDetailChart.beforeRender = beforeRender;

            // directive method
            function fetch(){
                $scope.annualConsumptionDetailChart.status = STATUS_PENDING;
                logger.debug('annualConsumptionChartDirective', 'fetch started');
                var params = {
                    isWebView: $scope.webView.isWebView
                };
                $http.get("/chart/getAnnualConsumptionDetailedCharts.json", {params:params})
                    .then(function onSuccess(response) {
                        var annualConsumptionCharts = response.data;
                        logger.debug('annualConsumptionDetailChartDirective', 'fetch done');
                        $scope.annualConsumptionDetailChart.status = STATUS_OK;
                        $scope.annualConsumptionDetailChart.charts = annualConsumptionCharts;
                        logger.debug('annualConsumptionDetailChartDirective', 'broadcast event', 'annualConsumptionChartsHasChanged');
                        $scope.$broadcast('annualConsumptionDetailChartsHasChanged', annualConsumptionCharts);
                        beforeRender();
                    })
                    .catch(function onError() {
                        logger.error('annualConsumptionDetailChartDirective', 'fetch fail');
                        $scope.annualConsumptionDetailChart.status = STATUS_ERROR;
                        $scope.annualConsumptionDetailChart.charts = null;
                        render( $i18next('annualConsumptionChart.errorLoadingChart') );
                    });

            }

            // decide which graph should be displayed
            function beforeRender(){
                if( !$scope.annualConsumptionDetailChart.charts ) return;
                if( $scope.annualConsumptionDetailChart.chartDataType.name == ref.CHART_DATA_TYPES.KWH.name || !$scope.annualConsumptionDetailChart.charts.euro ){
                    $scope.annualConsumptionDetailChart.chartDataType = ref.CHART_DATA_TYPES.KWH;
                    render(null, $scope.annualConsumptionDetailChart.charts.kwh);
                } else if( $scope.annualConsumptionDetailChart.chartDataType.name == ref.CHART_DATA_TYPES.EURO.name ) {
                    render(null, $scope.annualConsumptionDetailChart.charts.euro);
                }
            }

            function render( errorMessage, chart ) {
                var $annualConsumptionDetailChart = angular.element('.annualConsumptionDetailChart');
                if (errorMessage){
                    $annualConsumptionDetailChart.highcharts( config.emptyChart );
                    $annualConsumptionDetailChart.highcharts().showLoading( errorMessage );
                }
                else{
                    addToolTipFormaterToChart( chart );
                    $annualConsumptionDetailChart.highcharts( chart );
                }
            }

            $scope.annualConsumptionDetailChart.hasPendingStatus = function () {
                return $scope.annualConsumptionDetailChart.status === STATUS_PENDING;
            };

            $scope.annualConsumptionDetailChart.hasOkStatus = function () {
                return $scope.annualConsumptionDetailChart.status === STATUS_OK;
            };

            function addToolTipFormaterToChart(chart){
                const unit = $rootScope.getUnitFromChartType($scope.annualConsumptionDetailChart.chartDataType);
                if( chart.tooltip && chart.tooltip.formatter && tooltipFormatter.isKnownFormatter( chart.tooltip.formatter, unit ) ){
                    chart.tooltip.formatter = tooltipFormatter[ chart.tooltip.formatter ]( $scope.annualConsumptionDetailChart.chartDataType, unit )
                }
            }
        }]
    }
};
