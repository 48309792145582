const _ = require('underscore');

const fr = require('./adviceBox.fr.json');

module.exports = {
    getAdvicesKeys($q, currentMonthMoment, selectedSite) {
        const keys = _.keys(fr.smartAdvices);
        const result = [];
        for (let i = 0; i < 3; ++i) {
            const random = randomCurrentMonth(currentMonthMoment, selectedSite, keys.length, i);
            result.push('smartAdvices.' + keys[random]);
            keys.splice(random, 1);
        }
        return $q.resolve(result);
    },
};

function randomCurrentMonth(currentMonthMoment, selectedSite, max, i) {
    return (currentMonthMoment.month() + currentMonthMoment.year() + selectedSite) * (i + 1) % max;
};
