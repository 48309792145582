module.exports = function () {
    return {
        restrict: 'E',
        templateUrl: './newScheduler/customBox/modes/temperature-selector.html',
        scope: {
            appliance: '=',
        },
        controller: ['$scope', '$i18next',function($scope) {
            $scope.increaseTemperature = function() {
                const currentTemperature = $scope.appliance.setpointTemperatureInCelsius;
                $scope.appliance.setpointTemperatureInCelsius = Math.min(32, currentTemperature + 0.5);
            };

            $scope.decreaseTemperature = function() {
                const currentTemperature = $scope.appliance.setpointTemperatureInCelsius;
                $scope.appliance.setpointTemperatureInCelsius = Math.max(14, currentTemperature - 0.5);
            };
        }],
    };
};
