var logger = require('logger'),
    ref = require('enum'),
    config = require('config');

var dateFr = config.dateFormat.dateFr;

module.exports = function(){
    return {
        restrict: 'E',
        template : '<div class="monthlyConsumptionDetailChart" style="height: inherit"><loader></loader></div>',
        scope: {
            options: "="
        },
        link: function(scope){
            logger.debug('monthlyConsumptionDetailChart', 'link');

            scope.$on( 'selectedSiteHasChanged', function(event, userSiteId){
                logger.debug('monthlyConsumptionDetailChart', 'receiving event', 'selectedSiteHasChanged');
                scope.monthlyConsumptionDetailChart.fetch();
            });

            scope.monthlyConsumptionDetailChart.fetch();
        },
        controller: ['$scope', '$http', '$i18next', 'tooltipFormatter',
            function($scope, $http, $i18next, tooltipFormatter){

            var STATUS_PENDING = {},
                STATUS_ERROR = {},
                STATUS_OK = {};

            $scope.CHART_DATA_TYPES = ref.CHART_DATA_TYPES;

            // directive attributes
            $scope.monthlyConsumptionDetailChart = {};
            $scope.monthlyConsumptionDetailChart.charts = null;
            $scope.monthlyConsumptionDetailChart.status = null;
            $scope.monthlyConsumptionDetailChart.chartDataType = ref.CHART_DATA_TYPES.KWH;
            $scope.monthlyConsumptionDetailChart.fetch = fetch;

            // directive method
            function fetch(){
                $scope.monthlyConsumptionDetailChart.status = STATUS_PENDING;
                logger.debug('monthlyConsumptionDetailChart', 'fetch started');
                $http({
                    method: 'GET',
                    url: '/chart/getDetailConsumptionCharts.json',
                    params: {
                        startDate: $scope.options.startDate.format(dateFr),
                        endDate: $scope.options.endDate.format(dateFr),
                        isPdfMonthlyChart: true,
                        hasDepartmentSerie : true
                    }
                })
                    .then(function onSuccess(response) {
                        logger.debug('monthlyConsumptionDetailChartDirective', 'fetch done');
                        $scope.monthlyConsumptionDetailChart.status = STATUS_OK;
                        $scope.monthlyConsumptionDetailChart.charts = response.data;
                        var chart = getMonthlyReportPdfChart();
                        emitChart( chart );
                        //Sometimes chart.series exist but it's empty
                        if(chart && chart.series && chart.series.length > 0){
                            render(null, chart);
                        }
                        else{
                            render( $i18next("detailedConsumptionChart.errorNoConsumption") );
                        }
                    })
                    .catch(function onError() {
                        logger.error('monthlyConsumptionDetailChartDirective', 'fetch fail');
                        $scope.monthlyConsumptionDetailChart.status = STATUS_ERROR;
                        $scope.monthlyConsumptionDetailChart.charts = null;
                        render( $i18next('annualConsumptionChart.errorLoadingChart') );
                    });

            }


            function getMonthlyReportPdfChart(){
                var consumptionCharts = $scope.monthlyConsumptionDetailChart.charts['DETAIL'];
                return consumptionCharts[$scope.monthlyConsumptionDetailChart.chartDataType.name];
            }


            function render( errorMessage, chart ) {
                var $chart = angular.element('.monthlyConsumptionDetailChart');
                if (errorMessage){
                    $chart.highcharts( config.emptyChart );
                    $chart.highcharts().showLoading( errorMessage );
                }
                else{
                    addToolTipFormaterToChart(chart);
                    $chart.highcharts( chart );
                }
            }

            $scope.monthlyConsumptionDetailChart.hasPendingStatus = function () {
                return $scope.monthlyConsumptionDetailChart.status === STATUS_PENDING;
            };

            $scope.monthlyConsumptionDetailChart.hasOkStatus = function () {
                return $scope.monthlyConsumptionDetailChart.status === STATUS_OK;
            };

            function emitChart( chart ){
                $scope.$emit('onMonthlyDetailedChartChange', chart); 
            }

            function addToolTipFormaterToChart(chart){
                if( chart.tooltip && chart.tooltip.formatter && tooltipFormatter.isKnownFormatter( chart.tooltip.formatter ) ){
                    chart.tooltip.formatter = tooltipFormatter[ chart.tooltip.formatter ]( $scope.monthlyConsumptionDetailChart.chartDataType );
                }
            }
        }]
    }
};
