var moment = require('moment');
var _ = require("underscore");
var angular = require("angular");

module.exports = ['$timeout', function($timeout){
    return {
        restrict: 'E',
        scope: {
            scheduler: '=scheduler',
            isWebView: '=isWebView',
            divId: "=divId"
        },
        template: "<div id ='{{divId}}' class='schedulerChart'></div>",
        link: function(scope) {
            var barHeight = 20;
            var xAxisHeight = 50;
            var tickPositionList = [];

            for(var i = 0; i <= 24; i += 2){
                tickPositionList.push(moment().startOf("day").add(i,"h").valueOf());
            }
            var tickPositionListMobile = [];
            tickPositionListMobile.push(moment().startOf("day").valueOf());
            tickPositionListMobile.push(moment().startOf("day").add(6,"h").valueOf());
            tickPositionListMobile.push(moment().startOf("day").add(12,"h").valueOf());
            tickPositionListMobile.push(moment().startOf("day").add(18,"h").valueOf());
            tickPositionListMobile.push(moment().startOf("day").add(24,"h").valueOf());

            function formatSchedulerForHighcharts(scheduler){
                scheduler.data = _.sortBy(scheduler.data, "timeBegin");
                var currentScheduler = {};
                currentScheduler.id = scheduler.id;
                currentScheduler.name = scheduler.name;
                currentScheduler.categories = [];
                currentScheduler.colors = [];
                currentScheduler.data = [];
                var currentRow = 0;
                _.each(scheduler.data, function(timeSlot){
                    if(timeSlot.timeBegin !== null && timeSlot.timeEnd !== null && timeSlot.mode.id !== null) {
                        var timeSlotBegin = {
                            hours: parseInt(timeSlot.timeBegin.split(":")[0]),
                            minutes: parseInt(timeSlot.timeBegin.split(":")[1])
                        };
                        var timeSlotEnd = {
                            hours: parseInt(timeSlot.timeEnd.split(":")[0]),
                            minutes: parseInt(timeSlot.timeEnd.split(":")[1])
                        };
                        //si c'est la dernière plage horaire du programme, ajoute 24 heures à l'heure de fin pour correspondre au '00:00' du jour suivant
                        var dataSegment = {
                            x: moment().startOf("day").add(timeSlotBegin.hours, "hours").add(timeSlotBegin.minutes, "minutes").valueOf(),
                            x2: moment().startOf("day").add(timeSlotEnd.hours === 0 ? 24: timeSlotEnd.hours , "hours").add(timeSlotEnd.minutes, "minutes").valueOf(),
                            y: currentRow
                        };
                        currentScheduler.data.push(dataSegment);
                        currentScheduler.colors.push(timeSlot.mode.colorMode);
                        currentScheduler.categories.push(timeSlot.mode.labelMode);
                        ++currentRow;
                    }
                });
                return currentScheduler
            }

            function displaySchedulerChart() {
                $timeout(function () {
                    var formattedScheduler = formatSchedulerForHighcharts(scope.scheduler);
                    var element = angular.element("#" + scope.divId);
                    element.highcharts({
                        exporting: {
                            enabled: false
                        },
                        colors: formattedScheduler.colors,
                        chart: {
                            type: 'xrange',
                            height: formattedScheduler.data.length * barHeight + 45// hauteur xAxis, sans le 45 affichage tronqué
                        },
                        title: {
                            text: ''
                        },
                        xAxis: {
                            minRange: 24 * 3600 * 1000,
                            max: moment().startOf("day").add(24,"h").valueOf(),
                            type: 'datetime',
                            dateTimeLabelFormats: {
                                day: '%H %M'
                            },
                            offset: 0,
                            tickPositions: scope.isWebView ? tickPositionListMobile : tickPositionList,
                            labels: {
                                formatter: function () {
                                    return moment(this.value).format("HH:mm");
                                }
                            },
                            gridLineWidth: 1,
                            height: formattedScheduler.data.length * barHeight
                        },
                        yAxis: {
                            title: {
                                text: ''
                            },
                            categories: formattedScheduler.categories,
                            height: formattedScheduler.data.length * barHeight,
                            reversed: true
                        },
                        credits: {
                            enabled: false
                        },
                        tooltip: {
                            enabled: true, //!scope.isWebView,
                            formatter: function () {
                                return moment(this.point.x).format("HH:mm") + " - " + moment(this.point.x2).format("HH:mm");
                            }
                        },
                        plotOptions: {
                            series: {
                                animation: scope.scheduler.hasAnimation
                            }
                        },
                        series: [{
                            name: "Programme",
                            borderColor: 'none',
                            pointWidth: barHeight,
                            showInLegend: false,
                            data: formattedScheduler.data,
                            dataLabels: {
                                enabled: false,
                                formatter: function () {
                                    return moment(this.point.x).format("HH:mm") + " - " + moment(this.point.x2).format("HH:mm");
                                }
                            }
                        }]
                    });
                })
            }
            displaySchedulerChart();
        }
    };
}];
