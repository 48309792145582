/**
 * Describes the events that a user might trigger and that shall imply server-side logging.
 * See REF_TYPE_USER_EVENT table in database.
 * @type {{LOGOUT: number, CHANGE_PASSWORD_REQUEST: number, RECOVER_PASSWORD_REQUEST: number, CHART_REQUEST: number, LOGIN: number}}
 */
module.exports = {
    LOGIN: 1,
    LOGOUT: 2,
    CHART_REQUEST: 3,
    CHANGE_PASSWORD_REQUEST: 4,
    RECOVER_PASSWORD_REQUEST: 5
};