var $appScope = require('appScope'),
    ref = require('enum');

var displayState = {
    LOADING: 1,
    ERROR: 2,
    NO_CONTRACT: 3,
    FORM: 4,
    RESULT: 5,
    ANALYZING: 6,
    NO_SURFACE: 7,
    NOT_ENOUGH_DATA: 8,
    NO_WINTER_DATA: 9
};

var defaultPlanning = [2,2,2,2,2,2,2,2,2,2,2,2,2,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,2,2,2];

var REGULATED_CONTRACT_TYPE_ID = 1;

module.exports = ['$scope', '$http', 'flash', '$timeout', function ($scope, $http, flash, $timeout) {

    //*** Analyse Contrat ***
    $scope.optiContract = { form: {} };

    var subscriber = $appScope.get( 'user' );
    $scope.optiContract.subscriber = subscriber;
    $scope.optiContract.siteWithGlobal = subscriber.getSelectedSite().isSiteWithGlobalLink;
    var contract = subscriber.getSelectedSite().contract;
    $scope.optiContract.optionName = contract == null ? null : contract.optionName;
    $scope.optiContract.showResult = false;

    // *** TODO Contrats ***
    // TODO : Actualiser les données en cas de changement de site
    // TODO : Gérer les cas particuliers (contrats sans correspondance Base <-> HPHC)
    // TODO : Ajouter la gestion du planning
    // TODO : Faire les TODO du jade
    // TODO : Corriger problème d'affichage de message flash en cas d'erreur de requête successive.
    // TODO : Harmoniser les noms de variables pour les contrats
    // TODO : Charger les options de référence depuis la base
    // TODO : Internationalisation

    var cForm = $scope.optiContract.form;
    cForm.comparedSubscriptionPrice = null;
    cForm.comparedBasePowerPrice = null;
    cForm.comparedPeakPowerPrice = null;
    cForm.comparedOffpeakPowerPrice = null;
    cForm.comparedOptionId = null;


    $scope.optiContract.optionList = null;
    $scope.optiContract.refSubscriptionPrice = null;
    $scope.optiContract.refPowerBasePrice = null;
    $scope.optiContract.refPowerPeakPrice = null;
    $scope.optiContract.refPowerOffpeakPrice = null;
    $scope.optiContract.refOptionId = null;
    $scope.optiContract.refOption = null;
    $scope.optiContract.isAutoAnalyze = true;
    $scope.optiContract.result = {};
    $scope.optiContract.isRefContractRegulated = null;
    $scope.optiContract.display = displayState.LOADING;
    $scope.optiContract.form.comparedPlanning = defaultPlanning;

    $scope.optiContract.validateFields = function validateContractFields(){
        return $scope.optiContractForm.$valid;
    };

    $scope.optiContract.useHPHCForRefContract = function useHPHCForRefContract(){
        return $scope.optiContract.refOptionId === ref.CONTRACT_OPTION.HPHC;
    };

    $scope.optiContract.useHPHCForComparedContract = function useHPHCForComparedContract(){
        return $scope.optiContract.form.comparedOptionId === ref.CONTRACT_OPTION.HPHC;
    };

    $scope.optiContract.autoAnalyze = function showAutoAnalyze(){
        $scope.optiContract.isAutoAnalyze = true;
        $scope.optiContract.analyze();
    };

    $scope.optiContract.newCustomAnalyze = function newCustomAnalyze(){
        $scope.optiContract.isAutoAnalyze = false;
        $scope.optiContract.display = displayState.FORM;

    };

    $scope.optiContract.customAnalyze = function showCustomAnalyze(){
        var contract = $scope.optiContract.subscriber.getSelectedSite().contract;
        if( null == contract || 0 == contract.length ){
            $scope.optiContract.display = displayState.NO_CONTRACT;
            return;
        }

        $scope.optiContract.isAutoAnalyze = false;
        $scope.optiContract.refSubscriptionPrice = contract.subscriptionMonthlyFee;
        $scope.optiContract.refPowerBasePrice = contract.basePrice;
        $scope.optiContract.refPowerPeakPrice = contract.peakHourPrice;
        $scope.optiContract.refPowerOffpeakPrice = contract.offpeakHourPrice;
        $scope.optiContract.refOptionId = contract.optionId;

        // TODO : Récupérer depuis la base ou fichier de ref
        if( null == $scope.optiContract.optionList ){
            $scope.optiContract.optionList = [{ id: 1, description: "Base" }, {id: 2, description: "Heures pleines / Heures creuses"}];
            $scope.optiContract.form.comparedOptionId = 1;
        }

        $scope.optiContract.display = displayState.FORM;
    }

    $scope.optiContract.analyze = function analyzeContract(){
        $scope.optiContract.display = displayState.ANALYZING;

        var contract = $scope.optiContract.subscriber.getSelectedSite().contract;
        if( null == contract || 0 == contract.length ){
            $scope.optiContract.display = displayState.NO_CONTRACT;
            return;
        }
        $scope.optiContract.siteWithGlobal = subscriber.getSelectedSite().isSiteWithGlobalLink;
        $scope.optiContract.isRefContractRegulated = (contract.typeId == REGULATED_CONTRACT_TYPE_ID );

        /*if( !$scope.optiContract.validateFields() ){
            flash( 'alert-warning', 'Les valeurs saisies ne sont pas valides', 10000 );
            return;
        }*/

        $timeout(function(){
            $http({
                method: 'POST',
                url:'/computeConsumptionAnnualCosts',
                data:{
                    isAutoAnalyze: $scope.optiContract.isAutoAnalyze,
                    comparedOptionId: $scope.optiContract.form.comparedOptionId,
                    comparedSubscriptionPrice: $scope.optiContract.form.comparedSubscriptionPrice,
                    comparedBasePowerPrice: $scope.optiContract.form.comparedBasePowerPrice,
                    comparedPeakPowerPrice: $scope.optiContract.form.comparedPeakPowerPrice,
                    comparedOffpeakPowerPrice: $scope.optiContract.form.comparedOffpeakPowerPrice,
                    comparedPlanning: $scope.optiContract.form.comparedPlanning
                }
            })
                .then(function onSuccess(response) {
                    var data = response.data.data;
                    if( null != data && data.notEnoughData ){
                        $scope.optiContract.display = displayState.NOT_ENOUGH_DATA;
                        return;
                    }

                    $scope.optiContract.result.currentOption = data.currentOption;
                    $scope.optiContract.result.comparedOption = data.comparedOption;
                    $scope.optiContract.result.priceDiffEuros = data.priceDiffEuros;
                    $scope.optiContract.result.priceDiffPercent = data.priceDiffPercent;
                    $scope.optiContract.result.percentOfReceivedData = data.percentOfReceivedData;

                    $scope.optiContract.display = displayState.RESULT;
                } )
                .catch( function onError(response) {
                    $scope.optiContract.display = displayState.ERROR;
                })
        }, getGeneratedDelay())

    };

    $scope.optiContract.result.absolutePriceDiff = function(){
        if( null == $scope.optiContract.result.priceDiffEuros ){
            return null;
        }
        return Math.abs( $scope.optiContract.result.priceDiffEuros );
    };

    $scope.optiContract.result.absolutePriceDiffPercent = function(){
        if( null == $scope.optiContract.result.priceDiffPercent ){
            return null;
        }
        return Math.abs( $scope.optiContract.result.priceDiffPercent );
    };

    $scope.optiContract.result.currentContractIsMoreExpensive = function(){
        return $scope.optiContract.result.priceDiffEuros >= 0;
    };

    $scope.optiContract.resetResult = function resetContractResult(){
        $scope.optiContract.showResult = false;
    };

//    $scope.optiContract.result.displayIncompleteDataWarning = function(){
//        return $scope.optiContract.result.percentOfReceivedData < 0.90;
//    }

    $scope.optiContract.displayLoading = function displayLoading(){
        return $scope.optiContract.display == displayState.LOADING;
    };

    $scope.optiContract.displayError = function displayError(){
        return $scope.optiContract.display == displayState.ERROR;
    };

    $scope.optiContract.displayNoContract = function displayNoContract(){
        return $scope.optiContract.display == displayState.NO_CONTRACT;
    };

    $scope.optiContract.displayForm = function displayForm(){
        return $scope.optiContract.display == displayState.FORM;
    };

    $scope.optiContract.displayResult = function displayResult(){
        return $scope.optiContract.display == displayState.RESULT;
    };

    $scope.optiContract.displayAnalyzing = function displayAnalyzing(){
        return $scope.optiContract.display == displayState.ANALYZING;
    };

    $scope.optiContract.displayNotEnoughData = function displayNotEnoughData(){
        return $scope.optiContract.display == displayState.NOT_ENOUGH_DATA;
    };

    // *** TODO Puissance ***
    // TODO : Actualiser les données en cas de changement de site ?

    //*** Analyse Puissance ***
    $scope.optiPower = {};
    $scope.optiPower.display = displayState.LOADING;
    $scope.optiPower.availibilityDate = null;

    $scope.optiPower.result = {};
    $scope.optiPower.result.currentPowerIsExceeded = null; // currentPowerIsExceeded ca veux dire qu'il y a plusieurs type de conso au dessus de la puissance souscrite
    $scope.optiPower.result.lowerPowerIsExceeded = null; // lowerPowerIsExceeded si t'as jamais dépasser neuf est-ce que t'as dépasser 6
    $scope.optiPower.subscribedPower = null;

    $scope.optiPower.analyze = function analyzeSubscribedPower(){
        $scope.optiPower.display = displayState.ANALYZING;
        $scope.optiPower.availibilityDate = null;
        var contract = $scope.optiContract.subscriber.getSelectedSite().contract;
        if( null == contract || 0 == contract.length ){
            $scope.optiPower.display = displayState.NO_CONTRACT;
            return;
        }

        $scope.optiPower.subscribedPower = contract.subscribedPower;
        $scope.optiPower.siteWithGlobal = subscriber.getSelectedSite().isSiteWithGlobalLink;

        $timeout(function(){
            $http.get( 'getPowerAnalysis.json' )
                .then( function onSuccess( response ){
                    var data = response.data.data;
                    if( null != data && data.notEnoughData ){
                        $scope.optiPower.display = displayState.NOT_ENOUGH_DATA;
                        $scope.optiPower.availibilityDate = data.availibilityDate;
                        return;
                    }

                    $scope.optiPower.result.currentPowerIsExceeded = data.currentPowerIsExceeded;
                    $scope.optiPower.result.lowerPowerIsExceeded = data.lowerPowerIsExceeded;
                    $scope.optiPower.display = displayState.RESULT;
                    $scope.optiPower.result.pic = data.pic;
                })
                .catch( function onError(){
                    $scope.optiPower.display = displayState.ERROR;
                });
        }, getGeneratedDelay())
    };

    $scope.optiPower.displayLoading = function displayLoading(){
        return $scope.optiPower.display == displayState.LOADING;
    };

    $scope.optiPower.displayError = function displayError(){
        return $scope.optiPower.display == displayState.ERROR;
    };

    $scope.optiPower.displayNoContract = function displayNoContract(){
        return $scope.optiPower.display == displayState.NO_CONTRACT;
    };

    $scope.optiPower.displayResult = function displayResult(){
        return $scope.optiPower.display == displayState.RESULT;
    };

    $scope.optiPower.displayAnalyzing = function displayAnalyzing(){
        return $scope.optiPower.display == displayState.ANALYZING;
    };

    $scope.optiPower.displayNotEnoughData = function displayNotEnoughData(){
        return $scope.optiPower.display == displayState.NOT_ENOUGH_DATA;
    };

    $scope.optiPower.redirectToPicComsumptionDay = function redirectToPicComsumptionDay(){
        var startDate = moment( $scope.optiPower.result.pic.dateMeter ),
            endDate = startDate.clone().endOf('day') ;
        $appScope.set('detailConsumptionZoom', {
            startDate: startDate,
            endDate: endDate,
            chartDataType: ref.CHART_DATA_TYPES.KWH
        });
        $appScope.get('visit').page = 'detailConsumptionZoom';
    };

    // *** TODO DPE ***
    // TODO : Fusionner affichage échelle avec celle déjà existante

    $scope.optiDpe = {};
    $scope.optiDpe.result = {};
    $scope.optiDpe.result.class = null;
    $scope.optiDpe.display = displayState.LOADING;

    $scope.optiDpe.getSelectedClassForItem = function getSelectedClassForItem( lineClass ){
        if( lineClass == $scope.optiDpe.result.class )
            return 'selectedEtiquette';
        return '';
    };

    $scope.optiDpe.classname = function getDpeClassname(){
        return 'vDpe' + $scope.optiDpe.result.class;
    };

    $scope.optiDpe.computeDpe = function computeDpe(){
        $scope.optiDpe.display = displayState.ANALYZING;
        $scope.optiDpe.result.dpe = null;
        $scope.optiDpe.result.class = null

        // check logement
        var site = $scope.optiContract.subscriber.getSelectedSite();
        if( null == site.myHouse || null == site.myHouse.surface || 0 >= site.myHouse.surface ){
            $scope.optiDpe.display = displayState.NO_SURFACE;
            return;
        }

        $timeout(function(){
            $http.get( 'computeDpe.json' )
                .then( function onSuccess( response ){
                    var data = response.data.data;
                    if( null != data && data.notEnoughData ){
                        $scope.optiDpe.display = displayState.NOT_ENOUGH_DATA;
                        return;
                    }

                    $scope.optiDpe.result.dpe = data.dpe;
                    $scope.optiDpe.result.class = getDpeClass( $scope.optiDpe.result.dpe );
                    $scope.optiDpe.display = displayState.RESULT;

                })
                .catch( function onError(){
                    $scope.optiDpe.result.class = -1;
                    $scope.optiDpe.display = displayState.ERROR;
                });
        }, getGeneratedDelay());

    };

    $scope.optiDpe.displayLoading = function displayLoading(){
        return $scope.optiDpe.display == displayState.LOADING;
    };

    $scope.optiDpe.displayError = function displayError(){
        return $scope.optiDpe.display == displayState.ERROR;
    };

    $scope.optiDpe.displayNoSurface = function displayNoSurface(){
        return $scope.optiDpe.display == displayState.NO_SURFACE;
    };

    $scope.optiDpe.displayResult = function displayResult(){
        return $scope.optiDpe.display == displayState.RESULT;
    };

    $scope.optiDpe.displayAnalyzing = function displayAnalyzing(){
        return $scope.optiDpe.display == displayState.ANALYZING;
    };

    $scope.optiDpe.displayNotEnoughData = function displayNotEnoughData(){
        return $scope.optiDpe.display == displayState.NOT_ENOUGH_DATA;
    };

    // TODO : Move into tool class
    function getDpeClass( dpe ){
        if( null == dpe ){
            return null;
        }

        if( dpe <= 50 ){
            return 'A';
        } else if( dpe <= 90 ){
            return 'B';
        } else if( dpe <= 150 ){
            return 'C';
        } else if( dpe <= 230 ){
            return 'D';
        } else if( dpe <= 330 ){
            return 'E';
        } else if( dpe <= 450 ){
            return 'F';
        } else {
            return 'G';
        }
    }
}];

function getGeneratedDelay(){
    return Math.floor(Math.random() * 3000) + 1000;
}