var _ = require('underscore');

module.exports = ['$scope', 'mapTools', function ($scope, mapTools) {

    $scope.scrollTo = scrollTo;

    $scope.meteoOptions = {
        displayOnly: true,
        evo: -16,
        meteo: 18,
        usage: -34,
        unit: 'week'
    };

    $scope.mapWeekOptions = {
        useLegend: false,
        mapType: '7days',
        displayOnly: true,
        map: data.map.week
    };

    $scope.mapAnnualOptions = {
        useLegend: true,
        mapType: 'annual', // disable legend
        displayOnly: true,
        heatingConsumption: data.map.annual.heatingConsumption,
        map: data.map.annual.map
    };

    function scrollTo(id){
        var $anchorTarget = angular.element('#'+id);
        if ($anchorTarget.length) {
            angular.element(".anchorHighlight").removeClass("anchorHighlight");
            $anchorTarget.find('h3').addClass("anchorHighlight");
            var anchorOffsetY = $anchorTarget.offset().top;
            var headerHeight = angular.element(".headerVoltalis").height();
            var scroll = anchorOffsetY - headerHeight;
            $(document).scrollTop(scroll);
        }
    }
}];

var data = {
    map: {
        week:{
            "chart": {
                "backgroundColor": "transparent",
                "reflow": false
            },
            "credits": {
                "enabled": false
            },
            "exporting": {
                "enabled": false
            },
            "title": {
                "text": null
            },
            "tooltip": {
                "enabled": true,
                "pointFormat": "{point.name} : <b>{point.value:,.0f}</b> kWh<br/>"
            },
            "colorAxis": {
                "min": 0,
                "max": 400,
                "stops": [
                    [
                        0,
                        "#4BC223"
                    ],
                    [
                        0.33,
                        "#F2EF22"
                    ],
                    [
                        0.66,
                        "#D97707"
                    ],
                    [
                        0.8,
                        "#DE180D"
                    ],
                    [
                        1,
                        "#75100B"
                    ]
                ],
                "tickPositions": [
                    0,
                    194.0498333333332,
                    400
                ],
                "gridLineColor": "rgba(0, 0, 0, 0.8)",
                "minorGridLineColor": "rgba(0, 0, 0, 0)",
                "tickWidth": 0,
                "gridLineWidth": 3,
                "minorTickLength": 0,
                "minorTickPosition": "inside",
                "tickPosition": "outside"
            },
            "legend": {
                "enabled": false,
                "layout": "horizontal",
                "align": "center",
                "verticalAlign": "bottom",
                "floating": false,
                "x": 9,
                "y": 0
            },
            "series": [
                {
                    "data": [
                        {
                            "name": "Aube",
                            "value": 166.53599999999997,
                            "dptCode": "10",
                            "hc-key": "fr-g-ab"
                        },
                        {
                            "name": "Aude",
                            "value": 62.016000000000005,
                            "dptCode": "11",
                            "hc-key": "fr-k-ad"
                        },
                        {
                            "name": "Aveyron",
                            "value": 73.32,
                            "dptCode": "12",
                            "hc-key": "fr-n-av"
                        },
                        {
                            "name": "Bouches-du-Rhône",
                            "value": 77.976,
                            "dptCode": "13",
                            "hc-key": "fr-u-bd"
                        },
                        {
                            "name": "Calvados",
                            "value": 152.83200000000002,
                            "dptCode": "14",
                            "hc-key": "fr-p-cv"
                        },
                        {
                            "name": "Cantal",
                            "value": 133.10399999999998,
                            "dptCode": "15",
                            "hc-key": "fr-c-cl"
                        },
                        {
                            "name": "Charente",
                            "value": 131.352,
                            "dptCode": "16",
                            "hc-key": "fr-t-ct"
                        },
                        {
                            "name": "Charente-Maritime",
                            "value": 123.312,
                            "dptCode": "17",
                            "hc-key": "fr-t-cm"
                        },
                        {
                            "name": "Cher",
                            "value": 154.032,
                            "dptCode": "18",
                            "hc-key": "fr-f-ch"
                        },
                        {
                            "name": "Corrèze",
                            "value": 191.496,
                            "dptCode": "19",
                            "hc-key": "fr-l-cz"
                        },
                        {
                            "name": "Côtes-d'Armor",
                            "value": 122.016,
                            "dptCode": "22",
                            "hc-key": "fr-e-ca"
                        },
                        {
                            "name": "Dordogne",
                            "value": 0,
                            "dptCode": "24",
                            "hc-key": "fr-b-dd"
                        },
                        {
                            "name": "Drôme",
                            "value": 112.896,
                            "dptCode": "26",
                            "hc-key": "fr-v-dm"
                        },
                        {
                            "name": "Eure",
                            "value": 141.38400000000001,
                            "dptCode": "27",
                            "hc-key": "fr-q-eu"
                        },
                        {
                            "name": "Eure-et-Loir",
                            "value": 138.07199999999997,
                            "dptCode": "28",
                            "hc-key": "fr-f-el"
                        },
                        {
                            "name": "Finistère",
                            "value": 118.152,
                            "dptCode": "29",
                            "hc-key": "fr-e-fi"
                        },
                        {
                            "name": "Gard",
                            "value": 100.584,
                            "dptCode": "30",
                            "hc-key": "fr-k-ga"
                        },
                        {
                            "name": "Haute-Garonne",
                            "value": 116.064,
                            "dptCode": "31",
                            "hc-key": "fr-n-hg"
                        },
                        {
                            "name": "Gironde",
                            "value": 124.77599999999998,
                            "dptCode": "33",
                            "hc-key": "fr-b-gi"
                        },
                        {
                            "name": "Hérault",
                            "value": 87.408,
                            "dptCode": "34",
                            "hc-key": "fr-k-he"
                        },
                        {
                            "name": "Ille-et-Vilaine",
                            "value": 121.56,
                            "dptCode": "35",
                            "hc-key": "fr-e-iv"
                        },
                        {
                            "name": "Indre",
                            "value": 147.84,
                            "dptCode": "36",
                            "hc-key": "fr-f-in"
                        },
                        {
                            "name": "Indre-et-Loire",
                            "value": 157.128,
                            "dptCode": "37",
                            "hc-key": "fr-f-il"
                        },
                        {
                            "name": "Isère",
                            "value": 136.34400000000002,
                            "dptCode": "38",
                            "hc-key": "fr-v-is"
                        },
                        {
                            "name": "Jura",
                            "value": 335.112,
                            "dptCode": "39",
                            "hc-key": "fr-i-ju"
                        },
                        {
                            "name": "Landes",
                            "value": 17.880000000000003,
                            "dptCode": "40",
                            "hc-key": "fr-b-ld"
                        },
                        {
                            "name": "Loir-et-Cher",
                            "value": 115.344,
                            "dptCode": "41",
                            "hc-key": "fr-f-lc"
                        },
                        {
                            "name": "Loire",
                            "value": 132.672,
                            "dptCode": "42",
                            "hc-key": "fr-v-lr"
                        },
                        {
                            "name": "Loire-Atlantique",
                            "value": 98.56800000000001,
                            "dptCode": "44",
                            "hc-key": "fr-r-la"
                        },
                        {
                            "name": "Loiret",
                            "value": 139.776,
                            "dptCode": "45",
                            "hc-key": "fr-f-lt"
                        },
                        {
                            "name": "Lot",
                            "value": 96.16799999999999,
                            "dptCode": "46",
                            "hc-key": "fr-n-lo"
                        },
                        {
                            "name": "Lot-et-Garonne",
                            "value": 109.82400000000001,
                            "dptCode": "47",
                            "hc-key": "fr-b-lg"
                        },
                        {
                            "name": "Lozère",
                            "value": 145.656,
                            "dptCode": "48",
                            "hc-key": "fr-k-lz"
                        },
                        {
                            "name": "Maine-et-Loire",
                            "value": 122.712,
                            "dptCode": "49",
                            "hc-key": "fr-r-ml"
                        },
                        {
                            "name": "Manche",
                            "value": 89.97600000000001,
                            "dptCode": "50",
                            "hc-key": "fr-p-mh"
                        },
                        {
                            "name": "Marne",
                            "value": 153.48,
                            "dptCode": "51",
                            "hc-key": "fr-g-mr"
                        },
                        {
                            "name": "Haute-Marne",
                            "value": 1.512,
                            "dptCode": "52",
                            "hc-key": "fr-g-hm"
                        },
                        {
                            "name": "Mayenne",
                            "value": 161.61599999999999,
                            "dptCode": "53",
                            "hc-key": "fr-r-my"
                        },
                        {
                            "name": "Meurthe-et-Moselle",
                            "value": 128.16000000000003,
                            "dptCode": "54",
                            "hc-key": "fr-m-mm"
                        },
                        {
                            "name": "Meuse",
                            "value": 0,
                            "dptCode": "55",
                            "hc-key": "fr-m-ms"
                        },
                        {
                            "name": "Morbihan",
                            "value": 128.52,
                            "dptCode": "56",
                            "hc-key": "fr-e-mb"
                        },
                        {
                            "name": "Moselle",
                            "value": 180.336,
                            "dptCode": "57",
                            "hc-key": "fr-m-mo"
                        },
                        {
                            "name": "Nièvre",
                            "value": 161.544,
                            "dptCode": "58",
                            "hc-key": "fr-d-ni"
                        },
                        {
                            "name": "Nord",
                            "value": 129.84,
                            "dptCode": "59",
                            "hc-key": "fr-o-no"
                        },
                        {
                            "name": "Oise",
                            "value": 133.896,
                            "dptCode": "60",
                            "hc-key": "fr-s-oi"
                        },
                        {
                            "name": "Orne",
                            "value": 183.33599999999996,
                            "dptCode": "61",
                            "hc-key": "fr-p-or"
                        },
                        {
                            "name": "Pas-de-Calais",
                            "value": 120.648,
                            "dptCode": "62",
                            "hc-key": "fr-o-pc"
                        },
                        {
                            "name": "Puy-de-Dôme",
                            "value": 121.68,
                            "dptCode": "63",
                            "hc-key": "fr-c-pd"
                        },
                        {
                            "name": "Pyrénées-Atlantiques",
                            "value": 128.328,
                            "dptCode": "64",
                            "hc-key": "fr-b-pa"
                        },
                        {
                            "name": "Pyrénées-Orientales",
                            "value": 36.792,
                            "dptCode": "66",
                            "hc-key": "fr-k-po"
                        },
                        {
                            "name": "Bas-Rhin",
                            "value": 108.744,
                            "dptCode": "67",
                            "hc-key": "fr-a-br"
                        },
                        {
                            "name": "Haut-Rhin",
                            "value": 118.00799999999998,
                            "dptCode": "68",
                            "hc-key": "fr-a-hr"
                        },
                        {
                            "name": "Rhône",
                            "value": 144.312,
                            "dptCode": "69",
                            "hc-key": "fr-v-rh"
                        },
                        {
                            "name": "Haute-Saône",
                            "value": 334.41600000000005,
                            "dptCode": "70",
                            "hc-key": "fr-i-hn"
                        },
                        {
                            "name": "Saône-et-Loire",
                            "value": 135.312,
                            "dptCode": "71",
                            "hc-key": "fr-d-sl"
                        },
                        {
                            "name": "Sarthe",
                            "value": 84.096,
                            "dptCode": "72",
                            "hc-key": "fr-r-st"
                        },
                        {
                            "name": "Savoie",
                            "value": 145.704,
                            "dptCode": "73",
                            "hc-key": "fr-v-sv"
                        },
                        {
                            "name": "Haute-Savoie",
                            "value": 148.87199999999999,
                            "dptCode": "74",
                            "hc-key": "fr-v-hs"
                        },
                        {
                            "name": "Paris",
                            "value": 87.936,
                            "dptCode": "75",
                            "hc-key": "fr-j-vp"
                        },
                        {
                            "name": "Seine-Maritime",
                            "value": 128.496,
                            "dptCode": "76",
                            "hc-key": "fr-q-sm"
                        },
                        {
                            "name": "Seine-et-Marne",
                            "value": 117.33600000000001,
                            "dptCode": "77",
                            "hc-key": "fr-j-se"
                        },
                        {
                            "name": "Yvelines",
                            "value": 138.98399999999998,
                            "dptCode": "78",
                            "hc-key": "fr-j-yv"
                        },
                        {
                            "name": "Deux-Sèvres",
                            "value": 124.416,
                            "dptCode": "79",
                            "hc-key": "fr-t-ds"
                        },
                        {
                            "name": "Somme",
                            "value": 158.496,
                            "dptCode": "80",
                            "hc-key": "fr-s-so"
                        },
                        {
                            "name": "Tarn",
                            "value": 0,
                            "dptCode": "81",
                            "hc-key": "fr-n-ta"
                        },
                        {
                            "name": "Tarn-et-Garonne",
                            "value": 35.736000000000004,
                            "dptCode": "82",
                            "hc-key": "fr-n-tg"
                        },
                        {
                            "name": "Var",
                            "value": 108.144,
                            "dptCode": "83",
                            "hc-key": "fr-u-vr"
                        },
                        {
                            "name": "Vaucluse",
                            "value": 102.76799999999999,
                            "dptCode": "84",
                            "hc-key": "fr-u-vc"
                        },
                        {
                            "name": "Vendée",
                            "value": 52.32,
                            "dptCode": "85",
                            "hc-key": "fr-r-vd"
                        },
                        {
                            "name": "Vienne",
                            "value": 127.89600000000002,
                            "dptCode": "86",
                            "hc-key": "fr-t-vn"
                        },
                        {
                            "name": "Haute-Vienne",
                            "value": 172.10399999999998,
                            "dptCode": "87",
                            "hc-key": "fr-l-hv"
                        },
                        {
                            "name": "Vosges",
                            "value": 145.07999999999998,
                            "dptCode": "88",
                            "hc-key": "fr-m-vg"
                        },
                        {
                            "name": "Essonne",
                            "value": 105.504,
                            "dptCode": "91",
                            "hc-key": "fr-j-es"
                        },
                        {
                            "name": "Hauts-de-Seine",
                            "value": 113.088,
                            "dptCode": "92",
                            "hc-key": "fr-j-hd"
                        },
                        {
                            "name": "Seine-Saint-Denis",
                            "value": 99.096,
                            "dptCode": "93",
                            "hc-key": "fr-j-ss"
                        },
                        {
                            "name": "Val-de-Marne",
                            "value": 131.424,
                            "dptCode": "94",
                            "hc-key": "fr-j-vm"
                        },
                        {
                            "name": "Val-d'Oise",
                            "value": 142.224,
                            "dptCode": "95",
                            "hc-key": "fr-j-vo"
                        },
                        {
                            "name": "Ain",
                            "value": 137.784,
                            "dptCode": "01",
                            "hc-key": "fr-v-ai"
                        },
                        {
                            "name": "Aisne",
                            "value": 122.52,
                            "dptCode": "02",
                            "hc-key": "fr-s-as"
                        },
                        {
                            "name": "Allier",
                            "value": 210.23999999999998,
                            "dptCode": "03",
                            "hc-key": "fr-c-al"
                        },
                        {
                            "name": "Alpes-de-Haute-Provence",
                            "value": 0,
                            "dptCode": "04",
                            "hc-key": "fr-u-ap"
                        },
                        {
                            "name": "Hautes-Alpes",
                            "value": 1.68,
                            "dptCode": "05",
                            "hc-key": "fr-u-ha"
                        },
                        {
                            "name": "Alpes-Maritimes",
                            "value": 85.224,
                            "dptCode": "06",
                            "hc-key": "fr-u-am"
                        },
                        {
                            "name": "Ardèche",
                            "value": 60.623999999999995,
                            "dptCode": "07",
                            "hc-key": "fr-v-ah"
                        },
                        {
                            "name": "Ardennes",
                            "value": 0,
                            "dptCode": "08",
                            "hc-key": "fr-g-an"
                        }
                    ],
                    "joinBy": "hc-key",
                    "name": "Consommation moyenne par adhérent",
                    "dataLabels": {
                        "enabled": false,
                        "format": "{point.name}"
                    },
                    "borderColor": "#444444",
                    "nullColor": "#cccccc"
                }
            ]
        },
        annual: {
            "map": {
                "chart": {
                    "backgroundColor": "transparent",
                    "reflow": false
                },
                "credits": {
                    "enabled": false
                },
                "exporting": {
                    "enabled": false
                },
                "title": {
                    "text": null
                },
                "tooltip": {
                    "enabled": true,
                    "pointFormat": "{point.name} : <b>{point.value:,.0f}</b> kWh<br/>"
                },
                "colorAxis": {
                    "min": 0,
                    "max": 4000,
                    "stops": [
                        [
                            0,
                            "#4BC223"
                        ],
                        [
                            0.33,
                            "#F2EF22"
                        ],
                        [
                            0.66,
                            "#D97707"
                        ],
                        [
                            0.8,
                            "#DE180D"
                        ],
                        [
                            1,
                            "#75100B"
                        ]
                    ],
                    "tickPositions": [
                        0,
                        2708.154499999999,
                        4000
                    ],
                    "gridLineColor": "rgba(0, 0, 0, 0.8)",
                    "minorGridLineColor": "rgba(0, 0, 0, 0)",
                    "tickWidth": 0,
                    "gridLineWidth": 3,
                    "minorTickLength": 0,
                    "minorTickPosition": "inside",
                    "tickPosition": "outside"
                },
                "legend": {
                    "enabled": true,
                    "layout": "horizontal",
                    "align": "center",
                    "verticalAlign": "bottom",
                    "floating": false,
                    "x": 9,
                    "y": 0
                },
                "series": [
                    {
                        "data": [
                            {
                                "name": "Aube",
                                "value": 1815.192,
                                "dptCode": "10",
                                "hc-key": "fr-g-ab"
                            },
                            {
                                "name": "Aude",
                                "value": 741.089,
                                "dptCode": "11",
                                "hc-key": "fr-k-ad"
                            },
                            {
                                "name": "Aveyron",
                                "value": 1019.1199999999999,
                                "dptCode": "12",
                                "hc-key": "fr-n-av"
                            },
                            {
                                "name": "Bouches-du-Rhône",
                                "value": 670.749,
                                "dptCode": "13",
                                "hc-key": "fr-u-bd"
                            },
                            {
                                "name": "Cantal",
                                "value": 1416.81,
                                "dptCode": "15",
                                "hc-key": "fr-c-cl"
                            },
                            {
                                "name": "Charente",
                                "value": 1311.0700000000002,
                                "dptCode": "16",
                                "hc-key": "fr-t-ct"
                            },
                            {
                                "name": "Charente-Maritime",
                                "value": 1220.989,
                                "dptCode": "17",
                                "hc-key": "fr-t-cm"
                            },
                            {
                                "name": "Cher",
                                "value": 1632.221,
                                "dptCode": "18",
                                "hc-key": "fr-f-ch"
                            },
                            {
                                "name": "Corrèze",
                                "value": 2343.766,
                                "dptCode": "19",
                                "hc-key": "fr-l-cz"
                            },
                            {
                                "name": "Côtes-d'Armor",
                                "value": 1343.518,
                                "dptCode": "22",
                                "hc-key": "fr-e-ca"
                            },
                            {
                                "name": "Drôme",
                                "value": 1104.135,
                                "dptCode": "26",
                                "hc-key": "fr-v-dm"
                            },
                            {
                                "name": "Eure",
                                "value": 1610.7540000000001,
                                "dptCode": "27",
                                "hc-key": "fr-q-eu"
                            },
                            {
                                "name": "Eure-et-Loir",
                                "value": 1528.8790000000001,
                                "dptCode": "28",
                                "hc-key": "fr-f-el"
                            },
                            {
                                "name": "Finistère",
                                "value": 1259.78,
                                "dptCode": "29",
                                "hc-key": "fr-e-fi"
                            },
                            {
                                "name": "Gard",
                                "value": 981.456,
                                "dptCode": "30",
                                "hc-key": "fr-k-ga"
                            },
                            {
                                "name": "Haute-Garonne",
                                "value": 1191.767,
                                "dptCode": "31",
                                "hc-key": "fr-n-hg"
                            },
                            {
                                "name": "Gironde",
                                "value": 1252.4569999999999,
                                "dptCode": "33",
                                "hc-key": "fr-b-gi"
                            },
                            {
                                "name": "Hérault",
                                "value": 840.6410000000001,
                                "dptCode": "34",
                                "hc-key": "fr-k-he"
                            },
                            {
                                "name": "Ille-et-Vilaine",
                                "value": 1355.841,
                                "dptCode": "35",
                                "hc-key": "fr-e-iv"
                            },
                            {
                                "name": "Indre",
                                "value": 1605.091,
                                "dptCode": "36",
                                "hc-key": "fr-f-in"
                            },
                            {
                                "name": "Indre-et-Loire",
                                "value": 1787.348,
                                "dptCode": "37",
                                "hc-key": "fr-f-il"
                            },
                            {
                                "name": "Isère",
                                "value": 1291.926,
                                "dptCode": "38",
                                "hc-key": "fr-v-is"
                            },
                            {
                                "name": "Jura",
                                "value": 3657.258,
                                "dptCode": "39",
                                "hc-key": "fr-i-ju"
                            },
                            {
                                "name": "Landes",
                                "value": 369.77,
                                "dptCode": "40",
                                "hc-key": "fr-b-ld"
                            },
                            {
                                "name": "Loir-et-Cher",
                                "value": 1328.409,
                                "dptCode": "41",
                                "hc-key": "fr-f-lc"
                            },
                            {
                                "name": "Loire",
                                "value": 1484.103,
                                "dptCode": "42",
                                "hc-key": "fr-v-lr"
                            },
                            {
                                "name": "Loire-Atlantique",
                                "value": 1219.177,
                                "dptCode": "44",
                                "hc-key": "fr-r-la"
                            },
                            {
                                "name": "Loiret",
                                "value": 1497.592,
                                "dptCode": "45",
                                "hc-key": "fr-f-lt"
                            },
                            {
                                "name": "Lot",
                                "value": 874.415,
                                "dptCode": "46",
                                "hc-key": "fr-n-lo"
                            },
                            {
                                "name": "Lot-et-Garonne",
                                "value": 912.659,
                                "dptCode": "47",
                                "hc-key": "fr-b-lg"
                            },
                            {
                                "name": "Lozère",
                                "value": 1471.308,
                                "dptCode": "48",
                                "hc-key": "fr-k-lz"
                            },
                            {
                                "name": "Maine-et-Loire",
                                "value": 1445.9379999999999,
                                "dptCode": "49",
                                "hc-key": "fr-r-ml"
                            },
                            {
                                "name": "Manche",
                                "value": 913.113,
                                "dptCode": "50",
                                "hc-key": "fr-p-mh"
                            },
                            {
                                "name": "Marne",
                                "value": 1636.942,
                                "dptCode": "51",
                                "hc-key": "fr-g-mr"
                            },
                            {
                                "name": "Haute-Marne",
                                "value": 1861.866,
                                "dptCode": "52",
                                "hc-key": "fr-g-hm"
                            },
                            {
                                "name": "Mayenne",
                                "value": 1810.527,
                                "dptCode": "53",
                                "hc-key": "fr-r-my"
                            },
                            {
                                "name": "Meurthe-et-Moselle",
                                "value": 1353.3300000000002,
                                "dptCode": "54",
                                "hc-key": "fr-m-mm"
                            },
                            {
                                "name": "Morbihan",
                                "value": 1391.597,
                                "dptCode": "56",
                                "hc-key": "fr-e-mb"
                            },
                            {
                                "name": "Moselle",
                                "value": 1821.4370000000001,
                                "dptCode": "57",
                                "hc-key": "fr-m-mo"
                            },
                            {
                                "name": "Nièvre",
                                "value": 1692.886,
                                "dptCode": "58",
                                "hc-key": "fr-d-ni"
                            },
                            {
                                "name": "Nord",
                                "value": 1350.0330000000001,
                                "dptCode": "59",
                                "hc-key": "fr-o-no"
                            },
                            {
                                "name": "Oise",
                                "value": 1491.3300000000002,
                                "dptCode": "60",
                                "hc-key": "fr-s-oi"
                            },
                            {
                                "name": "Orne",
                                "value": 2111.517,
                                "dptCode": "61",
                                "hc-key": "fr-p-or"
                            },
                            {
                                "name": "Pas-de-Calais",
                                "value": 1258.291,
                                "dptCode": "62",
                                "hc-key": "fr-o-pc"
                            },
                            {
                                "name": "Puy-de-Dôme",
                                "value": 1359.387,
                                "dptCode": "63",
                                "hc-key": "fr-c-pd"
                            },
                            {
                                "name": "Pyrénées-Atlantiques",
                                "value": 1375.783,
                                "dptCode": "64",
                                "hc-key": "fr-b-pa"
                            },
                            {
                                "name": "Bas-Rhin",
                                "value": 1186.912,
                                "dptCode": "67",
                                "hc-key": "fr-a-br"
                            },
                            {
                                "name": "Rhône",
                                "value": 1414.084,
                                "dptCode": "69",
                                "hc-key": "fr-v-rh"
                            },
                            {
                                "name": "Haute-Saône",
                                "value": 3924.963,
                                "dptCode": "70",
                                "hc-key": "fr-i-hn"
                            },
                            {
                                "name": "Saône-et-Loire",
                                "value": 1541.737,
                                "dptCode": "71",
                                "hc-key": "fr-d-sl"
                            },
                            {
                                "name": "Sarthe",
                                "value": 986.246,
                                "dptCode": "72",
                                "hc-key": "fr-r-st"
                            },
                            {
                                "name": "Savoie",
                                "value": 1382.797,
                                "dptCode": "73",
                                "hc-key": "fr-v-sv"
                            },
                            {
                                "name": "Haute-Savoie",
                                "value": 1494.482,
                                "dptCode": "74",
                                "hc-key": "fr-v-hs"
                            },
                            {
                                "name": "Paris",
                                "value": 915.5229999999999,
                                "dptCode": "75",
                                "hc-key": "fr-j-vp"
                            },
                            {
                                "name": "Seine-Maritime",
                                "value": 1413.104,
                                "dptCode": "76",
                                "hc-key": "fr-q-sm"
                            },
                            {
                                "name": "Seine-et-Marne",
                                "value": 1259.844,
                                "dptCode": "77",
                                "hc-key": "fr-j-se"
                            },
                            {
                                "name": "Yvelines",
                                "value": 1515.7169999999999,
                                "dptCode": "78",
                                "hc-key": "fr-j-yv"
                            },
                            {
                                "name": "Deux-Sèvres",
                                "value": 1356.448,
                                "dptCode": "79",
                                "hc-key": "fr-t-ds"
                            },
                            {
                                "name": "Somme",
                                "value": 1678.424,
                                "dptCode": "80",
                                "hc-key": "fr-s-so"
                            },
                            {
                                "name": "Tarn-et-Garonne",
                                "value": 1688.1059999999998,
                                "dptCode": "82",
                                "hc-key": "fr-n-tg"
                            },
                            {
                                "name": "Var",
                                "value": 966.4939999999999,
                                "dptCode": "83",
                                "hc-key": "fr-u-vr"
                            },
                            {
                                "name": "Vaucluse",
                                "value": 885.251,
                                "dptCode": "84",
                                "hc-key": "fr-u-vc"
                            },
                            {
                                "name": "Vendée",
                                "value": 510.994,
                                "dptCode": "85",
                                "hc-key": "fr-r-vd"
                            },
                            {
                                "name": "Vienne",
                                "value": 1338.87,
                                "dptCode": "86",
                                "hc-key": "fr-t-vn"
                            },
                            {
                                "name": "Vosges",
                                "value": 1561.404,
                                "dptCode": "88",
                                "hc-key": "fr-m-vg"
                            },
                            {
                                "name": "Essonne",
                                "value": 1109.612,
                                "dptCode": "91",
                                "hc-key": "fr-j-es"
                            },
                            {
                                "name": "Hauts-de-Seine",
                                "value": 1189.335,
                                "dptCode": "92",
                                "hc-key": "fr-j-hd"
                            },
                            {
                                "name": "Seine-Saint-Denis",
                                "value": 1093.055,
                                "dptCode": "93",
                                "hc-key": "fr-j-ss"
                            },
                            {
                                "name": "Val-de-Marne",
                                "value": 1365.63,
                                "dptCode": "94",
                                "hc-key": "fr-j-vm"
                            },
                            {
                                "name": "Val-d'Oise",
                                "value": 1499.6499999999999,
                                "dptCode": "95",
                                "hc-key": "fr-j-vo"
                            }
                        ],
                        "joinBy": "hc-key",
                        "name": "Consommation moyenne par adhérent",
                        "dataLabels": {
                            "enabled": false,
                            "format": "{point.name}"
                        },
                        "borderColor": "#444444",
                        "nullColor": "#cccccc"
                    }
                ]
            },
            "heatingConsumption": 2708.154499999999
        }
    }
};
