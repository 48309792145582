var _ = require('underscore');
var conf = require("config");

var cookieName = 'trackingEnabled';

var tracker = _.noop;
if (isTracking()) {
    tracker = initGA();
}

function shouldAsk() {
    return $.cookie(cookieName) == null;
}

function isTracking() {
    var trackingEnabledCookie = $.cookie(cookieName);
    if (trackingEnabledCookie == null)
        return true;
    else
        return trackingEnabledCookie == "true" || trackingEnabledCookie == true;
}

function initGA() {
    if (!window.ga) {
        (function(i, s, o, g, r, a, m) {
            i['GoogleAnalyticsObject'] = r;
            i[r] = i[r] || function() {
                (i[r].q = i[r].q || []).push(arguments)
            }, i[r].l = 1 * new Date();
            a = s.createElement(o),
                m = s.getElementsByTagName('title')[0];
            a.async = 1;
            a.src = g;
            m.parentNode.insertBefore(a, m)
        })(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');
        ga('create', conf.gaId);
        ga('send', 'pageview');
        return ga;
    } else
        return window.ga;
}

function setTracking(shouldTrack) {
    $.cookie(cookieName, shouldTrack, { expires: 365, path: '/' });
    if (shouldTrack) {
        tracker = initGA();
    } else {
        tracker('send', 'event', 'tracking', 'disableTracking');
        tracker = _.noop;
        removeGACookie('__utma');
        removeGACookie('__utmb');
        removeGACookie('__utmz');
        removeGACookie('__utmv');
        removeGACookie('_ga');
    }
}

function removeGACookie(name) {
    $.removeCookie(name);
    $.removeCookie(name, { path: '/' });
    $.removeCookie(name, { domain: '.' + window.location.hostname });
    $.removeCookie(name, { path: '/', domain: '.' + window.location.hostname });
}

module.exports = {
    setTracking: setTracking,
    isTracking: isTracking,
    shouldAsk: shouldAsk,
    track: function() {
        tracker.apply(this, arguments);
    }
};