//old version of datePicker2 (before the commit 29d24f52e3e421458f9894dfc33676cfa189bb36)
//this version is meant to work with the comparaison tools on detail page
//the 2 versions of datePickers, don't return the data in the same format
//this difference prevents us to use only one datePicker for both pages

var _ = require('underscore');
var moment = require('moment');

module.exports = function () {
    return {
        restrict: 'E',
        template: '<input class="form-control">',
        scope: {
            ngModel: '='
        },
        controller: ['$scope', '$element', '$i18next', function link($scope, $element, $i18next) {

            var startDate = moment().startOf("day");
            if ($scope.ngModel) {
                startDate = $scope.ngModel.startOf('day');
            }

            $element.find('input').daterangepicker({
                format: 'DD/MM/YYYY',
                opens: 'left',
                locale:
                {
                    selection: $i18next("dateRangePicker.selection"),
                    daysOfWeek: $i18next("dateRangePicker.daysOfWeek"),
                },
                buttonClasses: 'btn btn-block',
                singleDatePicker: true
            }, function (start) {
                $scope.ngModel = start;
            });

            $element.find('input').val(startDate.format("DD/MM/YYYY"));
        }]
    }
};
