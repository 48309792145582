require('modal');

module.exports = function() {
    return {
        restrict: 'AE',
        scope: {
            modal: "="
        },
        link: function link(scope, element) {

            element.on('hide.bs.modal', function() {
                scope.modal = false;
            });

            scope.$watch('modal', function(newValue) {
                if( newValue ){
                    // This will remove extra modal backdrop
                    if( angular.element('.modal-backdrop').length > 0 ){
                        angular.element('.modal-backdrop').eq(0).remove();
                    }
                    element.modal('show')

                }
                else{
                    element.modal('hide');
                }
            });
        }
    };
};