'use strict';

const access = require("./const/access");
const appliance = require("./const/appliance");
const calendar = require("./const/calendar");
const chart = require("./const/chart");
const consumption = require("./const/consumption");
const contract = require("./const/contract");
const country = require("./const/country");
const programming = require("./const/programming");
const weather = require("./const/weather");
const myv_parameters = require("./const/myv_parameters");
const cookies = require("./const/cookies");
const userEvents = require("./const/user-events");
const colors = require("./const/colors");
const chartsDisplayModes = require("./const/chartsDisplayModes");
const error = require("./const/error");

module.exports = {
    MYV_PARAMETERS: myv_parameters.MYV_PARAMETERS,
    DURATION: chart.DURATION,
    DAY: calendar.DAY,
    MONTH: calendar.MONTH,
    METEO_TYPE: weather.METEO_TYPE,
    HOUR_TYPE: consumption.HOUR_TYPE,
    CONTRACT_OPTION: contract.CONTRACT_OPTION,
    APPLIANCE: appliance.APPLIANCE,
    CHART_DATA_TYPES: chart.CHART_DATA_TYPES,
    COUNTRY: country.COUNTRY,
    SPECIAL_DAY: calendar.SPECIAL_DAY,
    PROGRAMMING_MODE: programming.MODE,
    ABSENCE: programming.ABSENCE,
    PROGRAMMATION: programming.MAX,
    ACCESS: access.ACCESS,
    COOKIE: cookies,
    USER_EVENT: userEvents,
    COLORS: colors,
    CHARTS_DISPLAY_MODES: chartsDisplayModes,
    ERROR_INVALID_CHART: error.ERROR_INVALID_CHART
};
