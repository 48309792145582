const WIRE = {id: 17};

const MODES = {

    STOP: {
        order: 1,
        imageName: 'stop',
        dbId: 7,
        translationKey: 'off',
    },
    FROST_GUARD: {
        order: 2,
        dbId: 6,
        imageName: 'noFrost',
        translationKey: 'no-frost',
    },
    ECO: {
        order: 3,
        dbId: 1,
        imageName: 'eco',
        translationKey: 'eco',
    },
    ECOV: {
        order: 3,
        dbId: 8,
        imageName: 'eco',
        translationKey: 'eco',
    },
    ON: {
        order: 4,
        imageName: 'marche',
        dbId: 3,
        translationKey: 'on',
    },
    COMFORT: {
        order: 4,
        dbId: 3,
        imageName: 'comfort',
        translationKey: 'comfort',
        requiredModulatorTypeId: WIRE.id,
    },
    COMFORT1: {
        order: 5,
        dbId: 4,
        imageName: 'comfort-1',
        translationKey: 'comfort-1',
    },
    COMFORT2: {
        order: 6,
        dbId: 5,
        imageName: 'comfort-2',
        translationKey: 'comfort-2',
    },
    TEMPERATURE: {
        order: 7,
        imageName: 'temperature',
        dbId: 2,
        translationKey: 'temperature',
    },
};

function getModeById(databaseId, modulatorTypeId) {
    let modesAsObject = Object.values(MODES);
    const requiredForModulatorType = modesAsObject.find( mode => mode.requiredModulatorTypeId === modulatorTypeId && mode.dbId === databaseId);
    if (requiredForModulatorType) {
        return requiredForModulatorType;
    }
    return modesAsObject.find(mode => mode.dbId === databaseId);
}

// depreacted
const RELAY = {id: 16};

// depreacted
const VX_MODULATOR_TYPE = [RELAY, WIRE];

// depreacted
function isVx(typeId) {
    const find = VX_MODULATOR_TYPE.find(type => type.id === typeId);
    return Boolean(find);
}

function vxType(typeId) {
    return VX_MODULATOR_TYPE.find(type => type.id === typeId);
}

// depreacted
function availableModes(typeId) {
    if (vxType(typeId) === WIRE) {
        return [MODES.STOP, MODES.FROST_GUARD, MODES.ECO, MODES.COMFORT,/*MODES.COMFORT1, MODES.COMFORT2,*/ MODES.TEMPERATURE];
    } else {
        return [MODES.STOP, MODES.ON, MODES.ECO];
    }
}


module.exports = {
    MODES: MODES,
    isVX: isVx,
    availableModes: availableModes,
    getModeById: getModeById,
};
