var _ = require('underscore'),
    moment = require('moment'),
    logger = require('logger'),
    config = require('config'),
    ref = require('enum'),
    $appScope = require('appScope');


var STATUS_PENDING = {},
    STATUS_ERROR = {},
    STATUS_OK = {};


module.exports = function () {
    return {
        restrict: 'E',
        scope: {
            options: "="
        },
        templateUrl : './dataComponents/dayNightConsumption/day-night-consumption.html',
        link: function (scope) {
            logger.debug('dayNightConsumptionDirective', 'link');

            scope.$on( 'selectedSiteHasChanged', function(event){
                logger.debug('dayNightConsumptionDirective', 'receiving event', 'selectedSiteHasChanged');
                if( scope.dayNightConsumption.status !== STATUS_PENDING )
                    scope.dayNightConsumption.fetch();
            });

            scope.dayNightConsumption.fetch();

        },
        controller: ['$scope', '$http', '$element', function ($scope, $http, $element) {

            $scope.Math = Math;

            // directive attributes
            $scope.dayNightConsumption = {};
            $scope.dayNightConsumption.data = null;
            $scope.dayNightConsumption.status = null;
            $scope.dayNightConsumption.fetch = fetch;
            $scope.dayNightConsumption.getPercent = getPercent;

            // directive method
            function fetch() {
                $scope.dayNightConsumption.status = STATUS_PENDING;
                logger.debug('dayNightConsumptionDirective', 'fetch started');
                $http({
                    method: 'GET',
                    url: '/siteData/getDayNightConsumption.json',
                    params: {
                        startDate: $scope.options.startDate.valueOf(),
                        endDate: $scope.options.endDate.valueOf()
                    }
                })
                    .then(function onSuccess(dayNightConsumption) {
                        logger.debug('dayNightConsumptionDirective', 'fetch done');
                        $scope.dayNightConsumption.status = STATUS_OK;
                        $scope.dayNightConsumption.data = dayNightConsumption.data;
                        render(null, dayNightConsumption.data);
                    })
                    .catch(function onError(response) {
                        logger.error('dayNightConsumptionDirective', 'fetch fail');
                        $scope.dayNightConsumption.status = STATUS_ERROR;
                        $scope.dayNightConsumption.data = null;
                        render(response.data.message);
                    });

            }

            function render(errorMessage, dayNightConsumption){
                if( errorMessage ){
                    $element.find('.errorMessage').html(errorMessage);
                }
                else{
                    // nothing atm
                }
            }



            function getPercent( dayOrNight ){
                return  $scope.dayNightConsumption.data[dayOrNight].percent + '%';
            }

            $scope.dayNightConsumption.hasPendingStatus = function () {
                return $scope.dayNightConsumption.status === STATUS_PENDING;
            };

            $scope.dayNightConsumption.hasOkStatus = function () {
                return $scope.dayNightConsumption.status === STATUS_OK;
            };

            $scope.dayNightConsumption.hasErrorStatus = function () {
                return $scope.dayNightConsumption.status === STATUS_OK;
            };

        }]
    }
};

