module.exports = function () {
    return {
        restrict: 'E',
        templateUrl: './components/confirmationPopup/confirmation-popup.html',
        scope: {
            divId: "=divId",
            okAction: "=okAction",
            okActionArg: "=okActionArg",
            confirmationText: "=confirmationText",
            titleText: "=titleText",
            errMessages: "=errMessages"
        }
    }
};