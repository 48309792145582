var _ = require('underscore');
var immediateConsumptionGaugeConfGlobal = require('../immediateConsumptionInkW/immediateConsumptionLinearGaugeConfiguration.json');
var immediateConsumptionGaugeConfItem = require('../immediateConsumptionInkWbyItem/immediateConsumptionLinearGaugeConfiguration.json');
var logger = require('logger');
var $appScope = require('appScope');
var Highcharts = require('highcharts');
var moment = require('moment');

module.exports = ['$scope', '$http', 'flash', '$element', '$i18next', function ($scope, $http, flash, $element, $i18next) {
    // directive attributes
    var STATUS_PENDING = {};
    var STATUS_OK = {};
    var STATUS_ERROR = {};
    var subscriber = $appScope.get('user');
    var chart = null;
    var isGlobalConsumption = (typeof $scope.csLinkId === 'undefined');
    $scope.immediateConsumptionInkW = {};
    $scope.immediateConsumptionInkW.status = null;
    $scope.immediateConsumptionInkW.visitMyContract = visitMyContract;
    // directive methods
    $scope.immediateConsumptionInkW.hasPendingStatus = function () {
        return $scope.immediateConsumptionInkW.status === STATUS_PENDING;
    };

    $scope.immediateConsumptionInkW.hasErrorStatus = function () {
        return $scope.immediateConsumptionInkW.status === STATUS_ERROR;
    };

    $scope.immediateConsumptionInkW.hasOkStatus = function () {
        return $scope.immediateConsumptionInkW.status === STATUS_OK;
    };

    //display loader until we got a consumption value from parent page
    $scope.immediateConsumptionInkW.status = STATUS_PENDING;

    $scope.$watch("[immediateConsumption, immediateConsumptionError]", function(){
        //no consumption = no data for this modulator (mostly for the power bar by appliance)

        if ($scope.immediateConsumption == null) { //don't use !$scope.immediateConsumption, 0 value will be catch otherwise
            $scope.immediateConsumptionError = $scope.noConsumptionCustomMessage ?
                $i18next($scope.noConsumptionCustomMessage) : $i18next('detailedConsumptionChart.errorNoConsumption');
            $scope.immediateConsumptionInkW.status = STATUS_ERROR;
            return;
        }

        $scope.immediateConsumptionError = null;

        if (!chart){
            render($scope.immediateConsumption);
        } else {
            chart.series[0].points[0].update($scope.immediateConsumption);
        }
        $scope.immediateConsumptionInkW.status = STATUS_OK; //stop loader and display value
    }, true);

    /**
     * Get max value of the power bar
     * @param cb callback {err, max} with max value as subscriber power if contract is filled, otherwise it's the consumption peak of the site
     */
    function getPowerBarMaxValue(cb){
        var defaultMax = 15;
        var contract = $appScope.get('user').getSelectedSite().contract;
        if(contract && contract.subscribedPower) {
            cb(null, contract.subscribedPower);
        }else{
            //if no contract filled, set power bar max as the consumption peak of the site
            var startDate = moment().startOf('month');
            startDate.subtract( 1, 'year' );

            var endDate = moment();
            endDate.subtract( 1, 'month').endOf( 'month' );

            $http({
                method: 'GET',
                url: '/siteData/getSiteMaxPower.json',
                params: {
                    startDate: startDate.valueOf(),
                    endDate: endDate.valueOf()
                }
            })
                .then(function onSuccess( response ) {
                    if(!$.trim(response.data)) {
                        cb(null, response.data.maxPower);
                    }else{
                        cb(null, defaultMax);
                    }
                })
                .catch(function onError( response ) {
                    logger.error(response);
                    cb(response, defaultMax); //just in case, there's no contract filled and no consumption peak
                });
        }
    }

    /**
     * Render a consumption in kW into the power bar
     * @param instantConsumptionInkW
     */
    function render(instantConsumptionInkW) {
        var contract = subscriber.getSelectedSite().contract;

        getPowerBarMaxValue(function(err, max){
            if(isGlobalConsumption){
                if (contract && contract.subscribedPower) {
                    max = contract.subscribedPower;
                }
            }

            var def = isGlobalConsumption ? immediateConsumptionGaugeConfGlobal : immediateConsumptionGaugeConfItem;
            _.extend(def.chart, {renderTo: $element.find('.immediateConsumptionBarContainer')[0]});
            _.extend(def.title, {text: $i18next("immediateConsumptionInkW.suscribedPower") + ': ' + max + ' kW'});
            _.extend(def.yAxis, {max: max});
            def.series[0].dataLabels.formatter = function () {
                //if the value is greater than the power bar max value,
                //display the raw value in data label and fix the bar at the end of the power bar
                let rawValue = this.point.y;
                if(rawValue > this.point.series.yAxis.max){
                    this.point.y = this.point.series.yAxis.max;
                }
                return rawValue.toFixed(2) + " kW";
            };
            chart = Highcharts.chart(def);
            chart.series[0].points[0].update(instantConsumptionInkW);
        });
    }

    function visitMyContract() {
        var user = $appScope.get('user');
        var visit = $appScope.get('visit');
        if (!user.hasContractForSelectedSite()) {
            visit.goTo("myContract");
        }
    }
}];
