/**
 * This directive allows to deactivate angular input validation
 * on an input field that has an ng-model binding.
 * Simply add input-no-validation as an attribute in the input tag.
 * @returns {Object} AngularJS directive description
 */
module.exports = function() {
    return {
        restrict: 'A',
        require: 'ng-model',
        link: function (scope, element, attr, ctrl) {
            if (ctrl) {
                ctrl.$validators = {}
            }
        }
    }
};