const logger = require('logger');
const $appScope = require('appScope');

const classTools = require('tools/classTools');
module.exports = function(){
    return {
        restrict: 'E',
        templateUrl : './dataComponents/meteoEffect/meteo-effect.html',
        scope: {
            options: "="
        },
        link: function(scope){
            logger.debug('meteoEffectDirective', 'link');
            scope.$on( 'selectedSiteHasChanged', scope.meteoEffect.fetch);

            // si une date de départ à été préciser, c'est juste de l'affichage, sinon on récupére l'effet meteo
            if( !scope.options.displayOnly ){
                scope.meteoEffect.fetch();
            }
            else{
                scope.meteoEffect.render(null, scope.options);
            }

        },
        controller: ['$scope', '$http', 'flash', '$i18next',
            function($scope, $http, flash, $i18next){
            // directive attributes

            var STATUS_PENDING = {};
            var STATUS_OK = {};
            var STATUS_ERROR = {};
	        var subscriber = $appScope.get('user'),
		    selectedSite = subscriber.getSelectedSite(),
            department = selectedSite.department;
            $scope.Math = Math;

            $scope.meteoEffect = {};
            $scope.meteoEffect.status = null;
            $scope.meteoEffect.data = null;
            $scope.meteoEffect.headerText = null;
            $scope.meteoEffect.fetch = fetch;
            $scope.meteoEffect.render = render;
            $scope.meteoEffect.getEvolution = getEvolution;
            $scope.meteoEffect.getSign = getSign;

            initialize();

            function initialize(){
                if( $scope.options.unit === "week" ){
                    $scope.meteoEffect.headerText = $i18next('meteoEffect.overTheLast7Days');
                }
                else if($scope.options.unit === "month"){
                    $scope.meteoEffect.headerText = $i18next('meteoEffect.overMonth');
                }
            }

            // directive method
            function fetch(){
                $scope.meteoEffect.status = STATUS_PENDING;
                $http({
                    method: 'GET',
                    url: '/meteo/getMeteoEffect.json',
                    params: {
                        startDate: $scope.options.startDate.valueOf(),
                        endDate: $scope.options.endDate.valueOf(),
                        unit: $scope.options.unit
                    }
                })
                    .then(function onSuccess(response) {
                        render( null, response.data );
                    })
                    .catch(function onError( response ) {
                        render( response.data.message );
                    });

            }

            function render( errorMessage, data ){
                if (errorMessage){
                    flash('alert-danger', errorMessage);
                    $scope.meteoEffect.status = STATUS_ERROR;
                    return;
                }
                logger.debug('meteoEffectDirective', 'emit event', 'meteoEffectHasChanged');
                $scope.$emit('meteoEffectHasChanged', data);
                $scope.meteoEffect.data = data;
                $scope.meteoEffect.status = STATUS_OK;
            }

            function getEvolution(){
                return classTools.getEvolution(this.data.usage + this.data.meteo);
            }

            function getSign(value, color) {
                var returnClass = '';
                if (value > 0)
                    returnClass += 'fa-plus';
                else if (value < 0)
                    returnClass += 'fa-minus';
                if (color != false) {
                    if (value > 0)
                        returnClass += ' v-fa-danger';
                    else if (value < 0)
                        returnClass += ' v-fa-success';
                }
                return returnClass
            }

            $scope.meteoEffect.hasPendingStatus = function () {
                return $scope.meteoEffect.status === STATUS_PENDING;
            };

            $scope.meteoEffect.hasErrorStatus = function () {
                return $scope.meteoEffect.status === STATUS_ERROR;
            };

            $scope.meteoEffect.hasOkStatus = function () {
                return $scope.meteoEffect.status === STATUS_OK;
            };
        }]
    }
};
