const _ = require('underscore');

module.exports = function () {
    return {
        restrict: 'E',
        scope: {
            selected: '&',
            latest: '&',
            move: '&',
        },
        templateUrl: './dataComponents/yearProgress/year-progress.html',
        controller: ['$scope', function ($scope) {
            const selectedMonth = $scope.selected();
            const latestMonth = $scope.latest();

            $scope.current = $scope.selected().format('MMMM YYYY');
            $scope.before = $scope.selected().clone().subtract(1, 'month');
            if (selectedMonth.isBefore(latestMonth)) {
                $scope.after = $scope.selected().clone().add(1, 'month');
                $scope.nextYear = () => {
                    const startDate = (latestMonth.isSame(selectedMonth, 'year') ? latestMonth : selectedMonth).clone().endOf('year').add(1, 'month');
                    $scope.move()(startDate);
                };
            }

            $scope.previousYear = () => {
                const startDate = (latestMonth.isSame(selectedMonth, 'year') ? latestMonth : selectedMonth).clone().startOf('year').subtract(1, 'month');
                $scope.move()(startDate);
            };

            $scope.getProgress = function () {
                const progress = [];
                const selectedMonth = $scope.selected();
                const latestMonth = $scope.latest();
                let startDate = (latestMonth.isSame(selectedMonth, 'year') ? latestMonth : selectedMonth).clone().startOf('year');

                for (let i = 0; i < 12; i++) {
                    const title = startDate.format('MMMM');
                    const currentMonth = startDate.clone();

                    const isFuture = startDate.isAfter(latestMonth);

                    progress.push({
                        title: title,
                        value: '',
                        click: !isFuture ? () => $scope.move()(currentMonth) : undefined,
                        args: null,
                        class: {
                            past: !isFuture,
                            current: startDate.isSame(selectedMonth),
                            future: isFuture,
                        },
                        icon: !isFuture ? 'fa-check' : '',
                        score: 100,
                    });

                    startDate.add(1, 'month');
                }

                return progress;
            };

            $scope.getProgressBarNowWidth = function (data) {
                const pastAndSameNb = Math.max(_.reduce(data, function (acc, item) {
                    return !item.class.future ? acc + 1 : acc;
                }, -1), 0);

                const oneStep = 1 / data.length;
                return Math.min(0.5 * oneStep + pastAndSameNb / data.length, 1) * 100 + '%';
            };
        }],
    };
};
