/**
 * Dropdown menu for language selection.
 * Include in HTML with the 'language-selector' element.
 * To add a new language, see lang.js and fill 'fallbackLng' and 'languageToCountryMap'.
 */

const lang = require('../../lang');

module.exports = function() {
    return {
        restrict: 'E',
        templateUrl : './components/languageSelector/languageSelector.html',
        controller: ['$scope', '$i18next', function($scope, $i18next) {
            $scope.languageCodeList = lang.config.fallbackLng;
            $scope.getCountryFlag = (lng) => {
                const countryCode = lang.languageToCountryMap[lng];
                return ['flag-icon', `flag-icon-${countryCode}`];
            };
            $scope.setLanguage = (lng) => {
                $i18next.options.lng = lng;
            };
        }]};
};