const _ = require('underscore');
const logger = require('logger');
const ref = require('enum');
const moment = require('moment');

const classTools = require('tools/classTools');
const SmartAdvices = require('../dataComponents/adviceBox/smartAdvices');

module.exports = ['$scope', '$http', '$q', '$routeParams', '$route', '$i18next', 'flash', function ($scope, $http, $q, $routeParams, $route, $i18next, flash) {
    $scope.CHART_DATA_TYPES = ref.CHART_DATA_TYPES;

    $scope.myReports = {};

    $scope.myReports.promises = {};
    $scope.myReports.deferred = {};

    $scope.$on('$destroy', () => {
        _.forEach($scope.myReports.promises, (promise) => promise.isPending() && promise.cancel());
    });

    // currentMonth initialization (YYYY-MM)
    // récupération du premier mois ou les rapport son dispo (movinDate + 1)
    $scope.myReports.firstAvailableMonthMoment = moment().startOf('year').subtract(1,'year').startOf('month');
    $scope.myReports.latestMonthMoment = moment.utc().startOf('month').subtract(1, 'month');
    if (_.isString($routeParams.currentMonth) && $routeParams.currentMonth.match(/[0-9]{4}-[0-9]{2}/)) {
        $scope.myReports.currentMonthMoment = moment.utc($routeParams.currentMonth, 'YYYY-MM');
    }

    if (!$scope.myReports.currentMonthMoment || !$scope.myReports.currentMonthMoment.isValid() ||
        $scope.myReports.currentMonthMoment.isAfter(moment())) {
        $scope.myReports.currentMonthMoment = $scope.myReports.latestMonthMoment.clone();
    }
    $scope.myReports.endDateMoment = $scope.myReports.currentMonthMoment.clone()
        .add(1, 'month')
        .subtract(1, 'day');

    $scope.myReports.goTo = (momentMonth) => $scope.visit.goTo('myReports', { currentMonth: momentMonth.format('YYYY-MM') });

    // detailed charts data (report-consumption-chart, monthConsumption, avoidedCO2)
    $scope.myReports.chartDataType = ref.CHART_DATA_TYPES.KWH;

    $scope.myReports.monthConsumption = {};
    $scope.myReports.visibleConsumption = {};
    $scope.myReports.totalModulated = {};
    $scope.myReports.globalConsumption = null;

    // out of init due to initialization linked to reportConsumptionChart (which is not initialized explicitely)
    $scope.myReports.deferred.visibleConsumption = $q.defer();
    $scope.myReports.promises.visibleConsumption = $scope.myReports.deferred.visibleConsumption.promise;
    $scope.myReports.deferred.monthConsumption = $q.defer();
    $scope.myReports.promises.monthConsumption = $scope.myReports.deferred.monthConsumption.promise;

    $scope.myReports.consumptionChart = null;
    // create and update
    $scope.$on('reportConsumptionChartChanged', (event, reportConsumptionCharts) => {
        logger.debug('myReportController', 'consumptionChartChanged');
        $scope.myReports.consumptionChart = reportConsumptionCharts;
        $scope.myReports.totalPowerConsumption = reportConsumptionCharts.globalKwh;
        const chartConsumption = calculateConsumptionFromCharts(reportConsumptionCharts);

        $scope.myReports.promises.monthConsumption.isPending() && $scope.myReports.deferred.monthConsumption.resolve();
        $scope.myReports.monthConsumption = chartConsumption.totalConsumption;
        $scope.myReports.promises.visibleConsumption.isPending() && $scope.myReports.deferred.visibleConsumption.resolve();
        $scope.myReports.visibleConsumption = chartConsumption.visibleConsumption;

        if ($scope.myReports.promises.previousConsumption.isFulfilled()) {
            $scope.updateConsumptionEvolution();
        }
    });

    $scope.$on('reportConsumptionChartError', (e) => {
        $scope.myReports.deferred.monthConsumption.reject(e);
        $scope.myReports.deferred.visibleConsumption.reject(e);
    });

    $scope.myReports.consumptionEvolutionClass = () => classTools.getEvolution($scope.myReports.consumptionEvolution);

    $scope.myReports.updateMyReportByMailOptin = function(newValue) {
        $scope.myReports.promises.updateMyReportByMailOptin = updateMyReportByMailOptin(newValue)
            .then(() => {
                $scope.myReports.myReportByMailOptin = newValue;
                flash('alert-success', $i18next('myReports.optinUpdateSuccess'), 5000);
            })
        ;
    };

    $scope.$on('reportConsumptionChartLegendClicked', (event, clickedId) => {
        $scope.changeSerieVisibility(clickedId);
        const chartConsumption = calculateConsumptionFromCharts($scope.myReports.consumptionChart);
        $scope.myReports.visibleConsumption = chartConsumption.visibleConsumption;
    });

    $scope.updateConsumptionEvolution = () => {
        const selectedMonthConsumption = $scope.myReports.monthConsumption.kwh;
        const previousMonthConsumption = $scope.myReports.previousMonthConsumption;
        if (previousMonthConsumption == null || previousMonthConsumption == 0) {
            $scope.myReports.totalPowerConsumptionDelta = 0;
            $scope.myReports.consumptionEvolution = null;
        }
        else {
            const consumptionEvolution = (selectedMonthConsumption - previousMonthConsumption) / previousMonthConsumption;
            const delta = Math.round(selectedMonthConsumption - previousMonthConsumption);
            $scope.myReports.totalPowerConsumptionDelta = delta > 0 ? `+${delta}` : delta.toString();
            $scope.myReports.consumptionEvolution = consumptionEvolution * 100;
        }
    };

    $scope.changeSerieVisibility = (serieId) => {
        ['kwh', 'euro'].forEach(type => {
            const selectedChart = $scope.myReports.consumptionChart[type];
            updateSerieVisibility(selectedChart.series, serieId);
        });
    };

    initialize();
    $scope.$on('selectedSiteHasChanged', () => {
        $route.reload();
    });

    function initialize() {
        logger.debug('myReportsController', 'init');
        $scope.myReports.promises.smartAdvices = loadAdvices()
            .then((res) => {
                $scope.myReports.smartAdvices = res;
            })
        ;

        $scope.myReports.promises.personalTips = loadPersonalTips({
            monthReport: $scope.myReports.endDateMoment.format('YYYY-MM-DD'),
            siteId: $scope.user.getSelectedSite().id,
        })
            .then((res) => {
                $scope.myReports.personalTips = res.data.personalAdvice;
            })
        ;

        if ($scope.user.hasAccessPermission(ref.ACCESS.WIDGET.BUDGET_NOTIFICATION_ALERT)) {
            $scope.myReports.promises.myReportByMailOptin = loadMyReportByMailOptin()
                .then((isReceivingReports) => {
                    // if no data, returns false
                    $scope.myReports.myReportByMailOptin = isReceivingReports;
                })
            ;
        }

        $scope.myReports.promises.totalModulatedPowerAndCO2 = getTotalModulatedPower($scope.myReports.currentMonthMoment, $scope.myReports.endDateMoment)
            .then(result => {
                $scope.myReports.totalModulated.power = result.totalModulatedPower;
                $scope.myReports.totalModulated.CO2Saved = result.CO2Saved;
                $scope.myReports.totalModulatedPowerDelta = $scope.myReports.totalModulated.power * 100 / 16;
                $scope.myReports.totalAvoidedCO2Delta = $scope.myReports.totalModulated.CO2Saved * 1000 / 130;
            })
        ;

        $scope.myReports.promises.previousConsumption = getPreviousTotalConsumption($scope.myReports.currentMonthMoment.clone().subtract(1, 'month'))
            .then((previousMonthConsumption) => {
                $scope.myReports.previousMonthConsumption = previousMonthConsumption;
            })
        ;

        $scope.myReports.promises.consumptionEvolution = $q.all([
            $scope.myReports.promises.previousConsumption,
            $scope.myReports.promises.monthConsumption,
        ])
            .then(() => {
                $scope.updateConsumptionEvolution();
            })
        ;
    }

    function updateSerieVisibility(series, serieId) {
        if (!series) {
            return;
        }
        const serie = _.find(series, serie => serie.id === serieId);
        if (serie) {
            serie.visible = !serie.visible;
        }
    }

    function loadPersonalTips(advicesParameters) {
        logger.debug('myReportController', 'loadPersonalTips', 'loading');
        return $http.get('/monthlyReports/personal-advices.json', { params: advicesParameters })
            .tap(() => logger.debug('myReportController', 'loadPersonalTips', 'fetch'))
            .tapCatch((e) => logger.error('myReportController', 'loadPersonalTips', 'in error', e))
        ;
    }

    function loadAdvices() {
        logger.debug('myReportController', 'loadAdvices', 'loading');
        return SmartAdvices.getAdvicesKeys($q, $scope.myReports.currentMonthMoment, $scope.user.getSelectedSite().id)
            .tap(() => logger.debug('myReportController', 'loadAdvices', 'fetch'))
            .tapCatch((e) => logger.error('myReportController', 'loadAdvices', 'in error', e))
        ;
    }

    function loadMyReportByMailOptin() {
        logger.debug('myReportController', 'loadMyReportByMailOptin', 'loading');

        return $http.get('/monthlyReports/getReceivingEmailOption.json')
            .then(response => response.data)
            .then(data => data && data.isReceivingReports) // returns false if no data
            .tap((data) => logger.debug('myReportController', 'loadMyReportByMailOptin', 'fetch'))
            .tapCatch((e) => logger.error('myReportController', 'loadMyReportByMailOptin', 'failed'))
        ;
    }

    function updateMyReportByMailOptin (newValue) {
        logger.debug('myReportController', 'updateMyReportByMailOptin', 'starting');
        return $http.post('/monthlyReports/updateReceivingEmailOption.json', {
            isReceivingReports: newValue,
        })
            .tap(() => logger.debug('myReportController', 'updateMyReportByMailOptin', 'updated'))
            .tapCatch((e) => logger.error('myReportController', 'updateMyReportByMailOptin', 'in error', e))
        ;
    }

    // todo: extract in service
    function getPreviousTotalConsumption(monthToRetrieve) {
        logger.debug('myReportController', 'getPreviousTotalConsumption', 'loading');
        const params = {
            isWebView: 'false', // todo find a way to use correct value
            monthToDisplay: monthToRetrieve.toISOString(),
        };
        return $http.get('/chart/getReportConsumptionCharts.json', { params })
            .then(response => response.data)
            .tap(() => logger.debug('myReportController', 'getPreviousTotalConsumption', 'fetch'))
            .then((chart) => {
                const previousConsumptionResult = chart.globalKwh;
                return !previousConsumptionResult ? 0 : previousConsumptionResult;
            })
            .tapCatch((e) => logger.error('myReportController', 'getPreviousTotalConsumption', 'in error', e))
        ;
    }

    // duplicated code. Extract into external service
    function getTotalModulatedPower(startDate, endDate) {
        logger.debug('myReportController', 'getTotalModulatedPower', 'loading');
        return $http({
            method: 'GET',
            url: '/siteData/getTotalModulatedPower.json',
            params: {
                startDate: startDate.valueOf(),
                endDate: endDate.valueOf(),
            },
        })
            .then(result => result.data)
            .tap(() => logger.debug('myReportsController', 'getTotalModulatedPower', 'fetch'))
            .tapCatch((e) => logger.error('myReportController', 'getTotalModulatedPower', 'in error', e))
        ;
    }

    // almost fully duplicated from mainBordController
    function sumSerieData(serie) {
        if (!serie || !serie.data) {
            return 0;
        }

        return serie.data.reduce((sum, point) => {
            return sum + (point.y || 0);
        }, 0);
    }

    function calculateConsumptionFromCharts(consumptionCharts) {
        const consumption = {
            totalProduction: {
                kwh: 0,
                euro: 0,
            },
            totalConsumption: {
                kwh: 0,
                euro: 0,
            },
            visibleConsumption: {
                kwh: 0,
                euro: 0,
            },
        };

        if (!consumptionCharts) {
            return consumption;
        }

        ['euro', 'kwh'].forEach(serieType => {
            if (!consumptionCharts[serieType]) {
                return;
            }
            const series = consumptionCharts[serieType].series;
            // todo: change serie.sevenDaysConsumption to serieType {consumption, production, temperature} instead of using name
            series.forEach(serie => {
                if (!serie.sevenDaysConsumption && !serie.sevenDaysCost) {
                    return;
                }
                const consumptionSum = sumSerieData(serie);
                consumption.totalConsumption[serieType] += consumptionSum;
                if (serie.visible) {
                    consumption.visibleConsumption[serieType] += consumptionSum;
                }
            });
        });
        return consumption;
    }
}];
