module.exports = function() {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: './dataComponents/myProfileGauge/my-profile-gauge.html',
        link: function(scope) {
            var percentage = scope.profileCompletionRate;
            var bracketHeight = 143;
            var totalAnimationTime = 2000;

            var leftAnimationTime = computeLeftAnimationTime(totalAnimationTime, percentage);
            var rightAnimationTime = totalAnimationTime - leftAnimationTime;
            var leftBracketFinalHeight = computeLeftBracketHeight(bracketHeight, percentage);
            var rightBracketFinalHeight = computeRightBracketHeight(bracketHeight, percentage);


            var profileGaugeLeftBracket = angular.element('.profileGaugeLeftBracket').eq(0);
            var profileGaugeRightBracket = angular.element('.profileGaugeRightBracket');

            profileGaugeLeftBracket.animate({height: leftBracketFinalHeight}, leftAnimationTime, 'linear', function() {
                profileGaugeRightBracket.animate({height: rightBracketFinalHeight}, rightAnimationTime, 'linear');
            });
        }
    }
};

function computeLeftBracketHeight(bracketHeight, percentage)
{
    if (percentage >= 50) {
        return bracketHeight;
    } else {
        return 2 * (percentage/100) * bracketHeight;
    }
}

function computeRightBracketHeight(bracketHeight, percentage)
{
    if (percentage <= 50) {
        return 0;
    } else {
        return ((percentage - 50)/100) * bracketHeight * 2;
    }
}

function computeLeftAnimationTime(totalTime, percentage)
{
    var leftAnimationTime;
    if (percentage <= 50) {
        leftAnimationTime =  totalTime;
    } else {
        leftAnimationTime = (50/percentage) * totalTime;
    }
    return leftAnimationTime;

    // Note : linear calculation, thus work better with linear easing for the animation.
    // Might create other funtion to fine-grain easing depending on percentages.
}
