module.exports = ['$compile', '$http', function($compile, $http) {
    return {
        restrict: "E",
        link: function(scope, element, attrs) {
            $http.get('/getCookies.html').then(function (result) {
                element.replaceWith($compile(result.data)(scope));
            });
        }
    };
}];
