var _ = require('underscore');
_.str = require('underscore.string');
var ModelError = require('./ModelError');


// Constructor
var Model = function(){
    try{
        new ModelError('This is an abstract class, please do not use it');
    }
    catch(err){
        console.error(err.message);
        console.error(err.stack);
    }
};

// Private Methods (please prefix with underscore, and never add them to prototype)
// ================================================================================

// Public Methods
// ==============

// Public method need to be included to prototype (otherwise they'll remain private)
_.extend(Model.prototype, {
    get: getAttribute,
    set: setAttribute,
    toJSON: toJSON
});

// Write public methods here

function getAttribute( attr ){
    return this.attributes[attr]
}

function setAttribute(key, val) {
    var attr, attrs;
    if (key == null) return this;

    // Handle both `"key", value` and `{key: value}` -style arguments.
    if (typeof key === 'object') {
        attrs = key;
    } else {
        (attrs = {})[key] = val;
    }

    for (attr in attrs) {
        this.attributes[attr] = attrs[attr];
    }

    return this;
}

function toJSON(){
    return _.clone(this.attributes);
}

module.exports = Model;