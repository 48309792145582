const logger = require('logger');

module.exports = ['$http', function($http) {
    return {
        sendEmail: sendEmail,
    };

    function sendEmail(email){
        return new Promise((resolve, reject) => {
            try {
                email.isValid(); // this will throw a model error if email isn't validated
            }
            catch (err) {
                logger.error('something went wrong with emailManager : ' + err);
                reject(err);
            }
            $http.post('/email', {mailMessage : email})
                .then(function onSuccess(response) {
                    resolve(response.data);
                })
                .catch((response) => {
                    reject(response.data);
                });
        });
    }
}];
