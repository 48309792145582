var appScope = require('appScope');

module.exports = ['$scope', '$http', 'flash', function($scope, $http, flash) {

    // directive attributes
    var STATUS_PENDING = {};
    var STATUS_OK = {};
    var STATUS_ERROR = {};

    $scope.privacy = {};
    $scope.privacy.status = null;
    $scope.privacy.content = null; // fetched html
    $scope.scrollTo = scrollTo;

    initialize();

    function initialize(){
        fetch();
    }

    function fetch(){
        // we keep that to keep the status maintain, the request is done twice, with the controller and directive.
        // TODO: find a way to update privacy.status in the directive
        $scope.privacy.status = STATUS_PENDING;
        $http.get("/getPrivacy.html")
            .then(function onSuccess( response ) {
                $scope.privacy.status = STATUS_OK;
            })
            .catch(function onError( response ) {
                $scope.privacy.status = STATUS_ERROR;
                flash( 'alert-danger', response.data.message, 5000 );
            });
    }

    /**
     * @description fix to angular issue #2070 (https://github.com/angular/angular.js/issues/2070)
     * @param id
     */
    function scrollTo(id){
        var $anchorTarget = angular.element('#'+id);
        if ($anchorTarget.length) {
            angular.element(".anchorHighlight").removeClass("anchorHighlight");
            $anchorTarget.addClass("anchorHighlight");
            var anchorOffsetY = $anchorTarget.offset().top;
            var headerHeight = angular.element(".headerVoltalis").height();
            var scroll = anchorOffsetY - headerHeight;
            $(document).scrollTop(scroll);
        }
    }

    $scope.privacy.hasPendingStatus = function () {
        return $scope.privacy.status === STATUS_PENDING;
    };

    $scope.privacy.hasOkStatus = function () {
        return $scope.privacy.status === STATUS_OK;
    };

    $scope.privacy.hasErrorStatus = function(){
        return $scope.privacy.status === STATUS_ERROR;
    }

}];

