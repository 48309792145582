var _ = require('underscore');
_.str = require('underscore.string');
var moment = require('moment');

module.exports = ['numberFormatter','$i18next', function( numberFormatter,$i18next ) {

    return {
        secondFormatter: function( dataType, unit ) {
            function formatter() {
                // Pour les points au pas seconde la période d'affichage est variable
                // Il faut la calculer depuis les données du graphe
                let step = null;
                if (!_.isEmpty(this.points)) {
                    if( this.points[0].series && !_.isEmpty(this.points[0].series.data)) {
                        step = (this.points[0].series.data[1].x - this.points[0].series.data[0].x ) / 1000
                    }
                }

                let dateTitle = '<b>' + _.str.capitalize(moment(this.x).format('dddd D MMMM YYYY')) + ' ' +
                    moment(this.x).format('HH:mm:ss');
                if(step != null ) {
                    dateTitle += ' - ' + moment(this.x + step * 1000).format('HH:mm:ss');
                }
                dateTitle += '</b><br/>';
                return dateTitle.concat( getTooltipContent( this, dataType, unit ) );
            }

            return formatter
        },
        minuteFormatter: function( dataType, unit ) {
            function formatter() {
                var dateTitle = '<b>' + _.str.capitalize(moment(this.x).format('dddd D MMMM YYYY')) + ' ' +
                        moment(this.x).format('HH:mm') + ' - ' + moment(this.x + 10 * 60 * 1000).format('HH:mm') +
                    '</b><br/>';
                return dateTitle.concat( getTooltipContent( this, dataType, unit ) );
            }

            return formatter
        },
        hourlyFormatter: function (dataType, unit) {
            function formatter() {
                var dateTitle = '<b>' + _.str.capitalize(moment(this.x).format('dddd D MMMM YYYY')) + ' ' +
                        moment(this.x).format('HH:mm') + ' - ' + moment(this.x + 3600 * 1000).format('HH:mm') +
                    '</b><br/>';
                return dateTitle.concat( getTooltipContent( this, dataType, unit ) );
            }

            return formatter
        },
        dailyFormatter: function (dataType, unit) {
            function formatter() {
                var dateTitle = '<b>' + _.str.capitalize(moment(this.x).format('dddd D MMMM YYYY')) + '</b><br/>';
                return dateTitle.concat( getTooltipContent( this, dataType, unit ) );
            }

            return formatter
        },
        monthlyFormatter: function (dataType, unit) {
            function formatter() {
                var dateTitle = '<b>' + _.str.capitalize(moment(this.x).format('MMMM YYYY')) + '</b>';
                return dateTitle.concat( getTooltipContent( this, dataType, unit ) );
            }

            return formatter
        },
        isKnownFormatter: function (formatter, unit) {
            if (_.isFunction(formatter))
                return false;
            var formatters = _.keys(this);
            var isFormatter = _.contains(formatters, formatter);
            if (isFormatter)
                return isFormatter;
            else {
                console.log(formatter + ' is an unknown tooltip formatter');
                return isFormatter
            }
        }
    };

    // Titre : souligné avec valeur et unités en gras
    function getGlobalLegend( text, value, unit ){
        var noBreakSpace = '\u00A0';
        var legend = '<br/><span style="color:#333; text-decoration:underline">' + text + '</span>' + noBreakSpace + ': ';
        legend += '<strong>' + value.toFixed( 2 ) + noBreakSpace + unit + '</strong><br/>';
        return legend;
    }

    // Point : Carré de couleur puis nom puis valeur et unités en gras
    function getPointLegend( color, text, value, unit ){
        var noBreakSpace = '\u00A0';
        var legend = '<br/><span style="font-weight: bold;color:' + color + '">\u25A0' + noBreakSpace + '</span>';
        legend += '<span style="color:#333">' + text + '</span>' + noBreakSpace + ': ';

        // si la voie à une conso négative
        // if( value < 0 ) value = 0; (doit être fais au niveau de la DATA coté serveur)
        // si la voie à une conso inférieur à 1, le préciser avec le signe

        var sign = "";
        if( value < 0.01 && value > 0 ){
            sign = " &lt; ";
            value = 0.01;
        }
        legend += '<strong>' + sign + value.toFixed( 2 ) + noBreakSpace + unit + '</strong><br/>';

        return legend;
    }

    // Le dataType est le type de graph (envoyé via la directive de la chart), soit kwh/watt/euro
    // pour différencier la donnée température, il faut se fié au stackName
    function getDataUnit( dataType, stackName, unit ){
        if( stackName == 'temperature' ){
            return '°C';
        }
        else if ( dataType.name == 'euro' ){
            return unit == null ? dataType.unit : unit;
        }
        return dataType.unit;
    }

    function getTotalTitleFromStackName( stackName ){
        var title = $i18next("myTooltipFormatter.wholeConso");
        if( stackName == 'offpeak' ){
            title = $i18next("myTooltipFormatter.offPeakHours");
        }
        if( stackName == 'peak' ){
            title = $i18next("myTooltipFormatter.peakHours");
        }
        if( stackName == 'temperature' ){   // useless ?
            title = 'Température';
        }
        return title;
    }

    function getTooltipContent( elt, dataType, unit ){
        const blankLine = '<span>\n</span><br/>'; // Hack (Highcharts n'accepte pas mon <br/><br/>)

        const total = {};
        const messages = {};
        const count = {};

        // Group legends by stack
        for( let i=0; i<elt.points.length; ++i ){
            const stack = elt.points[i].series.userOptions.stack;
            const unitToDisplay = getDataUnit( dataType, stack, unit );
            if( null == total[ stack ] ){   // new stack
                messages[ stack ] = '';
                var totalValue = null;
                if( null != elt.points[i].total ){
                    totalValue = elt.points[i].total;
                } else {
                    totalValue = elt.points[i].y;
                }

                total[ stack ] = getGlobalLegend( getTotalTitleFromStackName( stack ), totalValue, unitToDisplay );
                count[ stack ] = 0;
            }

            messages[ stack ] += getPointLegend( elt.points[i].series.color, elt.points[i].series.name, elt.points[i].y, unitToDisplay );
            count[ stack ]++;
        }

        var fullLegend = '';
        for( var key in total ){
            if( count[ key ] == 1 ){
                fullLegend += messages[ key ];  // Pas de 'titre' si un seul élément (ex température)
            } else {
                fullLegend += total[ key ] + messages[ key ];
            }
            fullLegend += blankLine;
        }
        return fullLegend;
    }
}];
