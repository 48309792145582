var _       = require('underscore'),
    querystring = require('querystring');
_.str       = require('underscore.string');

var ModelError = require('./ModelError'),
    Model      = require('./Model');

var ID_DEFAULT_DISPLAY_GROUP = 1;

var defaultOptions = {
    id: null,
    description: null,
    displayGroupLogo: null,
    displayGroupFavicon: null,
    imageList: [],
    isActive: null,
    isDefault: null,
    isOverwritingDefault: null,
    linksHtmlFile: null,
    mainHtmlFile: null,
    takesPrecedence: null
};

// Constructor
var DisplayGroup = function( attributes ){

    var attr = this.attributes = {};
    attributes = _mergeOptions( attributes );

    // Here you can write in constructor
    try{
        this.set( attributes );
    }

    // Do not touch this
    catch(err){
        console.error(err.message);
        console.error(err.stack);
    }
};

// Heritage
DisplayGroup.prototype = Object.create( Model.prototype );
// Add default model attributes into prototype
DisplayGroup.prototype.defaultOptions = defaultOptions;
DisplayGroup.prototype.ID_DEFAULT_DISPLAY_GROUP = ID_DEFAULT_DISPLAY_GROUP;

// Private Methods (please prefix with underscore, and never add them to prototype)
// ================================================================================


/**
 * @description merges default options and args provided to constructor
 * @param args
 * @returns {*}
 * @private
 */
function _mergeOptions(args){
    return _.defaults( args || {}, DisplayGroup.prototype.defaultOptions );
}

// Public Methods
// ==============

// Public method need to be included to prototype (otherwise they'll remain private)
_.extend(DisplayGroup.prototype, {

});

module.exports = DisplayGroup;