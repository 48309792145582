// TODO: faire le menage entre le debounce, le render et le reflow, c'est incompréhensible
var _ = require('underscore'),
    moment = require('moment');

var config = require('config'),
    logger = require('logger'),
    colors = require('enum').COLORS;

var dateFr = config.dateFormat.dateFr;

var STATUS_PENDING = {},
    STATUS_ERROR = {},
    STATUS_OK = {},
    DATE_FORMAT = "DD/MM/YYYY";

module.exports = function () {
    return {
        restrict: 'E',
        template: '<div class="detailedConsumptionChart" style="height: inherit"><loader></loader></div>',
        scope: {
            options: "=options",
            isWebView: "=isWebView"
        },
        link: function(scope){
            logger.debug('detailedConsumptionChart', 'link');

            scope.$on( 'selectedSiteHasChanged', function(event){
                logger.debug('detailedConsumptionChartDirective', 'receiving event', 'selectedSiteHasChanged');
                scope.$emit("detailedConsumptionUpdateRequested");
            });

            scope.$watch('options.period.startDate', function(){
                logger.debug('detailedConsumptionChartDirective', 'options', 'period.startDate', 'changed');
                var displayPeriod = scope.detailedConsumptionChart.getValidePeriod(scope.options.period.startDate, scope.options.period.endDate);

                if ( displayPeriod !=null && (!(displayPeriod.startDate.isSame(scope.options.period.startDate) )
                        || !(displayPeriod.endDate.isSame(scope.options.period.endDate) ) ) ){

                    scope.options.period.startDate = displayPeriod.startDate;
                    scope.options.period.endDate = displayPeriod.endDate;
                }
                scope.$emit("detailedConsumptionUpdateRequested");

            });

            scope.$watch('options.period.endDate', function(){
                var displayPeriod = scope.detailedConsumptionChart.getValidePeriod(scope.options.period.startDate, scope.options.period.endDate);
                if ( displayPeriod !=null && (!(displayPeriod.startDate.isSame(scope.options.period.startDate) )
                        || !(displayPeriod.endDate.isSame(scope.options.period.endDate) ) ) ){

                    scope.options.period.startDate = displayPeriod.startDate;
                    scope.options.period.endDate = displayPeriod.endDate;
                }
                scope.$emit("detailedConsumptionUpdateRequested");

            });

            scope.$watch('options.compareDate', function(){
                logger.debug('detailedConsumptionChartDirective', 'options', 'compareDate', 'changed');
                scope.$emit("detailedConsumptionUpdateRequested");

            });

            scope.$watch('options.isCompared', function(){
                logger.debug('detailedConsumptionChartDirective', 'options', 'isCompared', 'changed');
                scope.$emit("detailedConsumptionUpdateRequested");
            });

            scope.$watch('options.mode', function(){
                //it seems useless to do this if because we do the same thing in the reflow,
                //but if we don't do that at the load of the page it's "Aucune consomations relevée sur ce logement" which appears
                // instead of "Chargement en cours"
                logger.debug('detailedConsumptionChartDirective', 'options', 'mode', 'changed');
                var chart = scope.detailedConsumptionChart.getChartFromCurrentOptions();
                if(chart)
                    scope.detailedConsumptionChart.render(null, chart);
            });

            scope.$watch('options.chartDataType', function(){
                logger.debug('detailedConsumptionChartDirective', 'options', 'chartDataType', 'changed');
                scope.detailedConsumptionChart.reflow();
            });
            scope.$on("detailedConsumptionChartFetch", function(){
                scope.detailedConsumptionChart.fetch();
            });
        },
        controller: ['$rootScope', '$scope', '$element', '$http', '$i18next', 'tooltipFormatter',
            function ($rootScope, $scope, $element, $http, $i18next, tooltipFormatter) {
                let debounceRender = _.debounce(function( errorMessage, chart ) {
                    let $detailedConsumptionChart = angular.element('.detailedConsumptionChart');
                    if ( $detailedConsumptionChart == null )
                        return;
                    if (errorMessage){
                        $detailedConsumptionChart.highcharts( config.emptyChart );
                        $detailedConsumptionChart.highcharts().showLoading( errorMessage );
                    }
                    else{
                        addToolTipFormaterToChart( chart );
                        addStackFormaterToChart( chart );
                        setVisibilitySeries(chart);
                        $detailedConsumptionChart.highcharts( chart );
                    }
                    emitCharts( chart );
                }, 500).bind(this);

                $scope.detailedConsumptionChart = {};
                $scope.detailedConsumptionChart.status = null;
                $scope.detailedConsumptionChart.getChartFromCurrentOptions = getChartFromCurrentOptions;
                $scope.detailedConsumptionChart.getValidePeriod = getValidePeriod;
                $scope.detailedConsumptionChart.render = debounceRender;
                $scope.detailedConsumptionChart.data = null; // fetched data
                $scope.detailedConsumptionChart.reflow = reflow;

                $scope.detailedConsumptionChart.fetch = function (){
                    $scope.$emit("onLoadingChartDataChange", true);
                    $scope.detailedConsumptionChart.status = STATUS_PENDING;
                    logger.debug('detailedConsumptionChartDirective', 'fetch started');

                    debounceRender( $i18next("detailedConsumptionChart.loadingChart") );

                    $http({
                        method: 'GET',
                        url: '/chart/getDetailConsumptionCharts.json',
                        params: {
                            startDate: $scope.options.period.startDate.format(dateFr),
                            endDate: $scope.options.period.endDate.format(dateFr),
                            compStartDate: $scope.options.compareDate.format(dateFr),
                            useComparedMode: $scope.options.isCompared,
                            chartWithLegend: false,
                            isWebView: $scope.isWebView
                        }
                    })
                        .then(function onSuccess( response ) {
                            logger.debug('detailedConsumptionChartDirective', 'fetch done');
                            $scope.detailedConsumptionChart.data = response.data;
                            emitWattAvailibility( response.data );
                            let chart = getChartFromCurrentOptions();

                            if( chart )
                                debounceRender( null, chart );
                            else
                                debounceRender( $i18next("detailedConsumptionChart.errorNoConsumption") );

                            $scope.detailedConsumptionChart.status = STATUS_OK;
                            $scope.$emit("onLoadingChartDataChange", false);
                        })
                        .catch(function onError() {
                            logger.error('detailedConsumptionChartDirective', 'fetch fail');
                            debounceRender( $i18next('detailedConsumptionChart.errorLoadingChart') );
                            $scope.detailedConsumptionChart.status = STATUS_ERROR;
                            $scope.$emit("onLoadingChartDataChange", false);
                        });
                };

                /**
                 * return from fetched data chart coinciding with options
                 * @returns {*}
                 */
                function getChartFromCurrentOptions(){
                    try{
                        return $scope.detailedConsumptionChart.data[$scope.options.mode][$scope.options.chartDataType.name];
                    }
                    catch(e){
                        logger.warn('detailedConsumptionChartDirective', 'no data for selected site');
                        return null;
                    }
                }

                function emitCharts( chart ){
                    $scope.$emit('onDetailedChartsChange', chart); //chart from getChartFromCurrentOptions. Function called each times we call the function render()
                }

                function emitWattAvailibility( response ){
                    try{
                        if( response[$scope.options.mode]["watt"] ){
                            logger.debug('detailedConsumptionChartDirective', 'watt data');
                            $scope.$emit('detailedConsumptionChartHasWattChart', true);
                        }
                        else{
                            throw new Error('no watt');
                        }
                    }
                    catch(e){
                        $scope.$emit('detailedConsumptionChartHasWattChart', false);
                        logger.warn('detailedConsumptionChartDirective', 'no watt data');
                    }
                }

                // Intermediary function created to tested if there is data in the chart.
                // If there is data it displays the chart, if there isn't it display an error message
                function reflow(){
                    var chart = getChartFromCurrentOptions();
                    if(chart){
                        debounceRender( null, chart );
                    }
                    else{
                        debounceRender( $i18next("detailedConsumptionChart.errorNoConsumption") );
                    }
                }

                function setVisibilitySeries(chart){
                    if(chart){
                        _.each(chart.series, function(serie){
                            serie.visible = true;
                        });
                    }
                }

                /**
                 * add tooltip formatter (used by highcharts, can't be processed serverside)
                 * @param chart
                 * @returns {*}
                 */
                function addToolTipFormaterToChart(chart){
                    const unit = $rootScope.getUnitFromChartType($scope.options.chartDataType);
                    if(chart && chart.tooltip && chart.tooltip.formatter && tooltipFormatter.isKnownFormatter( chart.tooltip.formatter ) ){
                        chart.tooltip.formatter = tooltipFormatter[ chart.tooltip.formatter ]( $scope.options.chartDataType, unit )
                    }
                }


                // Adds HP/HC stack labels
                function addStackFormaterToChart( chart ){
                    if(chart){
                        var powerYAxis = chart.yAxis;
                        if( null == powerYAxis ){
                            return;
                        }

                        if(_.isArray( powerYAxis ) ){
                            powerYAxis = powerYAxis[0]; // only power series will have stack labels
                        }

                        if( powerYAxis.stackLabels && powerYAxis.stackLabels.enabled ) {
                            powerYAxis.stackLabels.formatter = function(){
                                var label = $i18next("detailedConsumptionChart.peakShortName");
                                var color = colors.PEAK.color;
                                if( this.stack == 'offpeak' ){
                                    label = $i18next("detailedConsumptionChart.offPeakShortName");
                                    color = colors.OFFPEAK.color;
                                }
                                return '<span style="color:' + color + ';">' + label + '</span>';
                            }
                        }
                    }
                }

                // todo: voir s'il est possible d'utiliser getDataSource dans siteData
                function getValidePeriod(startDateStr, endDateStr) {
                    var startDate = moment( startDateStr, DATE_FORMAT ),
                        endDate = moment( endDateStr, DATE_FORMAT ).endOf('day');

                    var duration = moment.duration( endDate.valueOf() - startDate.valueOf() );
                    var periods = {};
                    if( duration.asMonths() > 2 ){
                        periods.startDate = startDate.startOf( 'month' );
                        periods.endDate = endDate.endOf( 'month' );
                    } else{
                        periods.startDate = startDate;
                        periods.endDate = endDate;
                    }
                    return periods
                }
            }
        ]
    }
};
