var _ = require('underscore');

module.exports = function () {
    return {
        substractTimeZoneOffset: function (momentDate) {
            var zoneOffset = momentDate.zone();
            return momentDate.subtract(zoneOffset, 'minutes')
        }


    };
};
