module.exports = {
    COUNTRY: {
        // CONF_V3.REF_COUNTRY
        FRANCE: 1,
        ITALY: 2,
        BELGIUM: 3,
        FINLAND: 4,
        INDONESIA: 5,
        SLOVENIA: 6
    }
};