module.exports = {
    DURATION: {
        ONE_MINUTE_IN_MILLISECONDS: 60 * 1000,
        TEN_MINUTE_IN_MILLISECONDS: 10 * 60 * 1000
    },
    CHART_DATA_TYPES: {
        KWH: {
            name: 'kwh',
            unit: 'kWh'
        },
        EURO: {
            name: 'euro',
            unit: '€'
        },
        WATT: {
            name: 'watt',
            unit: 'W'
        }
    }
};