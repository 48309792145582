const moment = require('moment'),
    logger = require('logger'),
    config = require('config'),
    ref = require('enum'),
    _ = require('underscore'),
    $appScope = require('appScope');

module.exports = function(){
    return {
        restrict: 'E',
        template: '<div class="immediateConsumption24hChart" style="height: inherit"><loader></loader></div>',
        link: function(scope){
            logger.debug('immediateConsumption24hChart', 'link');

            scope.$on( 'selectedSiteHasChanged', function(event, userSiteId){
                logger.debug('immediateConsumption24hChartDirective', 'receiving event', 'selectedSiteHasChanged');
                scope.immediateConsumption24h.fetch(true);
            });

            scope.immediateConsumption24h.fetch(false);
        },
        controller: ['$scope', '$http', '$i18next', 'tooltipFormatter','$timeout',
            function($scope, $http, $i18next, tooltipFormatter, $timeout){

            var STATUS_PENDING = {},
                STATUS_ERROR = {},
                STATUS_OK = {};

            const subscriber = $appScope.get('user');
            const hasInstantPowerAccess = subscriber.hasAccessPermission(ref.ACCESS.WIDGET.DISPLAY_INSTANT_POWER);

            // directive attributes
            $scope.immediateConsumption24h = {};
            $scope.immediateConsumption24h.chart = null;
            $scope.immediateConsumption24h.status = null;
            $scope.immediateConsumption24h.fetch = fetch;
            $scope.immediateConsumption24h.render = render;
            $scope.immediateConsumption24h.updatePromise = null;

            $scope.$on("$destroy", function() {
                if( $scope.immediateConsumption24h.updatePromise != null ) {
                    $timeout.cancel($scope.immediateConsumption24h.updatePromise);
                }
            });


            /**
             * Fetch data for the 24h chart
             * The chart is render if there wasn't any chart before
             * Otherwise we just update the old one
             * @param isChartAlreadyRender {boolean}
             */
            function fetch(isChartAlreadyRender){
                if(!isChartAlreadyRender){
                    $scope.immediateConsumption24h.status = STATUS_PENDING;
                    render( $i18next("detailedConsumptionChart.loadingChart") );
                }

                let intervalDates = getIntervalDates(hasInstantPowerAccess);

                $http({
                    method: 'GET',
                    url: '/chart/getImmediateConsumptionCharts.json',
                    params: {
                        startDate: intervalDates.startDate,
                        endDate: intervalDates.endDate,
                        chartWithLegend: true,
                        withSubscriptionSerie: true,
                        isWebView: $scope.webView.isWebView
                    }
                })
                    .then(function onSuccess( response ) {
                        let chart = getChartFromDetailConsumptionChartsResponse( response.data );
                        let $chart = angular.element('.immediateConsumption24hChart').highcharts();
                        let refresh_period = ref.DURATION.ONE_MINUTE_IN_MILLISECONDS;
                        $scope.immediateConsumption24h.status = STATUS_OK;

                        if(isChartValidWithData(chart)) {
                            if(hasInstantPowerAccess) {
                                hideNonGlobalSeries(chart);
                            }
                            const period = chart.series[0].data[1].x - chart.series[0].data[0].x;
                            //get refresh period with instant power access without checking the period between data
                            if ( period < ref.DURATION.TEN_MINUTE_IN_MILLISECONDS || hasInstantPowerAccess) {
                                refresh_period = $scope.user.getRealTimeRefreshPeriod();
                            }

                            // fixed display ranged to 1 day. automatically set to last serie value on server side
                            chart.xAxis.minRange = 24*60*60*1000;
                            if(!isChartAlreadyRender){
                                render(null, chart);
                            }else{
                                updateChart($chart, chart, refresh_period);
                            }
                        }else{
                            //invalid chart
                            render( $i18next("immediateConsumption24hChart.errorNoConsumption") );
                        }

                        $scope.immediateConsumption24h.updatePromise = $timeout(function() {
                            fetch(true);
                        }, refresh_period );
                    })

                    .catch(function onError(err) {
                        logger.error(err);
                        if(!isChartAlreadyRender) {
                            $scope.immediateConsumption24h.status = STATUS_ERROR;
                            render($i18next('weekConsumptionChart.errorLoadingChart'));
                        }
                    });
            }

            /**
             * Update old chart with data of new chart
             * @param oldChart
             * @param newChart object as described in getChartFromDetailConsumptionChartsResponse()
             * @param refreshPeriod
             */
            function updateChart(oldChart, newChart, refreshPeriod){
                // old chart if valid
                if( oldChart && oldChart.series.length > 1 ) {

                    let old_refresh_period = ref.DURATION.ONE_MINUTE_IN_MILLISECONDS;
                    let old_period = oldChart.series[0].data[1].x - oldChart.series[0].data[0].x;
                    if( old_period < ref.DURATION.TEN_MINUTE_IN_MILLISECONDS) {
                        old_refresh_period = $scope.user.getRealTimeRefreshPeriod();
                    }
                    // new and old chart are similar
                    if (newChart.series.length === oldChart.series.length
                        && old_refresh_period === refreshPeriod) {
                        for (const index in newChart.series) {
                            oldChart.series[index].update({
                                data: newChart.series[index].data
                            }, true);
                        }
                        oldChart.xAxis[0].update(newChart.xAxis, true);
                        oldChart.yAxis[0].update(newChart.yAxis, true);
                    }
                    // new chart is different, force render
                    else {
                        render(null, newChart);
                    }
                }
                // old chart is not valid, force render
                else {
                    render(null, newChart);
                }
            }

            // decide which graph should be displayed
            function render( errorMessage, chart ){
                var $chart = angular.element('.immediateConsumption24hChart');
                if (errorMessage){
                    $chart.highcharts( config.emptyChart );
                    $chart.highcharts().showLoading( errorMessage );
                }
                else{
                    $chart.highcharts( chart );
                }
            }

            function getChartFromDetailConsumptionChartsResponse( res ){
                var charts = res["DETAIL"];
                var chart = charts && charts.watt;
                chart = addToolTipFormaterToChart(chart);
                return chart;
            }

            /**
             * add tooltip formatter (used by highcharts, can't be processed serverside)
             * @param chart
             * @returns {*}
             */
            function addToolTipFormaterToChart(chart){
                if( chart.tooltip && chart.tooltip.formatter && tooltipFormatter.isKnownFormatter( chart.tooltip.formatter ) ){
                    chart.tooltip.formatter = tooltipFormatter[ chart.tooltip.formatter ](ref.CHART_DATA_TYPES.WATT);
                }
                return chart;
            }

            $scope.immediateConsumption24h.hasPendingStatus = function () {
                return $scope.immediateConsumption24h.status === STATUS_PENDING;
            };

            $scope.immediateConsumption24h.hasOkStatus = function () {
                return $scope.immediateConsumption24h.status === STATUS_OK;
            };

            $scope.immediateConsumption24h.hasErrorStatus = function () {
                return $scope.immediateConsumption24h.status === STATUS_ERROR;
            };
        }]
    }
};

/**
 * Get start and end date boundaries for the chart
 * With instant power, its returns the last 5 minutes
 * Otherwise, it's the entire current day
 * @param hasInstantPowerAccess {boolean}
 * @returns {{startDate: string, endDate: string}}
 */
function getIntervalDates(hasInstantPowerAccess){
    let startDate = null;
    let endDate = null;

    if(hasInstantPowerAccess){
        startDate = moment().subtract(5, 'minutes').format("DD/MM/YYYY HH:mm:ss");
        endDate = moment().format("DD/MM/YYYY HH:mm:ss");
    }else {
        startDate = moment().startOf('day').format(config.dateFormat.dateFr);
        endDate = moment().startOf('day').format(config.dateFormat.dateFr);
    }

    return {startDate, endDate};
}

/**
 * Hide all the non-global series in a chart
 * @param chart
 */
function hideNonGlobalSeries(chart){
    for(let series of chart.series){
        if(!series.isGlobal){
            series.visible = false;
            series.showInLegend = false;
        }
    }
}

/**
 * Test if a chart is valid and if there's at least one serie (excluding the subscribed power) with at least 2 points
 * @param chart object from getChartFromDetailConsumptionChartsResponse()
 * @returns {boolean} true if it's a valid chart, false if it's a null chart or a chart without data
 */
function isChartValidWithData(chart){
    let valid = false;

    if(chart) {
        //extract the subscribed power series to only the data series
        let seriesWithoutSubscribedPower = _.reject(chart.series, function(serie){
            return serie.id === "subscribedPower";
        });

        for(let serie of seriesWithoutSubscribedPower){
            if(serie.data.length > 1){
                valid = true;
            }
        }
    }

    return valid;
}
