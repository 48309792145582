module.exports={
    "smartAdvices": {
        "advice1": "La température conseillée est 19°C dans le salon et la salle à manger et 16°C dans les chambres.",
        "advice2": "Lors de mon absence en journée, je laisse les volets et rideaux fermés pour conserver la chaleur et baisser le chauffage.",
        "advice3": "Je baisse le chauffage dans les pièces inoccupées.",
        "advice4": "J’utilise le programmateur disponible dans mon espace MyVoltalis",
        "advice5": "J'éteins la lumière et les appareils électriques (TV, Hi-fi, ordinateur...) lorsque je ne m'en sers pas",
        "advice6": "J'éteins les radiateurs quand les fenêtres sont ouvertes pour aérer.",
        "advice7": "J'éteins les appareils plutôt que de les laisser en veille. Leur consommation en veille peut représenter jusqu'à 10% de ma facture d'électricité hors-chauffage.",
        "advice8": "Je débranche les appareils électriques lorsque je ne les utilise pas : chargeur de portable, petit électroménager...",
        "advice9": "Je dépoussière les lampes et abat-jour pour une meilleure luminosité.",
        "advice10": "Je ne mets pas d'aliments encore chauds dans le congélateur ou le réfrigérateur.",
        "advice11": "Je dépoussière les grilles arrières de mon réfrigérateur et de mon congélateur",
        "advice12": "Je dégivre dès que la couche de givre dépasse 3mm.",
        "advice13": "Je ne place pas mon réfrigérateur près d'une source de chaleur (four ou fenêtre ensoleillée par exemple).",
        "advice14": "Je lave mon linge à basse température (25% d'électricité économisée avec un lavage à 40°C au lieu de 60°C).",
        "advice15": "Je remplis entièrement mon lave-linge avant de le mettre en marche",
        "advice16": "Je fais sécher mon linge à l'air libre (le sèche-linge est très énergivore). Si ce n'est pas possible : j'essore bien le linge avant de le mettre dans le sèche-linge.",
        "advice17": "Je remplis entièrement le lave-vaisselle avant de le mettre en marche.",
        "advice18": "Je couvre les casseroles lorsque je fais bouiller de l'eau (cela divise par 4 la consommation d'énergie).",
        "advice19": "Je démarre un nettoyage par pyrolyse à la fin d'une cuisson pour profiter de la chaleur déjà produite.",
        "advice20": "En cas d'absence prolongée, j'arrête la production d'eau chaude depuis mon espace MyVoltalis",
        "advice21": "L'installation d'équipements performants (pour le chauffage, l'électroménager, l'éclairage...) me permettra de réduire significativement ma consommation d'énergie",
        "advice22": "Lorsque j'achète des nouveaux équipements, je préfère ceux les mieux classés sur l'étiquette énergie (de A+++ à G)",
        "advice23": "Si je souhaite réaliser des travaux de rénovation énergétique dans mon habitation, je me renseigne en amont sur les travaux les plus utiles et sur les éventuelles aides financières"
    },

      "personalTips": {
        "default": "Votre consommation est inférieure à celle des utilisateurs avec un profil similaire au votre ! Le saviez-vous ? Vous pouvez réaliser davantage d’économies d’énergie  en programmant votre consommation sur votre espace MyVoltalis."
    }
}
