var _ = require('underscore'),
    logger = require('logger'),
    $appScope = require('appScope'),
    DisplayGroup = require('models/DisplayGroup');

module.exports = ['$scope', '$sce', function ($scope, $sce) {

    var subscriber = $appScope.get('user');
    $scope.myAdvices = {};
    $scope.myAdvices.displayGroupList = [];
    $scope.myAdvices.selected = null;

    initialize();

    function initialize(){
        setDisplayGroupListWithModel( subscriber.getDisplayGroupListForSelectedSite() );
    }

    function setDisplayGroupListWithModel( displayGroupList ){

        $scope.myAdvices.displayGroupList = [];

        _.each(displayGroupList, function (item) {
            $scope.myAdvices.displayGroupList.push(new DisplayGroup(item));
        });


        $scope.myAdvices.selected = (_.first($scope.myAdvices.displayGroupList, 2)).reverse()[0];
    }

    $scope.$on('selectedSiteHasChanged', function(){
        logger.debug('myAdvicesController', 'receiving event', 'selectedSiteHasChanged');
        setDisplayGroupListWithModel( subscriber.getDisplayGroupListForSelectedSite() );
    });


    $scope.getHtml = function (selector) {
        if( $scope.myAdvices.selected ){
            return $sce.trustAsHtml($scope.myAdvices.selected.get(selector));
        }
        else{
            //flash("alert-danger", "Le service est indisponible.");
            var flash = '<ol class="flash-messages"><li class="alert alert-danger">Le service est indisponible.</li></ol>';
            return $sce.trustAsHtml(flash);
        }
    };
}];