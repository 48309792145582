module.exports = {
    ACCESS: {
        TYPE: {
            PAGE: 1,
            WIDGET: 2,
            ORDER: 3
        },
        PAGE: {
            MY_PERSONAL_INFORMATION: 1,
            MY_CONTRACT: 2,
            MY_HOUSE: 3,
            MY_INSTALLATION: 4,
            MY_BUDGET: 5,
            OPTI_CONSUMPTION: 6,
            MY_ADVICES: 7,
            MY_OBJECTIVES: 8,
            MY_SCHEDULER: 9,
            NEWS: 10,
            HELP: 11,
            GLOSSARY: 12,
            CONTACT: 13,
            ABSENCE: 14,
            ONOFF: 15,
            NEWSCHEDULER: 16,
            MY_REPORTS: 17
        },
        WIDGET: {
            HEATMAP: 18,
            MY_NEIGHBORS: 19,
            TREND_DETAILS: 20,
            DISPLAY_INSTANT_POWER: 22,
            BUDGET_NOTIFICATION_ALERT: 23,
            ECO_MODE: 24,
        },
        ORDER: {
            REAL_TIME_ACTIVATION: 21,
        },
        DISPLAY_GROUP: {
            VOLTALIS: 1,
            GENI: 2,
            FINLAND: 3,
            SCOTLAND: 4,
            MOBILE: 5,
            TEST: 6,
            EKWATEUR: 7,
        }
    }
};
