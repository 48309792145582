var moment = require('moment');
var $appScope = require('../appScope');
var _ = require('underscore');
var logger = require('logger');
var angular = require('angular');
var ref = require('enum');
var tools = require('tools').tools;
const applianceTools = require('tools').applianceTools;

module.exports = ['$scope', '$http', '$i18next', '$q', function ($scope, $http, $i18next, $q) {
    $scope.availableScheduler = [];

    $scope.schedulerColors = ['#5484ed', '#78b14e', '#fbd75b', '#f18034', '#f13434'];

    $scope.availableModes = [];
    $scope.csLinkList = $appScope.get('user').getSelectedSiteCsLinkList();
    $scope.vxDevices = $scope.csLinkList.filter(csLink => applianceTools.isVX(csLink.modulatorTypeId)).length === $scope.csLinkList.length;
    const visibleMode = new Set();
    $scope.toggleModeClick = toggleModeClick;
    function toggleModeClick(mode) {
        if (visibleMode.has(mode.id)) {
            visibleMode.delete(mode.id);
        }
        else {
            visibleMode.add(mode.id);
        }
    }
    $scope.isToggle = isToggle;
    function isToggle(mode) {
        return visibleMode.has(mode.id);
    }
    $scope.modeConfigTemp = {};
    $scope.schedulerConfigTemp = {};
    $scope.schedulerToDisplay = [];
    $scope.msgErrorScheduler = [];
    $scope.msgErrorMode = [];
    $scope.schedulerConflicts = [];
    $scope.msgErrorConflicts = [];
    $scope.newScheduler = {};
    $scope.availableProgrammationModesByModulatorType = {};
    loadAvailableModes()
    $scope.modeName = modeName;
    $scope.MODE_TYPE = {
        COMFORT: 0,
        ECO: 1,
        CUSTOM: -1,
    };

    function getFirstAvailableColor() {
        const usedColor = $scope.availableModes.map(mode => mode.color);
        return $scope.schedulerColors.filter(color => !usedColor.includes(color))[0];
    }

    $scope.getDayFormat = function (numDay, format) {
        //moment's locale is forced for the native app (they have trouble to detect the locale otherwise)
        var lang = $.cookie(ref.COOKIE.LANG.NAME) || navigator.language;
        var day = moment().locale(lang).isoWeekday(numDay).format(format);
        if (format === 'dd') {
            return day = day.charAt(0);
        }
        return day;
    };

    $scope.suppressionPopupTitle = $i18next('confirmation.title');
    // todo: tester avec la VX
    $scope.getConfirmationText = function (type) {
        switch (type) {
            case 'mode':
                return $i18next('confirmation.mode');
            case 'scheduler':
                return $i18next('confirmation.scheduler');
        }
    };

    // todo: remove?
    $scope.initializeSchedulerConfigModal = function (scheduler) {
        $scope.schedulerConfigTemp = angular.copy(scheduler);
    };

    /**
     * Look for conflict between the current scheduler and the others saved schedulers
     * A conflict happen when two schedulers covers the same day
     * @param currentSchedulerIndex index of the current scheduler (in the list of user's schedulers)
     * @return boolean true if there are conflicts
     */
    const checkSchedulerConflict = function (currentSchedulerIndex) {
        if (!$scope.availableScheduler[currentSchedulerIndex].isActive) {
            return false;
        }

        resetSchedulerConflict();
        _.each($scope.availableScheduler, function (scheduler, schedulerId) {
            //don't compare the same scheduler and only compare it with active schedulers of same level
            if (schedulerId === currentSchedulerIndex || !scheduler.isActive ||
                scheduler.isException !== $scope.availableScheduler[currentSchedulerIndex].isException) {
                return;
            }

            _.each(scheduler.dayOfWeek, function (day) {
                if (!day.dayIsOn) {
                    return;
                }

                _.each($scope.availableScheduler[currentSchedulerIndex].dayOfWeek, function (currentSchedulerDay) {
                    if (currentSchedulerDay.idDay !== day.idDay || !currentSchedulerDay.dayIsOn) {
                        return;
                    }

                    $scope.schedulerConflicts.push({
                        currentScheduler: currentSchedulerIndex,
                        conflictScheduler: schedulerId,
                        day: day.idDay,
                    });
                });
            });
        });

        if ($scope.schedulerConflicts.length <= 0) {
            return false;
        }

        $('#schedulerConflict').modal('show');
        return true;
    };

    $scope.updateSchedulerConfig = function () {
        resetErrorScheduler();

        // Check if scheduler is valid. Check scheduler also indicates errors to the user on the page.
        if (!$scope.checkScheduler()) {
            return;
        }

        $('#schedulerConfiguration').modal('hide');

        sendUpdatedScheduler($scope.schedulerConfigTemp, function (err, isSuccess) {
            if (!err && isSuccess) {
                checkSchedulerConflict(getSchedulerIndexInList($scope.schedulerConfigTemp.id, $scope.availableScheduler));
            }
        });
    };

    /**
     * Resolve scheduler conflicts according to the user's wishes
     * For each conflict's day, the user has the choice between two schedulers
     * The day of the chosen scheduler stay selected,
     * and the other scheduler's day is unselected
     */
    $scope.resolveSchedulerConflicts = function () {
        var dailyConflict = null;
        var nbChoicesMade = 0;
        var idSchedulerToUpdate = 0;
        //save list of schedulers, it'll be updated if all the conflicts are resolved
        var savedSchedulers = angular.copy($scope.availableScheduler);

        _.each($scope.schedulerConflicts, function (conflict) {
            //get radio button data
            dailyConflict = document.getElementsByName('conflict' + conflict.day);

            _.each(dailyConflict, function (choice) {
                if (!choice.checked) {
                    return;
                }

                nbChoicesMade++;

                // if the conflicted scheduler is selected, deselect the current day in the current scheduler
                idSchedulerToUpdate = Number(choice.value) === conflict.conflictScheduler ? conflict.currentScheduler : conflict.conflictScheduler;

                _.each(savedSchedulers[idSchedulerToUpdate].dayOfWeek, function (day) {
                    if (day.idDay === conflict.day) {
                        day.dayIsOn = false;
                    }
                });
            });
        });

        //all conflicts are not resolved
        if (nbChoicesMade < $scope.schedulerConflicts.length) {
            $scope.msgErrorConflicts.push($i18next('schedulerConflict.error.conflictUnsolved'));
            return;
        }

        $scope.availableScheduler.length = 0; //empty the list to initiate the reload of the data
        $scope.availableScheduler = savedSchedulers;

        //send updated schedulers to the server
        _.each(savedSchedulers, function (scheduler) {
            sendUpdatedScheduler(scheduler);
        });

        resetSchedulerConflict();
        $('#schedulerConflict').modal('hide');
    };

    $scope.createTimeSlot = function () {
        var dataSegment = {
            timeBegin: null,
            timeEnd: null,
            mode: {
                id: null,
                colorMode: null,
                labelMode: null,
            },
        };
        $scope.schedulerConfigTemp.data.push(dataSegment);
    };

    $scope.deleteTimeSlot = function (timeSlot) {
        const itemIndex = _.findIndex($scope.schedulerConfigTemp.data, t => _.isEqual(t, timeSlot));
        $scope.schedulerConfigTemp.data.splice(itemIndex, 1);
    };

    $scope.createScheduler = function () {
        $scope.schedulerConfigTemp.id = null;
        $scope.schedulerConfigTemp.name = '';
        $scope.schedulerConfigTemp.isActive = false;
        $scope.schedulerConfigTemp.isException = false;
        $scope.schedulerConfigTemp.data = [];
        $scope.createTimeSlot(); // Create an empty time slot so that there is always at least on (avoids bug in GLQ code :) )
        $scope.schedulerConfigTemp.dayOfWeek = [
            {
                idDay: 1,
                dayIsOn: false,
            },
            {
                idDay: 2,
                dayIsOn: false,
            },
            {
                idDay: 3,
                dayIsOn: false,
            },
            {
                idDay: 4,
                dayIsOn: false,
            },
            {
                idDay: 5,
                dayIsOn: false,
            },
            {
                idDay: 6,
                dayIsOn: false,
            },
            {
                idDay: 7,
                dayIsOn: false,
            },
        ];
    };

    $scope.deleteScheduler = function (scheduler) {
        var data = {
            schedulerId: scheduler.id,
        };
        $http({
            method: 'POST',
            url: '/scheduler/deleteScheduler',
            data: data,
        })
            .then(function onSuccess(response) {
                logger.debug('Delete Scheduler', 'success');
                $scope.availableScheduler = _.reject($scope.availableScheduler, function (currentScheduler) {
                    return currentScheduler.id === scheduler.id;
                });
            })
            .catch(function onError(response) {
                logger.error('Delete Scheduler', response.data.message);
            });

        // Modal backdrop stays after deletion without those last 2 lines
        $('body').removeClass('modal-open');
        $('.modal-backdrop').remove();
    };

    /**
     * Send updated scheduler to the server
     * @param updatedScheduler
     * @param {function} cb with (err, isSuccess) parameters
     */
    function sendUpdatedScheduler(updatedScheduler, cb) {
        var data = {
            scheduler: updatedScheduler,
        };
        $http({
            method: 'POST',
            url: '/scheduler/updateSchedulerConfig',
            data: data,
        })
            .then(function onSuccess(response) {
                logger.debug("Update Scheduler Config", "success");
                getSchedulerList();
            })
            .catch(function onError(response) {
                logger.error('Update Scheduler Config', response.data.message);
                if (typeof cb === 'function') {
                    cb(response.data.message);
                }
            });
    }

    function updateTimeSlotModeInfo() {
        _.each($scope.availableScheduler, function (scheduler) {
            _.each(scheduler.data, function (timeSlot) {
                if (timeSlot.mode.id === null) {
                    return;
                }

                const modeInfo = _.find($scope.availableModes, mode => mode.id === timeSlot.mode.id);
                timeSlot.mode.labelMode = modeInfo.name;
                timeSlot.mode.colorMode = modeInfo.color;
            });
        });
    }

    function updateSchedulerChartsInfo() {
        updateTimeSlotModeInfo();
        const schedulerListCopy = angular.copy($scope.availableScheduler); //angular.copy est nécessaire pour changer la référence de l'objet  sinon le ng-repeat ne detecte pas que le tableau à été vidé puis rempli ($timeout fonctionne aussi)
        $scope.availableScheduler.length = 0; //vide le tableau et le rerempli pour généré de nouveau le code dans le ng-repeat
        $scope.availableScheduler = schedulerListCopy;
    }

    $scope.$watch('schedulerConfigTemp.data', function () {
        if (!$scope.schedulerConfigTemp.data) {
            return;
        }
        _.each($scope.schedulerConfigTemp.data, function (timeSlot) {
            if (timeSlot.mode.id !== null) {
                var modeInfo = _.find($scope.availableModes, function (mode) {
                    return mode.id === timeSlot.mode.id;
                });
                timeSlot.mode.labelMode = modeInfo.name;
                timeSlot.mode.colorMode = modeInfo.color;
            }
        });
        $scope.schedulerToDisplay.length = 0;
        $scope.schedulerToDisplay.push(angular.copy($scope.schedulerConfigTemp));
    }, true);

    $scope.selectColor = function (color) {
        // focus css attribute isn't working with rect it works on circle
        $scope.selectedColor = color;
        // deselect every rectangles
        angular.element('rect').removeClass('selected');
        _.forEach(angular.element('rect'), function (item) {
            if (item.attributes['fill'].value === color) {
                item.classList.add('selected');
            }
        });
    };

    function getModeFromTargets ($scope, targets) {
        let typeMode = $scope.MODE_TYPE.COMFORT; // Comfort is always { status: true, isEcoV: false }
        _.some(targets, (item) => {
            if (item.status) {
                return false;
            }

            // EcoV is always { status: false, isEcoV: true }
            typeMode = item.isEcoV ? $scope.MODE_TYPE.ECO : $scope.MODE_TYPE.CUSTOM;
            return true; // stops the loop
        });

        return typeMode;
    }

    $scope.initializedModeConfigModal = function (mode) {
        $scope.modeConfigTemp = angular.copy(mode);
        $scope.selectedColor = mode.color;
        $scope.modeConfigTemp.type = getModeFromTargets($scope, mode.targets);
    };

    $scope.updateModeConfig = function () {
        const updatedMode = angular.copy($scope.modeConfigTemp);
        updatedMode.color = $scope.selectedColor ? $scope.selectedColor : updatedMode.color;
        //update current config with selected color
        $scope.msgErrorMode = getErrorMessages(updatedMode);
        if ($scope.msgErrorMode.length > 0) {
            return;
        }

        $('#modalSchedulerMode').modal('hide');
        $('body').removeClass('modal-open'); //closing modal doesn't re-enable scrolling without this
        var data = {
            programmationMode: updatedMode,
        };
        const csLinkIdList = $scope.csLinkList;
        $http({
            method: 'POST',
            url: '/scheduler/updateModeConfig',
            data: data,
        })
            .then(() => {
                logger.debug('Update Mode Config', 'success');
                let previousColor = $scope.modeConfigTemp.color;
                getModeList(csLinkIdList)
                    .then((availableModes) => {
                        $scope.availableModes = _.sortBy(availableModes, 'id');
                        const updatedMode = $scope.availableModes.find(mode => mode.id);
                        if (previousColor !== updatedMode.color) {
                            updateSchedulerChartsInfo();
                        }
                    });
            })
            .catch(response => {
                logger.error('Update Mode Config', response.data.message);
            });
    };

    $scope.onModeNameChanged = function () {
        $scope.msgErrorMode = getErrorMessages(angular.copy($scope.modeConfigTemp));
    };

    $scope.createNewMode = function () {
        if ($scope.newScheduler.modeListPromise.isPending()) {
            return;
        }

        $scope.modeConfigTemp.id = null;
        $scope.modeConfigTemp.name = `Mode ${$scope.availableModes.length + 1}`;
        $scope.modeConfigTemp.type = $scope.MODE_TYPE.COMFORT;
        $scope.modeConfigTemp.color = getFirstAvailableColor();
        $scope.selectColor($scope.modeConfigTemp.color);

        $scope.modeConfigTemp.group = [{
            id: null,
            idSensor: null,
            temp: null,
            programmationMode: null,
        }];
        $scope.modeConfigTemp.targets = createNewCsLinkForMode($scope.csLinkList, $scope.availableProgrammationModesByModulatorType);
    };

    $scope.deleteMode = function (mode) {
        resetErrorMode();
        if (isUsedInScheduler(mode.id)) {
            $scope.msgErrorMode.push($i18next('modeConfiguration.error.modeUsedInScheduler'));
            return;
        }

        $scope.availableModes = _.reject($scope.availableModes, modeToReject => modeToReject.id === mode.id);
        const data = {
            mode,
        };
        $http({
            method: 'POST',
            url: '/scheduler/deleteModeConfig',
            data: data,
        })
            .then(function onSuccess(response) {
                logger.debug('Delete Mode Config', 'success');
            })
            .catch(function onError(response) {
                logger.error('Delete Mode Config', response.data.message);
            })
        ;

        // Modal backdrop stays after deletion without those last 2 lines
        $('body').removeClass('modal-open');
        $('.modal-backdrop').remove();
    };

    /**
     * Returns true of the mode identified by the given ID is used in at least 1 scheduler.
     * @param {Number} modeId
     * @returns {boolean}
     */
    function isUsedInScheduler(modeId) {
        var isUsed = false;
        _.each($scope.availableScheduler, function (scheduler) {
            _.each(scheduler.data, function (timeSlot) {
                if (timeSlot.mode.id === modeId) {
                    isUsed = true;
                }
            });
        });
        return isUsed;
    }

    /** Looks for issues in scheduler's planning
     * Will highlight & display messages for :
     * - Absence of name
     * - Unselected application days
     * - Unselected mode for time slots
     * - time slots overlaps are highlighted in red
     * - an error message display all timing gap
     * - already used name
     * @returns {Boolean} true if the scheduler is valid
     */
    $scope.checkScheduler = function () {
        var isValid = true;
        var overlap = false;
        var gap = '';
        var messagesError = [];
        //order planning in chronological order
        $scope.schedulerConfigTemp.data = _.sortBy($scope.schedulerConfigTemp.data, 'timeBegin');

        //check if the planning start at midnight on the current day
        if ($scope.schedulerConfigTemp.data[0].timeBegin !== '00:00') {
            var gapEnd = ($scope.schedulerConfigTemp.data[0].timeBegin != null) ? $scope.schedulerConfigTemp.data[0].timeBegin : '00:00';
            gap += '00:00-' + gapEnd;
        }

        //compute the difference between the end of the current time slot and the beginning of the next time slot
        //if the difference is positive, there's a gap in the planning
        //if the difference is negative, there's two time slots at the same time
        var i;
        for (i = 0; i < $scope.schedulerConfigTemp.data.length - 1; i++) {
            var currentTimeEnd = $scope.schedulerConfigTemp.data[i].timeEnd.split(':');
            var nextTimeBegin = $scope.schedulerConfigTemp.data[i + 1].timeBegin.split(':');
            var nextTimeEnd = $scope.schedulerConfigTemp.data[i + 1].timeEnd.split(':');

            currentTimeEnd = moment().utc().hour(currentTimeEnd[0]).minute(currentTimeEnd[1]).millisecond(0);
            //if the time slot end is at midnight, it's the time of the next day
            if (currentTimeEnd.hour() === 0 && currentTimeEnd.minute() === 0) {
                currentTimeEnd.add(1, 'days');
            }

            nextTimeBegin = moment().utc().hour(nextTimeBegin[0]).minute(nextTimeBegin[1]).millisecond(0);
            nextTimeEnd = moment().utc().hour(nextTimeEnd[0]).minute(nextTimeEnd[1]).millisecond(0);
            if (nextTimeEnd.hour() === 0 && nextTimeEnd.minute() === 0) {
                nextTimeEnd.add(1, 'days');
            }

            var duration = moment.duration(nextTimeBegin.diff(currentTimeEnd)).asMinutes();
            //compute only if the current time slot isn't overlaping the next one
            if (duration > 0 && !currentTimeEnd.isAfter(nextTimeEnd)) {
                gap += ', ' + $scope.schedulerConfigTemp.data[i].timeEnd + '-' + $scope.schedulerConfigTemp.data[i + 1].timeBegin;
            } else if (duration < 0) {
                //highlight the overlaping time slots in red
                angular.element(document.querySelector('#stop' + i)).addClass('error');
                angular.element(document.querySelector('#start' + (i + 1))).addClass('error');
                overlap = true;
            } else if (currentTimeEnd.isAfter(nextTimeEnd)) {
                overlap = true;
            }
        }

        //check if the planning end at midnight on the next day (if there isn't time slot overlap)
        var endOfLastTimeSlot = $scope.schedulerConfigTemp.data[$scope.schedulerConfigTemp.data.length - 1].timeEnd;
        if (endOfLastTimeSlot !== '00:00' && endOfLastTimeSlot != null && !overlap) {
            gap += ', ' + $scope.schedulerConfigTemp.data[$scope.schedulerConfigTemp.data.length - 1].timeEnd + '-00:00';
        }

        // Check if a name has been given
        if (!(typeof $scope.schedulerConfigTemp.name === 'string') || !$scope.schedulerConfigTemp.name.length > 0) {
            angular.element(document.querySelector('#tempSchedulerName')).addClass('error');
            messagesError.push($i18next('schedulerConfiguration.error.noName'));
            isValid = false;
        }

        // Check if at least a day has been selected
        var daySelected = false;
        _.each($scope.schedulerConfigTemp.dayOfWeek, function (day) {
            if (day.dayIsOn) {
                daySelected = true;
            }
        });
        if (!daySelected) {
            messagesError.push($i18next('schedulerConfiguration.error.noDaySelected'));
        }

        // Check if each timeSlot has an attributed mode & if timeStart is anterior to timeEnd
        _.each($scope.schedulerConfigTemp.data, function (timeSlot, index) {
            if (!timeSlot.hasOwnProperty('mode') || !Number.isFinite(timeSlot.mode.id)) {
                angular.element(document.querySelector('#mode' + index)).addClass('error');
                messagesError.push($i18next('schedulerConfiguration.error.noModeSelected', { index: index + 1 }));
                isValid = false;
            }

            if (moment(timeSlot.timeBegin, 'HH:mm') > moment(timeSlot.timeEnd, 'HH:mm') && timeSlot.timeEnd !== '00:00') {
                angular.element(document.querySelector('#stop' + index)).addClass('error');
                angular.element(document.querySelector('#start' + (index))).addClass('error');
                messagesError.push($i18next('schedulerConfiguration.error.endBeforeStart'));
                isValid = false;
            }
        });

        //Check if the name of the scheduler is used by an other scheduler of the same site
        _.each($scope.availableScheduler, function (scheduler) {
            if (scheduler.id !== $scope.schedulerConfigTemp.id &&
                scheduler.name === $scope.schedulerConfigTemp.name) {
                messagesError.push($i18next('schedulerConfiguration.error.usedName'));
            }
        });

        if (gap.length > 0) {
            messagesError.push($i18next('schedulerConfiguration.error.gap', { gap }));
            isValid = false;
        }

        if (overlap) {
            messagesError.push($i18next('schedulerConfiguration.error.overlap'));
            isValid = false;
        }

        //reset error messages
        $scope.msgErrorScheduler = [];
        if (messagesError.length > 0)
            {$scope.msgErrorScheduler = messagesError;}

        return isValid;
    };

    /**
     * Checks that the given programmation mode has:
     * - An available name
     * - A color
     * Only parameters that a user could miss are checked, data integrity is checked backend.
     * @param currentMode {Object}
     * @returns {[]} the list of raised errors
     */
    function getErrorMessages(currentMode) {
        const errorMessageList = [];

        // Check color validity
        if (!isValidColor(currentMode.color)) {
            errorMessageList.push($i18next('modeConfiguration.error.invalidModeColor'));
        }

        // Check mode name validity
        if (!currentMode.hasOwnProperty('name') || typeof currentMode.name !== 'string' || currentMode.name.length < 1) {
            errorMessageList.push($i18next('modeConfiguration.error.modeNameNotFilled'));
        }

        // Check mode name availability
        _.each($scope.availableModes, function (mode) {
            if (mode.id !== currentMode.id && mode.name === currentMode.name) {
                errorMessageList.push($i18next('modeConfiguration.error.usedName'));
            }
        });

        return errorMessageList;
    }

    /**
     * Returns true if the given string is a valid hex color representation
     * @param {String} color
     * @returns {boolean}
     */
    function isValidColor(color) {
        return /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(color);
    }

    /**
     * Function called when cancelling or quitting the mode configuration pop-up
     */
    $scope.cancelModeConfig = function () {
        resetErrorMode();
        unselectColor();
    };

    $scope.cancelSchedulerConfig = function () {
        resetErrorScheduler();
    };

    $scope.cancelSchedulerConflict = function () {
        //if user cancel the resolution of conflicts, switch current scheduler back to inactive state
        $scope.availableScheduler[$scope.schedulerConflicts[0].currentScheduler].isActive = false;
        resetSchedulerConflict();
        //reset error log
        $scope.msgErrorConflicts = [];
    };

    function resetErrorMode() {
        $scope.msgErrorMode = [];
    }

    function unselectColor() {
        $scope.selectedColor = null;
        angular.element('rect').removeClass('selected');
    }

    function resetErrorScheduler() {
        $scope.msgErrorScheduler = [];

        // reset time slots with red highlight
        const timeSlotsError = document.querySelectorAll('.oneSlot.error');
        for (let i = 0; i < timeSlotsError.length; i++) {
            timeSlotsError[i].classList.remove('error');
        }
        const modeSlotsErrors = document.querySelectorAll('.oneSlotMode.error');
        for (let i = 0; i < modeSlotsErrors.length; i++) {
            modeSlotsErrors[i].classList.remove('error');
        }
    }

    /**
     * Reset list of scheduler's conflicts
     */
    function resetSchedulerConflict() {
        $scope.schedulerConflicts = [];
    }

    /**
     * The status returned corresponds to the ON/OFF state of an appliance in a mode.
     * If the given cs is within the list, the link should be modulated (status OFF)
     * @param modulatedList {Array} of objects containing idCsLink & idCsToCut
     * @param csId {Number}
     * @returns {boolean} ON -> true , OFF -> false
     */
    // status is used for V2/V5
    function _getStatusFromCsList(modulatedList, csId) {
        const modulator = modulatedList.find(modulator => modulator.idCsLink === csId);
        if (modulator && modulator.currentProgrammationMode && modulator.currentProgrammationMode.dbId === applianceTools.MODES.COMFORT.dbId) {
            return true;
        }
        else if (modulator && modulator.currentProgrammationMode && modulator.currentProgrammationMode.dbId === applianceTools.MODES.STOP.dbId) {
            return false;
        }
        else if (modulator) {
            return false;
        }
        return true;
    }

    function _getEcoVFromCsList(csLinkList, csId) {
        for (let i = 0; i < csLinkList.length; i++) {
            if (csLinkList[i].idCsLink === csId) {
                return csLinkList[i].isEcoV;
            }
        }
        return null;
    }

    function createNewCsLinkForMode(csLinkList, avalaibleProgrammationModeByModulatorType) {
        return _.reduce(csLinkList, (acc, csLink) => {
            if (!tools.isProgrammable(csLink)) {
                return acc;
            }
            // load available modes for device on creation page
            const availableProgrammationMode = avalaibleProgrammationModeByModulatorType[csLink.modulatorTypeId]
            if (availableProgrammationMode === undefined)
                return acc;
            const currentCsLink = {
                name: csLink.name ? _.clone(csLink.name) : $i18next(csLink.defaultName),
                groupId: null,
                status: true, // status is used to know if it's ecoV or custom
                id: csLink.csLinkId,
                idToCut: csLink.csLinkToCutId,
                isEcoV: false,
                modulatorTypeId: csLink.modulatorTypeId,
                currentProgrammationMode: availableProgrammationMode.find(mode => mode.dbId === applianceTools.MODES.COMFORT.dbId),
                availableProgrammationMode: availableProgrammationMode,
                setpointTemperatureInCelsius: csLink.setpointTemperatureInCelsius,
            };

            acc.push(currentCsLink);
            return acc;
        }, []);
    }

    function initializeAvailableModeFromProgrammationModeList(programmationModeList, csLinkList) {
        return _.map(programmationModeList, function (mode) {
            const currentMode = {
                name: mode.name,
                color: mode.color,
                id: mode.id,
                group: mode.group,
                isDefaultMode: mode.isDefaultMode,
                targets: [],
            };

            currentMode.group.forEach(function (group) {
                csLinkList.forEach(function (csLink) {
                    if (!tools.isProgrammable(csLink)) {
                        return;
                    }

                    // if csLinkInGroup == null => V2/V5
                    let csLinkInGroup = group.csLinkIdList.find(groupCsLink => groupCsLink.idCsLink === csLink.csLinkId);
                    const status = _getStatusFromCsList(group.csLinkIdList, csLink.csLinkId);
                    const currentCsLink = {
                        name: csLink.name ? _.clone(csLink.name) : $i18next(csLink.defaultName),
                        groupId: group.id,
                        id: csLink.csLinkId,
                        status: status,
                        idToCut: csLink.csLinkToCutId,
                        modulatorTypeId: csLink.modulatorTypeId,
                        isEcoV: _getEcoVFromCsList(group.csLinkIdList, csLink.csLinkId),
                        currentProgrammationMode: calculateCurrentMode(group.csLinkIdList, csLink.csLinkId),
                        availableProgrammationMode: csLinkInGroup == null ? null : csLinkInGroup.availableModesForModulator,
                        setpointTemperatureInCelsius: csLinkInGroup == null ? null : csLinkInGroup.setpointTemperatureInCelsius,
                    };
                    currentMode.targets.push(currentCsLink);
                });
            });

            return currentMode;
        });
    }

    // A revoir. S'assurer qu'il n'est pas plus simple d'inserer directement en base les valeurs pour les anciens modes
    function calculateCurrentMode(csLinkList, csLinkId) {
        let csLink = csLinkList.find( csLink => csLink.idCsLink === csLinkId);
        if (csLink == null) {
            return applianceTools.MODES.ON;
        }
        if (csLink.isEcoV) {
            return applianceTools.MODES.ECO;
        }
        return csLinkList.find(groupCsLink => groupCsLink.idCsLink === csLinkId).currentProgrammationMode;
    }

    /**
     * Get the programmation mode list from the server and store it locally.
     */
    function getModeList(csLinkList) {
        return $http.get('scheduler/getModeList.json')
            .then(response => response.data)
            .tap(() => logger.debug('getModeList', 'success '))
            .then((data) => {
                if (data.programmationModeList.length !== 0) {
                    return initializeAvailableModeFromProgrammationModeList(data.programmationModeList, csLinkList);
                }

                const newModeTargets = createNewCsLinkForMode(csLinkList, $scope.availableProgrammationModesByModulatorType);
                return $http.post('scheduler/createDefaultModes', { csLinkIdList: newModeTargets })
                    .then(response => response.data)
                    .then((data) => {
                        return initializeAvailableModeFromProgrammationModeList(data.programmationModeList, csLinkList);
                    });
            })
            .catch(() => logger.error('getModeList', 'unable to retrieve mode list'));
    }

    $scope.newScheduler.hasOnlyDefaultModes = function() {
        return $scope.availableModes.length ? !_.some($scope.availableModes, item => !item.isDefaultMode) : false;
    };

    function getSchedulerList() {
        $http.get('/scheduler/getSchedulerList.json')
            .then(function onSuccess(response) {
                logger.debug('getSchedulerList', 'success ');
                const tempSchedulerList = _.sortBy(response.data.schedulerList, 'id');
                _.each(tempSchedulerList, function (scheduler) {
                    scheduler.isException = scheduler.isException === 1;
                    scheduler.isActive = scheduler.isActive === 1;
                    scheduler.data = _.sortBy(scheduler.data, 'timeBegin');
                    scheduler.hasAnimation = true;
                    _.each(scheduler.data, function (timeSlot) {
                        timeSlot.timeBegin = timeSlot.timeBegin.substr(0, 5);
                        timeSlot.timeEnd = timeSlot.timeEnd.substr(0, 5);
                    });
                });
                $scope.availableScheduler = tempSchedulerList;
            })
            .catch(function onError(response) {
                logger.error('getSchedulerList', response.data.message);
            });
    }

    function loadAvailableModes() {
        $http.get('/scheduler/availableProgrammationMode.json')
            .then(response => {
                const mapWithModulatorTypeAsKey = {};
                Object.keys(response.data.availableModesByModulatorType).forEach( (key) => {
                    mapWithModulatorTypeAsKey[parseInt(key)] = [];
                    response.data.availableModesByModulatorType[key].forEach(availableMode => {
                        mapWithModulatorTypeAsKey[parseInt(key)].push(availableMode);
                    });
                });
                $scope.availableProgrammationModesByModulatorType = mapWithModulatorTypeAsKey;
            });
    }

    function getModesList() {
        console.log($scope.csLinkList)
        $scope.newScheduler.modeListPromise = getModeList($scope.csLinkList)
            .then((availableModes) => {
                $scope.availableModes = _.sortBy(availableModes, 'id');
                $scope.modesAreFetched = true; // Indicates that the request has been done (used to hide a warning msg)
            });
    }

    function initialize() {
        getModesList();
        getSchedulerList();
    }

    /**
     * Returns true if adding a scheduler is currently allowed.
     * A scheduler can be added if at least one mode is available, and if the maximum number is not reached yet.
     * @returns {Boolean}
     */
    $scope.canAddScheduler = function () {
        return ($scope.availableModes.length >= 1
            && !$scope.hasReachedMaxSchedulers());
    };

    $scope.hasReachedMaxSchedulers = function () {
        return $scope.availableScheduler.length >= ref.PROGRAMMATION.MAX_SCHEDULERS;
    };

    /**
     * Returns true if a mode can added.
     * A mode can be added of the maximum number is not reached yet.
     * @returns {boolean}
     */
    $scope.canAddMode = function () {
        return $scope.availableModes.length < ref.PROGRAMMATION.MAX_MODES;
    };

    initialize();

    /**
     * Triggered when switching a scheduler status between ON & OFF, sends a POST request to the server so it can
     * activate or deactivate the scheduler.
     * @param {Number} schedulerId
     * @param {Boolean} isActive
     */
    $scope.changeSchedulerState = function (schedulerId, isActive) {
        var schedulerIndex = getSchedulerIndexInList(schedulerId, $scope.availableScheduler);
        if (schedulerIndex == null || checkSchedulerConflict(schedulerIndex)) {
            return;
        }

        var params = {
            schedulerId: schedulerId,
            isActive: isActive,
        };
        $http({
            method: 'POST',
            url: '/scheduler/changeSchedulerState',
            data: params,
        })
            .then(function onSuccess(response) {

            })
            .catch(function onError(response) {
                logger.error('getSchedulerList', response.data.message);
            });
    };

    /**
     * Return the index of the scheduler corresponding to the given ID in the given list. Null if not present.
     * @param {Number} schedulerId
     * @param {Array} schedulerList
     * @returns {Number}
     */
    function getSchedulerIndexInList(schedulerId, schedulerList) {
        for (var i = 0; i < schedulerList.length; i++) {
            if (schedulerList[i].id === schedulerId) {
                return i;
            }
        }
        return null;
    }

    $scope.setComfortMode = function () {
        $scope.modeConfigTemp.type = $scope.MODE_TYPE.COMFORT;

        $scope.modeConfigTemp.targets.forEach(function (item) {
            item.isEcoV = false;
            item.status = true;
        });
    };

    $scope.setEcoMode = function () {
        $scope.modeConfigTemp.type = $scope.MODE_TYPE.ECO;

        $scope.modeConfigTemp.targets.forEach(function (item) {
            item.isEcoV = true;
            item.status = false;
        });
    };

    $scope.setCustomMode = function () {
        $scope.modeConfigTemp.type = $scope.MODE_TYPE.CUSTOM;

        $scope.modeConfigTemp.targets.forEach(function (item) {
            item.isEcoV = false;
            item.status = true;
        });
    };
    function modeName(appliance) {
        return {translationKey: appliance.currentProgrammationMode.translationKey, constraint: appliance.targetedValue};
    }
}];
