module.exports = function(){
    return {
        restrict: 'EA',
        replace: true,
        template: ''+
        '<ol class="flash-messages">' +
            '<li ng-repeat="m in messages" class="alert {{m.level}}">{{m.text}}</li>' +
        '</ol>',
        controller: ['$scope', '$rootScope', function($scope, $rootScope) {
            $rootScope.$on('flash:message', function(_, messages, timeout, done) {
                $scope.messages = messages;
                done();
                if( null != timeout && timeout > 0 ){   // Code repris de la V1
                    var $targetId = jQuery(".flash-messages");
                    $targetId.css('display', 'block').delay( timeout ).fadeOut('slow', function() {
                        $targetId.css('display', 'none');
                    })
                }
            });
        }]
    };
};