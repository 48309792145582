var _ = require('underscore'),
    logger = require('logger');

var $appScope = require('appScope');
var Contract = require('models/Contract');

// Ordre dans lequel saisir les champs
var steps = {
    SUPPLIER: 0,
    TYPE: 1,
    OPTION: 2,
    SUBSCRIBED_POWER: 3
};

// TODO : À déplacer dans un fichier de référence
var option = {
    BASE: 1,
    HPHC: 2
};

// TODO : À déplacer dans un fichier de référence
var defaultPlanning = [2,2,2,2,2,2,2,2,2,2,2,2,2,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,2,2,2];

module.exports = ['$rootScope', '$scope', '$http', 'flash', '$i18next', function ($rootScope, $scope, $http, flash, $i18next) {

    var STATUS_PENDING = {};
    var STATUS_OK = {};

    // Hack for the case when the page is called from a modal (otherwise the black overlay stays) @DKA est-ce encore utile depuis que la gestion est traiter au niveau du routeur ? (TODO: tester le cas sans la ligne)
    angular.element('.modal-backdrop').remove();


    $scope.contract = new Contract();
    $scope.myContract = {form: {}};
    $scope.myContract.loadingSelectItemList = [
        {id: '?', description: $i18next('myContract.loadingSelectItemList')}
    ];

    var subscriber = $appScope.get( 'user' );
    $scope.myContract.subscriber = subscriber;
    $scope.myContract.status = null;

    // TODO : Utiliser l'objet contrat
    // TODO : Gérer la saisie des nombres avec des virgules au lieu de points.
    // TODO : Ajouter la gestion de la locale Angular pour l'affichage des nombres
    // TODO : Bug ? : un contrat custom n'a pas été supprimé en base
    // TODO : Remplacer le datePicker par un sélecteur double (jour + mois), définir une année par défaut en base
    // TODO : Remplacer l'envoi de date par l'envoi de timestamp (inutile si TODO précédent fait)
    // TODO : Erreur si la date n'est pas renseignée : normal ou non ? (si non, ne pas activer le bouton valider tant qu'elle est vide)
    // TODO : Le nom du contrat doit-il être obligatoire ?


    // Init fields
    $scope.myContract.supplierList = null;
    $scope.myContract.form.supplierId = null;

    $scope.myContract.typeList = null;
    $scope.myContract.form.typeId = null;

    $scope.myContract.optionList = null;
    $scope.myContract.form.optionId = null;

    $scope.myContract.powerList = null;
    $scope.myContract.form.subscribedPowerId = null;

    $scope.myContract.subscriptionMonthlyFee = null;
    $scope.myContract.priceList = null;

    $scope.myContract.form.planning = defaultPlanning;

    $scope.myContract.form.customTypeName = null;
    $scope.myContract.form.customSubscriptionMonthlyFee = null;
    $scope.myContract.form.customBasePrice = null;
    $scope.myContract.form.customOffpeakHourPrice = null;
    $scope.myContract.form.customPeakHourPrice = null;

    $scope.myContract.form.anniversaryDate = null;

    $scope.myContract.useCustomContract = false;

    $scope.myContract.submit = submit;
    $scope.myContract.updateFields = updateFields;
    $scope.myContract.setFieldsFromContract = setFieldsFromContract;
    $scope.myContract.useHPHC = useHPHC;


    initialize();

    function initialize(){
        logger.debug('myContractController', 'initialization');
        $scope.myContract.status = STATUS_PENDING;

        $scope.myContract.supplierList = $scope.myContract.loadingSelectItemList;
        $scope.myContract.form.supplierId = $scope.myContract.supplierList[0].id;

        $scope.myContract.typeList = $scope.myContract.loadingSelectItemList;
        $scope.myContract.form.typeId = $scope.myContract.typeList[0].id;

        $scope.myContract.optionList = $scope.myContract.loadingSelectItemList;
        $scope.myContract.form.optionId = $scope.myContract.optionList[0].id;

        $scope.myContract.powerList = $scope.myContract.loadingSelectItemList;
        $scope.myContract.form.subscribedPowerId = $scope.myContract.powerList[0].id;

        $scope.$on( 'selectedSiteHasChanged', setFieldsFromContract );

        $http.get( 'getSupplierList.json' )
            .then( function onSuccess( response ){
                $scope.myContract.supplierList = response.data.supplierList;
                $scope.myContract.form.supplierId = $scope.myContract.supplierList[0].id;

                $http.get( 'getStandardContractList.json' )
                    .then( function onSuccess( response ){
                        $scope.myContract.status = STATUS_OK;
                        $scope.myContract.contractList = response.data.contractList;

                        var selectedSite = subscriber.getSelectedSite();
                        if( null != selectedSite.contract && null != selectedSite.contract.id ){
                            setFieldsFromContract();
                        }
                        else{
                            $scope.myContract.hasContract = false;
                            $scope.myContract.updateFields( steps.SUPPLIER );
                        }
                    })
            });
    }

    function submit(){
        if( !$scope.myContractForm.$valid ){
            flash( 'alert-warning', 'Vous devez compléter votre profil avant de le valider.', 10000 );
            return;
        }

        var newContractId = null;
        if( !$scope.myContract.useCustomContract ){
            var filteredList = filterContractList(
                $scope.myContract.contractList,
                $scope.myContract.form.supplierId,
                $scope.myContract.form.typeId,
                $scope.myContract.form.optionId,
                $scope.myContract.form.subscribedPowerId );

            if( null == filteredList || 0 === filteredList.length || filteredList.length > 1 ){
                return;
            }

            newContractId = filteredList[0].contractId;
        }

        var planning = null;
        if( $scope.myContract.useHPHC( $scope ) ){
            // $$$LSN This function is a temporary solution to comply with DB arch changes for contract internationalisation
            // Changes must be made to this once the GUI is adapted.
            planning = getPlanningForWholeWeek($scope.myContract.form.planning);
        }

        // Pas très propre
        if( null != $scope.myContract.form.anniversaryDate && null != $scope.myContract.form.anniversaryDate.startDate ){
            $scope.myContract.form.anniversaryDate = $scope.myContract.form.anniversaryDate.startDate.format( 'DD/MM/YYYY' );
        }
        var anniversaryDate = $scope.myContract.form.anniversaryDate;

        $http({
            method: 'POST',
            url:'/insertOrUpdateContract',
            data:{
                siteId: $scope.myContract.subscriber.getSelectedSite().id,
                contractId: newContractId,
                planning: planning,
                supplierId: $scope.myContract.form.supplierId,
                optionId: $scope.myContract.form.optionId,
                subscribedPowerId: $scope.myContract.form.subscribedPowerId,
                customTypeName: $scope.myContract.form.customTypeName,
                customSubscriptionMonthlyFee: $scope.myContract.form.customSubscriptionMonthlyFee,
                customBasePrice: $scope.myContract.form.customBasePrice,
                customOffpeakHourPrice: $scope.myContract.form.customOffpeakHourPrice,
                customPeakHourPrice: $scope.myContract.form.customPeakHourPrice,
                anniversaryDate: anniversaryDate
            }
        })
            .then( function onSuccess( response ){
                // Update contracts in user object
                var user = $appScope.get( 'user' );
                var siteList = user.get( 'siteList' );
                var updatedSite = null;

                for( var i=0; i<siteList.length; ++i ){
                    if( response.data.contract.siteId === siteList[i].id ){
                        siteList[i].contract = response.data.contract;
                        updatedSite = siteList[i];
                        break;
                    }
                }

                user.set( 'siteList', siteList );

                $scope.myContract.hasContract = true;
                flash( 'alert-success', 'Modifications enregistrées.', 3000 );
            } )
            .catch( function onError( response ){
                console.log( response.message );
                flash( 'alert-danger', 'Erreur, modifications non enregistrées.', 5000 );
            })
    }

    // contractList : contractId, supplierId, supplierName, typeId, typeDescription, subscription,
    // priceDescriptionList, hourTypeIdList, priceList, powerId, powerDescription, optionId, optionDescription

    // Met à jour le contenu des select en cascade. Met à jour les éléments sélectionnés
    // Le contenu des select n'est pas récupéré par requête mais en filtrant la liste des contrats possibles (déjà chargée)
    function updateFields( step ){
        var contractList = $scope.myContract.contractList;
        var supplierId = $scope.myContract.form.supplierId;
        var typeId = $scope.myContract.form.typeId;

        var filteredList = null;
        //contracts
        if( step <= steps.SUPPLIER ){
            filteredList = filterContractList( contractList, supplierId, null, null, null );
            var typeList = extractContractTypeList( filteredList );
            if( null != typeList[0] ){
                switchToStandardContract();
                if( null == typeId || !_contains( filteredList, 'typeId', typeId ) ){ // select previous element if available
                    typeId = typeList[0].id;
                }
            } else {
                switchToCustomContract();
                typeId = null;
            }
            $scope.myContract.typeList = typeList;
            $scope.myContract.form.typeId = typeId;
        }

        var useCustomContract = $scope.myContract.useCustomContract;

        //options
        if( step <= steps.TYPE && !useCustomContract ){
            filteredList = filterContractList( contractList, supplierId, $scope.myContract.form.typeId, null, null );
            $scope.myContract.optionList = extractOptionList( filteredList );
            if( null != $scope.myContract.optionList[0] ){
                if( null == $scope.myContract.form.optionId || !_contains( filteredList, 'optionId', $scope.myContract.form.optionId ) ){ // select previous element if available
                    $scope.myContract.form.optionId = $scope.myContract.optionList[0].id;
                }
            } else {
                $scope.myContract.form.optionId = null;
            }
        } else if( useCustomContract ){
            $scope.myContract.optionList = extractCustomOptionList( contractList );
        }

        // power
        if( step <= steps.OPTION ){
            if( useCustomContract ){
                filteredList = filterContractList( contractList, null, null, null, null );  // Pas de filtre, on veut toutes les options possibles
            } else {
                filteredList = filterContractList( contractList, supplierId, $scope.myContract.form.typeId, $scope.myContract.form.optionId, null );
            }
            $scope.myContract.powerList = extractSubscribedPowerList( filteredList );
            if( null != $scope.myContract.powerList[0] ){
                if( null == $scope.myContract.form.subscribedPowerId || !_contains( filteredList, 'powerId', $scope.myContract.form.subscribedPowerId ) ){  // select previous element if available
                    $scope.myContract.form.subscribedPowerId = $scope.myContract.powerList[0].id;
                }
            } else {
                $scope.myContract.form.subscribedPowerId = null;
            }
        }

        // Update cost
        if( !useCustomContract ){
            filteredList = filterContractList( contractList, supplierId, $scope.myContract.form.typeId, $scope.myContract.form.optionId, $scope.myContract.form.subscribedPowerId );
            if( null != filteredList && filteredList.length > 0 ){
                $scope.myContract.subscriptionMonthlyFee = filteredList[0].subscription;
                var priceList = filteredList[0].priceList.split(';');
                var priceDescriptionListTranslationKey =  filteredList[0].priceDescriptionListTranslationKey.split(';');
                var hourTypeIdList =  filteredList[0].hourTypeIdList.split(';');
                $scope.myContract.priceList = [];
                for( var i=0; i<priceList.length; ++i ){
                    const currency = $rootScope.translatedCurrency();
                    const description = $i18next('myContract.' + priceDescriptionListTranslationKey[i], {currency: currency});
                    $scope.myContract.priceList.push( {price: Number(priceList[i]), description: description, hourTypeId: hourTypeIdList[i]} );
                }
            }
        }
    }

    function resetFields(){
        switchToStandardContract();
        $scope.myContract.form.supplierId = $scope.myContract.supplierList[0].id;
        $scope.myContract.form.typeId = null;
        $scope.myContract.form.optionId = null;
        $scope.myContract.form.subscribedPowerId = null;

        $scope.myContract.form.planning = defaultPlanning;
        $scope.myContract.form.customTypeName = null;
        $scope.myContract.form.customSubscriptionMonthlyFee = null;
        $scope.myContract.form.customBasePrice = null;
        $scope.myContract.form.customOffpeakHourPrice = null;
        $scope.myContract.form.customPeakHourPrice = null;
        $scope.myContract.form.anniversaryDate = null;
        $scope.myContract.useCustomContract = false;


        $scope.myContract.updateFields( steps.SUPPLIER );
    }

    function switchToStandardContract(){
        $scope.myContract.useCustomContract = false;
    }

    function switchToCustomContract(){
        $scope.myContract.useCustomContract = true;
    }

    function setFieldsFromContract(){
        resetFields();

        var contract = $scope.myContract.subscriber.getSelectedSite().contract;
        if( null == contract || 0 == contract.length ){
            $scope.myContract.hasContract = false;
            return;
        }

        $scope.myContract.hasContract = true;
        $scope.myContract.form.supplierId = contract.supplierId;
        $scope.myContract.form.typeId = contract.typeId;
        $scope.myContract.form.optionId = contract.optionId;
        $scope.myContract.form.subscribedPowerId = contract.subscribedPowerId;
        $scope.myContract.form.customTypeName = contract.customTypeName;
        $scope.myContract.form.anniversaryDate = contract.anniversaryDate;

        if( !contract.isStandard ) {
            $scope.myContract.form.customSubscriptionMonthlyFee = contract.subscriptionMonthlyFee;
            $scope.myContract.form.customBasePrice = contract.basePrice;
            $scope.myContract.form.customOffpeakHourPrice = contract.offpeakHourPrice;
            $scope.myContract.form.customPeakHourPrice = contract.peakHourPrice;
        }

        if( null == contract.planning){
            $scope.myContract.form.planning = defaultPlanning;
        }
        else if (_.isArray(contract.planning)) {
            $scope.myContract.form.planning = contract.planning;
        } else {
            // $$$LSN Temporary ! The monday planning is displayed until changes have been made to the GUI
            $scope.myContract.form.planning = contract.planning['1'];
        }

        $scope.myContract.updateFields( steps.SUPPLIER );
    }

    function useHPHC(){
        return $scope.myContract.form.optionId === option.HPHC;
    }

    function extractOptionList( contractList ){
        var optionList = [];
        for( var i=0; i < contractList.length; ++i ){
            if( !_contains( optionList, 'id', contractList[i].optionId ) ){
                optionList.push( {
                    id: contractList[i].optionId,
                    description: $i18next('myContract.' + contractList[i].optionTranslationKey) } );
            }
        }
        return optionList;
    }

    // Pour un contrat personnalisé on ne propose que Base et HP/HC
    function extractCustomOptionList( contractList ){
        var optionList = extractOptionList( contractList );
        var filteredOptionList = [];
        for( var i=0; i<optionList.length; ++i ){
            if( optionList[i].id == option.BASE || optionList[i].id == option.HPHC ){
                filteredOptionList.push( optionList[i] );
            }
        }
        return filteredOptionList;
    }

    //*****
    $scope.myContract.hasPendingStatus = function () {
        return $scope.myContract.status === STATUS_PENDING;
    };

    $scope.myContract.hasOkStatus = function () {
        return $scope.myContract.status === STATUS_OK;
    };
}];

/**
 * Creates a week planning using the same given planning for every day.
 * @param {Array} planning A single day JS planning
 * @returns A planning for a whole week
 */
function getPlanningForWholeWeek(planning) {
    return {
        1: planning,
        2: planning,
        3: planning,
        4: planning,
        5: planning,
        6: planning,
        7: planning
    }
}

function filterContractList( contractList, supplierId, typeId, optionId, powerId ){
    var filteredList = [];
    for( var i=0; i < contractList.length; ++i ){
        if( ( null != supplierId && contractList[i].supplierId != supplierId )
        || ( null != typeId && contractList[i].typeId != typeId )
        || ( null != optionId && contractList[i].optionId != optionId )
        || ( null != powerId && contractList[i].powerId != powerId ) )
        {
            continue;
        }
        filteredList.push( contractList[i] );
    }
    return filteredList;
}

//*****
//** Extrait de la liste des contrats les éléments demandés
// C'est comme un _.pluck qui retournerait un objet
function extractSupplierList( contractList ){
    var supplierList = [];
    for( var i=0; i < contractList.length; ++i ){
        if( !_contains( supplierList, 'id', contractList[i].supplierId ) ){
            supplierList.push( {id: contractList[i].supplierId, description: contractList[i].supplierDescription } );
        }
    }
    return supplierList;
}

function extractContractTypeList( contractList ){
    var typeList = [];
    for( var i=0; i < contractList.length; ++i ){
        if( !_contains( typeList, 'id', contractList[i].typeId ) ){
            typeList.push( {id: contractList[i].typeId, description: contractList[i].typeDescription } );
        }
    }
    return typeList;
}

function extractSubscribedPowerList( contractList ){
    var powerList = [];
    for( var i=0; i < contractList.length; ++i ){
        if( !_contains( powerList, 'id', contractList[i].powerId ) ){
            powerList.push( {id: contractList[i].powerId, description: contractList[i].powerDescription } );
        }
    }
    return powerList;
}

// Raccourci pour ne pas faire _.contains( _.pluck(...) ) à chaque fois
function _contains( arrayOfObjects, propertyOfObjectToCompare, valueToFind ){
    return _.contains( _.pluck( arrayOfObjects, propertyOfObjectToCompare ), valueToFind );
}
