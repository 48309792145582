const logger = require('logger'),
    $appScope = require('appScope'),
    ref = require('enum'),
    _ = require('underscore')

module.exports = function($http, $i18next, $rootScope){

    $rootScope.refreshTimingInMs = ref.DURATION.ONE_MINUTE_IN_MILLISECONDS;
    $rootScope.refreshId = null;

    let subscriber = $appScope.get('user');

    return {
        // refreshLoopPowerBar: refreshLoopPowerBar,
        fetchPowerBarData: fetchPowerBarData,
    };

    /**
     * Init a refreshing loop of the power bar in N milliseconds, N is defined by the last global consumption duration
     * @param currentPageScope scope of the current page
     * @param pageName name of the page as described as in its own scope (ex for main board, it's 'mainBoard')
     * @param lastConsumptionsError
     * @param lastConsumptions
     */
    function refreshLoopPowerBar(currentPageScope, pageName, lastConsumptionsError, lastConsumptions){
        //use a watch on the current page scope, so when we leave the page it will stop to watch the change
        let isError = !!lastConsumptionsError;

        //if there's no consumption and no error, do nothing
        let duration = ref.DURATION.TEN_MINUTE_IN_MILLISECONDS;

        if( null != lastConsumptions
            && null != lastConsumptions.immediateConsumptionInkW
            && null != lastConsumptions.immediateConsumptionInkW.duration
            && ! isError ){
            duration = lastConsumptions.immediateConsumptionInkW.duration * 1000;
        } else {
            return;
        }

        let loopRefreshTiming = ref.DURATION.ONE_MINUTE_IN_MILLISECONDS;
        if( duration < ref.DURATION.TEN_MINUTE_IN_MILLISECONDS ) {
            loopRefreshTiming = $appScope.get('user').getRealTimeRefreshPeriod();
        }
        if (!currentPageScope.resfreshIds) {
          currentPageScope.resfreshIds = [];
        }
        currentPageScope.resfreshIds.push(
            setTimeout(function () {
                if ($appScope.get('user').isLogged()) {
                    fetchPowerBarData(currentPageScope, pageName);
                }
            }, loopRefreshTiming));
    }

    /**
     * Get the most most recent consumptions (global and by appliances)
     * First we try to get real time (time step in seconds) otherwise try the immediate consumption (time step in min)
     * @param currentPageScope scope of the current page
     * @param pageName name of the page as described as in its own scope (ex for main board, it's 'mainBoard')
     */
    function fetchPowerBarData(currentPageScope, pageName){
        currentPageScope.$on('$destroy', function() {
            if ( null != currentPageScope.resfreshIds ) {
                let length = currentPageScope.resfreshIds.length;
                while (length--) {
                    clearTimeout(currentPageScope.resfreshIds[length]);
                    currentPageScope.resfreshIds.splice(length, 1);
                }
            }
        });

        currentPageScope[pageName].lastConsumptionsError = null;
        let source = subscriber.hasAccessPermission(ref.ACCESS.WIDGET.DISPLAY_INSTANT_POWER)?fetchInstantPower:fetchRealTimeConsumption;
        source(function (err, consumptionData) {
            _.extend(currentPageScope[pageName],{
                    lastConsumptionsError: err,
                    lastConsumptions: consumptionData
                });
            refreshLoopPowerBar(currentPageScope, pageName, err, consumptionData);
        });
    }

    function fetchInstantPower(cb){
        $http.get("/instantPower/last.json")
            .then(function onSuccess(response){
                //TODO temporary solution : build the same object as real time data
                let instantPowerData = {
                    immediateConsumptionInkW: {
                        consumption: response.data.instantPower.consumption,
                        duration: response.data.instantPower.duration,
                    }
                };
                cb(null, instantPowerData);
            })
            .catch(function onError(err) {
                cb(err);
            });
    }

    /**
     * Get real time consumption (data from DATA_CONTINUOUS.DATA_REAL_TIME_POWER_*)
     * @param cb
     */
    function fetchRealTimeConsumption(cb) {
        $http.get('/siteDataRealTime/lastMinuteConsumption.json')
            .then(function onSuccess(response) {
                if (response.status === 200 && response.data) {
                    cb(null, response.data);
                } else if (response.status === 204) {
                    //if no data found for real time consumption, look for immediate consumption
                    fetchImmmediateConsumption(cb);
                }
            })
            .catch(function onError(response) {
                //if failed to fetch real time consumption look for immediate consumption
                fetchImmmediateConsumption(cb);
            });
    }

    /**
     * Get immediate consumption (data from DATA_V3.DATA_HIST_POWER)
     * @param cb
     */
    function fetchImmmediateConsumption(cb) {
        /*
        For now, the real time activation is sent once to the pilot when the user is logged in
        The activation time only last 10 min, so during 10 min the pilot will send its 2 seconds points
        If we wait 10 min to check the data, we'll miss the activation period
        That's why we wait for only 1 minute
        */

        $http.get('/siteData/immediateConsumptionInkW.json')
            .then(function onSuccess(response) {
                if (response.status === 200 && response.data) {
                    cb(null, response.data);
                //no data and no data in cache
                } else if(response.status === 204 || (response.status === 304 && !response.data)){
                    cb($i18next('detailedConsumptionChart.errorNoConsumption'));
                }
            })
            .catch(function onError(response) {
                logger.error(response);
                cb($i18next('annualConsumptionChart.errorLoadingChart'));
            });
    }
};
