const ref = require('../enum');

module.exports = {
    getDisplayGroupByHostName,
    getPageTitleByHostName,
    getDescriptionByHostName,
    getLogoURLByHostName,
    getLogoMinURLByHostName,
    isEkwateur,
};

function getDisplayGroupByHostName(hostname) {
    if (hostname.includes('electrik')) {
        return ref.ACCESS.DISPLAY_GROUP.GENI;
    }

    if (hostname.includes('myek') || hostname.includes('ekwateur')) {
        return ref.ACCESS.DISPLAY_GROUP.EKWATEUR;
    }

    if (hostname.includes('voltalis') || hostname.includes('localhost')) {
        return ref.ACCESS.DISPLAY_GROUP.VOLTALIS;
    }

    return null;
}

function getPageTitleByHostName(hostname) {
    const displayGroup = getDisplayGroupByHostName(hostname);
    if (displayGroup === ref.ACCESS.DISPLAY_GROUP.GENI) {
        return "My Gen-i";
    }

    if (displayGroup === ref.ACCESS.DISPLAY_GROUP.EKWATEUR) {
        return "Boîtier NARCO by ekWateur – Pilotage de chauffage électrique";
    }

    if (displayGroup === ref.ACCESS.DISPLAY_GROUP.VOLTALIS) {
        return "MyVoltalis | Pilotez vos consommations d’énergie";
    }

    return hostname;
}

function getDescriptionByHostName(hostname) {
    const displayGroup = getDisplayGroupByHostName(hostname);
    if (displayGroup === ref.ACCESS.DISPLAY_GROUP.EKWATEUR) {
        return "Avec ekWateur économisez jusqu’à 30% d’énergie. Suivez votre consommation, pilotez et mettez votre chauffage électrique en sommeil sans impact sur votre confort.";
    }

    if (displayGroup === ref.ACCESS.DISPLAY_GROUP.VOLTALIS) {
        return "Votre espace pour suivre en temps réel et optimiser votre consommation électrique, piloter vos radiateurs à distance et réaliser des économies d’énergie ! Sans frais, ni abonnement. Pas encore équipés du boîtier Voltalis ? Contactez-nous !";
    }

    return "Analysez, comparez et maîtrisez votre consommation électrique.";
}

function getLogoURLByHostName(hostname) {
    if (hostname.includes('electrik')) {
        return 'gen-i_60_w.png';
    }

    if (hostname.includes('myek') || hostname.includes('ekwateur')) {
        return 'Ekwateur_Logo_V2.png';
    }

    if (hostname.includes('voltalis') || hostname.includes('localhost')) {
        return 'my_voltalis_logo_small_60.png';
    }

    return null;
}

function getLogoMinURLByHostName(hostname) {
    if (hostname.includes('electrik')) {
        return 'gen-i_60_w.png';
    }

    if (hostname.includes('myek') || hostname.includes('ekwateur')) {
        return 'ekwateur_favicon.png';
    }

    if (hostname.includes('voltalis') || hostname.includes('localhost')) {
        return 'my_voltalis_logo_small_short_version_50.png';
    }

    return null;
}

function isEkwateur(hostname) {
    return getDisplayGroupByHostName(hostname) == ref.ACCESS.DISPLAY_GROUP.EKWATEUR;
}
