const _ = require('underscore');

module.exports = function(){
    return {
        restrict: 'E',
        scope: {
            getProgress: "&",
            getStepWidth: "&",
        },
        templateUrl : './dataComponents/progressBar/progress-bar.html',
        link: function(scope) {
            scope.$on( 'selectedSiteHasChanged', function() {
                scope.progressBar.progress = scope.progressBar.getProgress();
                scope.progressBar.width = scope.progressBar.getStepWidth(scope.progressBar.progress);
            });
        },
        controller: ['$scope', function($scope){
            $scope.progressBar = {};
            $scope.progressBar.getProgress = $scope.getProgress();
            $scope.progressBar.getStepWidth = $scope.getStepWidth();

            $scope.progressBar.progress = $scope.progressBar.getProgress();
            $scope.progressBar.width = $scope.progressBar.getStepWidth($scope.progressBar.progress);
        }]
    }
};
