var moment = require('moment'),
    _ = require('underscore');

var ref = require('enum'),
    logger = require('logger');

var hourType = {
    OFFPEAK: {
        id: ref.HOUR_TYPE.OFFPEAK,
        name: 'creuse'
    },
    PEAK: {
        id: ref.HOUR_TYPE.PEAK,
        name: 'pleine'
    }
};

var SELECTED = ' selected';

module.exports = function () {
    return {
        restrict: 'EA',
        replace: true,
        scope: {
            datasource: '='
        },
        templateUrl: './components/peakHourPicker/peak-hour-picker.html',
        link: function watchDataSource(scope, elm, attr) {
            logger.debug('peakHourDirective', 'link');

            scope.initTimeTable( scope.datasource );

            scope.$watch( 'datasource', function( newValue, oldValue ) {
                if( newValue !== oldValue ) {
                    scope.initTimeTable( newValue );
                }
            }, true);

            if( scope.isTouchScreen ){
                logger.debug('peakHourDirective', 'using touch events');
                angular.element(elm).addClass('touchPicker');
                scope.touchEvent( true, elm );
                scope.$on('$destroy', function(){
                    scope.touchEvent( false, elm );
                });
            }
        },
        controller: ['$scope', function ($scope) {
            $scope.touchEvent = touchEvent;
            $scope.initTimeTable = initTimeTable;
            $scope.getHours = getHours;
            $scope.getClass = getClass;
            $scope.start = start;
            $scope.stop = stop;
            $scope.display = display;
            $scope.offPeakHours = [];
            $scope.from = null;
            $scope.mouseover = null;
            $scope.wip = false;
            $scope.selectionClass = null;

            function save() {
                _.each($scope.times, function (time) {
                    time.class = time.class || time.defaultClass;
                    time.defaultClass = time.class.replace( SELECTED, '');
                    time.class = null;
                });
                getOffPeakHours();
                $scope.datasource = getPlanning();
            }

            /**
             * Complete $scope.times from data
             * @param datasource
             */
            function initTimeTable( datasource ){
                $scope.times = [];
                $scope.offPeakHours = datasource;
                var ms = 60 * 60 * 1000;
                var step = 0.5;
                for( var t = 0; t < 24; t += step ) {
                    var className = hourType.PEAK.name;
                    if( hourType.OFFPEAK.id == $scope.datasource[t*2] ){
                        className = hourType.OFFPEAK.name;
                    }
                    var time = {
                        value: t,
                        hour: moment(t * ms).subtract(1, 'h').format("HH"),
                        startTime: moment(t * ms).subtract(1, 'h').format("HH:mm"),
                        endTime: moment((t + step) * ms).subtract(1, 'h').format("HH:mm"),
                        defaultClass: className
                    };
                    time.title = time.startTime + ' - ' + time.endTime;
                    $scope.times.push(time);
                }
            }

            function display( time ){
                if ($scope.wip) {

                    var start = $scope.from.value,
                        to = time.value;

                    _.each($scope.times, function (time) {
                        if (to > start) {
                            if (time.value >= start && time.value <= to)
                                time.class = $scope.selectionClass + SELECTED;
                            else
                                time.class = time.defaultClass;
                        }
                        else {
                            if (time.value <= start && time.value >= to)
                                time.class = $scope.selectionClass + SELECTED;
                            else
                                time.class = time.defaultClass;
                        }
                    });
                }
            }

            function getHours(){
                var hours = _.map($scope.times, function (time) {
                    return time.hour;
                });
                return _.uniq(hours);
            }

            function getClass( time ){
                time.defaultClass = time.defaultClass || hourType.PEAK.name;
                if (time == $scope.from)
                    return (time.class || time.defaultClass) + SELECTED;
                else
                    return time.class || time.defaultClass;
            }

            function getOffPeakHours() {
                var offPeakHours = [],
                    startOffPeakHours = false,
                    endOffPeakHours = false;

                _.each($scope.times, function (time) {
                    if (time.defaultClass.indexOf( hourType.OFFPEAK.name ) > -1){
                        if (!startOffPeakHours) {
                            startOffPeakHours = _.clone(time.startTime)
                        }
                        endOffPeakHours = _.clone(time.endTime)
                    } else {
                        if (startOffPeakHours) {
                            offPeakHours.push([startOffPeakHours, endOffPeakHours]);
                            startOffPeakHours = false;
                        }
                    }
                });

                if( startOffPeakHours ) {
                    offPeakHours.push([startOffPeakHours, endOffPeakHours]);
                }

                $scope.offPeakHours = JSON.stringify(offPeakHours);
                return  $scope.offPeakHours;
            }

            function getPlanning(){
                var result = [];
                var t = $scope.times;
                for( var i=0; i<t.length; ++i ){
                    if( t[i].defaultClass.indexOf( hourType.OFFPEAK.name ) > -1 ){
                        result.push( hourType.OFFPEAK.id );
                    } else {
                        result.push( hourType.PEAK.id );
                    }
                }
                return result;
            }

            function start( time ){
                if ($scope.wip)
                    $scope.stop();
                $scope.from = time;
                $scope.wip = true;
                $scope.selectionClass = switchClass(time.defaultClass);
                time.class = $scope.selectionClass;
            }

            function stop(){
                save();
                $scope.wip = false;
                $scope.from = null;
            }
            function switchClass(className) {
                return (className.indexOf( hourType.OFFPEAK.name ) > -1) ? hourType.PEAK.name : hourType.OFFPEAK.name;
            }

            function touchEvent( doBind, container ){
                var fn = (doBind) ? "on" : "off";
                var $ = angular.element; // reading confort

                $(container)[fn]('touchstart', '.time', function(e){
                    var found = _.findWhere($scope.times, {title: $(this).attr('title')});
                    start( found );
                });

                $(container)[fn]('touchend', '.time', function(e){
                    stop();
                });

                $(container)[fn]('touchmove', '.time', function(e){
                    var touch = event.touches.item(0);
                    var hoverItem = document.elementFromPoint(touch.clientX, touch.clientY);
                    var found = _.findWhere($scope.times, {title: hoverItem.title});
                    if( found ) display(found);
                });


            }

        }]
    };
};