// TODO: faire le menage entre le debounce, le render et le reflow, c'est incompréhensible
// todo: duplicated from detailedConsumptionPieChart
const _ = require('underscore');
const moment = require('moment');

const $appScope = require('appScope');
const config = require('config');
const logger = require('logger');


const ref = require('enum');

const dateFr = config.dateFormat.dateFr;

const STATUS_PENDING = {};
const STATUS_ERROR = {};
const STATUS_OK = {};

module.exports = function () {
    return {
        restrict: 'E',
        template: '<div class="reportConsumptionPieChart" style="height: inherit;"><loader></loader></div>',
        scope: {
            startDate: "=",
            endDate: "=",
            chartDataType: '=',
            isWebView: "="
        },
        link: function(scope) {
            logger.debug('reportConsumptionPieChart', 'link');

            // todo: voir pour ne pas faire un fetch si chartDataType change
            scope.$watch("chartDataType", function(){
                logger.debug('startDate or endDate updated');
                scope.reportConsumptionPieChart.fetch()
            });
            scope.reportConsumptionPieChart.fetch();
        },
        controller: ['$rootScope', '$scope', '$element', '$http', '$timeout', '$i18next',
            function ($rootScope, $scope, $element, $http, $timeout, $i18next) {
            $scope.reportConsumptionPieChart = {};
            $scope.reportConsumptionPieChart.status = null;
            $scope.reportConsumptionPieChart.fetch = fetch;
            $scope.reportConsumptionPieChart.getChartFromCurrentOptions = getChartFromCurrentOptions;
            $scope.reportConsumptionPieChart.render = render;
            $scope.reportConsumptionPieChart.data = null; // fetched data

            function fetch(){
                logger.debug('reportConsumptionPieChartDirective', 'fetch started');
                $scope.reportConsumptionPieChart.status = STATUS_PENDING;
                render( $i18next("reportConsumptionChart.loadingChart") );

                $http({
                    method: 'GET',
                    url: '/chart/getReportConsumptionPieCharts.json',
                    params: {
                        startDate: $scope.startDate.format(dateFr),
                        endDate: $scope.endDate.format(dateFr),
                        isWebView: $scope.isWebView
                    }
                })
                    .then(function onSuccess( response ) {
                        logger.debug('reportConsumptionPieChartDirective', 'fetch done');
                        $scope.reportConsumptionPieChart.status = STATUS_OK;
                        $scope.reportConsumptionPieChart.data = response.data;
                        const chart = getChartFromCurrentOptions();
                        if( chart )
                            render( null, chart );
                        else
                            render( $i18next("reportConsumptionChart.errorNoConsumption") );

                    })
                    .catch(function onError() {
                        logger.error('reportConsumptionChartDirective', 'fetch fail');
                    });
            }

            /**
             * return from fetched data chart coinciding with options
             * @returns {*}
             */
            function getChartFromCurrentOptions(){
                try{
                    // si on est sur un mode watt, on affiche le graph kwh
                    let mode = $scope.chartDataType;
                    if( mode.name === ref.CHART_DATA_TYPES.WATT.name ){
                        mode = ref.CHART_DATA_TYPES.KWH;
                    }
                    let unit = $rootScope.getUnitFromChartType(mode);
                    const reportConsumptionCharts = $scope.reportConsumptionPieChart.data.TOTAL;

                    const chart = reportConsumptionCharts[mode.name];

                    chart.plotOptions.pie.dataLabels.formatter = function(){
                        const maxValue = 4;

                        if(this.percentage > maxValue && !this.point.hideData){
                            return '<span>' + this.percentage.toFixed(0) + '%</span>';
                        }
                        return null;
                    };
                    chart.tooltip = {
                        enabled: !$scope.isWebView,
                            formatter: function() {
                            if ( this.point.hideData != null && this.point.hideData == true)
                                return false;
                            return this.point.name + ' : <b> ' + this.point.y.toFixed(2) + ' ' + unit +
                                ' </b> (' + this.point.percentage.toFixed(0) + '%)<br/>'
                        }
                    };
                    return chart;
                }
                catch(e){
                    logger.warn('reportConsumptionPieChartDirective', 'no data for selected site');
                    return null;
                }
            }

            function render( errorMessage, chart ) {
                var $chart = $element.find('.reportConsumptionPieChart');
                if ( $chart == null )
                    return;
                if (errorMessage){
                    $chart.highcharts( config.emptyChart );
                    $chart.highcharts().showLoading(errorMessage);
                }
                else{
                    showAllDatas(chart);
                    $chart.highcharts( chart );
                }
            }

            function showAllDatas(chart){
                chart.series.forEach(serie => {
                    serie.data.forEach( data => {
                        data.visibile = true;
                    })
                })
            }
        }]
    }
};
