var _ = require('underscore');

module.exports = ['$i18next',function ($i18next) {
    return {
        addFeatureCollection: addFeatureCollection,
        addColorAxisCustomLabel: addColorAxisCustomLabel,
        adjustRendering: adjustRendering,
    };
}];

function addFeatureCollection( map ){
    _.each(map.series, function(serie){
        serie.mapData = Highcharts.maps['countries/fr/custom/fr-all-all-mainland'];
    });
    return map;
}

function addColorAxisCustomLabel( map, heatingConsumption ){
    map.colorAxis.labels = {
        formatter: function(){
            if( heatingConsumption == this.value ){
                return '<span style="font-weight:bold" class="myConsumptionLegend">Ma conso</span>';
            }
            return '';
        },
        y: 17 // La valeur par défaut (20) fait dépasser le texte sous Chrome
    }
}



// Code un peu crade pour replacer les éléments (exception 'Ma conso') de la légende au dessus du colorAxis (sinon il y a des pbs de superposition)
// TOOD : Trouver un moyen de faire ça en Highcharts

function adjustRendering( $map,$i18next ){
    var $container = $map;
    var $labelsContainer = $container.find('.highcharts-coloraxis-labels').eq(0);

    if( null == $labelsContainer ){
        return;
    }

    // On replace le premier et le dernier item de type text
    var legendEltsCount = $labelsContainer.children().length;
    if( legendEltsCount > 0 ){
        // Des fois il y a un élément path à la fin
        if( $labelsContainer.children()[ legendEltsCount - 1 ].tagName != "text" ){
            legendEltsCount--;
        }

        $labelsContainer.children()[ 0 ].setAttribute( "y", 0 );
        $labelsContainer.children()[ 0 ].setAttribute( "x", -15 );
        $labelsContainer.children()[ 0 ].setAttribute( "opacity", 1 );
        $labelsContainer.children()[ legendEltsCount - 1 ].setAttribute( "y", 0 );
        $labelsContainer.children()[ legendEltsCount - 1 ].setAttribute( "opacity", 1 );
    }

    // Remplacer ma conso par ma conso de chauffage (si on met directement ma conso de chauffage, highcharts va faire pivoter le texte en créant la chart)

    const $labelMyConsumption = $labelsContainer.find('.myConsumptionLegend').eq(0);
    if (null == $labelMyConsumption) {
        return;
    }
    $labelMyConsumption.html($i18next('myTooltipFormatter.myHeatingConso'));
}
