var $appScope = require('../appScope'),
    moment = require("moment"),
    _ = require("underscore"),
    angular = require('angular'),
    logger = require('logger'),
    ref = require("enum");

var tools = require('tools').tools;

module.exports = ['$scope', '$timeout', '$http', '$i18next', function($scope, $timeout, $http, $i18next) {
    var dateFormat = "DD/MM/YYYY HH:mm";

    $scope.absenceMode = {};
    $scope.absenceModeTemp = {};
    $scope.headerBorderClass = "";
    $scope.availableAppliance = [];
    $scope.availableApplianceTemp = [];
    $scope.csLinkList = $appScope.get('user').getSelectedSiteCsLinkList();
    $scope.slider = {
        value : 1,
        options: {
            showSelectionBar: true,
            disabled: false,
            showTicksValues: 1,
            translate: function(value){
                if(value/24 < 1)
                    return value + "h";
                else
                    return value/24 + $i18next("absence.dayShortCut");
            },
            stepsArray: [1,2,3,5,8,12,24,48,96,192]
        }
    };

    $scope.initializeAbsenceConfigModal = function(){
        $scope.availableApplianceTemp = angular.copy($scope.availableAppliance);
    };

    $scope.updateAbsenceConfig = function(){
        $scope.availableAppliance = angular.copy($scope.availableApplianceTemp);
        var data = {
            "config": $scope.availableAppliance
        };
        $http({
            method: 'POST',
            url: "/absence/updateAbsenceConfig",
            data: data
        })
            .then(function onSuccess(response){
                logger.debug("Absence Config", "Update success");
            })
            .catch(function onError(response) {
                logger.error('error', response.data.message);
            });
    };

    $scope.initializeAbsenceMode = function(){
        $scope.absenceModeTemp.dateChoosen = "";
        $scope.absenceModeTemp.hasEndDate = false;
        $scope.absenceModeTemp.isActivated = false;
    };

    $scope.updateAbsenceMode = function(){
        var value = $scope.slider.value;
        var now = moment();
        $scope.absenceModeTemp.dateChoosen = now.add(value,"h").format(dateFormat);
        $scope.absenceModeTemp.isActivated = true;
        $scope.absenceMode = angular.copy($scope.absenceModeTemp);
        $scope.user.absenceMode.isActivated = true;
        const data = {
            absenceModeConfig: {
                absenceConfig: $scope.availableAppliance,
                absenceMode: $scope.absenceMode,
            }
        };
        $http({
            method: 'POST',
            url: '/programmationEvent/updateAbsenceMode',
            data: data,
        })
            .then(function onSuccess(response){
                logger.debug("Update Absence Event", "success");
            })
            .catch(function onError(response){
                logger.error("Update Absence Event", response.data.message);
            });
    };

    $scope.cancelBtn = function(){
        $scope.switchState = $scope.absenceMode.isActivated;
    };

    $scope.render = function(){
        //timeout pour le slider qui se génère mal quand il est dans une balise invisible au chargement de la page, ne fonctionne pas sans les 200ms
        $timeout(function() {
            $scope.$broadcast('rzSliderForceRender');
        },400);
        $scope.slider.value = 1;
    };

    function getAbsenceState(currentCsLink, cb){
        var params = {
            csLinkId: currentCsLink.csLinkId
        };
        $http.get('/absence/getAbsenceState.json',{"params":params})
            .then(function onSuccess(response) {
                currentCsLink.status = response.data.absenceState;
                cb();
            })
            .catch(function onError(response) {
                cb(response.data.message);
            });

    }

    function getAbsenceModeState(absenceMode, cb){
        $http.get("/programmationEvent/getAbsenceModeState.json")
            .then(function onSuccess(response){
                logger.debug("Absence Mode State Fetch", "success");
                absenceMode.isActivated = response.data.absenceModeState;
                $scope.user.absenceMode.isActivated = absenceMode.isActivated;
                $scope.absenceMode.dateChoosen = response.data.endDate;
                $scope.absenceMode.hasEndDate = response.data.hasEndDate;
                cb();
            })
            .catch(function onError(response){
                logger.debug("Absence Mode State Fetch", "error");
                cb(response.data.message)
            });
    }

    function initialize(){
        getAbsenceModeState($scope.absenceMode, function(err){
            if (err){
                logger.error("getAbsenceModeState error", err);
                return;
            }
            $scope.switchState = $scope.absenceMode.isActivated;
        });
        _.each($scope.csLinkList,function(csLink){
            if(tools.isProgrammable(csLink)) {
                var currentCsLink = {};
                currentCsLink.name = csLink.name ? csLink.name : $i18next( csLink.defaultName );
                currentCsLink.csLinkId = csLink.csLinkId;
                currentCsLink.csLinkToCutId = csLink.csLinkToCutId;
                getAbsenceState(currentCsLink, function (err) {
                    if (err) {
                        logger.error("getAbsenceState error", err);
                        return;
                    }
                    $scope.availableAppliance.push(currentCsLink);
                    $scope.availableAppliance = _.sortBy($scope.availableAppliance, 'csLinkId');
                })
            }
        });
    }

    initialize();



    $('#onOff').on('change', function(e){
        if(!e.target.checked){
            $scope.absenceMode.dateChoosen = "";
            $scope.absenceMode.hasEndDate = false;
            $scope.absenceMode.isActivated = false;
            $scope.user.absenceMode.isActivated = false;
            $http({
                method: 'POST',
                url: '/programmationEvent/cancelAbsenceMode'
            })
                .then(function onSuccess(response){
                    // allow to see again the Absence pop-up, if we activate it again
                    $.removeCookie('alreadySawAbsencePopup');
                    logger.debug("Cancel Absence Event", "success")
                })
                .catch(function onError(response){
                    logger.error("Cancel Absence Event", response.data.message)
                });
        }
        else {
            $('#modalDate').modal({backdrop: 'static', keyboard: false});
            $scope.initializeAbsenceMode();
        }
    });

    $scope.$watch("switchState", function(){
        if($scope.switchState){
            $scope.headerBorderClass = "greenBorder";
        }
        else {
            $scope.headerBorderClass = "";
        }
    });
}];

