var Highcharts = require('highcharts');

//TODO refactor this function
$(function (H) {
    H.seriesType('lineargauge', 'column', null, {
        setVisible: function () {
            H.seriesTypes.column.prototype.setVisible.apply(this, arguments);
            if (this.markLine) {
                this.markLine[this.visible ? 'show' : 'hide']();
            }
        },
        drawPoints: function () {
            // Draw the Column like always
            H.seriesTypes.column.prototype.drawPoints.apply(this, arguments);

            // Add a Marker
            var chart = this.chart,
                inverted = chart.inverted,
                xAxis = this.xAxis,
                yAxis = this.yAxis,
                point = this.points[0], // we know there is only 1 point
                markLine = this.markLine,
                ani = markLine ? 'animate' : 'attr';


            // Hide column
            point.graphic.hide();

            if (!markLine) {
                var verticalLine = ['M', 0, 0 -15, 'L', 0, 12 + xAxis.len ];
                markLine = this.markLine = chart.renderer.path(verticalLine )
                    .attr({
                        'fill': '#b80501',
                        'stroke': '#b80501',
                        'stroke-width': 3
                    }).add();
            }
            markLine[ani]({
                translateX: inverted ? xAxis.left + yAxis.translate(point.y) : xAxis.left,
                translateY: inverted ? xAxis.top : yAxis.top + yAxis.len -  yAxis.translate(point.y)
            });
        }
    });

}(Highcharts));

module.exports = function(){
    return {
        restrict: 'E',
        templateUrl : './dataComponents/immediateConsumptionInkWbyItem/immediate-consumption-in-kw-by-item.html',
        scope: {
            csLinkId: '=csLinkId',
            immediateConsumption: '=immediateConsumption',
            immediateConsumptionError: '=immediateConsumptionError',
            noConsumptionCustomMessage: '=noConsumptionCustomMessage',
        },
        controller: require('../immediateConsumption/immediateConsumptionController'),
    }
};
