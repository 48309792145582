var _ = require('underscore'),
    logger = require('logger'),
    ref = require('enum'),
    moment = require('moment');

var $appScope = require('../appScope');

module.exports = ['$scope', 'immediateConsumptionTools', function($scope, immediateConsumptionTools) {

    var STATUS_PENDING = {};
    var STATUS_OK = {};

    var subscriber = $appScope.get('user'),
    selectedSite = subscriber.getSelectedSite(),
    department = selectedSite.department;

    $scope.Math = Math;
    $scope.CHART_DATA_TYPES = ref.CHART_DATA_TYPES;

    $scope.meteoOptions = {
        startDate: null,
        endDate: null,
        unit: 'week'
    };

    $scope.mapOptions = {
        mapType: '7days',
        useLegend: false
    };

    $scope.mainBoard = {};
    $scope.mainBoard.status = null;
    $scope.mainBoard.weekConsumption = null;
    $scope.mainBoard.mapData = null;
    $scope.mainBoard.lastConsumptions = {};
    $scope.mainBoard.lastConsumptionsError = null;

    initialize();

    $scope.mainBoard.hasPendingStatus = function () {
        return $scope.mainBoard.status === STATUS_PENDING;
    };

    $scope.mainBoard.hasOkStatus = function () {
        return $scope.mainBoard.status === STATUS_OK;
    };


    function initialize(){
        logger.debug('mainBoardController', 'initialization');

        // Mois précédent

        var startDate = moment().startOf('day').subtract(1, 'week'),
            endDate = moment().endOf('day').subtract(1, 'day');

        $scope.meteoOptions.startDate = startDate;
        $scope.meteoOptions.endDate = endDate;

        $scope.mainBoard.status = STATUS_PENDING;

        // Events meaning that weekConsumptionChartsHasChanged events will be triggered, meanwhile, display pending status
        $scope.$on('selectedSiteHasChanged', function(){
            logger.debug('mainBoardController', 'receiving event', 'selectedSiteHasChanged');
            $scope.mainBoard.status = STATUS_PENDING;
        });

        // Events for "Conso 7 jours" and "Coût 7 jours", data are picked from weekConsumptionChartDirective
        $scope.$on('weekConsumptionChartsHasChanged', function(event, weekConsumptionCharts){
            logger.debug('mainBoardController', 'receiving event', 'weekConsumptionChartsHasChanged');
            $scope.mainBoard.status = STATUS_OK;
            $scope.mainBoard.weekConsumption = calculateWeekConsumptionFromCharts( weekConsumptionCharts );
        });

        $scope.$on('7DaysConsumptionDepartment', function(event, mapData){
            $scope.mainBoard.mapData = mapData;
        });

        // immediateConsumptionTools.refreshLoopPowerBar($scope, 'mainBoard');
        immediateConsumptionTools.fetchPowerBarData($scope, 'mainBoard');
    }
}];

function sumSerieData(serie) {
    let sum = 0;
    if (serie) {
        serie.data.forEach(point => {
            sum += point.y != null ? point.y : 0;
        });
    }
    return sum;
}

function calculateWeekConsumptionFromCharts( weekConsumptionCharts ) {
    var weekConsumption = {
        production: {
            kwh: 0,
            euro: 0
        }, consumption: {
            kwh: 0,
            euro: 0
        }
    };

    if( weekConsumptionCharts.kwh ){
        const kwhSeries = weekConsumptionCharts.kwh.series;

        const sevenDaysConsumption = kwhSeries.filter(serie => serie.sevenDaysConsumption);
        sevenDaysConsumption.forEach(serie => {
            weekConsumption.consumption.kwh += sumSerieData(serie)
        })

        const production = kwhSeries.find(serie => {return serie.id === 'production'})
        weekConsumption.production.kwh= sumSerieData(production);
    }

    if( weekConsumptionCharts.euro ){
        const costSerie = weekConsumptionCharts.euro.series.filter( serie => serie.sevenDaysCost);
        costSerie.forEach(serie => {
            weekConsumption.consumption.euro += sumSerieData(serie)
        })
    }
    return weekConsumption;
}