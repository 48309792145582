var _ = require('underscore');
var moment = require('moment');

module.exports = function () {
    return {
        restrict: 'E',
        templateUrl: './components/dateRangePicker/date-range-picker.html',
        scope: {
            ngModel: '=',
            link: '=',
            ngDisabled: "="
        },
        controller: ['$scope', '$element', '$i18next', function link($scope, $element, $i18next) {
            $scope.dateRangePicker = {};
            $scope.isRangeSelected = false;

            initialize();

            function initialize(){
                var startDate = moment($scope.ngModel.startDate).startOf('day') || moment().startOf("day");
                var endDate = moment($scope.ngModel.endDate).endOf('day') || moment().endOf("day");

                var ranges = {};
                ranges[$i18next("dateRangePicker.today")]= [moment().startOf("day"), moment().endOf('day')];
                ranges[$i18next("dateRangePicker.week")]= [moment().startOf('week'), moment().endOf('week')];
                ranges[$i18next("dateRangePicker.lastWeek")]= [moment().startOf('week').subtract(1, 'week'), moment().endOf('week').subtract(1, 'week')];
                ranges[$i18next("dateRangePicker.months")]=[moment().startOf('month'), moment().endOf('month')];
                ranges[$i18next("dateRangePicker.year")]=[moment().startOf('year'), moment().endOf('year')];

                var options = {
                    format: 'DD/MM/YYYY',
                    opens: 'left',
                    buttonClasses: "btn btn-mobile",
                    alwaysShowCalendars: true,
                    linkedCalendars: false,
                    locale: {
                        applyLabel: $i18next("dateRangePicker.applyLabel"),
                        cancelLabel: $i18next("dateRangePicker.cancelLabel"),
                        fromLabel: $i18next("dateRangePicker.fromLabel"),
                        toLabel: $i18next("dateRangePicker.toLabel"),
                        customRangeLabel: 'Personnalisé',
                        daysOfWeek: $i18next("dateRangePicker.daysOfWeek"),
                        firstDay: 1,
                        selection: $i18next("dateRangePicker.selection"),
                        quickselect: $i18next("dateRangePicker.quickselect")
                    }
                };
                $element.find('#dateRangePicker').daterangepicker(_.extend(_.extend(options,{ranges:ranges}), $scope.ngModel.options), function (start, end, label) {
                    $scope.ngModel.startDate = start;
                    $scope.ngModel.endDate = end;
                    $scope.isRangeSelected = (label != null || label !== options.customRangeLabel);
                    onDateChange();
                });

                $element.find('#dateRangePicker').data('daterangepicker').setStartDate(startDate);
                $element.find('#dateRangePicker').data('daterangepicker').setEndDate(endDate);

                function onDateChange(){
                    updateInput($scope.ngModel.startDate, $scope.ngModel.endDate);
                }

                $scope.$watchGroup(['ngModel.startDate', 'ngModel.endDate'], onDateChange);
                $scope.$watch('ngDisabled', function() {
                    $scope.dateRangePicker.disabled = $scope.ngDisabled;
                });
            }

            $scope.setRange = function(toAdd) {
                var endDate = $scope.ngModel.endDate;
                var startNextDate = moment(endDate).add(1,'d').startOf('day').valueOf();
                var startDate = $scope.ngModel.startDate;

                var rangeInYears = moment(startNextDate).diff(startDate, 'years', true);
                var rangeInMonths = moment(startNextDate).diff(startDate, 'months', true);
                var rangeInWeeks = moment(startNextDate).diff(startDate, 'weeks', true);
                var rangeInDays = moment(endDate).diff(startDate, 'days', true);

                var rangeToApply;
                var unitToApply;
                if (rangeInYears >= 1) {
                    rangeToApply = rangeInYears;
                    unitToApply = 'years';
                } else if (rangeInMonths >= 1 && $scope.isRangeSelected) {
                    rangeToApply = rangeInMonths;
                    unitToApply = 'months';
                } else if (rangeInMonths >= 1 && ! $scope.isRangeSelected) {
                    rangeToApply = rangeInDays;
                    unitToApply = 'days';
                } else if (rangeInWeeks >= 1) {
                    rangeToApply = rangeInWeeks;
                    unitToApply = 'week';
                } else if (rangeInDays >= 1) {
                    rangeToApply = rangeInDays;
                    unitToApply = 'days';
                } else {
                    rangeToApply = 1;
                    unitToApply = 'days';
                }
                // LSN : This self-assignment instruction is NEEDED because of what looks like a bug in an uglifyJS optimisation.
                // The optimisation tries to inline the set of the variables rangeToApply & unitToApply within the
                // changeDate function call. Inlining the 2 at the same time fails with rangeToApply being undefined.
                // The below instruction makes the inline assignment pointless and thus uglifyJS discards the optimization.
                rangeToApply = rangeToApply;

                var newDates = changeDate(toAdd, rangeToApply, unitToApply);
                $scope.ngModel.endDate = moment(newDates.endDate);
                $scope.ngModel.startDate = moment(newDates.startDate);

                $('.noFocusBtn').blur();
            };

            function changeDate(toAdd, value, unit) {
                var endDate = $scope.ngModel.endDate;
                var startNextDate = moment(endDate).add(1, 'd').startOf('day').valueOf();
                var startDate = $scope.ngModel.startDate;
                var newDates;
                if (toAdd) {
                    if (unit == 'months') {
                        endDate = moment(startNextDate).add(value, unit).subtract(1,'d').valueOf();
                        startDate = moment(startDate).add(value, unit).valueOf();
                    } else {
                        endDate = moment(endDate).add(value, unit).valueOf();
                        startDate = moment(startDate).add(value, unit).valueOf();
                    }
                } else {
                    if (unit == 'months') {
                        endDate = moment(startNextDate).subtract(value, unit).subtract(1,'d').valueOf();
                        startDate = moment(startDate).subtract(value, unit).valueOf();
                    } else {
                        endDate = moment(endDate).subtract(value, unit).valueOf();
                        startDate = moment(startDate).subtract(value, unit).valueOf();
                    }
                }
                newDates = {endDate: endDate, startDate: startDate};
                return newDates;
            }

            function updateInput( startDate, endDate ){
                $element.find('#dateRangePicker').data('daterangepicker').setStartDate(startDate); // permet de mettre à jour le calendrier
                $element.find('#dateRangePicker').data('daterangepicker').setEndDate(endDate); // permet de mettre à jour le calendrier
                $element.find("#startDateValue").html(startDate.format('DD/MM/YYYY'));
                $element.find("#endDateValue").html(endDate.format('DD/MM/YYYY'));
            }
        }]
    }
};