var angular = require('angular');
var flashDirective = require('./flashDirective'),
    flashFactory = require('./flashFactory');

var flashModule = angular.module('flashModule', []);

// made from https://github.com/wmluke/angular-flash, plugin wasn't compatible browserify
flashModule.factory('flash', ['$rootScope', '$timeout', flashFactory]);
flashModule.directive('flashMessages', flashDirective);

module.exports = flashModule;