var $appScope = require('appScope');

module.exports = ['$scope', '$http', 'flash', function($scope, $http, flash) {

    var STATUS_PENDING = {};
    var STATUS_OK = {};
    var STATUS_NONE = {};
    var STATUS_ERROR = {};

    var subscriber = $appScope.get('user');

    $scope.myData = {};
    $scope.myData.subscriber = subscriber;
    $scope.myData.submit = onSubmit;
    $scope.myData.contactMail = $appScope.get('user').getSelectedSite().myVParams.CONTACT_ADDRESS;
    resetPasswordResetForm();


    function onSubmit(){
        if( $scope.myData.newPassword !== $scope.myData.newPasswordVerification ){
            flash('alert-danger', 'Erreur dans la saisie du nouveau mot de passe.', 5000);
            return null;
        }
        else if( !isPasswordCorrect( $scope.myData.newPassword ) ) {
            flash('alert-danger', 'Le nouveau mot de passe est invalide.', 5000);
            return null;
        }
        else if( $scope.myData.newPassword === $scope.myData.currentPassword ){
            flash('alert-danger', 'Le nouveau mot de passe ne peut pas être identique à l\'ancien.', 5000);
            return null;
        }

        $scope.myData.status = STATUS_PENDING;

        $http.post('/changeUserPassword', {
            currentPassword: $scope.myData.currentPassword,
            newPassword: $scope.myData.newPassword,
            newPasswordVerification: $scope.myData.newPasswordVerification
        }).then(function onSuccess(response){
            $scope.myData.status = STATUS_OK;
            flash('alert-success', response.data.message);

        }).catch(function onError(response){
            $scope.myData.status = STATUS_ERROR;
            flash('alert-danger', response.data.message);
        });
    }

    // Copy from server/subscriber/subscriberTools.js
    function isPasswordCorrect(password) {
        if (!password) {
            return false;
        }

        var minPasswordLength = 4;
        return password.length >= minPasswordLength
    }

    function resetPasswordResetForm(){
        $scope.myData.showPasswordResetForm = false;
        $scope.myData.status = STATUS_NONE;
        $scope.myData.currentPassword = "";
        $scope.myData.newPassword = "";
        $scope.myData.newPasswordVerification = "";
    }

    $scope.myData.hasPendingStatus = function () {
        return $scope.myData.status === STATUS_PENDING;
    };

    $scope.myData.hasErrorStatus = function () {
        return $scope.myData.status === STATUS_ERROR;
    };

    $scope.myData.hasOkStatus = function () {
        return $scope.myData.status === STATUS_OK;
    };

}];