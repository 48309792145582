const _ = require('underscore');
const logger = require('logger');
const $appScope = require('appScope');
const ref = require('enum');

module.exports = ['$scope', '$http', '$q', 'flash', function ($scope, $http, $q, flash) {

    $scope.myObjectives = {};

    $scope.myObjectives.objectives = {};
    $scope.myObjectives.form = {};

    $scope.myObjectives.monthNumber = -1;
    $scope.myObjectives.averageContractValue = null;
    $scope.myObjectives.siteContract = null;
    $scope.myObjectives.isSiteNotified = false;

    $scope.CHART_DATA_TYPES = ref.CHART_DATA_TYPES;

    $scope.myObjectives.submit = () => {
        $scope.myObjectives.submitPromise = submit($scope.myObjectives.form.objectiveEuro, $scope.myObjectives.isSiteNotified)
            .then((response) => {
                updateMyObjectiveInSelectedSite($scope.myObjectives.form.objectiveEuro);
                $scope.visit.goTo('myBudget');
                flash('alert-success', response.data.message, 3000);
            })
            .catch((response) => {
                $scope.myObjectives.init();
                flash('alert-danger', response.data.message, 5000);
            })
        ;
    };
    $scope.myObjectives.init = () => {
        const selectedSite = $appScope.get('user').getSelectedSite();
        const objectives = selectedSite.myObjective;
        $scope.myObjectives.form.objectiveEuro = objectives ? objectives.objective : 0;
        $scope.myObjectives.setKWhFields();
    };

    $scope.myObjectives.cancel = () => {
        $scope.visit.goTo('myBudget');
    };

    $scope.myObjectives.setKWhFields = () => {
        $scope.myObjectives.form.objectiveKWh = getKWhObjective($scope.myObjectives.form.objectiveEuro, $scope.myObjectives.averageContractValue);
    };
    $scope.myObjectives.setEuroFields = () => {
        $scope.myObjectives.form.objectiveEuro = getEuroObjective($scope.myObjectives.form.objectiveKWh, $scope.myObjectives.averageContractValue);
    };

    $scope.myObjectives.getObjectiveFromSelectedSite = () => {
        const selectedSite = $appScope.get('user').getSelectedSite();
        if (!selectedSite.contract) {
            return;
        }
        $scope.myObjectives.siteContract = selectedSite.contract;
        $scope.myObjectives.averageContractValue = getAverageContractValue($scope.myObjectives.siteContract);
        $scope.myObjectives.init();
        return getPastYearConsumption()
            .then((data) => {
                $scope.myObjectives.monthNumber = monthNumberCalculation(data);
                $scope.myObjectives.statementKWh = getTotalConsumedPowerFromCharts(data.kwh.series);
                $scope.myObjectives.statementEuro = getObjective($scope.myObjectives.statementKWh, $scope.myObjectives.averageContractValue);
            });
    };

    initialize();
    $scope.$on('selectedSiteHasChanged', initialize);

    function initialize() {
        logger.debug('myObjectivesController', 'init');
        $scope.myObjectives.consumptionPromise = $scope.myObjectives.getObjectiveFromSelectedSite();

        if ($scope.user.hasAccessPermission(ref.ACCESS.WIDGET.BUDGET_NOTIFICATION_ALERT)) {
            $scope.myObjectives.isSiteNotifiedPromise = loadIsSiteNotified()
                .then((res) => {
                    $scope.myObjectives.isSiteNotified = res.optIn && Boolean(res.optIn.isSiteNotified);
                })
            ;
        }
    }

    function getKWhObjective(objective, averageContractValue) {
        return Math.round(objective / averageContractValue);
    };
    function getObjective(objectiveInKWh, averageContractValue) {
        return Math.round(objectiveInKWh * averageContractValue);
    };
    function getEuroObjective(objectiveInKWh, averageContractValue) {
        return Math.round(objectiveInKWh * averageContractValue);
    };

    // Duplicated and adapted code from annualConsumptionController
    function getTotalConsumedPowerFromCharts(series) {
        return Math.round(_.reduce(series, (totalConsumption, serie) => {
            if (!serie.isConsumedPower) {
                return totalConsumption;
            }
            return serie.data.reduce((totalConsumption, data) => totalConsumption + data.y, totalConsumption);
        }, 0));
    }

    function getPastYearConsumption() {
        return $http.get("/chart/getAnnualConsumptionDetailedCharts.json")
            .then((response) => response.data)
            .tap((data) => logger.debug('myObjectivesController', 'getPastYearConsumption', 'Done'))
            .tapCatch((e) => logger.error('myObjectivesController', 'getPastYearConsumption', 'Fail', e))
        ;
    }

    function getAverageContractValue(siteContract) {
        const priceList = _.filter(siteContract.kwhPrices, function (item) {
            return item !== null;
        });

        const max = Math.max(...priceList);
        const min = Math.min(...priceList);

        return (max + min) / 2;
    }

    function submit(objectiveEuro, isSiteNotified) {
        return $q.all([
            $http.post('/site/updateOrInsertObjective.json', {
                objective: objectiveEuro,
            }),
            $http.post('/site/updateOrInsertConsumptionAlertOptIn.json', {
                isSiteNotified: Boolean(isSiteNotified),
            }),
        ]);
    }

    // TODO move in a service
    function loadIsSiteNotified() {
        return $http.get('/site/getConsumptionAlertOptInFromSiteId.json')
            .then(res => res.data)
        ;
    }

    function updateMyObjectiveInSelectedSite(myObjective) {
        $appScope.get('user').getSelectedSite().myObjective.objective = myObjective;
    }

    /**
     * Calculation of the number of month which have annual consumption data
     * @param annualConsumptionCharts
     * @returns {*}
     */
    function monthNumberCalculation(annualConsumptionCharts) {
        return annualConsumptionCharts.euro.series.reduce((monthNumber, item) => Math.max(item.data.length, monthNumber), 0);
    }
}];
