
module.exports = ['$scope', '$http', 'flash', function($scope, $http, flash){

    var STATUS_PENDING = {};
    var STATUS_OK = {};
    var STATUS_NONE = {};
    var STATUS_ERROR = {};

    $scope.forgottenPassword = {};
    resetForgottenPasswordForm();
    $scope.forgottenPassword.submit = onSubmit;

    function onSubmit(){
        $scope.forgottenPassword.status = STATUS_PENDING;

        $http.post('/forgottenPassword', {
            login: $scope.forgottenPassword.login,
            lastName: $scope.forgottenPassword.lastName
        }).then(function onSuccess(response){
            $scope.forgottenPassword.status = STATUS_OK;
            flash("alert-success", response.data.message, 5000);
            resetForgottenPasswordForm();
        }).catch(function onError(response){
            $scope.forgottenPassword.status = STATUS_ERROR;
            if( response.data.message )
                flash("alert-danger", response.data.message, 5000);
            else{
                flash("alert-danger", "Service indisponible.", 5000);
            }
        });
    }

    $scope.forgottenPassword.hasPendingStatus = function() {
        return $scope.forgottenPassword.status === STATUS_PENDING;
    };

    function resetForgottenPasswordForm(){
        $scope.forgottenPassword.status = STATUS_NONE;
        $scope.forgottenPassword.username = "";
        $scope.forgottenPassword.email = "";
    }
}];