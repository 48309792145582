var logger = require('logger');

module.exports = function(){
    return {
        restrict: 'E',
        templateUrl : './dataComponents/myNeighbors/my-neighbors.html',
        scope: {
            startDate: '=',
            endDate: '=',
        },
        link: function(scope){
            logger.debug('myNeighborsDirective', 'link');

            scope.$on( 'selectedSiteHasChanged', function(event, userSiteId){
                logger.debug('myNeighborsDirective', 'receiving event', 'selectedSiteHasChanged');
                scope.myNeighbors.fetch();
            });

            // gestion du debind tres important !
            angular.element(window).on('resize', scope.myNeighbors.render);
            scope.$on('$destroy', function(){
                angular.element(window).off('resize', scope.myNeighbors.render);
            });

            scope.$on('myNeighborsValuesChanged', function(){
                scope.myNeighbors.render();
            });

            scope.myNeighbors.fetch();
        },
        controller: ['$scope', '$http', function($scope, $http){

            var STATUS_PENDING = {},
                STATUS_ERROR = {},
                STATUS_OK = {};

            $scope.myNeighbors = {};
            $scope.myNeighbors.status = null;
            $scope.myNeighbors.class = null;
            $scope.myNeighbors.siteConsumption = null;
            $scope.myNeighbors.roundedSiteConsumption = null;
            $scope.myNeighbors.neighborsConsumption = null;
            $scope.myNeighbors.roundedNeighborsConsumption = null;
            $scope.myNeighbors.fetch = fetch;
            $scope.myNeighbors.render = render;

            $scope.myNeighbors.hasPendingStatus = function () {
                return $scope.myNeighbors.status === STATUS_PENDING;
            };

            $scope.myNeighbors.hasOkStatus = function () {
                return $scope.myNeighbors.status === STATUS_OK;
            };

            $scope.myNeighbors.hasErrorStatus = function () {
                return $scope.myNeighbors.status === STATUS_ERROR;
            };

            function render(){
                logger.debug('myNeighborsDirective', 'starting rendering');

                var power = {
                    site: $scope.myNeighbors.siteConsumption,
                    department: $scope.myNeighbors.neighborsConsumption
                };

                var $container = angular.element('.myNeighborsContainer'),
                    canvas = $container.find('#myNeighborsCanvas')[0];

                if( !canvas )
                    return;

                var ctx = canvas.getContext('2d');

                // Compute canvas math required data
                var drawingData = getDrawingData( $container, power.site, power.department );

                // Clear and resize the canvas
                prepareCanvas(canvas, ctx, drawingData.container.width, drawingData.container.height);

                // Draw
                draw( ctx, drawingData );
            }

            function fetch(){
                logger.debug('myNeighborsDirective', 'fetch started');
                $scope.myNeighbors.status = STATUS_PENDING;

                $http({
                    method: 'GET',
                    url: '/chart/getMyNeighbors.json',
                    params: {
                        startDate: $scope.startDate,
                        endDate: $scope.endDate,
                    },
                })
                    .then(function onSuccess( response ) {
                        logger.debug('myNeighborsDirective', 'fetch done');
                        $scope.myNeighbors.siteConsumption = response.data.siteConsumption;
                        $scope.myNeighbors.roundedSiteConsumption = Math.round($scope.myNeighbors.siteConsumption);
                        $scope.myNeighbors.neighborsConsumption = response.data.neighborsConsumption;
                        $scope.myNeighbors.roundedNeighborsConsumption = Math.round($scope.myNeighbors.neighborsConsumption);
                        $scope.$emit('myNeighborsValuesChanged', true);
                        $scope.myNeighbors.status = STATUS_OK;
                    })
                    .catch(function onError() {
                        logger.error('myNeighborsDirective', 'fetch fail');
                        $scope.myNeighbors.status = STATUS_ERROR;
                    });

            }
        }]
    }
};

function draw( ctx, drawingData ){

    function drawCircle( data ){
        ctx.beginPath();
        ctx.arc(data.Pc.x, data.Pc.y, data.R, 0, 2 * Math.PI );
        ctx.fillStyle = data.color;
        ctx.fill();
        ctx.lineWidth = 0;
        ctx.strokeStyle = data.color;
        ctx.stroke();
    }

    function drawLegendLine( data ){
        ctx.beginPath();
        ctx.moveTo(data.start.x, data.start.y);
        ctx.quadraticCurveTo(data.control.x, data.control.y, data.end.x, data.end.y);
        ctx.strokeStyle = data.color;
        ctx.stroke();
    }

    // le petit cercle doit superposer le grand, cela dépend de l'ordre dans lequel on dessine sur le plan
    if( drawingData.circleSite.V < drawingData.circleDepartment.V ){
        drawLegendLine( drawingData.legendDepartment );
        drawLegendLine( drawingData.legendSite );
        drawCircle( drawingData.circleDepartment );
        drawCircle( drawingData.circleSite );
    }
    else if( drawingData.circleSite.V >= drawingData.circleDepartment.V ){
        drawLegendLine( drawingData.legendSite );
        drawLegendLine( drawingData.legendDepartment );
        drawCircle( drawingData.circleSite );
        drawCircle( drawingData.circleDepartment );
    }
}

function getDrawingData( $container, sitekWh, departmentkWh ){
    // --------------------------------------------
    // --------- Paramètres getDrawingData --------
    // --------------------------------------------

    var LEGEND_X_PADDING = 20; // Pixels, déplace le point X de départ sous les légendes
    var MERGE_OFFSET_RATIO = 10; // Pourcentage, la superposition des cercles sera MERGE_OFFSET_RATIO % du rayon du cercle
    var COLOR_SITE = "#3FB9E2";
    var COLOR_DEPARTMENT = "#9BCD11";
    var COLOR_LINE_LEGEND = "#000";


    // --------------------------------------------
    // --------- Data pour les cercles ------------
    // --------------------------------------------

    var Vs = sitekWh; // Valeur site
    var Vd = departmentkWh; // Valeur departement
    // Les éléments ne sont pas affiché, on ne peut donc pas calculer les hauteur via cette méthode. Il faut trouver un solution pour la réactiver
    // getContainerHeight( $container ); // Hauteur container
    var CH = $container.height() - 60;
    var CW = $container.width(); // largeur container

    var As = null; // Air site
    var Ad = null; // Air department
    var Rs = null; // rayon du site
    var Ds = null; // diametre du site
    var Dd = null; // diametre departement
    var Rd = null; // rayon du département
    var ratio = null; // ratio entre grand et petit

    // Si la valeur du département est la plus grande
    if ( Vs < Vd ){
        Rd = CH / 2;
        Dd = CH;
        Ad = Math.PI * Math.pow(Rd, 2);
        ratio = Vd / Vs;
        As = Ad / ratio;
        Rs = Math.sqrt( As / Math.PI );
        Ds = Rs * 2;
    }
    // Si la valeur du site est la plus grande
    else if( Vs >= Vd ){
        Rs = CH / 2;
        Ds = CH;
        As = Math.PI * Math.pow(Rs, 2);
        ratio = Vs / Vd;
        Ad = As / ratio;
        Rd = Math.sqrt( Ad / Math.PI );
        Dd = Rd * 2;
    }

    // --------------------------------------------------------
    // --------- Data pour la position des cercles ------------
    // --------------------------------------------------------

    var PL = ( CW - Ds - Dd ) / 2; // padding left, entre container et premier cercle

    var Psl = { x: null, y: null }; // Point site gauche
    var Psc = { x: null, y: null }; // Point site center
    var Psr = { x: null, y: null }; // Point site droite
    var Pdl = { x: null, y: null }; // Point département gauche
    var Pdc = { x: null, y: null }; // Point département centre
    var Pdr = { x: null, y: null }; // Point département droit

    Psl.x = PL;
    Psl.y = CH / 2;
    Psc.x = PL + Rs + ( Rs / MERGE_OFFSET_RATIO );
    Psc.y = CH / 2;
    Psr.x = PL + Ds;
    Psr.y = CH / 2;

    Pdl.x = PL + Ds;
    Pdl.y = CH / 2;
    Pdc.x = PL + Ds + Rd - ( Rd / MERGE_OFFSET_RATIO );
    Pdc.y = CH / 2;
    Pdr.x = PL + Ds + Dd;
    Pdr.y = CH / 2;

    // -------------------------------------------------------------
    // --------- Data pour les traits vers les légendes ------------
    // -------------------------------------------------------------

    var Lss = { x: null, y: null }; // Point de depart de la legende site
    var Lsc = { x: null, y: null }; // Point de controle de la legende site
    var Lse = { x: null, y: null }; // Point de fin de la legende site
    var Lds = { x: null, y: null }; // Point de depart de la legende department
    var Ldc = { x: null, y: null }; // Point de controle de la legende department
    var Lde = { x: null, y: null }; // Point de fin de la legende department

    Lss.x = LEGEND_X_PADDING;
    Lss.y = 0;
    Lsc.x = Lss.x;
    Lsc.y = CH / 2;
    Lse = Psc;

    Lds.x = CW - Lss.x;
    Lds.y = 0;
    Ldc.x = CW - Lss.x;
    Ldc.y = CH / 2;
    Lde = Pdc;

    return {
        container: {
            height: CH,
            width: CW
        },
        legendSite: {
            start: Lss,
            control: Lsc,
            end: Lse,
            color: COLOR_LINE_LEGEND
        },
        legendDepartment: {
            start: Lds,
            control: Ldc,
            end: Lde,
            color: COLOR_LINE_LEGEND
        },
        circleSite: {
            V: Vs,
            A: As,
            R: Rs,
            D: Ds,
            Pl: Psl,
            Pc: Psc,
            Pr: Psr,
            color: COLOR_SITE
        },
        circleDepartment: {
            V: Vd,
            A: Ad,
            R: Rd,
            D: Dd,
            Pl: Pdl,
            Pc: Pdc,
            Pr: Pdr,
            color: COLOR_DEPARTMENT
        }
    }

    function getContainerHeight( $container ){
        var $legendBox = $container.find('.legendBox');
        // La legende est placé en absolue, elle ne gene pas donc il faut enlever sa hauteur et son bottom pos
        var legendBoxBottomPos = parseInt( $legendBox.css('bottom').slice(0,-2), 10 );
        var CH = $container.height() - ( $legendBox.outerHeight() + legendBoxBottomPos );
        // Il faut egalement enlever les 20px de article.v-padded
        return CH - 60;
    }
}

function prepareCanvas( canvas, ctx, width, height ){
    canvas.width = width;
    canvas.height = height;
    ctx.clearRect(0, 0, width, height);
}
