var _ = require('underscore'),
    logger = require('logger');

var $appScope = require('appScope');
var occupantList = [1,2,3,4,5,6,7,8,9,"10 et plus"];

module.exports = ['$scope', '$http', 'flash', '$i18next', function($scope, $http, flash, $i18next) {

    // Hack for the case when the page is called from a modal (otherwise the black overlay stays)
    angular.element('.modal-backdrop').remove();

    var subscriber = $appScope.get('user'),
        selectedSite = null;

    // directive attributes
    var STATUS_PENDING = {};
    var STATUS_SUBMITING = {};
    var STATUS_OK = {};
    var STATUS_ERROR = {};

    $scope.myHouse = {};
    $scope.myHouse.status = null;
    $scope.myHouse.submit = submit;
    $scope.myHouse.undoChange = populateFormWithSelectedSite;
    $scope.myHouse.getSiteLayoutList = getFilteredSiteLayoutList;
    $scope.myHouse.occupantList = occupantList;
    $scope.myHouse.typeSiteList = null;
    $scope.myHouse.siteLayoutList = null;
    $scope.myHouse.form = null;

    initialize();

    function initialize(){
        fetchReference();
        populateFormWithSelectedSite();
        $scope.$on( 'selectedSiteHasChanged', populateFormWithSelectedSite);
    }

    function setForm( form ){
        $scope.myHouse.form = {
            siteId: form && form.siteId, // required to detect changed on submit
            typeSiteId: form && form.typeSiteId,
            surface: form && form.surface,
            siteLayoutId: form && form.siteLayoutId,
            occupantNumber: form && form.occupantNumber
        };
    }

    function fetchReference(){
        $scope.myHouse.status = STATUS_PENDING;
        $http.get("/reference/typeSiteList.json")
            .then(function onSuccess(response) {
                $scope.myHouse.typeSiteList = response.data;
                updateStatus();
            })
            .catch(function onError( response ) {
                $scope.myHouse.status = STATUS_ERROR;
                flash( 'alert-danger', response.data.message, 5000 );
            });
        $http.get("/reference/siteLayoutList.json")
            .then(function onSuccess(response) {
                $scope.myHouse.siteLayoutList = response.data;
                updateStatus();
            })
            .catch(function onError( response ) {
                $scope.myHouse.status = STATUS_ERROR;
                flash( 'alert-danger', response.data.message, 5000 );
            });
    }

    function populateFormWithSelectedSite(){
        selectedSite = subscriber.getSelectedSite();
        setForm( _.clone(selectedSite.myHouse) );
    }

    function updateMyHouseInSelectedSite( myHouse ){
        var siteList = subscriber.get('siteList');
        for(var i = 0, max = siteList.length; i < max; i++){
            if( siteList[i].id === myHouse.siteId ){
                siteList[i].myHouse = myHouse;
                break;
            }
        }
    }

    function updateStatus(){
        if( $scope.myHouse.typeSiteList && $scope.myHouse.siteLayoutList )
            $scope.myHouse.status = STATUS_OK;
    }

    function submit(){
        if( angular.equals($scope.myHouse.form, selectedSite.myHouse) ){
            flash( 'alert-danger', $i18next("myHouse.error.equalForm"), 5000 );
            return;
        }
        $scope.myHouse.status = STATUS_SUBMITING;
        $http({
            method: 'POST',
            url:'/site/updateMyHouse',
            data: $scope.myHouse.form
        })
            .then( function onSuccess( response ){
                $scope.myHouse.status = STATUS_OK;
                updateMyHouseInSelectedSite( response.data.myHouse );
                flash( 'alert-success', response.data.message, 3000 );
            } )
            .catch( function onError( response ){
                $scope.myHouse.status = STATUS_ERROR;
                flash( 'alert-danger', response.data.message, 5000 );
            });
    }

    function getFilteredSiteLayoutList(){
        var typeSiteId = $scope.myHouse.form.typeSiteId,
            siteLayoutList = $scope.myHouse.siteLayoutList;
        return _.filter(siteLayoutList, function(siteLayout){
            return siteLayout.typeSiteId === typeSiteId;
        });
    }

    $scope.myHouse.hasPendingStatus = function () {
        return $scope.myHouse.status === STATUS_PENDING;
    };

    $scope.myHouse.hasSubmitingStatus = function () {
        return $scope.myHouse.status === STATUS_SUBMITING;
    };

    $scope.myHouse.hasOkStatus = function () {
        return $scope.myHouse.status === STATUS_OK;
    };
}];