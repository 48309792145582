var logger = require('logger'),
    $appScope = require('appScope'),
    ref = require('enum'),
    config = require('config');

module.exports = function(){
    return {
        restrict: 'E',
        templateUrl : './dataComponents/monthConsumptionHeatmap/month-consumption-heatmap.html',
        scope: {
            options: "="
        },
        link: function(scope, iElement, attrs){
            logger.debug('monthConsumptionHeatmap', 'link');

            scope.$on( 'selectedSiteHasChanged', function(event, userSiteId){
                logger.debug('monthConsumptionHeatmapDirective', 'receiving event', 'selectedSiteHasChanged');
                scope.monthConsumptionHeatmap.fetch();
            });

            scope.monthConsumptionHeatmap.fetch();
        },
        controller: ['$scope', '$http', '$i18next',
            function($scope, $http, $i18next){

            var STATUS_PENDING = {},
                STATUS_ERROR = {},
                STATUS_OK = {};

            $scope.user = $appScope.get('user');
            $scope.TO_KWH = 2; // divise par deux la legende pour avoir des kwh au lieu de kw

            // directive attributes
            $scope.monthConsumptionHeatmap = {};
            $scope.monthConsumptionHeatmap.status = null;
            $scope.monthConsumptionHeatmap.fetch = fetch;
            $scope.monthConsumptionHeatmap.render = render;
            $scope.monthConsumptionHeatmap.hphc = false;

            function fetch(){
                if( $appScope.get('user').hasContractForSelectedSite() ){
                    $scope.monthConsumptionHeatmap.status = STATUS_PENDING;
                    $scope.monthConsumptionHeatmap.hphc = $appScope.get('user').getSelectedSite().contract.optionId == ref.CONTRACT_OPTION.HPHC;


                    logger.debug('monthConsumptionHeatmapDirective', 'fetch started');
                    $http({
                        method: 'GET',
                        url: '/chart/getMonthConsumptionHeatmap.json',
                        params: {
                            startDate: $scope.options.startDate.valueOf(),
                            endDate: $scope.options.endDate.valueOf()
                        }
                    })
                        .then(function onSuccess( response ) {
                            logger.debug('monthConsumptionHeatmapDirective', 'fetch done');
                            $scope.monthConsumptionHeatmap.status = STATUS_OK;
                            render( null, addFormaters( response.data.heatmap, !$scope.monthConsumptionHeatmap.hphc ) );
                        })
                        .catch(function onError() {
                            logger.error('monthConsumptionHeatmapDirective', 'fetch fail');
                            $scope.monthConsumptionHeatmap.status = STATUS_ERROR;
                            render( $i18next('annualConsumptionChart.errorLoadingChart') );
                        });
                }
            }

            function render( errorMessage, heatmap ){
                var $chart = angular.element('.monthConsumptionHeatmap');
                if (errorMessage) {
                    $chart.highcharts( config.emptyChart );
                    $chart.highcharts().showLoading(errorMessage);
                }
                else {
                    $chart.highcharts(heatmap);
                }
            }


            // directive methods
            $scope.monthConsumptionHeatmap.hasPendingStatus = function () {
                return $scope.monthConsumptionHeatmap.status === STATUS_PENDING;
            };

            $scope.monthConsumptionHeatmap.hasErrorStatus = function () {
                return $scope.monthConsumptionHeatmap.status === STATUS_ERROR;
            };

            $scope.monthConsumptionHeatmap.hasOkStatus = function () {
                return $scope.monthConsumptionHeatmap.status === STATUS_OK;
            };
        }]
    }
};

function addFormaters( heatmap, isBaseContract ){
    heatmap.xAxis.labels.formatter = function () {   // N'affiche que les heures entières
        var message = this.value;
        if (-1 != message.indexOf('h30')) {
            message = '';
        }
        return message;
    };
    heatmap.tooltip.formatter = function(){
        var value = this.point.value;
        var hphcstr = 'Heure Pleine';

        if (value < 0) {
            hphcstr = 'Heure Creuse';
            value = -value;
        }


        var message = this.series.yAxis.categories[this.point.y] + ' ';
        if (this.point.x == 47) {
            message += this.series.xAxis.categories[this.point.x] + ' - 0h';
        }
        else {
            message += this.series.xAxis.categories[this.point.x] + ' - ' + this.series.xAxis.categories[this.point.x + 1];
        }
        if( !isBaseContract ){
            message += ' (' + hphcstr + ') : ';
        }
        else{
            message += '  : ';
        }

        if (null != value) {
            message += Math.round( value * 100 ) / 100 + ' kWh';
        }
        else {
            message += ' Donnée non disponible';
        }

        return message
    };
    return heatmap;
}