module.exports = function($timeout) {
    return {
        scope: {},
        require: 'ngModel',
        link: function(scope, elem, attrs, ctrl) {
            scope.check = function() {
                var val = elem[0].value;
                if (ctrl.$viewValue !== val) {
                    var originalPristineState = ctrl.$pristine;
                    ctrl.$setViewValue(val);
                    //if the form control was originally pristine, set it back to pristine
                    ctrl.$pristine = originalPristineState;
                }
                $timeout(scope.check, 300);
            };
            scope.check();
        }
    }
};