module.exports = function() {
    return {
        restrict: 'EA',
        scope: {
            tooltipTarget: '@',
            tooltipI18next: '@',
            tooltipOptions: '@',
            tooltipImg: '@'
        },
        link: function link(scope, element, attrs) {

            element.addClass("fa fa-info-circle");
            scope.tooltipDirective.element = element;

            attrs.$observe('tooltipTarget', function( target ){
                scope.tooltipDirective.tooltipName = target;
                scope.tooltipDirective.target = angular.element('[target-tooltip="' + target + '"]');
                _before();
            });
            attrs.$observe('tooltipI18next', function( key ){
                scope.tooltipDirective.i18next = key;
                _before();
            });

            attrs.$observe('tooltipOptions', function( key ){
                scope.options = key;
                _before();
            });

            attrs.$observe('tooltipImg', function( src ){
                scope.tooltipDirective.srcImage = src;
                _before();
            });

            scope.$watch(function(){
                    if(scope.tooltipDirective.target){
                        return scope.tooltipDirective.target.height();
                    }

                    return null;
                },
                function (newHeight, oldHeight) {
                    if (oldHeight != null && newHeight != oldHeight) {
                        scope.tooltipDirective.tooltip.remove();
                        scope.tooltipDirective.addTooltip();
                    }
                }
            );


            // destroy binded events on controller remove
            scope.$on('$destroy', scope.tooltipDirective.onDestroy);

            function _before(){
                var target = scope.tooltipDirective.target,
                    text = scope.tooltipDirective.i18next;
                if( !text || !target ) return;
                else if( scope.tooltipImg && !scope.tooltipDirective.srcImage) return;
                else if( !scope.tooltipDirective.processed ) scope.tooltipDirective.addTooltip();
            }


        },
        controller: ['$scope', '$i18next', function($scope, $i18next){
            $scope.tooltipDirective = {};
            $scope.tooltipDirective.processed = false;
            $scope.tooltipDirective.element = null;
            $scope.tooltipDirective.tooltipName = null;
            $scope.tooltipDirective.target = null;
            $scope.tooltipDirective.tooltip = null;
            $scope.tooltipDirective.i18next = null;
            $scope.tooltipDirective.srcImage = null;
            $scope.tooltipDirective.options = null;
            $scope.tooltipDirective.text = null;
            $scope.tooltipDirective.addTooltip = addTooltip;
            $scope.tooltipDirective.show = show;
            $scope.tooltipDirective.hide = hide;
            $scope.tooltipDirective.onDestroy = onDestroy;


            function addTooltip(){
                var target = $scope.tooltipDirective.target,
                    key = $scope.tooltipDirective.i18next,
                    src = $scope.tooltipDirective.srcImage,
                    opt = $scope.tooltipDirective.tooltipOptions || $scope.options || {},
                    img;
                var text;
                if ( $scope.tooltipOptions ) {
                    text = $i18next(key, JSON.parse($scope.tooltipOptions));
                } else
                    text = $i18next(key);
                if($scope.tooltipDirective.srcImage){
                    img = createTooltipImage( src );
                }
                $scope.tooltipDirective.tooltip = createTooltipNode( text, img, target );

                bindEvents();
                $scope.tooltipDirective.processed = true;
            }

            function bindEvents(){
                window.elem = $scope.tooltipDirective.element;
                $scope.tooltipDirective.element.on('mouseenter', show);
                $scope.tooltipDirective.element.on('mouseleave', hide);
            }

            function onDestroy(){
                $scope.tooltipDirective.element.off('mouseenter', show);
                $scope.tooltipDirective.element.off('mouseleave', hide);
            }

            function touchToggle(){
                $scope.tooltipDirective.tooltip.hasClass('active') ? hide() : show();
            }

            function show(){
                $scope.tooltipDirective.tooltip.addClass('active');
            }

            function hide(){
                $scope.tooltipDirective.tooltip.removeClass('active');
            }

        }]
    }
};

function createTooltipImage(src){
    var img = document.createElement('img');
    img.setAttribute('src', src);
    img.setAttribute('style', 'display: none;');
    img.onload = function(){
        img.setAttribute('style', 'max-width:100%; max-height:100%;');
    };
    return img;
}
function createTooltipNode( text, img, target ){
    var tooltip = document.createElement('div');
    tooltip.classList.add('v-absolute');
    tooltip.classList.add('v-tooltip');
    tooltip.id = generateId();

    var textContainer = document.createElement('div');
    textContainer.classList.add('tooltip-text');
    textContainer.classList.add('v-padded');

    var overlay = document.createElement('div');
    overlay.classList.add('tooltip-overlay');

    var textList = text.split( '<br>' );    // Reconnaissance des tags <br>
    for( var i=0; i<textList.length; ++i ){
        textContainer.appendChild( document.createTextNode( textList[ i ] ) );
        if( i < textList.length - 1 ){
            textContainer.appendChild( document.createElement( 'br' ) );
        }
    }

    if(img){
        textContainer.appendChild(img);
    }
    tooltip.appendChild(textContainer);
    tooltip.appendChild(overlay);

    createTooltipStyle( tooltip, target );
    target.append( tooltip );
    return angular.element( tooltip );
}

function getStyleText(id, height){
    var txt = '#' + id + ' { transform: translateY(0px); height: ' + height + 'px }\n';
    txt += '#' + id + '.active { transform: translateY(-' + height + 'px); }\n';
    return txt;
}


// usefull for css3 animation
function createTooltipStyle( tooltip, target ){
    var style = document.createElement('style');
    style.type = 'text/css';
    style.id = tooltip.id;
    style.innerHTML = getStyleText(tooltip.id, target.height());
    target.append( style );
    return style;
}

function generateId(){
    var id,
        tested;
    while( !tested ){
        id = Math.round( Math.random(1) * 1000 );
        id = "tooltip-" + id;
        tested = angular.element("#" + id);
    }
    return id;
}