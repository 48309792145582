
const ModelError = require('./ModelError');
class Email {

    constructor(fromName, fromEmail, subject, message) {
        this.fromName = fromName;
        this.fromEmail = fromEmail;
        this.subject = subject;
        this.message = message;
        this.isValid();
    }
    get from() {
        return {
            name: this.fromName,
            mail: this.fromEmail,
        };
    }

    isValid() {
        if (this.fromName === null) {
            throw new ModelError('Missing from name');
        }
        if (this.fromEmail === null) {
            throw new ModelError('Missing from email');
        }
        if (this.subject === null) {
            throw new ModelError('Missing subject');
        }
        if (this.message === null) {
            throw new ModelError('Missing message');
        }
    }

}
module.exports = Email;
