var moment = require("moment");

/**
 * This directives recreates the classic JS behaviour of input[type="time"] in Angular 1.4 +
 * It allows to bind a JS Date object in the view to a HH:mm time String in the model
 * @returns {Object} AngularJS directive description
 */
module.exports = function() {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, element, attr, ngModel) {
            function stringToDate(timeString) {
                return moment(timeString, "HH:mm").toDate();
            }
            function dateToString(timeDate) {
                return moment(timeDate).format("HH:mm");
            }
            ngModel.$parsers.push(dateToString);
            ngModel.$formatters.push(stringToDate);
            // Firefox has display issues with input[type="time], displaying seconds & milliseconds -> remove those
            ngModel.$formatters.unshift(function(value) {
                return value.replace(/:00\.000$/, '');
            });
        }
    };
};