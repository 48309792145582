// Important note :
// Because we are using ng-i18next 0.3.4, use this doc instead of new one
// Doc: https://github.com/i18next/ng-i18next/blob/a7fff6f58a5d7954838b35dc43e3adaf0f1faa76/README.md

module.exports = {
    // see i18next http://i18next.com/pages/doc_init.html for more options
    config: {
        fallbackLng: ['en', 'fr', 'sl', 'ja', 'de', 'fi', 'sv', 'zh'],
        useCookie: true,
        useLocalStorage: false, // no use of local storage => does not work in app webview
        localStorageExpirationTime: 1000 * 3600 * 24, // in ms, default 1 week
        getAsync: false,
        defaultLoadingValue: '', // ng-i18next option, *NOT* directly supported by i18next
    },
    languageToCountryMap: {
        en: 'gb',
        fr: 'fr',
        sl: 'si',
        ja: 'jp',
        de: 'de',
        fi: 'fi',
        sv: 'se',
        zh: 'cn',
    },
};
