'use strict';

// Lancer la generation de pdf depuis spiderman-webpage2pdf
// https://git.momagroup.com/spiderman-team/spiderman-webpage2pdf/blob/master/README.md

var logger = require('logger'),
    config = require('config'),
    _ = require('underscore'),
    ref = require('enum'),
    moment = require('moment');

module.exports = ['$scope', '$http', function ($scope, $http) {

    $scope.options = {
        startDate: null,
        endDate: null,
        unit: 'month'
    };

    $scope.Math = Math;
    
    $scope.monthlyReport = {};
    $scope.monthlyReport.consumptionByDays = null;
    $scope.monthlyReport.maxConsumptionPoint = null;
    $scope.monthlyReport.maxPower = null;
    $scope.monthlyReport.meteoEffect = null;
    $scope.monthlyReport.charts = {};
    $scope.monthlyReport.charts.monthlyChart = null;
    $scope.monthlyReport.charts.monthlyPieChart = null;
    $scope.monthlyReport.sum = {};
    $scope.monthlyReport.sum.euro = null;
    $scope.monthlyReport.sum.kwh = null;

    //function
    $scope.monthlyReport.getVisibleSeries = getVisibleSeries;

    initialize();

    function initialize(){
        logger.debug('monthlyReportController', 'init');

        // Mois précédent
	    var startDate = moment().subtract(1, 'months').startOf('month'),
	        endDate = moment().subtract(1, 'months').endOf('month');

	    $scope.options.startDate = startDate;
	    $scope.options.endDate = endDate;

        addDateToHeader( startDate.format('MMMM YYYY') );
        fetchMaxPower();

        $scope.$on('valueBoxesHasChanged', function(event, values){
            logger.debug('monthlyReportController', 'receiving', 'valueBoxesHasChanged');
            $scope.monthlyReport.sum.kwh = values.kwh;
            $scope.monthlyReport.sum.euro = values.euro;
        });

	    $scope.$on('onMonthlyDetailedChartChange', function(event, chart){
	        logger.debug('monthlyReportController', 'receiving', 'onMonthlyDetailedChartChange');
            setConsumptionsFromMonthlyChart( chart );
	        $scope.monthlyReport.charts.monthlyChart = chart;
	    });

        $scope.$on('onMonthlyDetailedPieChartChange', function(event, chart){
            logger.debug('monthlyReportController', 'receiving', 'onMonthlyDetailedPieChartChange');
            $scope.monthlyReport.charts.monthlyPieChart = chart;
        });

        $scope.$on('meteoEffectHasChanged', function(event, meteoEffect){
            logger.debug('monthlyReportController', 'receiving', 'meteoEffectHasChanged');
            $scope.monthlyReport.meteoEffect = meteoEffect;
        });
    }

    function addDateToHeader( dateStr ){
        angular.element('.date-month').html( dateStr );
    }

    function fetchMaxPower(){
        logger.debug('monthlyReportController', 'fetchMaxPower started');
        $http({
            method: 'GET',
            url: '/siteData/getSiteMaxPower.json',
            params: {
                startDate: $scope.options.startDate.valueOf(),
                endDate: $scope.options.endDate.valueOf()
            }
        })
            .then(function onSuccess( response ) {
                logger.debug('monthlyReportController', 'fetch done');
                $scope.monthlyReport.maxPower = response.maxPower;
            })
            .catch(function onError( response ) {
                logger.error('monthlyReportController', 'fetch fail');
            });
    }

    function getVisibleSeries(){
    	var currentChart = $scope.monthlyReport.charts.monthlyChart,
            currentPieChart = $scope.monthlyReport.charts.monthlyPieChart;
        if(!currentChart || !currentPieChart) return;
        var visibleSeries = [], i = 0;

        _.each(currentChart.series, function(serie){
            if(serie.showInLegend != false){
                serie.index = i;
                visibleSeries.push(serie);
            }
            i++;            
        });

        //The pieChart is in Euro and the chart in kWh, so there is series which are in the pieChart
        //and that aren't in the chart. So we try to determine them in this part of the function  
        _.each(currentPieChart.series[0].data, function(serie){
            var isInVisibleSeries = false;
            _.each(visibleSeries, function(visibleSerie){
                var id = null;
                if(visibleSerie.id) 
                    id = visibleSerie.id.split("_", 1);
                if(serie.id ==  id){
                    isInVisibleSeries = true;
                }
            });

            if(isInVisibleSeries == false){
                visibleSeries.push(serie);
            }
        });

        return visibleSeries;
    }

    function setConsumptionsFromMonthlyChart( chart ){
        var consumptionSeries = [];
        // selectionne les séries de consommations
        if(!chart) return;
        _.each(chart.series, function( serie ){
            if( serie.isConsumption ){
                consumptionSeries.push( serie );
            }
        });

        var byDays = {};
        // crée tout les dates
        _.each(consumptionSeries, function(serie){
            _.each(serie.data, function(data){
                if( !byDays[data.x] ) byDays[data.x] = 0;
            });
        });

        // concat les valeurs pour chaque dates
        _.each(consumptionSeries, function(serie){
            _.each(serie.data, function(data){
                byDays[data.x] += data.y;
            });
        });


        function getMaxConsumptionPoint( byDays ){
            var max = {x: null, y: null};
            for(var ts in byDays){
                if( max.y < byDays[ts] ){
                    max = {x: ts, y: byDays[ts]};
                }
            }
            return max;
        }

        $scope.monthlyReport.consumptionByDays = byDays;
        $scope.monthlyReport.maxConsumptionPoint = getMaxConsumptionPoint( byDays );

    }

}];