var _ = require('underscore'),
    ref = require('enum'),
    moment = require('moment');

var $appScope = require('appScope'),
    config = require('config'),
    logger = require('logger');

var dateFr = config.dateFormat.dateFr;

var STATUS_PENDING = {},
    STATUS_ERROR = {},
    STATUS_OK = {};

module.exports = function () {
    return {
        restrict: 'E',
        template: '<div class="annualObjectiveChart" style="height: inherit"><loader></loader></div>',
        link: function(scope){
            logger.debug('annualObjectiveChart', 'link');

            scope.$on( 'selectedSiteHasChanged', function(event){
                logger.debug('annualObjectiveChart', 'receiving event', 'selectedSiteHasChanged');
                if( scope.annualObjectiveChart.status !== STATUS_PENDING )
                    scope.annualObjectiveChart.fetch();
            });

            scope.annualObjectiveChart.fetch();
        },
        controller: ['$scope', '$element', '$http', '$i18next', function ($scope, $element, $http, $i18next) {

            $scope.annualObjectiveChart = {};
            $scope.annualObjectiveChart.status = null;
            $scope.annualObjectiveChart.fetch = fetch;
            $scope.annualObjectiveChart.render = render;
            $scope.annualObjectiveChart.data = null; // fetched data


            function fetch() {
                $scope.annualObjectiveChart.status = STATUS_PENDING;
                logger.debug('annualObjectiveChartDirective', 'fetch started');

                render( $i18next("detailedConsumptionChart.loadingChart") );

                $http({
                    method: 'GET',
                    url: '/chart/getAnnualObjectiveCharts.json'
                })
                    .then(function onSuccess(response) {
                        logger.debug('annualObjectiveChartDirective', 'fetch done');
                        $scope.annualObjectiveChart.status = STATUS_OK;
                        $scope.annualObjectiveChart.data = response.data;
                        const chart = response.data.chart;
                        emitData();

                        if (chart) {
                            render(null, chart);
                            return;
                        }

                        render( $i18next("detailedConsumptionChart.errorNoConsumption") );
                        $scope.$emit('onObjectiveChartError');
                    })
                    .catch(function onError( response ) {
                        logger.error('annualObjectiveChartDirective', 'fetch fail');
                        $scope.annualObjectiveChart.status = STATUS_ERROR;
                        if(response && response.data.message == "detailedConsumptionChart.errorNotEnoughConsumption") {
                            render( $i18next("detailedConsumptionChart.errorNotEnoughConsumption") );
                        } else {
                            render($i18next('detailedConsumptionChart.errorLoadingChart'));
                        }
                        $scope.$emit('onObjectiveChartError');
                    });

            }

            function render( errorMessage, chart ) {
                var $annualObjectiveChart = angular.element('.annualObjectiveChart');
                if (errorMessage){
                    $annualObjectiveChart.highcharts( config.emptyChart );
                    $annualObjectiveChart.highcharts().showLoading( errorMessage );
                }
                else{
                    addToolTipFormaterToChart(chart);
                    $annualObjectiveChart.highcharts( chart );
                }
            }

            function emitData() {
                const data = $scope.annualObjectiveChart.data;
                const chart = data.chart;
                let lastMonthConsumption = 0;
                let yearlyConsumption = 0;

                if (chart && chart.series) {
                    _.each(chart.series, (serie) => {
                        if (!serie.consumption) {
                            return;
                        }

                        const lastIndex = serie.data.length - 1;
                        if (lastMonthConsumption < serie.data[lastIndex].y) {
                            lastMonthConsumption = serie.data[lastIndex].y;
                        }

                        for (var i = 0; i <= lastIndex; i++) {
                            yearlyConsumption += serie.data[i].y;
                        }
                    });
                }

                $scope.$emit('onObjectiveChartChange', {
                    chart: chart,
                    lastMonthCost: data.lastMonthCost,
                    lastMonthConsumption: lastMonthConsumption,
                    yearlyConsumption: yearlyConsumption,
                    yearlyObjective: $scope.annualObjectiveChart.data.yearlyObjective,
                    monthlyObjective : $scope.annualObjectiveChart.data.monthlyObjective
                });
            }

            /**
             * add tooltip formatter (used by highcharts, can't be processed serverside)
             * @param chart
             * @returns {*}
             */
            function addToolTipFormaterToChart(chart){
                if(chart){
                    if(!chart.tooltip) chart.tooltip = {};
                    chart.tooltip.formatter = function(){
                        var title = $i18next("myTooltipFormatter.wholeConso"),
                            totalConsumption = 0,
                            legend = '';

                         for( var i=0; i<this.points.length; ++i ){
                            var consumption = this.points[i].series.userOptions.consumption,
                                objective = this.points[i].series.userOptions.objective;
                            if(consumption){
                                totalConsumption += this.points[i].y;
                            }
                            var color = (objective) ? this.points[i].series.userOptions.borderColor :  this.points[i].series.color,
                                name = this.points[i].series.name,
                                value = this.points[i].y,
                                unit = ref.CHART_DATA_TYPES.EURO.unit;
                            if(value != 0)
                                legend += getPointLegend(color, name, value, unit);
                        }

                        var dateTitle = '<b>' + _.str.capitalize(moment(this.x).format('MMMM YYYY')) + '</b>';

                        var noBreakSpace = '\u00A0';
                        var globalLegend = '<br/><span style="color:#333; text-decoration:underline">' + title + '</span>' + noBreakSpace + ': ';
                        globalLegend += '<strong>' + totalConsumption.toFixed( 2 ) + noBreakSpace + ref.CHART_DATA_TYPES.EURO.unit + '</strong><br/>';

                        return dateTitle + globalLegend + legend;
                    };
                }
            }

            function getPointLegend(color, text, value, unit){
                var noBreakSpace = '\u00A0';
                var legend = '<br/>';
                legend += '<br/><span style="font-weight: bold;color:' + color + '">\u25A0' + noBreakSpace + '</span>';
                legend += '<span style="color:#333">' + text + '</span>' + noBreakSpace + ': ';
                legend += '<strong>' + value.toFixed( 2 ) + noBreakSpace + unit + '</strong><br/>';
                return legend;
            }
        }]
    }
};
