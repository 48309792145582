//
// allowedPages
// Never remove or rename a urlName, as it matches urls users could have bookmarked.
//
// Note: a pageName is used internally. The corresponding urlName is used in the url.
// Example of pageName: "dashboard". Corresponding urlName: "tableau_de_bord".
//
// Note: "templateUrl"s are used in the main "app.js" file, so they should contain paths relative to that file.
//

const _ = require("underscore");
const ref = require('enum');
const logger = require('logger');

module.exports = {
    getDefaultPage,
    getUserHomePage,
    getPageConfigs,
    getConfigByPageName,
    getConfigByUrl,
    getUrl,
    setlang,
};

const configs = {
    login: {
        urlName: "",
        titleName: "Identification",
        templateUrl: "./login/login.html",
        controllerName: 'loginCtrl',
        auth: noauth,
        whiteLabel: true,
        default: true,
    },
    dashboard: {
        urlName: "tableau_de_bord",
        titleName: "Tableau de bord",
        templateUrl: "./mainBoard/main-board.html",
        controllerName: 'mainBoardCtrl',
        auth: auth,
        userHome: true,
    },
    onOffButton: {
        urlName: "bouton_on_off",
        titleName: "Bouton on off",
        templateUrl: "./onOffButton/on-off-button.html",
        controllerName: 'onOffButtonCtrl',
        hasUserAccess: subscriber => hasUserAccess(subscriber, ref.ACCESS.PAGE.ONOFF),
        auth: auth,
    },
    absence: {
        urlName: "absence",
        titleName: "Absence",
        templateUrl: "./absence/absence.html",
        controllerName: 'absenceCtrl',
        hasUserAccess: subscriber => hasUserAccess(subscriber, ref.ACCESS.PAGE.ABSENCE),
        auth: auth,
    },
    newScheduler: {
        urlName: "new_scheduler",
        titleName: "Nouveau Scheduler",
        templateUrl: "./newScheduler/new-scheduler.html",
        controllerName: 'newSchedulerCtrl',
        hasUserAccess: subscriber => hasUserAccess(subscriber, ref.ACCESS.PAGE.NEWSCHEDULER),
        auth: auth,
    },
    livecomsuption: {
        urlName: "ma_conso_en_live",
        titleName: "Ma conso live",
        templateUrl: "./immediateConsumption/immediate-consumption.html",
        controllerName: 'immediateConsumptionCtrl',
        auth: auth,
    },
    consos: {
        urlName: "ma_conso_detaillee",
        titleName: "Ma conso détaillée",
        templateUrl: "./detailedConsumption/detailed-consumption.html",
        controllerName: 'detailedConsumptionCtrl',
        auth: auth,
        resolve: {
            detailConsumptionZoom: _.noop,
        },
    },
    detailConsumptionZoom: {
        // ugly but quick workAround to use the same controller with two different resolve fn
        urlName: "ma_conso_en_detail",
        titleName: "Ma conso détaillée",
        templateUrl: "./detailedConsumption/detailed-consumption.html",
        controllerName: 'detailedConsumptionCtrl',
        auth: auth,
        resolve: {
            detailConsumptionZoom: function() {
                const $appScope = require('appScope');
                return $appScope.get('detailConsumptionZoom');
            },
        },
    },
    contact: {
        urlName: "contact",
        titleName: "Contact",
        templateUrl: "./contact/contact.html",
        controllerName: 'contactCtrl',
        auth: auth,
        hasUserAccess: subscriber => hasUserAccess(subscriber, ref.ACCESS.PAGE.CONTACT),
    },
    myData: {
        urlName: "mes_identifiants",
        titleName: "Mon profil",
        smallName: "Mon profil",
        templateUrl: "./myData/my-data.html",
        controllerName: 'myDataCtrl',
        hasUserAccess: subscriber => hasUserAccess(subscriber, ref.ACCESS.PAGE.MY_PERSONAL_INFORMATION),
        auth: auth,
    },
    myInstallation: {
        urlName: "mon_installation",
        titleName: "Mon installation",
        templateUrl: "./myInstallation/my-installation.html",
        controllerName: 'myInstallationCtrl',
        auth: auth,
        hasUserAccess:subscriber => hasUserAccess(subscriber, ref.ACCESS.PAGE.MY_INSTALLATION),
        resolve: {
            subscriber: function () {
                const $appScope = require('appScope');
                return $appScope.get('user');
            },
        },
    },
    myContract: {
        urlName: "mon_contrat",
        titleName: "Mon contrat",
        smallName: "Mon contrat",
        templateUrl: "./myContract/my-contract.html",
        controllerName: 'myContractCtrl',
        auth: auth,
        hasUserAccess: subscriber => hasUserAccess(subscriber, ref.ACCESS.PAGE.MY_CONTRACT),
    },
    glossary: {
        urlName: "lexique",
        titleName: "Lexique",
        templateUrl: "./glossary/glossary.html",
        controllerName: 'glossaryCtrl',
        auth: auth,
        hasUserAccess: subscriber => hasUserAccess(subscriber, ref.ACCESS.PAGE.GLOSSARY),
    },
    cookies: {
        urlName: "informations_sur_les_cookies",
        titleName: "Informations sur les cookies",
        templateUrl: "./cookies/cookies-page.html",
        controllerName: "cookiesCtrl",
        auth: noauth,
    },
    calculExplanation: {
        urlName: "comment_cette_evolution_est-elle_calculee",
        titleName: "Comment cette évolution est-elle calculée ?",
        templateUrl: "",
        auth: auth,
    },
    advices: {
        urlName: "mes_conseils",
        titleName: "Mes Conseils",
        templateUrl: "./myAdvices/my-advices.html",
        controllerName: 'myAdvicesCtrl',
        auth: auth,
        hasUserAccess: subscriber => hasUserAccess(subscriber, ref.ACCESS.PAGE.MY_ADVICES),
    },
    yearly: {
        urlName: "mon_annee",
        titleName: "Mon année",
        templateUrl: "./annualConsumption/annual-consumption.html",
        controllerName: 'annualConsumptionCtrl',
        auth: auth,
    },
    optimisation: {
        urlName: "mon_opti_conso",
        titleName: "Mon opti' conso",
        templateUrl: "./optiConsumption/opti-consumption.html",
        controllerName: 'optiConsumptionCtrl',
        auth: auth,
        hasUserAccess: (subscriber) => {
            return hasUserAccess(subscriber, ref.ACCESS.PAGE.OPTI_CONSUMPTION) && subscriber.hasContractForSelectedSite()
                && subscriber.hasAccessToTest();
        },
    },
    myBudget: {
        urlName: "mon_budget",
        titleName: "Mon budget",
        templateUrl: "./myBudget/my-budget.html",
        controllerName: 'myBudgetCtrl',
        auth: auth,
        hasUserAccess: (subscriber) => {
            return hasUserAccess(subscriber, ref.ACCESS.PAGE.MY_BUDGET);
        },
    },
    news: {
        urlName: "mon_actu",
        titleName: "Mon actu",
        templateUrl: "./news/news.html",
        controllerName: 'newsCtrl',
        auth: auth,
        hasUserAccess: subscriber => hasUserAccess(subscriber, ref.ACCESS.PAGE.NEWS),
    },
    myHouse: {
        urlName: "mon_logement",
        titleName: "Mon logement",
        templateUrl: "./myHouse/my-house.html",
        controllerName: 'myHouseCtrl',
        auth: auth,
        hasUserAccess: subscriber => hasUserAccess(subscriber, ref.ACCESS.PAGE.MY_HOUSE),
    },
    monthlyPdf: {
        urlName: "rapport_mensuel",
        titleName: "Rapport mensuel",
        templateUrl: "./pdf/monthly-report.html",
        controllerName: 'monthlyReportCtrl',
        auth: auth,
    },
    myObjectives: {
        urlName: "mon_objectif",
        titleName: "Mon objectif",
        templateUrl: "./myObjectives/my-objectives.html",
        controllerName: 'myObjectivesCtrl',
        auth: auth,
        hasUserAccess: subscriber => hasUserAccess(subscriber, ref.ACCESS.PAGE.MY_OBJECTIVES),
    },
    myReports: {
        urlName: "mes_rapports",
        titleName: "Mes rapports",
        templateUrl: "./myReports/my-reports.html",
        controllerName: 'myReportsCtrl',
        auth: auth,
        hasUserAccess: subscriber => hasUserAccess(subscriber, ref.ACCESS.PAGE.MY_REPORTS),
    },
    help: {
        urlName: "aide",
        titleName: "Aide",
        templateUrl: "./help/help.html",
        controllerName: 'helpCtrl',
        auth: auth,
        hasUserAccess: subscriber => hasUserAccess(subscriber, ref.ACCESS.PAGE.HELP),
    },
    legal: {
        urlName: "mentions_legales",
        titleName: "Mentions légales",
        templateUrl: "./legal/legal.html",
        controllerName: 'legalCtrl',
        auth: noauth,
    },
    privacy: {
        urlName: "protection_des_donnees",
        titleName: "Protection des données",
        templateUrl: "./privacy/privacy.html",
        controllerName: 'privacyCtrl',
        isNoIndex : true,
        auth: noauth,
    },
};

_.forEach(configs, (config, name) => {
    config.url = getUrl(config);
    config.name = name;
    config.controller = config.controllerName;
});

function hasUserAccess(subscriber, tabName) {
    // LOCAL TIP FRI : early return true for all access !!
    // return true;
    if (!auth(subscriber)) {
        return false;
    }
    return subscriber.hasAccessPermission(tabName);
}

function auth(subscriber) {
    return subscriber.isLogged();
}

function noauth() {
    return true;
}

function getDefaultPage() {
    const defaultPage = _.find(configs, config => config.default);
    if (!defaultPage) {
        logger.error('No default page configured!');
        return configs[0];
    }

    return defaultPage;
}

function getUserHomePage() {
    const userHomePage = _.find(configs, config => config.userHome);
    if (!userHomePage) {
        logger.error('No user home page configured!');
        return configs[0];
    }

    return userHomePage;
}

function getPageConfigs() {
    return _.filter(configs, config => !_.isEmpty(config.templateUrl));
}

function getConfigByPageName(pageName) {
    return configs[pageName];
}

function getConfigByUrl(urlName) {
    return _.find(configs, config => urlName === config.url);
}

function getUrl(config) {
    return `/${config.urlName || ''}`;
}

function setlang($i18next) {
    configs.myData.smallName = $i18next("myData.smallName");
    configs.myInstallation.titleName = $i18next("myInstallation.titleName");
    configs.myContract.smallName = $i18next("myContract.smallName");
    configs.myHouse.titleName = $i18next("myHouse.titleName");

    for (const key in configs) {
        if (configs.hasOwnProperty(key)) {
            configs[key].titleName = $i18next(key + ".titleName");
        }
    }
}
