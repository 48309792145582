const _ = require('underscore');

module.exports = [function () {
    return {
        /**
         * Fix max digit for a number
         * If the number has more digit than the limit 'maxDigit', the decimals are remove
         * Otherwise return the number with only two decimals
         * @param value {number}
         * @param maxDigit {number} digit limit
         * @returns {string}
         */
        getNumberWithMaximumDigit: function ( value, maxDigit ){
            if(!value) value = 0.0;
            let numberDecimals = (value.toFixed(0)).toString().length > maxDigit ? 0 : 2;
            return value.toFixed(numberDecimals);
        }
    };
}];
