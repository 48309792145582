module.exports = function(){
    return {
        restrict: 'E',
        //templateUrl : './components/footer/footer.html'
        templateUrl : '/footer',
        controller: ['$scope', '$location', function($scope, $location) {
            $scope.$on('$locationChangeSuccess', function  () {
                //$scope.displayContactLink=$location.url() !== "/contact";
                // Désactivation "temporaire" à cause de la Slovénie
                $scope.displayContactLink = false;
            });
        }]
    }
};