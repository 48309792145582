const _ = require('underscore');
const moment = require('moment');
const querystring = require('querystring');
_.str = require('underscore.string');

const ref = require('enum');
const ModelError = require('./ModelError');
const Model = require('./Model');

// Default model attributes if unset
var defaultOptions = {
    id: null,
    alternative_email: null,
    email: null,
    firstname: null,
    lastname: null,
    login: null,
    password: null,
    phone: null,
    country: null,
    selectedSiteId: null,
    siteList: [],
    username: null,
    stayLoggedIn: true
};

// Constructor
var Subscriber = function (attributes) {

    // Do not touch this
    var attr = this.attributes = {};
    attributes = _mergeOptions(attributes);

    // Here you can write in constructor
    try {
        attributes.username = _.str.trim(attributes.username);
        this.set(attributes);
        // Note : example, model validation can be done anytime, this example show how to validate inside the constructor
        //this.validate();
    }

        // Do not touch this
    catch (err) {
        console.error(err.message);
        console.error(err.stack);
    }
};

// Heritage
Subscriber.prototype = Object.create(Model.prototype);
// Add default model attributes into prototype
Subscriber.prototype.defaultOptions = defaultOptions;
// Subscriber cookie name
Subscriber.prototype.cookieName = 'session';

// Private Methods (please prefix with underscore, and never add them to prototype)
// ================================================================================


/**
 * @description merges default options and args provided to constructor
 * @param args
 * @returns {*}
 * @private
 */
function _mergeOptions(args) {
    return _.defaults( args || {}, Subscriber.prototype.defaultOptions );
}

/**
 * @description query server for login
 * @param subscriber
 * @param cb
 * @private
 */
// TODO $http?
function _loginWithData(subscriber, cb) {
    $.ajax({
        url: '/login',
        type: 'POST',
        dataType: "json",
        data: subscriber.toJSON(),
        success: function (result) {
            if (result.error) {
                cb(result.error);
            } else {
                cb(null, result);
            }
        },
        error: function (result) {
            cb(result);
        }
    });
}

/**
 * If a connection token is available in the environment, try to use it to auto-login.
 * Token can be stored in the URL (connection from EPP) or in a cookie ("remember me" auto-login).
 * @param {Subscriber} subscriber
 * @param {function} cb(err)
 * @private
 */
function _tryLoginWithToken(subscriber, cb) {
    let {subscriberId, token} = _getTokenFromUrl();
    if (subscriberId == null || token == null) {
        ({subscriberId, token} = _getTokenFromCookie());
    }
    // TODO _doLoginWithToken(subscriber, subscriberId, token, cb)?
    _doLoginWithToken(subscriber, subscriberId, token, function (err, res) {
        if (err) {
            return cb(err);
        }
        return cb(null, res);
    });
}

/**
 * Checks for the presence of a connection token in the URL
 * @returns {{subscriberId: Number, token: String} | null}
 * @private
 */
function _getTokenFromUrl() {
    const params = querystring.parse(location.href.slice( location.href.lastIndexOf('/') + 1 ));
    const subscriberId = params.id;
    const token = params.token;
    if (subscriberId == null || token == null) {
        return {subscriberId: null, token: null};
    }
    return {subscriberId, token};
}

/**
 * Checks for the presence of a rememberMe cookie, and tries to extract and returns the token that it contains.
 * @returns {{subscriberId: Number, token: String} | null}
 * @private
 */
function _getTokenFromCookie() {
    let loginCookie = $.cookie(ref.COOKIE.AUTO_LOGIN.NAME);
    if (loginCookie == null) {
        return {subscriberId: null, token: null};
    }
    loginCookie = JSON.parse(loginCookie);
    const subscriberId = loginCookie.subscriberId;
    const token = loginCookie.token;
    return {subscriberId, token};
}

/**
 * Tries to login the a subscriber with the given token information.
 * @param {Object} subscriberObj
 * @param {Number} subscriberId
 * @param {String} token
 * @param {function} cb(err, result) err is set if login isn't a success. res contains subscriber data if login is a success.
 * @private
 */
function _doLoginWithToken(subscriberObj, subscriberId, token, cb) {
    if (_.isFinite(subscriberId) && _.isString(token)) {
        subscriberObj.set({
            id: subscriberId,
            token: token
        });
    }
    // Even if the token is not valid, try to log in with session
    subscriberObj.doLogin(function(err, res) {
        cb(err, res);
    });
}

// Public Methods
// ==============

// Public method need to be included to prototype (otherwise they'll remain private)
_.extend(Subscriber.prototype, {
    doLogin: doLogin,
    doLogout: doLogout,
    tryInitialLogin: tryInitialLogin,
    validate: validate,
    isLogged: isLogged,
    getEmail: getEmail,
    getLogin: getLogin,
    getCountry: getCountry,
    hasContractForSelectedSite: hasContractForSelectedSite,
    hasBaseContractForSelectedSite: hasBaseContractForSelectedSite,
    hasMyHouseForSelectedSite: hasMyHouseForSelectedSite,
    getSelectedSite: getSelectedSite,
    getSelectedSiteModulatorList: getSelectedSiteModulatorList,
    getSelectedSiteCsModulatorIdList: getSelectedSiteCsModulatorIdList,
    getSelectedSiteSubscribedPower: getSelectedSiteSubscribedPower,
    getDisplayGroupListForSelectedSite: getDisplayGroupListForSelectedSite,
    hasDisplayGroup: hasDisplayGroup,
    hasDisplayGroupLogosForSelectedSite: hasDisplayGroupLogosForSelectedSite,
    hasObjectiveForSelectedSite: hasObjectiveForSelectedSite,
    hasAccessToContractPage:hasAccessToContractPage,
    hasAccessToTest:hasAccessToTest,
    getAllowedPages:getAllowedPages,
    getAllowedWidgets:getAllowedWidgets,
    hasAccessPermission: hasAccessPermission,
    getSelectedSiteCsLinkList:getSelectedSiteCsLinkList,
    getRealTimeRefreshPeriod: getRealTimeRefreshPeriod,
    hasProductionOnSelectedSite: hasProductionOnSelectedSite,
    getSiteCurrency: getSiteCurrency
});

// Write public methods here

/**
 * @description validate model attributes
 */
function validate() {
    if (this.get('username') === null) {
        throw new ModelError("Missing username");
    }
    if (this.get('password') === null) {
        throw new ModelError("Missing password");
    }
}


function doLogin(cb) {
    _loginWithData(this, cb);
}

/**
 * Function called upon loading the site. It will automatically try to login if possible (active session or token)
 * @param cb(err, res)
 */
function tryInitialLogin(cb) {
    _tryLoginWithToken(this, (err, res) => {
        if (!err) {
            return cb(null, res);
        }
        // Startup login with session/token has failed, remove any auto-login cookie
        $.removeCookie(ref.COOKIE.AUTO_LOGIN.NAME);
        return cb(err);
    });
}

/**
 * @description disconnect the user
 * @param isManual {boolean}
 */
function doLogout(isManual) {
    $.ajax({
        url: '/logout',
        type: 'POST',
        data: {isManual: isManual},
        success: (err, res) => {
            this.set( Subscriber.prototype.defaultOptions );
        },
    });
}

/**
 * @description tell if the user is logged
 * @returns {boolean}
 */
function isLogged() {
    return !!this.get('siteList').length;
}

/**
 * @description return subscriber email
 * @returns {String}
 */
function getEmail() {
    return this && ( this.get('email') || this.get('alternative_email') );
}

function getLogin() {
    return this && this.get('login');
}

function getCountry() {
    var site = this.getSelectedSite();
    return site.countryId;
}

function getSiteCurrency() {
    return this.getSelectedSite().currency;
}

function getSelectedSite(){
    return _.findWhere(this.get('siteList'), {id: this.get('selectedSiteId') });
}

function getSelectedSiteModulatorList(){
    var selectedSite = this.getSelectedSite();
    return selectedSite.modulatorList;
}

function getSelectedSiteCsModulatorIdList(){
    var currentSiteModulatorList = this.getSelectedSiteModulatorList(),
        csModulatorIdList = [];
    _.each(currentSiteModulatorList, function( modulator ){
        csModulatorIdList.push( modulator.csModulatorId );
    });
    return csModulatorIdList;
}

function hasContractForSelectedSite(){
    return !!this.getSelectedSite().contract;
}

function getAllowedPages(){
    return this.getSelectedSite().allowedPages;
}

function getAllowedWidgets(){
    return this.getSelectedSite().allowedWidgets;
}

function hasAccessPermission(requiredAccessID){
    if(this.getSelectedSite() != null){
        return this.getAllowedPages().includes(requiredAccessID) || this.getAllowedWidgets().includes(requiredAccessID);
    }
    return false;
}

function hasObjectiveForSelectedSite(){
    return !!this.getSelectedSite().myObjective && !!this.getSelectedSite().myObjective.objective;
}

function hasBaseContractForSelectedSite(){
    var contract = this.getSelectedSite().contract;
    return (contract && contract.optionId === ref.CONTRACT_OPTION.BASE);
}

function hasAccessToTest(){
    var contract = this.getSelectedSite().contract;
    if ( contract == null || !this.hasAccessPermission(ref.ACCESS.PAGE.OPTI_CONSUMPTION)) {
        return false;
    }
    var contractDate = new moment( contract.anniversaryDate, 'DD/MM/YYYY' );
    var minDateForTest = moment().subtract(13, 'month');  // Access only if there is at least a year of consumption data
    return minDateForTest.isAfter(contractDate);
}

function hasAccessToContractPage(){
    return this.hasAccessPermission(ref.ACCESS.PAGE.MY_CONTRACT);
}

function getSelectedSiteSubscribedPower(){
    if( !!this.getSelectedSite().contract ){
        return this.getSelectedSite().contract.subscribedPower;
    }
    return null;
}

function hasMyHouseForSelectedSite(){
    return !!this.getSelectedSite().myHouse;
}

function getDisplayGroupListForSelectedSite(){
    var selectedSite = this.getSelectedSite();
    return (selectedSite) ? selectedSite.displayGroupList : null;
}

/**
 * Check if the user is part of the given display group
 * @param idDisplayGroup {integer} id of the display group to test
 * @returns {boolean}
 */
function hasDisplayGroup(idDisplayGroup){
    const displayGroupList = this.getDisplayGroupListForSelectedSite();
    if(!displayGroupList){
        return false;
    }

    for(let displayGroup of displayGroupList){
        if(displayGroup.id === idDisplayGroup){
            return true;
        }
    }
    return false;
}

function hasDisplayGroupLogosForSelectedSite(){
    var displayGroupList = this.getDisplayGroupListForSelectedSite();
    for(var i = displayGroupList.length - 1; i >= 0; i--){
        if(displayGroupList[i].displayGroupLogo) return true;
    }
    return false;
}

function getSelectedSiteCsLinkList(){
    var csLinkList = [];
    _.each (this.getSelectedSiteModulatorList(), function(modulator){
        _.each(modulator.values,function(csLink){
            csLink.modulatorTypeId = modulator.modulatorTypeId;
            if (!csLink.isGlobal)
                csLinkList.push(csLink);
        });
    });
    return csLinkList;
}

// todo: passer une constante
function hasProductionOnSelectedSite(){
    let hasProducer = false;
    _.each (this.getSelectedSiteModulatorList(), function(modulator){
        _.each(modulator.values,function(csLink){
            Object.keys(csLink.appliances).forEach(function(id) {
                if ( csLink.appliances[id].isProducer ) {
                    hasProducer = true;
                }
            })

        });
    });
    return hasProducer;
}


function getRealTimeRefreshPeriod() {
    let site = this.getSelectedSite();
    if( site.allowedWidgets.includes(ref.ACCESS.WIDGET.DISPLAY_INSTANT_POWER)) {
        if( site.myVParams.hasOwnProperty(ref.MYV_PARAMETERS.INSTANT_DISPLAY_STEP_IN_SEC)) {
            return site.myVParams.INSTANT_DISPLAY_STEP_IN_SEC * 1000;
        }
    }else if( site.allowedOrders.includes(ref.ACCESS.ORDER.REAL_TIME_ACTIVATION)) {
        if( site.myVParams.hasOwnProperty(ref.MYV_PARAMETERS.REAL_TIME_DISPLAY_STEP_IN_SEC)) {
            return site.myVParams.REAL_TIME_DISPLAY_STEP_IN_SEC * 1000;
        }
    }
    //default 10 minutes
    return 10 * 60 * 1000;
}

module.exports = Subscriber;
