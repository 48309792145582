const _ = require('underscore');

//var
let extraConfigName = 'prod';
let isDev = false;
// if the domain name contains 'localhost', use the dev config
if (/localhost$/.test(document.location.hostname)) {
    extraConfigName = 'dev';
    isDev = true;
}
console.log('Using %s config', extraConfigName);

var extraConfigs = {
    prod: {
        logger: {
            displayDate: false,
            unmute: [],
        },
        now: new Date(),
        gaId: 'UA-47640629-2'
    },
    dev: {
        logger: {
            displayDate: true,
            unmute: ['debug', 'log', 'error', 'warn', 'dir', 'dirxml', 'info', 'table'],
        },
        now: new Date(),
        gaId: 'UA-47640629-1'
    }
};

module.exports = _.extend({
    name: extraConfigName,
    isDev,
    lang: {
        fr: {
            decimalPoint: ",",
            thousandsSep: ' '
        },
        en: {
            decimalPoint: ".",
            thousandsSep: ','
        }
    },
    emptyChart: {chart:{backgroundColor:"transparent"},credits:{enabled:false},exporting:{enabled:false},title:{text:null} },
    dateFormat: {
        dateFr: "DD/MM/YYYY"
    },
    gaInfoLink: "http://www.google.com/analytics/learn/privacy.html",
}, extraConfigs[extraConfigName]);
