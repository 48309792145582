require('appScope');
var conf = require('config');

module.exports = ['$scope', '$http', 'flash', function($scope, $http, flash) {
    $scope.cookies = {};
    $scope.cookies.gaInfoLink = conf.gaInfoLink;
    $scope.cookies.status = null;
    $scope.cookies.content = null; // fetched html
    $scope.scrollTo = scrollTo;

    var STATUS_PENDING = {};
    var STATUS_OK = {};
    var STATUS_ERROR = {};

    initialize();

    function initialize(){
        fetch();
    }

    function fetch(){
        // we keep that to keep the status maintain, the request is done twice, with the controller and directive.
        // TODO: find a way to update cookies.status in the directive
        $scope.cookies.status = STATUS_PENDING;
        $http.get("/getCookies.html")
            .then(function onSuccess( response ) {
                $scope.cookies.status = STATUS_OK;
            })
            .catch(function onError( response ) {
                $scope.cookies.status = STATUS_ERROR;
                flash( 'alert-danger', response.data.message, 5000 );
            });
    }

    /**
     * @description fix to angular issue #2070 (https://github.com/angular/angular.js/issues/2070)
     * @param id
     */
    function scrollTo(id){
        var $anchorTarget = angular.element('#'+id);
        if ($anchorTarget.length) {
            angular.element(".anchorHighlight").removeClass("anchorHighlight");
            $anchorTarget.addClass("anchorHighlight");
            var anchorOffsetY = $anchorTarget.offset().top;
            var headerHeight = angular.element(".headerVoltalis").height();
            var scroll = anchorOffsetY - headerHeight;
            $(document).scrollTop(scroll);
        }
    }

    $scope.cookies.hasPendingStatus = function () {
        return $scope.cookies.status === STATUS_PENDING;
    };

    $scope.cookies.hasOkStatus = function () {
        return $scope.cookies.status === STATUS_OK;
    };

    $scope.cookies.hasErrorStatus = function(){
        return $scope.cookies.status === STATUS_ERROR;
    }

}];

