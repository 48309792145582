// almost fully duplicated from WeekConsumptionChartDirective,
const logger = require('logger');
const config = require('config');
const ref = require('enum');
const $appScope = require('appScope');

module.exports = function () {
    return {
        restrict: 'E',
        template: '<div class="reportConsumptionChart"><loader></loader></div>',
        scope: {
            monthToDisplay: '=',
            isWebView: '=',
            chartDataType: '=',
        },
        link: function (scope) {
            logger.debug('reportConsumptionChart', 'link');
            scope.$watch('chartDataType', function () {
                logger.debug('reportConsumptionChartDirective', 'switch chartDataType');
                scope.reportConsumptionChart.beforeRender();
            });
            scope.$on('selectedSiteHasChanged', function (event, userSiteId) {
                logger.debug('reportConsumptionChartDirective', 'receiving event', 'selectedSiteHasChanged');
                scope.reportConsumptionChart.fetch();
            });
            scope.reportConsumptionChart.fetch();
        },
        controller: ['$rootScope', '$scope', '$http', '$i18next', '$q',
            function ($rootScope, $scope, $http, $i18next, $q) {

                const destroyDefer = $q.defer();
                const destroyPromise = destroyDefer.promise;
                $scope.$on('$destroy', () => {
                    const destroyError = new Error('Destroying chart');
                    destroyError.destroy = true;
                    destroyDefer.reject(destroyError);
                });

                // todo: comment ne pas avoir à remonter les evènements pou gérer le loading ici, ou mettre un $reportConsumptionChart.$on
                const STATUS_PENDING = {},
                    STATUS_ERROR = {},
                    STATUS_OK = {};

                // directive attributes
                const subscriber = $appScope.get('user');
                $scope.reportConsumptionChart = {};
                $scope.reportConsumptionChart.charts = null;
                $scope.reportConsumptionChart.status = null;
                $scope.reportConsumptionChart.fetch = fetch;
                $scope.reportConsumptionChart.beforeRender = beforeRender;
                $scope.reportConsumptionChart.userHasContractForSelectedSite = userHasContractForSelectedSite;

                // directive method
                function fetch() {
                    $scope.reportConsumptionChart.status = STATUS_PENDING;
                    logger.debug('reportConsumptionChartDirective', 'fetch started');
                    const params = {
                        isWebView: $scope.isWebView,
                        monthToDisplay: $scope.monthToDisplay.toISOString(),
                    };
                    $q.race([destroyPromise, $http.get("/chart/getReportConsumptionCharts.json", {"params": params})])
                        .then(function onSuccess(response) {
                            logger.debug('reportConsumptionChartDirective', 'fetch done');
                            $scope.reportConsumptionChart.status = STATUS_OK;
                            $scope.reportConsumptionChart.charts = response.data;

                            logger.debug('reportConsumptionChartDirective', 'emit event', 'reportConsumptionChartsHasChanged');
                            $scope.$emit('reportConsumptionChartsHasChanged', response.data);

                            beforeRender();
                        })
                        .catch(function onError(e) {
                            if (e.destroy) {
                                return;
                            }
                            logger.error('reportConsumptionChartDirective', 'fetch fail');
                            $scope.$emit('reportConsumptionChartError', e);

                            //todo: todo: voir ce qu'il faut faire de ces 2 lignes
                            $scope.reportConsumptionChart.status = STATUS_ERROR;
                            $scope.reportConsumptionChart.charts = null;
                            render($i18next('reportConsumptionChart.errorLoadingChart'));
                        });

                }

                function renderChart(euroAndKwhCharts, chartType) {
                    if (euroAndKwhCharts[chartType]) {
                        render(null, euroAndKwhCharts[chartType]);
                        $scope.$emit("reportConsumptionChartChanged", euroAndKwhCharts);
                        return;
                    }
                    render($i18next("reportConsumptionChart.errorNoConsumption"));
                    $scope.$emit("reportConsumptionChartChanged", null);
                }

                // decide which graph should be displayed
                function beforeRender() {
                    const euroAndKwhCharts = $scope.reportConsumptionChart.charts;
                    if (!euroAndKwhCharts) {
                        return;
                    }
                    switch ($scope.chartDataType.name) {
                        case ref.CHART_DATA_TYPES.KWH.name:
                            renderChart(euroAndKwhCharts, 'kwh');
                            break;
                        case ref.CHART_DATA_TYPES.EURO.name:
                            renderChart(euroAndKwhCharts, 'euro');
                            break;
                        default:
                            logger.error('reportConsumptionChartDirective', 'beforeRender', 'unknown type', $scope.chartDataType.name)
                            break;
                    }
                }

                function render(errorMessage, chart) {
                    const $reportConsumptionChart = angular.element('.reportConsumptionChart');
                    if (errorMessage) {
                        $reportConsumptionChart.highcharts(config.emptyChart);
                        $reportConsumptionChart.highcharts().showLoading(errorMessage);
                        return;
                    }
                    chart.plotOptions.series = {
                        events: {
                            legendItemClick: function () {return false;},
                        },
                    };

                    addXAxisFormatter(chart, 7);
                    $reportConsumptionChart.highcharts(chart);
                }

                // todo: extract in tools
                function addXAxisFormatter(chart, dayInterval = 1) {
                    if (!chart) {
                        return;
                    }

                    chart.xAxis[0].tickInterval = dayInterval * 24 * 3600 * 1000;
                    chart.xAxis[0].labels.formatter = function () {
                        const dayStr = Highcharts.dateFormat('%a', this.value);
                        const firstLetter = dayStr.substring(0, 1);
                        let label = '<span style="font-size: 20px; font-weight: bold; color: gray;">' + firstLetter + '</span>';
                        label += '<br/><span>' + Highcharts.dateFormat('%e %B', this.value) + '</span>';
                        return label;
                    };
                }

                $scope.reportConsumptionChart.hasPendingStatus = function () {
                    return $scope.reportConsumptionChart.status === STATUS_PENDING;
                };

                $scope.reportConsumptionChart.hasOkStatus = function () {
                    return $scope.reportConsumptionChart.status === STATUS_OK;
                };

                function userHasContractForSelectedSite() {
                    if (!subscriber.hasContractForSelectedSite()) {
                        //If we observe a chart in euro, and change the site which doesn't have a contract,
                        // the chartDataType will still be euro and we don't want that.
                        if ($scope.chartDataType.name === ref.CHART_DATA_TYPES.EURO.name) {
                            $scope.chartDataType = ref.CHART_DATA_TYPES.KWH;
                        }
                    }
                    return subscriber.hasContractForSelectedSite();
                }

            }]
    }
};
