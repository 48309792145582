var _ = require('underscore'),
    $ = require('jquery');
_.str = require('underscore.string');
var ModelError = require('./ModelError'),
    Model      = require('./Model');


// Default model attributes if unset
var defaultOptions = {
    supplier: null,
    type: null,
    pricingOption: null,
    alternatePricingOption: null,
    subscribedPower: null,
    oneOffpeakHourPrice: null,
    onePeakHourPrice: null,
    subscriptionMonthlyPrice: null,
    hourTypes: null,
    anniversaryDate: null,
    index: null,
    indexDate: null

};

// Constructor
var Contract = function( attributes ){

    var attr = this.attributes = {};
    attributes = _mergeOptions( attributes );

    try{
        this.set( attributes );
        //this.validate();
    }
    catch(err){
        console.error(err.message);
        console.error(err.stack);
    }
};

Contract.prototype = Object.create( Model.prototype );
Contract.prototype.defaultOptions = defaultOptions;

// Private Methods
function _mergeOptions(args){
    return _.defaults( args || {}, Contract.prototype.defaultOptions );
}

// Public Methods (need to be included to prototype)
_.extend(Contract.prototype, {
    validate: validate
});

function validate(){
    if( this.get('type') === null ){
        throw new ModelError( "Missing contract type" );
    }
}
module.exports = Contract;