/**
 * Dropdown menu for language selection.
 * Include in HTML with the 'language-selector' element.
 * To add a new language, see lang.js and fill 'fallbackLng' and 'languageToCountryMap'.
 */
const _ = require('lodash');
const applianceTools = require('tools').applianceTools;

module.exports = function () {
    return {
        restrict: 'E',
        templateUrl: './newScheduler/customBox/custom-box.html',
        scope: {
            appliance: '=',
        },
        controller: ['$scope', '$i18next',function($scope, $i18next) {

            $scope.showEditMode = false;
            $scope.collapse = function () {
                $scope.showEditMode = !$scope.showEditMode;
            };

            $scope.getModeTitle = function (mode) {
                return mode.isEnabled ? '': $i18next('newSchedulerCustomBox.modeSoonAvailable');
            };
            $scope.name = function () {
                return $scope.appliance.name;
            };
            $scope.remainingAvailableModes = function () {
                const remainingAvailableModes = $scope.appliance.availableProgrammationMode
                    .filter(mode => mode.dbId !== $scope.appliance.currentProgrammationMode.dbId);
                return _.sortBy(remainingAvailableModes, 'order');
            };
            $scope.saveSelectedMode = function(selectedMode) {
                $scope.appliance.currentProgrammationMode = selectedMode;
            };
            $scope.isTemperature = function () {
                if ($scope.appliance.currentProgrammationMode.dbId === applianceTools.MODES.TEMPERATURE.dbId)
                {
                    if (!$scope.appliance.setpointTemperatureInCelsius) {
                        $scope.appliance.setpointTemperatureInCelsius = 19;
                    }
                    return true;
                }
                 return false;
            };
            $scope.increaseTemperature = function() {
                const currentTemperature = $scope.appliance.setpointTemperatureInCelsius;
                $scope.appliance.setpointTemperatureInCelsius = Math.min(32, currentTemperature + 0.5);
            };

            $scope.decreaseTemperature = function() {
                const currentTemperature = $scope.appliance.setpointTemperatureInCelsius;
                $scope.appliance.setpointTemperatureInCelsius = Math.max(14, currentTemperature - 0.5);
            };
        }],

    };
};
