const logger = require('logger');

const MAP_TYPE = {
    ANNUAL: 'annual',
    SEVEN_DAYS: '7days',
};

module.exports = function(){
    return {
        restrict: 'EA',
        templateUrl : './dataComponents/countryConsumptionMap/country-consumption-map.html',
        scope: {
            options: '=options',
            isWebView: '=isWebView',
        },
        link: function(scope, iElement, attrs) {
            logger.debug('countryConsumptionMap', 'link');

            scope.countryConsumptionMap.$map = iElement.find('.countryConsumptionMap');
            if ( !scope.options.displayOnly ){
                scope.countryConsumptionMap.fetch();
            }
            else {
                scope.countryConsumptionMap.render(null, scope.options.map, scope.options.heatingConsumption);
            }

            scope.$on( 'selectedSiteHasChanged', function(event, userSiteId){
                logger.debug('countryConsumptionMap', 'receiving event', 'selectedSiteHasChanged');
                scope.countryConsumptionMap.fetch();
            });

        },
        controller: ['$scope', '$http', '$i18next', '$timeout', 'mapTools',
            function($scope, $http, $i18next, $timeout, mapTools){

                var STATUS_PENDING = {};
                var STATUS_OK = {};
                var STATUS_ERROR = {};

                $scope.countryConsumptionMap = {};
                $scope.countryConsumptionMap.$map = null;
                $scope.countryConsumptionMap.status = null;
                $scope.countryConsumptionMap.fetch = fetch;
                $scope.countryConsumptionMap.render = render;
                $scope.countryConsumptionMap.map = null;
                $scope.countryConsumptionMap.customizeMap = customizeMap;

                angular.element( window ).resize(function() {
                    const $map = $scope.countryConsumptionMap.$map;
                    if ( null != $map && $map.highcharts() ){
                        $scope.countryConsumptionMap.$map.highcharts().reflow();
                        $timeout( function(){
                            mapTools.adjustRendering( $map ,$i18next);
                        }, 500 );
                    }
                });

                // directive method
                function fetch(){
                    logger.debug('countryConsumptionMapDirective', 'fetch started');
                    $scope.countryConsumptionMap.status = STATUS_PENDING;
                    $http({
                        method: 'GET',
                        url: '/chart/getCountryConsumptionMap.json',
                        params: {
                            mapType: $scope.options.mapType,
                            useLegend: $scope.options.useLegend,
                            isWebView: $scope.isWebView,
                        },
                    })
                        .then(function onSuccess( response ) {
                            logger.debug('countryConsumptionMapDirective', 'fetch done');

                            if ( $scope.options.mapType === MAP_TYPE.SEVEN_DAYS && response.data.map != null){
                                logger.debug('countryConsumptionMapDirective', 'emit event', '7DaysConsumptionDepartment');
                                $scope.$emit('7DaysConsumptionDepartment', response.data.map.series[0].data);
                            }
                            render( null, response.data.map, response.data.heatingConsumption );
                            const $map = $scope.countryConsumptionMap.$map;
                            if ( null != $map && $map.highcharts() ){
                                // called to improve map scale visibilty
                                mapTools.adjustRendering( $map ,$i18next);
                            }
                        })
                        .catch(function onError() {
                            logger.error('countryConsumptionMapDirective', 'fetch fail');
                            $scope.countryConsumptionMap.status = STATUS_ERROR;
                        });

                }

                function render( errorMessage, map, heatingConsumption ) {
                    const $map = $scope.countryConsumptionMap.$map;
                    if (errorMessage){
                        logger.warn('countryConsumptionMapDirective', 'if necessary , handle this error', errorMessage);
                    }
                    else {
                        $scope.countryConsumptionMap.status = STATUS_OK;
                        const mapCopy = mapTools.addFeatureCollection( map );
                        if (heatingConsumption){
                            mapTools.addColorAxisCustomLabel( mapCopy, heatingConsumption );
                        }
                        $scope.countryConsumptionMap.map = mapCopy;
                        $map.highcharts('Map', mapCopy);
                    }
                }

                function customizeMap( valueSite ){
                    // please note, that IE11 now returns undefined again for window.chrome
                    const isChromium = window.chrome,
                        vendorName = window.navigator.vendor;
                    if (isChromium !== null && isChromium !== undefined && vendorName === 'Google Inc.') {
                        chromeCustomize(valueSite);
                    } else {
                        return false;
                    }

                    function chromeCustomize(valueSite){
                        logger.info('countryConsumptionMapDirective', 'customize map for chrome and chromium');
                        const $container = angular.element('.annualConsumptionContainer'),
                            $labelsContainer = $container.find('.highcharts-coloraxis-labels').eq(0),
                            $legendContainer = $container.find('.highcharts-legend-item');
                        let $myIndicator = $container.find('#myIndicator');

                        // remove previous indicator
                        $myIndicator.remove();

                        // turn strokes colors more opaque
                        $legendContainer.find('path[stroke="#C0C0C0"]').each(function(){
                            $(this).attr('stroke', 'rgba(255,255,255,.5)');
                        });

                        if ( !valueSite ) {
                            return;
                        }

                        let legendContent = $labelsContainer.html();

                        let $last = angular.element(legendContent).next('text').last();
                        // sometimes, $last text is hidden with y pos at -9999 when legend is too big for being displayed
                        while ( $last.attr('y') === '-9999' ) {
                            $last = $last.prev('text');
                        }

                        const yLast = parseFloat( $last.attr('y') ),
                            xLast = parseFloat( $last.attr('x')),
                            styleLast = $last.attr('style');

                        // we need the value in kWh
                        const valueLast = parseInt($last.html().slice(0, $last.html().indexOf('k')), 10) * 1000;

                        const ratio = valueLast / valueSite;

                        const ySite = yLast / ratio,
                            xSite = xLast;

                        $myIndicator = angular.element('<text>Moi - </text>');
                        $myIndicator.attr({
                            'id': 'myIndicator',
                            'x': '-' + xSite,
                            'y': ySite,
                            'style': styleLast,
                            'text-anchor': 'start',
                            'opacity': '1',
                        });

                        legendContent = $myIndicator[0].outerHTML + legendContent;
                        $labelsContainer.html( legendContent );
                    }
                }

                $scope.countryConsumptionMap.hasPendingStatus = function () {
                    return $scope.countryConsumptionMap.status === STATUS_PENDING;
                };

                $scope.countryConsumptionMap.hasErrorStatus = function () {
                    return $scope.countryConsumptionMap.status === STATUS_ERROR;
                };

                $scope.countryConsumptionMap.hasOkStatus = function () {
                    return $scope.countryConsumptionMap.status === STATUS_OK;
                };
            }],
    };
};
