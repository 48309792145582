var _ = require('underscore'),
    moment = require('moment');

var logger = require('logger'),
    ref = require('enum');

module.exports = ['$scope', '$http', 'flash', function ($scope, $http, flash) {

    var STATUS_PENDING = {};
    var STATUS_OK = {};
    var STATUS_ERROR = {};

    $scope.Math = Math;
    $scope.CHART_DATA_TYPES = ref.CHART_DATA_TYPES;

    $scope.mapOptions = {
        mapType: 'annual',
        useLegend: true,
    };

    $scope.annualConsumption = {};
    $scope.annualConsumption.totalModulated = {};
    $scope.annualConsumption.status = {};
    $scope.annualConsumption.status.maxPower = null;
    $scope.annualConsumption.status.totalModulated = null;
    $scope.annualConsumption.total = null; // loaded from annualConsumptionChart
    $scope.annualConsumption.maxPower = null;
    $scope.annualConsumption.totalModulated.power = null;
    $scope.annualConsumption.totalModulated.CO2Saved = null;

    initialize();

    $scope.annualConsumption.hasPendingStatus = function ( varName ) {
        return $scope.annualConsumption.status[varName] === STATUS_PENDING;
    };

    $scope.annualConsumption.hasOkStatus = function ( varName ) {
        return $scope.annualConsumption.status[varName] === STATUS_OK;
    };

    $scope.annualConsumption.hasErrorStatus = function ( varName ) {
        return $scope.annualConsumption.status[varName] === STATUS_ERROR;
    };

    function initialize(){
        logger.debug('annualConsumptionController', 'initialization');

        var startDate = moment().startOf('month');
        startDate.subtract( 1, 'year' );

        var endDate = moment();
        endDate.subtract( 1, 'month').endOf( 'month' );

        fetch( startDate, endDate );

        // Events meaning that weekConsumptionChartsHasChanged events will be triggered, meanwhile, display pending status
        $scope.$on('selectedSiteHasChanged', function(){
            logger.debug('annualConsumptionController', 'receiving event', 'selectedSiteHasChanged');
            fetch( startDate, endDate );
        });

        // Events for "Énergie consommée" and "Energie effacée", "Pic de conso", "Pic de conso" data are picked from annualConsumptionChartDirective
        $scope.$on('annualConsumptionDetailChartsHasChanged', function(event, annualConsumptionCharts){
            logger.debug('annualConsumptionController', 'receiving event', 'annualConsumptionDetailChartsHasChanged');
            $scope.annualConsumption.total = getTotalAnnualConsumptionFromCharts( annualConsumptionCharts );
            $scope.annualConsumption.status.total = STATUS_OK;
        });
    }

    function fetch( startDate, endDate ){
        logger.debug('annualConsumptionController', 'fetch started');
        $scope.annualConsumption.status.maxPower = STATUS_PENDING;
        $http({
            method: 'GET',
            url: '/siteData/getSiteMaxPower.json',
            params: {
                startDate: startDate.valueOf(),
                endDate: endDate.valueOf()
            }
        })
            .then(function onSuccess( response ) {
                logger.debug('annualConsumptionController', 'fetch done');
                $scope.annualConsumption.maxPower = response.data.maxPower;
                $scope.annualConsumption.status.maxPower = STATUS_OK;
            })
            .catch(function onError( response ) {
                logger.error('annualConsumptionController', 'fetch fail');
                $scope.annualConsumption.status.maxPower = STATUS_ERROR;
                flash('alert-danger', response.data.message);
            });

        $scope.annualConsumption.status.totalModulated = STATUS_PENDING;
        $http({
            method: 'GET',
            url: '/siteData/getTotalModulatedPower.json',
            params: {
                startDate: startDate.valueOf(),
                endDate: endDate.valueOf(),
            },
        })
            .then(function onSuccess( response ) {
                logger.debug('annualConsumptionController', 'fetch done');
                $scope.annualConsumption.totalModulated.power = response.data.totalModulatedPower;
                $scope.annualConsumption.totalModulated.CO2Saved = response.data.CO2Saved;
                $scope.annualConsumption.status.totalModulated = STATUS_OK;
            })
            .catch(function onError( response ) {
                logger.error('annualConsumptionController', 'fetch fail');
                $scope.annualConsumption.status.totalModulated = STATUS_ERROR;
                flash('alert-danger', response.data.message);
            });
    }

    $scope.getTooltipDates = function(){
        return {
            startDate : moment().startOf( 'month' ).subtract( 1, 'year' ).format( 'ddd LL' ),
            endDate: moment().subtract( 1, 'month').endOf( 'month' ).format( 'ddd LL' )
        };
    };

}];

function getTotalAnnualConsumptionFromCharts( annualConsumptionCharts ){
    var series = annualConsumptionCharts.kwh.series;
    var totalConsumption = null;
    _.each( series, function( serie ){
        if( serie.isConsumedPower ){
            _.each( serie.data, function(data){
                if( !totalConsumption ) totalConsumption = 0;
                totalConsumption += data.y;
            });
        }
    });
    return totalConsumption;
}
