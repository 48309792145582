var _ = require('underscore'),
    moment = require('moment'),
    logger = require('logger'),
    config = require('config'),
    ref = require('enum'),
    $appScope = require('appScope');

module.exports = function () {
    return {
        restrict: 'E',
        template: '<div class="weekConsumptionChart"><loader></loader></div>',
        link: function (scope) {
            logger.debug('weekConsumptionChart', 'link');
            scope.$watch("weekConsumptionChart.chartDataType", function () {
                logger.debug('weekConsumptionChartDirective', 'switch chartDataType to', scope.weekConsumptionChart.chartDataType.name);
                scope.weekConsumptionChart.beforeRender();
            });
            scope.$on('selectedSiteHasChanged', function (event, userSiteId) {
                logger.debug('weekConsumptionChartDirective', 'receiving event', 'selectedSiteHasChanged');
                scope.weekConsumptionChart.fetch();
            });
            scope.weekConsumptionChart.fetch();
        },
        controller: ['$rootScope','$scope', '$http', '$i18next',
            function ($rootScope, $scope, $http, $i18next) {

            var STATUS_PENDING = {},
                STATUS_ERROR = {},
                STATUS_OK = {};

            // directive attributes
            var subscriber = $appScope.get('user');
            $scope.weekConsumptionChart = {};
            $scope.weekConsumptionChart.charts = null;
            $scope.weekConsumptionChart.status = null;
            $scope.weekConsumptionChart.chartDataType = ref.CHART_DATA_TYPES.KWH;
            $scope.weekConsumptionChart.fetch = fetch;
            $scope.weekConsumptionChart.beforeRender = beforeRender;
            $scope.weekConsumptionChart.userHasContractForSelectedSite = userHasContractForSelectedSite;

            // directive method
            function fetch() {
                $scope.weekConsumptionChart.status = STATUS_PENDING;
                logger.debug('weekConsumptionChartDirective', 'fetch started');
                var params = {
                    isWebView: $scope.webView.isWebView
                };
                logger.debug("isWebView", params.isWebView);
                $http.get("/chart/getWeekConsumptionCharts.json", {"params":params})
                    .then(function onSuccess(response) {
                        logger.debug('weekConsumptionChartDirective', 'fetch done');
                        $scope.weekConsumptionChart.status = STATUS_OK;
                        $scope.weekConsumptionChart.charts = response.data;

                        addEventListenerToCharts( response.data );

                        logger.debug('weekConsumptionChartDirective', 'emit event', 'weekConsumptionChartsHasChanged');
                        $scope.$emit('weekConsumptionChartsHasChanged', response.data);

                        beforeRender();
                    })
                    .catch(function onError() {
                        logger.error('weekConsumptionChartDirective', 'fetch fail');

                        //todo: todo: voir ce qu'il faut faire de ces 2 lignes
                        $scope.weekConsumptionChart.status = STATUS_ERROR;
                        $scope.weekConsumptionChart.charts = null;
                        render($i18next('weekConsumptionChart.errorLoadingChart'));
                    });

            }

            function addEventListenerToCharts(charts){
                _.each(charts, function(chart){
                    if(chart){
                        // zoom sur le detail de la colonne clicker
                        chart.plotOptions.column.cursor = 'pointer';

                        // crée l'objet event qui n'existe pas dans le json
                        if( !chart.plotOptions.column.events ) chart.plotOptions.column.events = {};

                        // add click event to go on detailsConsumption (see pageConfigs.js for more info)
                        if( !chart.plotOptions.column.point ) chart.plotOptions.column.point = {};
                        if( !chart.plotOptions.column.point.events ) chart.plotOptions.column.point.events = {};
                        chart.plotOptions.column.point.events.click = function(){
                            var startDate = moment( this.x ),
                                endDate = startDate.clone().endOf('day') ;
                            $appScope.set('detailConsumptionZoom', {
                                startDate: startDate,
                                endDate: endDate,
                                chartDataType: $scope.weekConsumptionChart.chartDataType
                            });
                            var visit = $appScope.get('visit');
                            $appScope.get('visit').page = 'detailConsumptionZoom';
                        };
                    }

                });
            }

            // decide which graph should be displayed
            function beforeRender() {
                if (!$scope.weekConsumptionChart.charts) return;
                if ($scope.weekConsumptionChart.chartDataType.name === ref.CHART_DATA_TYPES.KWH.name) {
                    if($scope.weekConsumptionChart.charts.kwh){
                        render(null, $scope.weekConsumptionChart.charts.kwh);
                    }
                    else{
                        render( $i18next("weekConsumptionChart.errorNoConsumption") );
                    }
                } else if ($scope.weekConsumptionChart.chartDataType.name === ref.CHART_DATA_TYPES.EURO.name) {
                    if($scope.weekConsumptionChart.charts.kwh){
                        render(null, $scope.weekConsumptionChart.charts.euro);
                    }
                    else{
                        render( $i18next("weekConsumptionChart.errorNoConsumption") );
                    }
                }
            }

            function render(errorMessage, chart) {
                var $weekConsumptionChart = angular.element('.weekConsumptionChart');
                if (errorMessage) {
                    $weekConsumptionChart.highcharts( config.emptyChart );
                    $weekConsumptionChart.highcharts().showLoading( errorMessage );
                }
                else {
                    addTooltipFormatter(chart);
                    addXAxisFormatter(chart);
                    $weekConsumptionChart.highcharts(chart);
                }
            }

            function addXAxisFormatter(chart){
                if(chart){
                    chart.xAxis[0].labels.formatter = function(){
                        var dayStr = Highcharts.dateFormat('%a', this.value),
                            firstLetter = dayStr.substring(0, 1);
                        var label = '<span style="font-size: 20px; font-weight: bold; color: gray;">'+firstLetter+'</span>';
                        label += '<br/><span>' + Highcharts.dateFormat('%e %B', this.value) + '</span>';
                        return label;
                    }
                }
            }

            $scope.weekConsumptionChart.hasPendingStatus = function () {
                return $scope.weekConsumptionChart.status === STATUS_PENDING;
            };

            $scope.weekConsumptionChart.hasOkStatus = function () {
                return $scope.weekConsumptionChart.status === STATUS_OK;
            };

            function userHasContractForSelectedSite(){
                if(!subscriber.hasContractForSelectedSite()){
                    //If we observe a chart in euro, and change the site which doesn't have a contract,
                    // the chartDataType will still be euro and we don't want that.
                    if($scope.weekConsumptionChart.chartDataType.name == ref.CHART_DATA_TYPES.EURO.name){
                        $scope.weekConsumptionChart.chartDataType = ref.CHART_DATA_TYPES.KWH;
                    }
                }
                return subscriber.hasContractForSelectedSite();
            }

            function getConsumptionPerDateFromChart( axis,  ...searchingDate ) {
                const consumptionPerDay = new Map();
                axis.forEach((a)=> {
                    a.series.forEach((s) => {
                        s.points.forEach((p) => {
                            if ( searchingDate.includes(p.x)) {

                                if (consumptionPerDay.get(p.x) == null)
                                    consumptionPerDay.set(p.x, []);

                                consumptionPerDay.get(p.x).push(p)
                            }
                        })
                    })
                });
                return consumptionPerDay;
            }

            function addTooltipFormatter(chart){
                if(chart){
                    if(!chart.tooltip)
                        chart.tooltip = {};
                    chart.tooltip.formatter = function(chart){
                        let legend = '';
                        if(!this.points || !this.points[0] || this.points[0].total == 0)
                            return false;

                        const selectedDateInMs = this.points[0].x;
                        const nextDateInMs = moment(selectedDateInMs).add(7, "days").valueOf();
                        const previousDateInMs = moment(selectedDateInMs).subtract(7, "days").valueOf();

                        const chartXAxis = chart.chart.axes.filter((axis) => { return axis.isXAxis });

                        const consumptionPerDateMap = getConsumptionPerDateFromChart(chartXAxis, selectedDateInMs, previousDateInMs, nextDateInMs);
                        const sortedMap = new Map([...consumptionPerDateMap.entries()].sort());

                        const chartType = $scope.weekConsumptionChart.chartDataType;
                        sortedMap.forEach((consumption, date) => {
                            legend += '<b>' + _.str.capitalize(moment(Number(date)).format('dddd DD MMMM YYYY')) + '</b>';
                            let  totalConsumption = 0;
                            consumption.forEach((serie) => {

                                if (serie != null ) {
                                    let unit = $rootScope.getUnitFromChartType(chartType);
                                    const color = serie.series.color,
                                        name = serie.series.name,
                                        value = serie.y;
                                    if (serie.series.options != null && serie.series.options.isTemperature)
                                        unit = '°C';
                                    else if ( serie.series.options.id != "production")
                                        totalConsumption += value;
                                    legend += getPointLegend(color, name, value, unit);
                                }
                            });
                            if ( consumption.length > 1 ) {
                                let unit = $rootScope.getUnitFromChartType(chartType);
                                legend += totalConsumptionLegend(totalConsumption, unit )
                            }
                        });
                        return legend;

                    };
                }

            }

            function totalConsumptionLegend(value, unit) {
                return $i18next("myTooltipFormatter.wholeConso") + ":<strong> "+ value.toFixed(2) + '\u00A0' + unit + "<\strong> <br/>"
            }

            function getPointLegend(color, text, value, unit){
                if (value == null)
                    return '';
    
                const noBreakSpace = '\u00A0';
                let legend = '<br/>';
                legend += '<br/><span style="font-weight: bold;color:' + color + '">\u25A0' + noBreakSpace + '</span>';
                legend += '<span style="color:#333">' + text + '</span>' + noBreakSpace + ': ';
                legend += '<strong>' + value.toFixed( 2 ) + noBreakSpace + unit + '</strong><br/>';
                return legend;
            }
        }]
    }
};

