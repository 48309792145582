module.exports = {
    AUTO_LOGIN: {
        NAME: "rememberMe",
        VALIDITY_DURATION_IN_DAYS: 365*2,
        LENGTH: 60,
        PATH: "/"
    },
    LANG: {
        NAME: "i18next",
        VALIDITY_DURATION_IN_DAYS: 365*2,
    }
};