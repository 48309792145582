////
const _ = require('underscore');
const logger = require('logger');
const config = require('config');

// this will make available jquery & jquery plugins in window
const $ = window.jQuery = window.$ = require('jquery');
require('jquery.cookie');
require('bootstrap-daterangepicker');
require('bootstrap-styl/js/dropdown');

////
// Angular init stuf

const angular = require('angular');
const ngRoute = require('angular-route');
const ngSanitize = require('angular-sanitize');
const ngAnimate = require('angular-animate');
require("angularjs-slider");
const angularBluebirdPromises = require('angular-bluebird-promises');

// Translation
const lang = require('lang');
window.i18n = require('i18next-client'); // this will make available i18n to ng-i18next
require('ng-i18next');
require("moment/locale/fr");
require("moment/locale/en-gb");
require("moment/locale/sl");
require('moment/locale/ja');
require('moment/locale/de');
require('moment/locale/fi');
require('moment/locale/sv');
require('moment/locale/zh-cn');


const pageConfigs = require('./pageConfigs');
const $appScope = require('./appScope');

const leftMenuModule = require('./components/leftMenu/leftMenuModule');
const rightMenuModule = require('./components/rightMenu/rightMenuModule');
const footerModule = require('./components/footer/footerModule');
const notificationsModule = require('./components/notifications/notificationsModules');
const navBarModule = require('./components/headerNavBar/headerNavBarModule');
const flashModule = require('./components/flash/flashModule');
const emailManagerModule = require('./components/emailManager/emailManagerModule');
const carousel = require('./components/carousel/carouselModule');
const httpInterceptorModule = require('./components/httpInterceptor/httpInterceptorModule');
const immediateConsumptionToolsModule = require('./tools/immediateConsumptionToolsModule');

const app = angular.module('spiderman', [
    angularBluebirdPromises,
    'ngRoute',
    'ngSanitize',
    'jm.i18next',
    "rzSlider",
    'ngAnimate',
    flashModule.name,
    leftMenuModule.name,
    footerModule.name,
    notificationsModule.name,
    rightMenuModule.name,
    navBarModule.name,
    emailManagerModule.name,
    carousel.name,
    httpInterceptorModule.name,
    'colorpicker.module',
    immediateConsumptionToolsModule.name,
])
    .run(['$q', function($q) {
        // recommended from http://bluebirdjs.com/docs/install.html#browserify-and-webpack
        $q.Promise.config({
            longStackTraces: config.isDev,
            warnings: config.isDev,
        });
        $q.onPossiblyUnhandledRejection(function(err) {
            if (config.isDev) {
                logger.warn('Unhandled rejection', err);
            }
        });
    }])
    // TODO should be moved in either pageConfigs or navigation
    .run(['$rootScope', '$location', function ($rootScope, $location) {
        $rootScope.$on('$routeChangeStart', (e, next) => {
            // remove any modal effect (ugly but work)
            angular.element('.modal-open').removeClass('modal-open');
            angular.element('.modal-backdrop').remove();

            if (!next.$$route) {
                return;
            }

            // TODO duplicate of navigationController (auth and ACL?)
            const user = $appScope.get('user');

            // redirect if user is not authenticated
            const auth = next.$$route.auth;
            if (auth && !auth(user)) {
                $location.path(pageConfigs.getUrl(pageConfigs.getDefaultPage()));
            }

            // redirect if user has not the right to access this page
            const hasUserAccess = next.$$route.hasUserAccess;
            if (hasUserAccess && !hasUserAccess(user)) {
                $location.path(pageConfigs.getUrl(pageConfigs.getUserHomePage()));
            }
        });
    }])
    //filtre pour mettre première lettre d'une chaine de caractère en majuscule.
    //Utile lors de l'utilisation des dates : les filtres pour afficher le mois met toujours la première lettre en minuscule
    .filter('capitalize', function() {
        return function(input, scope) {
            if (typeof input !== 'string') {
              return input;
            }

            return input.substring(0, 1).toUpperCase() + input.substring(1).toLowerCase();
        }
    })
;


const mapTools = require('./services/mapTools');
app.factory('mapTools', mapTools);

const numberFormatter = require('./services/numberFormatter');
app.factory('numberFormatter', numberFormatter);
app.filter('numberFormatter', numberFormatter);

const tooltipFormatter = require('./services/tooltipFormatter');
app.factory('tooltipFormatter', tooltipFormatter);

const timeTools = require('./services/timeTools');
app.factory('timeTools', timeTools);

const dateRangePickerDirective = require('./components/dateRangePicker/dateRangePickerDirective');
app.directive('dateRangePicker', dateRangePickerDirective);

const datePickerDirective = require('./components/datePicker/datePickerDirective');
app.directive('datePicker', datePickerDirective);

const datePickerDirective2 = require('./components/datePicker2/datePickerDirective');
app.directive('datePicker2', datePickerDirective2);

const loginController = require('./login/loginController');
app.controller('loginCtrl', loginController);

const forgottenPasswordController = require('./login/forgottenPasswordController');
app.controller('forgottenPasswordCtrl', forgottenPasswordController);

const mainBoardController = require('./mainBoard/mainBoardController');
app.controller('mainBoardCtrl', mainBoardController);

const onOffButtonController = require('./onOffButton/onOffButtonController');
app.controller('onOffButtonCtrl', onOffButtonController);

const absenceController = require('./absence/absenceController');
app.controller('absenceCtrl', absenceController);

const newSchedulerController = require("./newScheduler/newSchedulerController");
app.controller('newSchedulerCtrl', newSchedulerController);

const navigationController = require('./navigation/navigationController');
app.controller('navigationCtrl', navigationController);

const detailedConsumptionController = require('./detailedConsumption/detailedConsumptionController');
app.controller('detailedConsumptionCtrl', detailedConsumptionController);

const monthlyReportController = require('./pdf/monthlyReportController');
app.controller('monthlyReportCtrl', monthlyReportController);

const myObjectivesController = require('./myObjectives/myObjectivesController');
app.controller('myObjectivesCtrl', myObjectivesController);

const myReportsController = require('./myReports/myReportsController');
app.controller('myReportsCtrl', myReportsController);

const detailedConsumptionChartDirective = require('./dataComponents/detailedConsumptionChart/detailedConsumptionChartDirective');
app.directive('detailedConsumptionChart', detailedConsumptionChartDirective);

const monthlyConsumptionDetailChartDirective = require('./dataComponents/monthlyDetailedConsumptionChart/monthlyConsumptionDetailChartDirective');
app.directive('monthlyConsumptionDetailChart', monthlyConsumptionDetailChartDirective);

const monthlyConsumptionDetailPieChartDirective = require('./dataComponents/monthlyDetailedConsumptionPieChart/monthlyConsumptionDetailPieChartDirective');
app.directive('monthlyConsumptionDetailPieChart', monthlyConsumptionDetailPieChartDirective);

const annualObjectiveChartDirective = require('./dataComponents/annualObjectiveChart/annualObjectiveChartDirective');
app.directive('annualObjectiveChart', annualObjectiveChartDirective);

const detailedConsumptionPieChartDirective = require('./dataComponents/detailedConsumptionPieChart/detailedConsumptionPieChartDirective');
app.directive('detailedConsumptionPieChart', detailedConsumptionPieChartDirective);

const myProfileGaugeDirective = require('./dataComponents/myProfileGauge/myProfileGaugeDirective');
app.directive('myProfileGauge', myProfileGaugeDirective);

const annualConsumptionController = require('./annualConsumption/annualConsumptionController');
app.controller('annualConsumptionCtrl', annualConsumptionController);

const optiConsumptionController = require('./optiConsumption/optiConsumptionController');
app.controller('optiConsumptionCtrl', optiConsumptionController);

const contactController = require('./contact/contactController');
app.controller('contactCtrl', contactController);

const myDataController = require('./myData/myDataController');
app.controller('myDataCtrl', myDataController);

const myInstallationController = require('./myInstallation/myInstallationController');
app.controller('myInstallationCtrl', myInstallationController);

const myBudgetController = require('./myBudget/myBudgetController');
app.controller('myBudgetCtrl', myBudgetController);

const myHouseController = require('./myHouse/myHouseController');
app.controller('myHouseCtrl', myHouseController);

const myContractController = require('./myContract/myContractController');
app.controller('myContractCtrl', myContractController);

const glossaryController = require('./glossary/glossaryController');
app.controller('glossaryCtrl', glossaryController);

const glossaryHtmlDirective = require('./glossary/glossaryHtmlDirective');
app.directive('glossaryHtml', glossaryHtmlDirective);

const legalHtmlDirective = require('./legal/legalHtmlDirective');
app.directive('legalHtml', legalHtmlDirective);

const cookiesHtmlDirective = require('./cookies/cookiesHtmlDirective');
app.directive('cookiesHtml', cookiesHtmlDirective);

const privacyHtmlDirective = require('./privacy/privacyHtmlDirective');
app.directive('privacyHtml', privacyHtmlDirective);

const myAdvicesController = require('./myAdvices/myAdvicesController');
app.controller('myAdvicesCtrl', myAdvicesController);

const helpController = require('./help/helpController');
app.controller('helpCtrl', helpController);

const legalController = require('./legal/legalController');
app.controller('legalCtrl', legalController);

const cookiesController = require('./cookies/cookiesController');
app.controller('cookiesCtrl', cookiesController);

const privacyController = require('./privacy/privacyController');
app.controller('privacyCtrl', privacyController);

const immediateConsumptionController = require('./immediateConsumption/immediateConsumptionController');
app.controller('immediateConsumptionCtrl', immediateConsumptionController);

const modalDirective = require('./components/modal/modalDirective');
app.directive('modal', modalDirective);

const autofillableDirective = require('./components/autofillable/autofillableDirective');
app.directive('autofillable', ["$timeout", autofillableDirective]);

const loaderDirective = require('./components/loader/loaderDirective');
app.directive('loader', loaderDirective);

const requireContractDirective = require('./components/requireContract/requireContractDirective');
app.directive('requireContract', requireContractDirective);

const requireObjectiveDirective = require('./components/requireObjective/requireObjectiveDirective');
app.directive('requireObjective', requireObjectiveDirective);

const futureFunctionalityDirective = require('./components/futureFunctionality/futureFunctionalityDirective');
app.directive('futureFunctionality', futureFunctionalityDirective);

const unexpectedErrorDirective = require('./components/unexpectedError/unexpectedErrorDirective');
app.directive('unexpectedError', unexpectedErrorDirective);

const peakHourPicker = require('./components/peakHourPicker/peakHourPickerDirective');
app.directive('peakhourpicker', peakHourPicker);

const immediateConsumptionInkWDirective = require('./dataComponents/immediateConsumptionInkW/immediateConsumptionInkWDirective');
app.directive('immediateConsumptionInKw', immediateConsumptionInkWDirective);

const immediateConsumptionInkWbyItemDirective = require('./dataComponents/immediateConsumptionInkWbyItem/immediateConsumptionInkWbyItemDirective');
app.directive('immediateConsumptionInKwByItem', immediateConsumptionInkWbyItemDirective);

const immediateConsumption24hChartDirective = require('./dataComponents/immediateConsumption24h/immediateConsumption24hChartDirective');
app.directive('immediateConsumption24hChart', immediateConsumption24hChartDirective);

const weekConsumptionHeatmapDirective = require('./dataComponents/weekConsumptionHeatmap/weekConsumptionHeatmapDirective');
app.directive('weekConsumptionHeatmap', weekConsumptionHeatmapDirective);

const monthConsumptionHeatmapDirective = require('./dataComponents/monthConsumptionHeatmap/monthConsumptionHeatmapDirective');
app.directive('monthConsumptionHeatmap', monthConsumptionHeatmapDirective);

const weekConsumptionChartDirective = require('./dataComponents/weekConsumptionChart/weekConsumptionChartDirective');
app.directive('weekConsumptionChart', weekConsumptionChartDirective);

const reportConsumptionChartDirective = require('./dataComponents/reportConsumptionChart/reportConsumptionChartDirective');
app.directive('reportConsumptionChart', reportConsumptionChartDirective);

const reportConsumptionPieChartDirective = require('./dataComponents/reportConsumptionChart/reportConsumptionPieChartDirective');
app.directive('reportConsumptionPieChart', reportConsumptionPieChartDirective);

const meteoEffectDirective = require('./dataComponents/meteoEffect/meteoEffectDirective');
app.directive('meteoEffect', meteoEffectDirective);

const meteoEffectDetailsDirective = require('./dataComponents/meteoEffectDetails/meteoEffectDetailsDirective');
app.directive('meteoEffectDetails', meteoEffectDetailsDirective);

const countryConsumptionMapDirective = require('./dataComponents/countryConsumptionMap/countryConsumptionMapDirective');
app.directive('countryConsumptionMap', countryConsumptionMapDirective);

const annualConsumptionChartDirective = require('./dataComponents/annualConsumptionChart/annualConsumptionChartDirective');
app.directive('annualConsumptionChart', annualConsumptionChartDirective);

const annualConsumptionDetailChartDirective = require('./dataComponents/annualConsumptionDetailChart/annualConsumptionDetailChartDirective');
app.directive('annualConsumptionDetailChart', annualConsumptionDetailChartDirective);

const myNeighborsDirective = require('./dataComponents/myNeighbors/myNeighborsDirective');
app.directive('myNeighbors', myNeighborsDirective);

const profileProgressDirective = require('./dataComponents/profileProgress/profileProgressDirective');
app.directive('profileProgress', profileProgressDirective);

const yearProgressDirective = require('./dataComponents/yearProgress/yearProgressDirective');
app.directive('yearProgress', yearProgressDirective);

const progressBarDirective = require('./dataComponents/progressBar/progressBarDirective');
app.directive('progressBar', progressBarDirective);

const tooltipDirective = require('./components/tooltip/tooltipDirective');
app.directive('tooltip', tooltipDirective);

const dayNightConsumptionDirective = require('./dataComponents/dayNightConsumption/dayNightConsumptionDirective');
app.directive('dayNightConsumption', dayNightConsumptionDirective);

const schedulerChart = require("./dataComponents/schedulerChart/schedulerChartDirective");
app.directive('schedulerChart',schedulerChart);

const confirmationPopup = require("./components/confirmationPopup/confirmationPopupDirective");
app.directive("confirmationPopup", confirmationPopup);

const inputTimeString = require("./components/customInputDirectives/inputTimeStringDirective/inputTimeStringDirective");
app.directive("inputTimeString", inputTimeString);

const inputNoValidation = require("./components/customInputDirectives/inputNoValidation/inputNoValidationDirective");
app.directive("inputNoValidation", inputNoValidation);

const languageSelector = require('./components/languageSelector/languageSelectorDirective');
app.directive('languageSelector', languageSelector);

const adviceBox = require('./dataComponents/adviceBox/adviceBoxDirective');
app.directive('adviceBox', adviceBox);

const customBox = require('./newScheduler/customBox/customBoxDirective.js');
app.directive('customBox', customBox);

const temperatureSelector = require('./newScheduler/customBox/modes/temperatureSelectorDirective');
app.directive('temperatureSelector', temperatureSelector);

app.config(['$routeProvider', '$locationProvider', function configureRoutes($routeProvider, $locationProvider) {
    _.each(pageConfigs.getPageConfigs(), (pageConfig) => {
        $routeProvider.when(pageConfig.url, pageConfig);
    });

    $routeProvider.otherwise({ redirectTo: pageConfigs.getUrl(pageConfigs.getDefaultPage()) });

    // use the HTML5 History API
    $locationProvider.html5Mode(true);
    $locationProvider.hashPrefix('!'); // for SEO
}]);

app.config(['$httpProvider', function ($httpProvider) {
    // Http Intercpetor to check auth failures for xhr requests
    $httpProvider.interceptors.push('httpInterceptor');
}]);

app.config(['$i18nextProvider', function ($i18nextProvider) {
    $i18nextProvider.options = lang.config;
}]);

module.exports = app;

// set size in window (must be the bootstrap media queries values)
// xs = "(min-width 0px) and (max-width 767px)"
// sm = "(min-width 768px) and (max-width 991px)"
// md = "(min-width 992px) and (max-width 1199px)"
// lg = "(min-width 1200px)"
setBootstrapMedia();

$(window).resize(setBootstrapMedia);

function setBootstrapMedia() {
    const xl = 1200;
    const lg = 992;
    const md = 768;
    const sm = 576;

    if ($(this).width() >= xl) {
        window.bootstrapMedia = "xl";
        return;
    }

    if ($(this).width() >= lg) {
        window.bootstrapMedia = "lg";
        return;
    }

    if ($(this).width() >= md) {
        window.bootstrapMedia = "md";
        return;
    }

    if ($(this).width() >= sm) {
        window.bootstrapMedia = "sm";
        return;
    }

    window.bootstrapMedia = "xs";
}
