var logger = require('logger');

module.exports = function(){
    return {
        restrict: 'E',
        templateUrl : './dataComponents/meteoEffectDetails/meteo-effect-details.html',
        scope: {
            options: "="
        },
        link: function(scope, iElement, attrs){
            logger.debug('meteoEffectDetailsDirective', 'link');
            scope.$on( 'selectedSiteHasChanged', scope.meteoEffectDetails.fetch);
            if( !scope.options.displayOnly ){
                scope.meteoEffectDetails.fetch();
            }
            else{
                scope.meteoEffectDetails.render(null, scope.options);
            }

        },
        controller: ['$scope', '$http', 'flash', function($scope, $http, flash){
            // directive attributes
            var STATUS_PENDING = {};
            var STATUS_OK = {};
            var STATUS_ERROR = {};

            $scope.Math = Math;

            $scope.meteoEffectDetails = {};
            $scope.meteoEffectDetails.status = null;
            $scope.meteoEffectDetails.data = null;
            $scope.meteoEffectDetails.fetch = fetch;
            $scope.meteoEffectDetails.render = render;
            $scope.meteoEffectDetails.getSign = getSign;

            // directive method
            function fetch(){
                $scope.meteoEffectDetails.status = STATUS_PENDING;
                $http({
                    method: 'GET',
                    url: '/meteo/getMeteoEffect.json',
                    params: {
                        startDate: $scope.options.startDate.valueOf(),
                        endDate: $scope.options.endDate.valueOf(),
                        unit: $scope.options.unit
                    }
                })
                    .then(function onSuccess(response) {
                        render( null, response.data );
                    })
                    .catch(function onError( response ) {
                        render( response.data.message );
                    });
            }

            function render( errorMessage, data ){
                if (errorMessage){
                    $scope.meteoEffectDetails.status = STATUS_ERROR;
                    flash('alert-danger', errorMessage);
                    return;
                }
                $scope.meteoEffectDetails.status = STATUS_OK;
                $scope.meteoEffectDetails.data = data;
            }

            function getSign(value, color) {
                var returnClass = '';
                if (value >= 0) //Add transparent sign when 0 in order to avoid a gap between data.evo and data.usage
                    returnClass += 'fa-plus';
                else if (value < 0)
                    returnClass += 'fa-minus';
                if (color != false) {
                    if (value > 0)
                        returnClass += ' v-fa-danger';
                    else if (value < 0)
                        returnClass += ' v-fa-success';
                    else
                        returnClass += ' v-fa-transparent'
                }
                return returnClass
            }

            $scope.meteoEffectDetails.hasPendingStatus = function () {
                return $scope.meteoEffectDetails.status === STATUS_PENDING;
            };

            $scope.meteoEffectDetails.hasErrorStatus = function () {
                return $scope.meteoEffectDetails.status === STATUS_ERROR;
            };

            $scope.meteoEffectDetails.hasOkStatus = function () {
                return $scope.meteoEffectDetails.status === STATUS_OK;
            };
        }]
    }
};