const pageConfigs = require("pageConfigs");
const ga = require('ga');
const moment = require('moment');
const logger = require('logger');
const _ = require("underscore");
const ref = require("enum");
const tools = require('tools').tools;

const whiteLabelTools = require('../tools/whiteLabelTools');
const Subscriber = require('models/Subscriber');

module.exports = [
  '$rootScope',
  '$scope',
  '$location',
  '$anchorScroll',
  '$i18next',
  '$route',
  '$timeout',
  '$window',
  '$http',
  function ($rootScope, $scope, $location, $anchorScroll, $i18next, $route, $timeout, $window, $http) {
    // WEBVIEW
    // ----------
    $scope.webView = {
        isWebView: false,
        webViewOn: function() {
            $scope.webView.isWebView = true;
            return $scope.webView.isWebView;
        },
        mobileView: function() {
            // Android Lollipop and Above detection, plus KitKat detection
            const isAndroidWebView = /(wv)/.test(navigator.userAgent) || /(^Chrome\..*Mobile$)/.test(navigator.userAgent);
            const isIOSWebView =  !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
            $scope.webView.isWebView = isAndroidWebView || isIOSWebView;
        },
    };
    $window.webViewOn = $scope.webView.webViewOn;
    $window.mobileView = $scope.webView.mobileView;
    $scope.webView.mobileView();
    $window.isWebView = $scope.webView.isWebView;

    // make const file visible even by jade files
    $rootScope.whiteLabelTools = whiteLabelTools;
    $rootScope.ref = ref;
    $rootScope.page = window.location.hostname;
    $rootScope.isVoltalis = whiteLabelTools.getDisplayGroupByHostName($rootScope.page) === ref.ACCESS.DISPLAY_GROUP.VOLTALIS;
    $rootScope.translatedCurrency = function() {
        return $scope.$i18next("currencies." +  $scope.user.getSiteCurrency().ISO, { returnObjectTrees: true });
    };
    $rootScope.getUnitFromChartType = function(chartType) {
        if (chartType.name === ref.CHART_DATA_TYPES.EURO.name) {
            return $scope.user.getSiteCurrency().symbol;
        }
        return chartType.unit;
    };
    $scope.formatNumberDecimals = tools.formatNumberDecimals;
    // Robots configuration
    const indexNoFollow = 'index,nofollow';
    const noIndexNoFollow = 'noindex,nofollow';
    $rootScope.robots = indexNoFollow;
    $scope.$on('$routeChangeStart',  (e, next) => {
        if (next.$$route.isNoIndex === true) {
            $rootScope.robots = noIndexNoFollow;
        } else {
            $rootScope.robots = indexNoFollow;
        }
    });
    // LANG
    //-----
    logger.info('Your preferred language is', $i18next.options.lng);
    $scope.$i18next = $i18next;

    /**
     * Checks if the given language is supported by Spiderman.
     * It will force to use a the short format (e.g : "en" rather than "en-US").
     * The string returned is the short format of the language given if supported, the default language otherwise.
     * @param {string} lng
     * @returns {string}
     */
    function getSupportedLanguageCode(lng) {
        if (_.isString(lng)) {
            lng = lng.substring(0, 2);
        }

        if (!$scope.$i18next.options.fallbackLng.includes(lng)) {
            // language required is not supported, use default
            lng = $scope.$i18next.options.fallbackLng[0];
        }

        return lng;
    }

    // $$$DGL : je n'ai rien compris à ces commentaires
    // TODO: ce watch ne change uniquement les locales des graphs.
    // en revanche, les boites conso 7 j, le graph en détail en particulier, ne sont pas concernner par ce watch.
    // à réintégrer quand la gestion des locales côté client est entierement prise en compte
    /**
     * Tries to switch the site language to the given parameter.
     * Will change the moment & highcharts locales & the pages names.
     * This function is called automatically whenever the i18next.options.lng attribute changes.
     * @param {String} lng
     */
    $scope.$watch('$i18next.options.lng', function switchLang(originalLng) {
        const lng = getSupportedLanguageCode(originalLng);
        logger.info('Switching language to ', lng);

        $.cookie(ref.COOKIE.LANG.NAME, lng, { expires: ref.COOKIE.LANG.VALIDITY_DURATION_IN_DAYS });

        $i18next.options.lng = lng;

        moment.locale(lng);

        Highcharts.setOptions({
            lang: JSON.parse($scope.$i18next("lang"))
        });

        // TODO pourquoi dans un $timeout ? (lié au $scope.apply supprimé ?)
        $timeout(function() {
            pageConfigs.setlang($scope.$i18next);

            // Update page title when language is switched
            updatePageTitle(getCurrentPage($route, $location));
        });
        $route.reload();
    });

    // If language is set in a cookie, take cookie language. Else it falls back to navigator language.
    // Cookie language is checked and can be modified if not supported by Spiderman
    if (typeof $i18next.options.lng === 'undefined' && typeof $.cookie(ref.COOKIE.LANG.NAME) !== 'undefined') {
        $i18next.options.lng = getSupportedLanguageCode($.cookie(ref.COOKIE.LANG.NAME));
    }

    // TEMPLATE
    //---------
    $scope.index = {
        menuShownWithSmallScreen: false,
        menuScrollableWithSmallScreen: false,
        userMenuVisible: false,
        toggleUserMenu: toggleUserMenu,
        toggleMenu: toggleMenu
    };


    // GA SCOPE
    //---------
    $scope.ga = {
        trackingEnabled: ga.isTracking(),
        shouldAskTracking: ga.shouldAsk(),
        updateTracking: updateTracking,
    };

    function updateTracking() {
        ga.setTracking($scope.ga.trackingEnabled);
    }

    $scope.$watch("ga.trackingEnabled", function (newValue, oldValue) {
        if (newValue !== oldValue && !$scope.ga.shouldAskTracking) {
            updateTracking();
        }
    });

    $scope.$watch("$routeChangeStart", function () {
        _.defer(function () {
            ga.track("send", "pageview");
        });
    });

    // VISIT SCOPE
    //-----------
    $scope.visit = {};

    $scope.visit.userHasAccess = (routeName) => {
        // LOCAL TIP FRI : early return true for all access !!
        // return true;
        const pageConfig = pageConfigs.getConfigByPageName(routeName);
        if (!pageConfig) {
            logger.warn(`Unknow page ${routeName}`);
            return false;
        }

        if (!pageConfig.hasUserAccess) {
            // all users has access to
            return true;
        }
        return pageConfig.hasUserAccess($scope.user);
    };

    $scope.visit.showCookieModal = function () {
        $scope.$broadcast('showCookieModal');
    };

    /**
     * If logged out, going back to this path
     * TODO is this the right behaviour?
     */
    const initialRequest = {
        path: $location.path(),
        search: $location.search(),
        toRedirect: true,
     };

    /**
     * Disconnect the current user
     * @param manual {boolean} true if it's a manual disconnection (if the user click on the 'Disconnect' button)
     */
    $scope.visit.disconnect = function (manual) {
        logger.info('Logging out');
        // forget that we already saw the Abscence pop-up
        $.removeCookie('alreadySawAbsencePopup');
        if (manual) {
            $.removeCookie(ref.COOKIE.AUTO_LOGIN.NAME);
        }

        $scope.user.doLogout(manual);

        if (manual) {
            $scope.visit.goTo(pageConfigs.getDefaultPage().name);
            return;
        }

        // in mobile version, we try to log again after a non-manual disconnect (for auto-login)
        $scope.user.tryInitialLogin(function (err, results) {
            if (err) {
                $scope.visit.goTo(pageConfigs.getDefaultPage().name);
                return;
            }

            $scope.visit.connect(results);
        });
    };

    $scope.visit.connect = function(results) {
        if (!results.subscriber) {
            return;
        }

        $scope.user.set(results.subscriber);

        // Pour les site en slovénie, on affiche le programme, mais on cache le contrat et le logement
        // todo: mettre dans un fichier de configuration pour ne pas avoir à refaire une version
        logger.info($scope.user.get('firstname'), ' ', $scope.user.get('lastname'), 'logging in (mail:', $scope.user.getEmail(), ', login:', $scope.user.getLogin(),')');
        const requestedPage = initialRequest.toRedirect && pageConfigs.getConfigByUrl(initialRequest.path);
        if (requestedPage && requestedPage !== pageConfigs.getDefaultPage()) {
            $scope.visit.goTo(requestedPage.name, initialRequest.search);
            initialRequest.toRedirect = false;
        } else {
            $scope.visit.goTo(pageConfigs.getUserHomePage().name);
        }

        // $$$ LSN TODO Refactor: the below code checks if absence mode is on to display a warning if it is the case
        // -> should use a general function (from the absence controller) to get the mode state & handling code should be in separate function
        $scope.user.absenceMode = {};
        $scope.user.absenceMode.isActivated = false;
        $http.get("/programmationEvent/getAbsenceModeState.json", {})
            .then((response) => {
                $scope.user.absenceMode.isActivated = response.data.absenceModeState;
                $scope.user.absenceMode.dateChoosen = response.data.endDate;
                $scope.user.absenceMode.hasEndDate = response.data.hasEndDate;
                if(response.data.absenceModeState && typeof $.cookie( 'alreadySawAbsencePopup' ) === 'undefined') {
                    $("#modalAbsence").modal({backdrop: 'static', keyboard: false});
                    $.cookie('alreadySawAbsencePopup', true, { path: '/' });
                }
            })
            .catch((response) => {
                logger.error("Absence Mode State Fetch", response.data.message);
            })
        ;

        const loginToken = results.loginToken;
        if (loginToken) {
            // If the server has returned a token to use for auto-login, create and store a cookie containing that token
            $.cookie(ref.COOKIE.AUTO_LOGIN.NAME, JSON.stringify(loginToken.value), {
                expires: moment(loginToken.expirationDate).toDate(),
                secure: true,
                path: ref.COOKIE.AUTO_LOGIN.PATH
            });
        }
    };

    // NAV SCOPE
    //----------
    $scope.nav = {};

    // USER SCOPE
    //-----------
    $scope.user = new Subscriber();
    $scope.user.tryInitialLogin(function (err, results) {
        if (err) {
            return;
        }

        $scope.visit.connect(results);
    });

    // NAVIGATION EVENTS
    //------------------
    $scope.visit.goTo = function(newRouteName, params = {}) {
        if (_.isObject(newRouteName)) {
            params = newRouteName;
            newRouteName = getCurrentPageName($route, $location);
        }

        if (!params.anchor && _.contains(newRouteName, '#')) {
            const split = newRouteName.split('#');
            newRouteName = split[0];
            params.anchor = split[1];
        }

        const pageConfig = pageConfigs.getConfigByPageName(newRouteName);
        if (!pageConfig) {
            logger.info(`${newRouteName} not found in pages`);
            return;
        }

        if ($scope.userMenuVisible) {
            $scope.userMenuVisible = !$scope.userMenuVisible;
        }

        if (!pageConfig.auth($scope.user)) {
            $location.path(pageConfig.getDefaultPageUrl());
            return;
        }

        const routeParams = _.omit(params, 'anchor');
        if (!$route.current || newRouteName !== getCurrentPageName($route, $location)) {
            $location.path(pageConfigs.getUrl(pageConfig));
            updatePageTitle(pageConfig);
        }

        $location.search(routeParams); // remove all routeParams if none given

        if (params.anchor) {
            // causes the page change
            $location.hash(params.anchor);
            $anchorScroll();
        }

        if ($scope.user.isLogged()) {
            updateFavicon();
        }
    };

    /**
    * On change of site ID, load the access permission for this site
    */
    $scope.$watch("user.attributes.selectedSiteId", function() {
        if (!$scope.user.getSelectedSite()) {
          return;
        }

        // first load
        $scope.user.getSelectedSite().allowedPages = $scope.user.getAllowedPages();
        $scope.user.getSelectedSite().allowedWidgets = $scope.user.getAllowedWidgets();

        logger.debug('navigationController', 'broadcast event', 'selectedSiteHasChanged');
        $scope.$broadcast('selectedSiteHasChanged', $scope.user.get('selectedSiteId'));
    });

    $scope.isActive = function (pageName) {
        return pageName === getCurrentPageName($route, $location);
    };

    // TODO when? after initialization, ok, but otherwise?
    $scope.$watch("user", function () {
        const currentPage = getCurrentPage($route, $location);
        if (!currentPage || !currentPage.auth($scope.user)) {
            $scope.visit.goTo(pageConfigs.getDefaultPage().name);
            return;
        }

        if (currentPage.default && $scope.user.isLogged()) {
            $scope.visit.goTo(pageConfigs.getUserHomePage());
        }
    });

    function toggleMenu() {
        $scope.index.menuShownWithSmallScreen = !$scope.index.menuShownWithSmallScreen;
    }

    function toggleUserMenu() {
        $scope.index.userMenuVisible = !$scope.index.userMenuVisible;
    }

    /**
     * Updates the site favicon.
     * The favicon displayed is specified by the display group in use.
     */
    function updateFavicon() {
        const dgList = $scope.user.getDisplayGroupListForSelectedSite();
        const len = dgList.length || 0;
        for (var i = 0; i < len; i++) {
            if ((dgList[i].isDefault || dgList[i].isOverwritingDefault) && dgList[i].displayGroupFavicon) {
                $rootScope.faviconUrl = `/getDisplayGroupPicture?displayGroupId=${dgList[i].id}&isFavicon=true&filename=${dgList[i].displayGroupFavicon.filename}`;
                return;
            }
        }
        $rootScope.faviconUrl = "";
    }

    /**
     * Updates the page title (displayed in the the tab next to the favicon).
     * The title is gotten from the page title described in the page config file, and is translated.
     * If the given page configuration is invalid, or the translation is not found, defaults to a generic title.
     * @param pageConfig the page configuration object
     */
    function updatePageTitle(pageConfig) {
        const defaultPageTitle = whiteLabelTools.getPageTitleByHostName(window.location.hostname);
        if (pageConfig.whiteLabel || !pageConfig || !pageConfig.titleName) {
            $rootScope.pageTitle = defaultPageTitle;
            return;
        }

        $rootScope.pageTitle = $i18next(pageConfig.titleName) || defaultPageTitle;
    }
}];

function getCurrentPage($route, $location) {
    return $route && $route.current && $route.current.$$route || pageConfigs.getConfigByUrl($location.path());
}

function getCurrentPageName($route, $location) {
    return getCurrentPage($route, $location).name;
}
