var logger = require('logger'),
    config = require('config');

module.exports = function(){
    return {
        restrict: 'E',
        template : '<div class="annualConsumptionChart" style="height: inherit"><loader></loader></div>',
        link: function(scope){
            logger.debug('annualConsumptionChart', 'link');
            scope.$watch("annualConsumptionChart.device", function(){
                logger.debug('annualConsumptionChartDirective', 'switch device to', scope.annualConsumptionChart.device);
                scope.annualConsumptionChart.beforeRender();
            });
            scope.$on( 'selectedSiteHasChanged', function(event, userSiteId){
                logger.debug('annualConsumptionChartDirective', 'receiving event', 'selectedSiteHasChanged');
                scope.annualConsumptionChart.fetch();
            });
            scope.annualConsumptionChart.fetch();
        },
        controller: ['$scope', '$http', '$i18next', function($scope, $http, $i18next){

            var STATUS_PENDING = {},
                STATUS_ERROR = {},
                STATUS_OK = {};

            var DEVICE_KWH = "kwh",
                DEVICE_EURO = "euro";

            // directive attributes
            $scope.annualConsumptionChart = {};
            $scope.annualConsumptionChart.charts = null;
            $scope.annualConsumptionChart.status = null;
            $scope.annualConsumptionChart.device = DEVICE_KWH;
            $scope.annualConsumptionChart.fetch = fetch;
            $scope.annualConsumptionChart.beforeRender = beforeRender;

            // directive method
            function fetch(){
                $scope.annualConsumptionChart.status = STATUS_PENDING;
                logger.debug('annualConsumptionChartDirective', 'fetch started');
                $http.get("/chart/getAnnualConsumptionCharts.json")
                    .then(function onSuccess(response) {
                        var annualConsumptionCharts = response.data;
                        logger.debug('annualConsumptionChartDirective', 'fetch done');
                        $scope.annualConsumptionChart.status = STATUS_OK;
                        $scope.annualConsumptionChart.charts = annualConsumptionCharts;
                        logger.debug('annualConsumptionChartDirective', 'broadcast event', 'annualConsumptionChartsHasChanged');
                        $scope.$broadcast('annualConsumptionChartsHasChanged', annualConsumptionCharts);
                        beforeRender();
                    })
                    .catch(function onError() {
                        logger.error('annualConsumptionChartDirective', 'fetch fail');
                        $scope.annualConsumptionChart.status = STATUS_ERROR;
                        $scope.annualConsumptionChart.charts = null;
                        _render( $i18next('annualConsumptionChart.errorLoadingChart') );
                    });

            }

            // decide which graph should be displayed
            function beforeRender(){
                if( !$scope.annualConsumptionChart.charts ) return;
                if( $scope.annualConsumptionChart.device === DEVICE_KWH || !$scope.annualConsumptionChart.charts.euro ){
                    $scope.annualConsumptionChart.device = DEVICE_KWH;
                    _render(null, $scope.annualConsumptionChart.charts.kwh);
                } else if( $scope.annualConsumptionChart.device === DEVICE_EURO ) {
                    _render(null, $scope.annualConsumptionChart.charts.euro);
                }
            }

            $scope.annualConsumptionChart.hasPendingStatus = function () {
                return $scope.annualConsumptionChart.status === STATUS_PENDING;
            };

            $scope.annualConsumptionChart.hasOkStatus = function () {
                return $scope.annualConsumptionChart.status === STATUS_OK;
            };
        }]
    }
};

function _render( errorMessage, chart ) {
    var $annualConsumptionChart = angular.element('.annualConsumptionChart');
    if (errorMessage){
        $annualConsumptionChart.highcharts( config.emptyChart );
        $annualConsumptionChart.highcharts().showLoading( errorMessage );
    }
    else{
        $annualConsumptionChart.highcharts( chart );
    }
}