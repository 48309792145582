module.exports = {
    // REF_TYPE_ELECTRICAL_APPLIANCE
    APPLIANCE: {
        COMPTEUR: 1,
        CLIMATISATION: 2,
        CHAUFFE_EAU: 4,
        RADIATEUR: 5,
        EMTI: 7,
        PLANCHER_CHAUFFANT: 8,
        COMPTEUR_EAU: 9,
        POMPE_A_CHALEUR: 10,
        POMPE_A_CHALEUR_REVERSIBLE: 11,
        CLIMATISATION_REVERSIBLE: 13,
        ACCUMULATEUR: 14,
        SONDE_DE_TEMPERATURE_RADIO: 15,
        MIXTE: 16,
        GROUPE_FROID: 17,
        GROUPE_CHAUD_FROID: 18,
        ROOFTOP: 19,
        INCONNU_ELECTRIQUE: 20,
        MOTEUR_POMPE: 273,
        CIRCUTOR: 274,
        PLAFOND_RAYONNANT: 529,
        CHAUDIERE: 785,
        VENTILO_CONVECTEUR: 1041,
        CTA: 1042,
        AEROTHERME: 1043,
        GTC: 1044,
        RADIATEUR_NON_MODULABLE: 1045
    }
};