const config = require('config').logger;

// Function exported from console to logger
const consoleFnExportList = [
    'log',
    'debug',
    'error',
    'info',
    'warn',
    'dir',
    'dirxml',
    'table',
];

const defaults = {
    displayDate: true,
    unmute: consoleFnExportList,
};

const unmute = config && config.unmute || defaults.unmute;
const displayDate = config && config.displayDate || defaults.displayDate;

function getLogger(name) {
    if (unmute.indexOf(name) === -1 || !window.console || !console[name]) {
        return function () {};
    }

    if (!displayDate) {
        return console[name];
    }

    return function () {
        const args = Array.prototype.slice.call(arguments);
        args.unshift(new Date().toLocaleString());
        console[name].apply(console, args);
    };
}

module.exports = consoleFnExportList.reduce(function (exported, fnName) {
    exported[fnName] = getLogger(fnName);
    return exported;
}, {});
