module.exports={
  "chart": {
    "type": "lineargauge",
    "margin": [30, 15, 35, 15],
    "plotBorderWidth": 1,
    "plotBorderColor": "black",
    "inverted": true,
    "height": 100,
    "plotBackgroundColor": {
      "linearGradient": {"x1": 0,"x2": 1,"y1": 0,"y2": 0},
      "stops": [
        [0, "#DFF2FF"],
        [0.40, "#1E7FCB"],
        [0.75, "#9bcd1c"],
        [1, "#FFFF99"]
      ]
    }
  },
  "credits":{ "enabled":false},
  "exporting":{"enabled":false},
  "legend":{"enabled":false},
  "title":{
    "style": {
      "color":"#666",
      "fontWeight" :"bold",
      "fontSize":"11px"
    },
    "y":76,
    "align":"right"
  },
  "tooltip":{
    "enabled":false
  },
  "xAxis": {
    "labels": {
      "enabled": false
    },
    "tickLength": 0
  },
  "yAxis": {
    "min": 0,
    "tickColor": "black",
    "tickInterval": 1,
    "tickPosition":"inside",
    "tickLength":15,
    "tickWidth":1,
    "gridLineWidth":0,
    "minorTicks" :true,
    "minorGridLineWidth":0,
    "minorTickInterval": 0.1,
    "minorTickWidth":1,
    "minorTickPosition":"inside",
    "minorTickColor":"black",
    "minorGridLineDashStyle":"Solid",
    "minorTickLength":7,
    "title":null,
    "labels":{
      "enabled":false
    }
  },
  "series": [{
    "data": [0],
    "color": "#000000",
    "dataLabels": {
      "y": 38,
      "enabled": true,
      "inside": true,
      "align": "right",
      "style": {
        "position": "relative",
        "textOutline": "0px",
        "fontSize": "bold",
        "color": "black"
      }
    }
  }]
}