require('carousel');
require('transition');

const _ = require('underscore');
const logger = require('logger');
const $appScope = require('appScope');

module.exports = ['$scope', 'flash', '$window', function ($scope, flash, $window) {

    const user = $appScope.get("user");
    const visit = $appScope.get('visit');

    // Scope login
    // -----------
    $scope.login = {};
    // Login form init state
    $scope.login.showmodal = false;
    $scope.login.showCookieModal = false;
    $scope.$on( 'showCookieModal', function(){
        $scope.login.showCookieModal = true;
    });
    $scope.login.showPasswordForgottenForm = false;
    // If an ajax request for logging is in progress
    var _logging = false;
    $scope.login.loginProcess = function () {
        return _logging;
    };
    // When user press form login submit
    $scope.login.submit = loginFromForm;

    initialize();

    function initialize(){
        // Reflow carousel for viewport
        reflowInterface();
    }

    function loginFromForm() {
        if (_logging)
            return;
        _logging = true;

        user.set({
            username: $scope.login.username,
            password: $scope.login.password,
            stayLoggedIn: true,
        });

        user.doLogin(function (err, results) {
            _logging = false;
            if (err) {
                flash('alert-danger', err.responseJSON.message, 5000);
            } else {
                resetScope();
                visit.connect( results );
            }
        });
    }

    function resetScope(){
        $scope.login.username = '';
        $scope.login.password = '';
        $scope.login.showmodal = false;
        $scope.login.showCookieModal = false;
        $scope.login.stayLoggedIn = true;
    }

}];


$(window).resize(reflowInterface);

function reflowInterface() {
    var headerHeight = angular.element(".headerVoltalis").outerHeight(true);
    var windowHeight = $(window).height();

    var $carouselHeader = angular.element("#carouselHeader");
    var $carouselFooter = angular.element("#carouselFooter");
    var $carouselItems = angular.element("#carouselContent").find(".item");
    var carousselHeight = windowHeight - headerHeight - $carouselHeader.outerHeight(true) - $carouselFooter.outerHeight(true);
    if (carousselHeight > 600)
        carousselHeight = 600;
    $carouselItems.css("height", carousselHeight > 250 ? carousselHeight : 250);
}
