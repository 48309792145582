var $appScope = require('appScope');

module.exports = ['$q', '$i18next', '$window', function($q, $i18next, $window) {

    return {
        request: function( request ){
            var subscriber = $appScope.get('user');
            if( subscriber && subscriber.isLogged() ){
                request.headers['User-Site-Id'] = subscriber.getSelectedSite().id;
            }

            request.headers['Accept-Language'] = $i18next.options.lng;

            return request || $q.when(request);
        },
        response: function(response) {
            return response || $q.when(response);
        },
        responseError: function(rejection) {
            var subscriber = $appScope.get('user');
            if ( rejection.status === 401 && subscriber && subscriber.isLogged() ) {
                //don't display expired session message on mobile application
                //otherwise this message will display multiple times during the auto-login
                //and give unexpected navigation behaviour

                var visit = $appScope.get('visit');
                visit.disconnect(false);
            }
            return $q.reject(rejection);
        }
    }
}];
