var _ = require('underscore'),
    moment = require('moment');

var $appScope = require('appScope'),
    ref = require('enum'),
    config = require('config'),
    logger = require('logger');

var dateFr = config.dateFormat.dateFr;

var STATUS_PENDING = {},
    STATUS_ERROR = {},
    STATUS_OK = {};


var REMAINING_RESIZE_EVENT = 2;

var n = 0;

module.exports = function () {
    return {
        restrict: 'E',
        template: '<div class="monthlyConsumptionDetailPieChart" style="height: inherit;"><loader></loader></div>',
        scope: {
            options: "=",
            isCompared: "=",
            isPeak: "=" // define if this pie is the compared pie or not
        },
        link: function(scope){
            logger.debug('monthlyConsumptionDetailPieChart', 'link');

            scope.$on( 'selectedSiteHasChanged', function(event, userSiteId){
                logger.debug('monthlyConsumptionDetailPieChartDirective', 'receiving event', 'selectedSiteHasChanged');
                if( scope.monthlyConsumptionDetailPieChart.status !== STATUS_PENDING )
                    scope.monthlyConsumptionDetailPieChart.fetch();
            });

            scope.monthlyConsumptionDetailPieChart.fetch();
        },
        controller: ['$scope', '$element', '$http', '$timeout', '$i18next',
            function ($scope, $element, $http, $timeout, $i18next) {

            $scope.monthlyConsumptionDetailPieChart = {};
            $scope.monthlyConsumptionDetailPieChart.status = null;
            $scope.monthlyConsumptionDetailPieChart.fetch = fetch;
            $scope.monthlyConsumptionDetailPieChart.getMonthlyReportPdfChart = getMonthlyReportPdfChart;
            $scope.monthlyConsumptionDetailPieChart.render = render;
            $scope.monthlyConsumptionDetailPieChart.chartDataType = ref.CHART_DATA_TYPES.EURO;
            $scope.monthlyConsumptionDetailPieChart.data = null; // fetched data
            
            function fetch(){
                $scope.monthlyConsumptionDetailPieChart.status = STATUS_PENDING;
                logger.debug('monthlyConsumptionDetailPieChartDirective', 'fetch started');
                
                $http({
                    method: 'GET',
                    url: '/chart/getDetailConsumptionPieCharts.json',
                    params: {
                        startDate: $scope.options.startDate.format(dateFr),
                        endDate: $scope.options.endDate.format(dateFr),
                        hasDataLabels: true,
                        isPdfMonthlyChart: true
                    }
                })
                    .then(function onSuccess( response ) {
                        logger.debug('monthlyConsumptionDetailPieChartDirective', 'fetch done');
                        $scope.monthlyConsumptionDetailPieChart.status = STATUS_OK;
                        $scope.monthlyConsumptionDetailPieChart.data = response.data.data;
                        emitSum($scope.monthlyConsumptionDetailPieChart.data);
                        var chart = getMonthlyReportPdfChart();
                        emitPieChart( chart );
                        //Sometimes chart.series[0].data exist but it's empty
                        if( chart && chart.series[0].data && chart.series[0].data.length > 0){
                            render( null, chart );
                        }
                        else{
                            render( $i18next("detailedConsumptionChart.errorNoConsumption") );
                        }
                    })
                    .catch(function onError() {
                        logger.error('detailedConsumptionPieChartDirective', 'fetch fail');
                        $scope.monthlyConsumptionDetailPieChart.status = STATUS_ERROR;
                        render( $i18next('detailedConsumptionChart.errorLoadingChart') );
                    });

            }


            /**
             * return from fetched data chart coinciding with options
             * @returns {*}
             */
            function getMonthlyReportPdfChart(){
                try{
                    var consumptionChart = $scope.monthlyConsumptionDetailPieChart.data['DETAIL'];

                    var $chart = $element.find('.monthlyConsumptionDetailPieChart'),
                    chart = consumptionChart[$scope.monthlyConsumptionDetailPieChart.chartDataType.name];

                     chart.plotOptions.pie.dataLabels.formatter = function(){
                        var maxValue = 4;

                        if(this.percentage > maxValue){
                            return '<span>' + this.percentage.toFixed(0) + '%</span>';
                        }
                        return null;
                    }

                    return chart;
                }
                catch(e){
                    logger.warn('monthlyConsumptionDetailPieChartDirective', 'no data for selected site');
                    return null;
                }
            }

            function render( errorMessage, chart ) {
                var $chart = $element.find('.monthlyConsumptionDetailPieChart');
                if (errorMessage){
                    $chart.highcharts( config.emptyChart );
                    $chart.highcharts().showLoading(errorMessage);
                }
                else{
                    $chart.highcharts( chart );
                }
            }

            function emitPieChart( chart ){
                $scope.$emit('onMonthlyDetailedPieChartChange', chart); 
            }

            

            function emitSum( dataCharts ){
                var data,
                sum = { kwh: 0, euro: 0 };
                
                try{
                    data = {
                        kwh: dataCharts['DETAIL']['kwh'].series[0].data,
                        euro: dataCharts['DETAIL']['euro'].series[0].data
                    };
                    _.each(data.kwh, function(data){
                        sum.kwh += data.y;
                    });
                    _.each(data.euro, function(data){
                        sum.euro += data.y;
                    });
                }
                catch(e){
                    render( $i18next("detailedConsumptionChart.errorNoConsumption") );
                }

                $scope.$emit('valueBoxesHasChanged', {
                    kwh: sum.kwh,
                    euro: sum.euro
                });
            }
        }]
    }
};