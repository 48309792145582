const Email = require('models/Email'),
    $appScope = require('appScope');

module.exports = ['$scope', 'flash', 'emailManager', function($scope, flash, emailManager) {

    let STATUS_NONE = {},
        STATUS_ERROR = {},
        STATUS_DONE = {},
        STATUS_PENDING = {};

    let user = $appScope.get('user');

    $scope.contact = {};
    $scope.contact.message = null;
    $scope.contact.submit = onSubmit;
    $scope.contact.hasPendingStatus = hasPendingStatus;
    resetContactForm();

    function onSubmit(){
        $scope.contact.status = STATUS_PENDING;

        let subject = 'MyVoltalis - ';
        switch ($scope.contact.type) {
            case 'cantHeatingSchedule':
                subject += 'Je n’arrive pas à utiliser la programmation du chauffage';
                break;
            case 'cantSeeElectricityConsump':
                subject += 'Je ne vois pas mes consommations électriques';
                break;
            case 'problemHeatingSys':
                subject += 'J’ai un problème avec mon système de chauffage';
                break;
            case 'other':
                subject += 'Autre demande';
                break;
        }
        subject += ' - ' + user.get('firstname') + ' ' + user.get('lastname') + ' (' + user.get('selectedSiteId') + ')';

        const from = {
            name: user.get('firstname') + ' ' + user.get('lastname'),
            email: user.getEmail().trim(),
        };

        let email;
        try {
            email = new Email(from.name, from.email, subject, $scope.contact.message);
        } catch (err) {
            $scope.contact.status = STATUS_ERROR;
            flash('alert-danger', err.message, 5000);
            return;
        }
        emailManager.sendEmail(email)
            .then((results) => {
                $scope.contact.status = STATUS_DONE;
                flash('alert-success', results.message, 5000);
                resetContactForm();
            })
            .catch((error) => {
                $scope.contact.status = STATUS_ERROR;
                flash('alert-danger', error.message, 5000);
            });
    }

    function hasPendingStatus(){
        return $scope.contact.status === STATUS_PENDING;
    }

    function resetContactForm(){
        $scope.contact.status = STATUS_NONE;
        $scope.contact.type = null;
        //reset the message with blank to have a validated message (according to required)
        $scope.contact.message = ' ';
    }
}];
