module.exports={
  "chart": {
    "type": "lineargauge",
    "margin": [15, 15, 15, 15],
    "backgroundColor": null,
    "plotBorderWidth": 1,
    "plotBorderColor": "black",
    "inverted": true,
    "height": 50,
    "plotBackgroundColor": {
      "linearGradient": {"x1": 0,"x2": 1,"y1": 0,"y2": 0},
      "stops": [
        [0, "#9BCD1C"],
        [0.40, "#FEF86C"],
        [0.75, "#FAEA73"],
        [1, "#FECD1C"]
      ]
    }
  },
  "credits":{ "enabled":false},
  "exporting":{"enabled":false},
  "legend":{"enabled":false},
  "title":{
    "style": {
      "color":"#666",
      "fontWeight" :"bold",
      "fontSize":"11px"
    },
    "y":76,
    "align":"right"
  },
  "tooltip":{
    "enabled":false
  },
  "xAxis": {
    "labels": {
      "enabled": false
    },
    "tickLength": 0
  },
  "yAxis": {
    "min": 0,
    "tickColor": "black",
    "tickInterval": 1,
    "tickPosition":"inside",
    "tickLength":8,
    "tickWidth":1,
    "gridLineWidth":0,
    "minorTicks" :true,
    "minorGridLineWidth":0,
    "minorTickInterval":0.1,
    "minorTickWidth":1,
    "minorTickPosition":"inside",
    "minorTickColor":"black",
    "minorGridLineDashStyle":"Solid",
    "minorTickLength":5,
    "title":null,
    "labels":{
      "enabled":false
    }
  },
  "series": [{
    "data": [0],
    "color": "#000000",
    "dataLabels": {
      "y": -20,
      "enabled": true,
      "style": {
        "position": "relative",
        "textOutline": "0px",
        "fontSize": "bold",
        "color": "black"
      }
    }
  }]
}