var logger = require('logger'),
    $appScope = require('appScope'),
    ref = require('enum'),
    config = require('config');

module.exports = function(){
    return {
        restrict: 'E',
        templateUrl : './dataComponents/weekConsumptionHeatmap/week-consumption-heatmap.html',
        link: function(scope, iElement, attrs){

            scope.$on( 'selectedSiteHasChanged', function(event, userSiteId){
                scope.weekConsumptionHeatmap.fetch();
            });

            scope.weekConsumptionHeatmap.fetch();
        },
        controller: ['$scope', '$http', '$i18next',
            function($scope, $http, $i18next){

            var STATUS_PENDING = {},
                STATUS_ERROR = {},
                STATUS_OK = {};

            // directive attributes
            $scope.weekConsumptionHeatmap = {};
            $scope.weekConsumptionHeatmap.status = null;
            $scope.weekConsumptionHeatmap.fetch = fetch;
            $scope.weekConsumptionHeatmap.render = render;
            $scope.weekConsumptionHeatmap.hphc = false;

            function fetch(){
                if( $appScope.get('user').hasContractForSelectedSite() ){
                    $scope.weekConsumptionHeatmap.status = STATUS_PENDING;
                    $scope.weekConsumptionHeatmap.hphc = $appScope.get('user').getSelectedSite().contract.optionId == ref.CONTRACT_OPTION.HPHC;

                    var params = {
                        isWebView: $scope.webView.isWebView
                    };
                    $http.get('/chart/getWeekConsumptionHeatmap.json', {"params":params})
                        .then(function onSuccess( response ) {
                            $scope.weekConsumptionHeatmap.status = STATUS_OK;
                            render( null, addFormaters( response.data.heatmap, !$scope.weekConsumptionHeatmap.hphc, $i18next ) );
                        })
                        .catch(function onError() {
                            $scope.weekConsumptionHeatmap.status = STATUS_ERROR;
                            render( $i18next('annualConsumptionChart.errorLoadingChart') );
                        });
                }
            }

            function render( errorMessage, heatmap ){
                var $chart = angular.element('.weekConsumptionHeatmap');
                if (errorMessage) {
                    $chart.highcharts( config.emptyChart );
                    $chart.highcharts().showLoading(errorMessage);
                }
                else {
                    $chart.highcharts(heatmap);
                }
            }


            // directive methods
            $scope.weekConsumptionHeatmap.hasPendingStatus = function () {
                return $scope.weekConsumptionHeatmap.status === STATUS_PENDING;
            };

            $scope.weekConsumptionHeatmap.hasErrorStatus = function () {
                return $scope.weekConsumptionHeatmap.status === STATUS_ERROR;
            };

            $scope.weekConsumptionHeatmap.hasOkStatus = function () {
                return $scope.weekConsumptionHeatmap.status === STATUS_OK;
            };
        }]
    }
};

function addFormaters( heatmap, isBaseContract, $i18next ){
    heatmap.xAxis.labels.formatter = function () {   // N'affiche que les heures entières
        var message = this.value;
        if (-1 != message.indexOf('h30')) {
            message = '';
        }
        return message;
    };
    heatmap.tooltip.formatter = function(){
        var value = this.point.value,
            hphcstr;

        if (value < 0) {
            hphcstr = '(' + $i18next("weekConsumptionHeatmap.offPeak") +')';
            //hphcstr = "(Heure Creuse)"
            value = -value;
        }
        else if( value > 0 ){
            hphcstr = '(' + $i18next("weekConsumptionHeatmap.peak") +')';
            //hphcstr = "(Heure Pleine)"
        }


        var message = this.series.yAxis.categories[this.point.y] + ' ';
        if (this.point.x == 47) {
            message += this.series.xAxis.categories[this.point.x] + ' - 0h';
        }
        else {
            message += this.series.xAxis.categories[this.point.x] + ' - ' + this.series.xAxis.categories[this.point.x + 1];
        }
        if( !isBaseContract && hphcstr ){
            message += ' ' + hphcstr + ' : ';
        }
        else{
            message += '  : ';
        }


        if (null != value) {
            message += Math.round( value * 100 ) / 100 + ' kW';
        }
        else {
            message += $i18next("weekConsumptionHeatmap.notAvailable");
        }

        return message
    };
    return heatmap;
}