module.exports = function($rootScope, $timeout){
    var messages = [];

    var reset;
    var cleanup = function() {
        $timeout.cancel(reset);
        reset = $timeout(function() { messages = []; });
    };

    var emit = function( messages, timeout ) {
        $rootScope.$emit('flash:message', messages, timeout, cleanup);
    };

    $rootScope.$on('$locationChangeSuccess', emit);

    var asMessage = function(level, text) {
        if (!text) {
            text = level;
            level = 'alert-success';
        }
        return { level: level, text: text };
    };

    var asArrayOfMessages = function(level, text) {
        if (level instanceof Array) return level.map(function(message) {
            return message.text ? message : asMessage(message);
        });
        return text ? [{ level: level, text: text }] : [asMessage(level)];
    };

    var flash = function(level, text, timeout) {
        messages = asArrayOfMessages(level, text);
        emit( messages, timeout );
    };

    ['alert-danger', 'alert-warning', 'alert-info', 'alert-success'].forEach(function (level) {
        flash[level] = function (text) { flash(level, text); };
    });

    return flash;
};