const _ = require('underscore');
const ref = require('enum');
const $appScope = require('appScope');

const pageConfigs = require('pageConfigs');

module.exports = function(){
    return {
        restrict: 'E',
        templateUrl : './dataComponents/profileProgress/profile-progress.html',
        controller: ['$scope', function($scope){
            const subscriber = $appScope.get('user');

            $scope.getProgressBarCompletedWidth = function (data){
                const statusList = _.pluck(data, 'done');
                const doneList = _.without(statusList, false);

                const oneStep = 1 / statusList.length;
                return Math.min(0.5 * oneStep + doneList.length / statusList.length, 1) * 100 + "%";
            };

            function progressBarItem(pageName, done) {
                return {
                    title: pageConfigs.getConfigByPageName(pageName).smallName || pageConfigs.getConfigByPageName(pageName).titleName,
                    click: visitPage,
                    args: pageName,
                    done: done,
                    class: {
                        done: done,
                        notdone: !done
                    },
                    icon: done ? 'fa-check' : '',
                    score: 100
                };
            }

            $scope.getProgress = function (){
                // NOTE : do not modify scores
                // they are used to valuate jalons, score isn't used at this moment
                const progress = [];
                // could be disable for demo
                if (subscriber.hasAccessPermission(ref.ACCESS.PAGE.MY_PERSONAL_INFORMATION)) {
                    progress.push(progressBarItem('myData', true));
                }

                // Completed by voltalis, by default task is done
                progress.push(progressBarItem('myInstallation', true));
                if ( subscriber.hasAccessPermission(ref.ACCESS.PAGE.MY_CONTRACT)){
                    progress.push(progressBarItem('myContract', subscriber.hasContractForSelectedSite()));
                }

                if ( subscriber.hasAccessPermission(ref.ACCESS.PAGE.MY_HOUSE )){
                    progress.push(progressBarItem('myHouse', subscriber.hasMyHouseForSelectedSite()));
                }

                const sortedProgress = _.sortBy(progress, function (jalon) {
                    return !jalon.done
                });

                sortedProgress.forEach(function(progressItem, i) {
                    if (progressItem.done) {
                        return;
                    }
                    progressItem.value = i + 1;
                });

                return sortedProgress;
            };

            function visitPage (jalon) {
                const visit = $appScope.get('visit');
                visit.goTo(jalon.args);
            }
        }]
    }
};
