/**
 * @description Our rootScope is navigationController, use for fast access
 * @type {{get: getAppScope}}
 */
module.exports = {
    get: get,
    set: set,
    $apply: $apply
};

function get( obj ){
    var $appScope = getAppScope();
    if( typeof $appScope !== 'undefined')
        return $appScope[obj];
    else
        return null;
}

function set( key, value ){
    var $appScope = getAppScope();
    $appScope[key] = value;
}

var SCOPE;
function getAppScope(){
    if( !SCOPE )
        SCOPE = angular.element($('[ng-controller]').eq(0)).scope();
    return SCOPE;
}

function $apply(){
    getAppScope().$apply();
}