// TODO: faire le menage entre le debounce, le render et le reflow, c'est incompréhensible
var _ = require('underscore'),
    moment = require('moment');

var $appScope = require('appScope'),
    config = require('config'),
    logger = require('logger');


var ref = require('enum');

var dateFr = config.dateFormat.dateFr;

var STATUS_PENDING = {},
    STATUS_ERROR = {},
    STATUS_OK = {};

module.exports = function () {
    return {
        restrict: 'E',
        template: '<div class="detailedConsumptionPieChart" style="height: inherit;"><loader></loader></div>',
        scope: {
            options: "=",
            isCompared: "=",
            isPeak: "=", // define if this pie is the compared pie or not
            isWebView: "="
        },
        link: function(scope){
            logger.debug('detailedConsumptionPieChart', 'link');

            scope.$on('visibilitySeriesHasChanged', function(event, updatedPoint, dataType){
                logger.debug('detailedConsumptionPieChartDirective', 'receiving event', 'visibilitySeriesHasChanged');
                const remainingVisiblePoints = scope.detailedConsumptionPieChart.changeDataVisibleState(updatedPoint);
                scope.detailedConsumptionPieChart.emitSum(remainingVisiblePoints, dataType);
            });

            scope.$watch('[options.chartDataType, options.mode]', function(){
                logger.debug('detailedConsumptionPieChartDirective', 'receiving event', 'options.chartDataType');
                // upper div size changed at the same time. delay let UI redering before rendering chart
                _.delay(function() {
                    var chart = scope.detailedConsumptionPieChart.getChartFromCurrentOptions();
                    if( chart)
                        scope.detailedConsumptionPieChart.render(null, chart);
                }.bind(scope), 0)
            });

            scope.$watch('[options.period.startDate, options.period.endDate, options.compareDate, options.isCompared]', function(){
                logger.debug('detailedConsumptionPieChartDirective', 'options', 'changed');
                scope.$emit("detailedConsumptionUpdateRequested");
            });

            scope.$on("detailedConsumptionPieChartFetch", function(){
                scope.detailedConsumptionPieChart.fetch()
            });
        },
        controller: ['$rootScope', '$scope', '$element', '$http', '$timeout', '$i18next',
            function ($rootScope, $scope, $element, $http, $timeout, $i18next) {
            $scope.detailedConsumptionPieChart = {};
            $scope.detailedConsumptionPieChart.status = null;
            $scope.detailedConsumptionPieChart.fetch = fetch;
            $scope.detailedConsumptionPieChart.getChartFromCurrentOptions = getChartFromCurrentOptions;
            $scope.detailedConsumptionPieChart.render = render;
            $scope.detailedConsumptionPieChart.changeDataVisibleState = changeDataVisibleState;
            $scope.detailedConsumptionPieChart.emitSum = emitSum;
            $scope.detailedConsumptionPieChart.data = null; // fetched data

            function fetch(){
                logger.debug('detailedConsumptionPieChartDirective', 'fetch started');
                $scope.$emit('onLoadingPieChartDataChange', true);
                $scope.detailedConsumptionPieChart.status = STATUS_PENDING;
                render( $i18next("detailedConsumptionChart.loadingChart") );

                $http({
                    method: 'GET',
                    url: '/chart/getDetailConsumptionPieCharts.json',
                    params: {
                        startDate: $scope.options.period.startDate.format(dateFr),
                        endDate: $scope.options.period.endDate.format(dateFr),
                        compStartDate: $scope.options.compareDate.format(dateFr),
                        useComparedMode: $scope.isCompared,
                        isComparedMode : $scope.options.isCompared,
                        isPeak : $scope.isPeak,
                        hasDataLabels : true,
                        isWebView: $scope.isWebView
                    }
                })
                    .then(function onSuccess( response ) {
                        logger.debug('detailedConsumptionPieChartDirective', 'fetch done');
                        $scope.detailedConsumptionPieChart.status = STATUS_OK;
                        $scope.detailedConsumptionPieChart.data = response.data;
                        displayCompareDateEnd( response.data.compareDateEnd );
                        var chart = getChartFromCurrentOptions();

                        if( chart )
                            render( null, chart );
                        else
                            render( $i18next("detailedConsumptionChart.errorNoConsumption") );

                        $scope.$emit('onLoadingPieChartDataChange', false)
                    })
                    .catch(function onError() {
                        logger.error('detailedConsumptionChartDirective', 'fetch fail');
                        $scope.detailedConsumptionPieChart.status = STATUS_ERROR;
                        render( $i18next('detailedConsumptionChart.errorLoadingChart') );
                        $scope.$emit('onLoadingPieChartDataChange', false)
                    });
            }

            /**
             * return from fetched data chart coinciding with options
             * @returns {*}
             */
            function getChartFromCurrentOptions(){
                try{
                    // si on est sur un mode watt, on affiche le graph kwh
                    var mode = $scope.options.chartDataType;
                    if( mode.name === ref.CHART_DATA_TYPES.WATT.name ){
                        mode = ref.CHART_DATA_TYPES.KWH;
                    }

                    let unit = $rootScope.getUnitFromChartType(mode);
                    const consumptionCharts = $scope.detailedConsumptionPieChart.data[$scope.options.mode];

                    const chart = consumptionCharts[mode.name];

                    chart.plotOptions.pie.dataLabels.formatter = function(){
                        const maxValue = 4;

                        if(this.percentage > maxValue && !this.point.hideData){
                            return '<span>' + this.percentage.toFixed(0) + '%</span>';
                        }
                        return null;
                    };
                    chart.tooltip = {
                        enabled: !$scope.isWebView,
                            formatter: function() {
                            if ( this.point.hideData != null && this.point.hideData == true)
                                return false;
                            return this.point.name + ' : <b> ' + this.point.y.toFixed(2) + ' ' + unit +
                                ' </b> (' + this.point.percentage.toFixed(0) + '%)<br/>'
                        }
                    };
                    return chart;
                }
                catch(e){
                    logger.warn('detailedConsumptionPieChartDirective', 'no data for selected site');
                    return null;
                }
            }


            /**
             * displayEndDate affiche la date de fin en réponse du serveur sur une periode comparé
             * @param compareDateEnd
             */
            function displayCompareDateEnd( compareDateEnd ){
                if( $scope.isCompared && compareDateEnd  ){
                    angular.element('.endDateCompare').html(" au " + compareDateEnd );
                }
            }

            function render( errorMessage, chart ) {
                var $chart = $element.find('.detailedConsumptionPieChart');
                if ( $chart == null )
                    return;
                if (errorMessage){
                    $chart.highcharts( config.emptyChart );
                    $chart.highcharts().showLoading(errorMessage);
                }
                else{
                    showAllDatas(chart);
                    $chart.highcharts( chart );
                }
                emitSum();
            }

            function showAllDatas(chart){
                chart.series.forEach(serie => {
                    serie.data.forEach( data => {
                        data.visibile = true;
                    })
                })
            }

            function getSum(dataCharts, visiblePoints, serieName) {
                if (dataCharts == null )
                    return;

                let values = dataCharts.series.filter( s => s.name == serieName )
                if ( _.isEmpty(values ) )
                    return;

                values = values[0].data;

                if ( visiblePoints ) {
                    values = values.filter( v => visiblePoints.includes(v.id));
                }
                let sum=0;
                _.each(values, function (data) {
                    sum += data.y;
                });
                return sum;
            }

            function changeDataVisibleState(idLegend) {
                const $chart = $element.find('.detailedConsumptionPieChart');
                const chart = $chart.highcharts();
                const series = chart.series;
                const visiblePoints = []
                series.forEach(serie => {
                    serie.points.forEach(point => {
                        if ( point.id == idLegend ) {
                            point.setVisible(!point.visible);
                        }
                        if (point.visible) {
                            visiblePoints.push(point.id);
                        }
                    })
                });
                updateHiddenSerie(series)
                return visiblePoints;
            }

            function getHiddenData(series, serieName) {
                try {
                    const consumptionSerie = series.find(s => {return s.name === serieName});
                    return consumptionSerie.data.find(data => {return !!data.hideData});
                } catch (e) {
                    return null;
                }
            }

            /**
             * Pie chart contains a non visible part.it allows to make charts with the
             * prod or consumption parts that does not go to 100%
             * @param series
             */
            function updateHiddenSerie(series) {
                const sums = getVisibleSeriesSums();
                if ( sums.production != null && sums.consumption != null) {
                    const consumptionHiddenData = getHiddenData(series, 'consumption');
                    const productionHiddenData = getHiddenData(series, 'production');

                    if ( sums.production > sums.consumption ) {
                        productionHiddenData.update({ y: 0}, false);
                        consumptionHiddenData.update({y: Math.max(0, sums.production - sums.consumption)});
                    }
                    else {
                        productionHiddenData.update({y: Math.max(0, sums.consumption - sums.production)}, false);
                        consumptionHiddenData.update({y: 0});
                    }
                }
            }

            function getVisibleSeriesSums() {
                let sums = {};
                const chart = $element.find('.detailedConsumptionPieChart');
                const chartSeries = chart.highcharts().series;
                chartSeries.forEach(serie => {
                    let sum = 0;
                    serie.data.forEach(data => {
                        sum += data.visible && data.applianceTypeId != null ? data.y : 0;
                    });
                    sums[serie.name] = sum
                });
                return sums;
            }

            function getVisibleIds() {
                const $chart = $element.find('.detailedConsumptionPieChart');
                try {
                    const chart = $chart.highcharts();
                    const chartSeries = chart.series;
                    if ( _.isEmpty(chartSeries)) {
                        return null;
                    }

                    const visiblePoints = [];
                    chartSeries.forEach(serie => {
                        serie.points.forEach(point => {
                            if (point.visible) {
                                visiblePoints.push(point.id);
                            }
                        })
                    });
                    return visiblePoints;
                } catch (e) {
                    return null;
                }
            }


            function emitSum(){
                const dataCharts = $scope.detailedConsumptionPieChart.data;
                if ( dataCharts == null)
                    return;
                const visiblePoints = getVisibleIds();
                const selectedChartMode = dataCharts[$scope.options.mode],
                    kwhChart = selectedChartMode[ref.CHART_DATA_TYPES.KWH.name],
                    euroChart = selectedChartMode[ref.CHART_DATA_TYPES.EURO.name];

                try {
                    const data = {
                        kwhSum: getSum(kwhChart, visiblePoints, "consumption"),
                        prodSum: getSum(kwhChart, visiblePoints, "production"),
                        euroSum: getSum(euroChart, visiblePoints, "consumption")
                    };
                    $scope.$emit('valueBoxesHasChanged', {
                        kwh: data.kwhSum,
                        prod: data.prodSum,
                        euro: data.euroSum,
                        pic: dataCharts.maxPower,
                        isCompared: $scope.isCompared
                    });
                }
                catch(e){
                    logger.error("detailedConsumptionPieChartDirective.emitSum", "fail");
                }
            }
        }]
    }
};