var $appScope = require('appScope');
var _ = require('underscore');

module.exports = ['$scope', '$http', 'subscriber', 'flash', '$i18next', function ($scope, $http, subscriber, flash, $i18next) {

    $scope.myInstallation = {};
    $scope.myInstallation.subscriber = subscriber;
    $scope.myInstallation.links = getModulatorLinks(subscriber.getSelectedSite().modulatorList);
    $scope.myInstallation.globalLinks = getOtherConsumptions();
    $scope.myInstallation.save = save;
    $scope.myInstallation.cancel = initLinks;
    $scope.myInstallation.isDefault = isDefault;
    $scope.myInstallation.hasChange = hasChange;
    //$scope.myInstallation.getFormatedDefaultName = getFormatedDefaultName;

    $scope.$on( 'selectedSiteHasChanged', function(){
        $scope.myInstallation.links = getModulatorLinks(subscriber.getSelectedSite().modulatorList);
    });

    function save(){
        var links = _.map($scope.myInstallation.links, function (link) {
            if (link.isGlobal)
                link.colorModel = $scope.myInstallation.globalLinks[0].colorModel;
            return {
                name: link.nameModel,
                color: link.colorModel,
                id: link.csLinkId
            }
        });
        $http.post('/modulator/updateCsLinkInterface', {links: links})
            .then(function onSuccess(response) {
                _.each($scope.myInstallation.links, function (link) {
                    link.name = _.clone(link.nameModel);
                    link.color = _.clone(link.colorModel);
                });
                flash( 'alert-success', response.data.message, 3000 );
            })
            .catch(function onError(e) {
                initLinks();
                flash( 'alert-danger', e.message, 5000 );
            });
    }

    function isDefault(){
        var isDefault = true;
        _.each($scope.myInstallation.links, function (link) {
            if (link.defaultName != link.nameModel || link.defaultColor != link.colorModel) {
                isDefault = false;
                return;
            }
        });
        return isDefault;
    }

    function hasChange(){
        var change = false;
        _.each($scope.myInstallation.links, function (link) {
            if (link.nameModel != link.name || link.colorModel != link.color) {
                change = true;
                return;
            }
        });
        return change;
    }

    function getOtherConsumptions() {
        var links = [];
        _.each($scope.myInstallation.links, function (link) {
            if (link.isGlobal)
                links.push(link);
        });
        return links;
    }

    function getModulatorLinks(modulatorList) {
        var links = [];
        _.each(modulatorList, function (modulator) {
            _.each(modulator.values, function (link) {
                links.push(link);
            })
        });
        initLinks(links);
        return links;
    }

    function initLinks(links) {
        if (typeof links === "undefined")
            links = $scope.myInstallation.links;
        _.each(links, function (link) {
            link.nameModel = link.name ? _.clone( link.name ) : $i18next( link.defaultName );
            link.colorModel = _.clone(link.color)
        });
    }

    /*function getFormatedDefaultName( defaultName ){
        return $i18next( defaultName );
        var charToIgnore = '(';
        if( defaultName.indexOf( charToIgnore ) != -1){
            return defaultName.slice(0, defaultName.indexOf(charToIgnore) - charToIgnore.length);
        }
        else{
            return defaultName;
        }
    }*/
}];
