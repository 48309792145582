var _ = require('underscore'),
    logger = require('logger'),
    ref = require('enum'),
    $appScope = require('appScope'),
    moment = require('moment');

module.exports = ['$scope', '$http', '$timeout', '$i18next','numberFormatter', 'detailConsumptionZoom', function ($scope, $http, $timeout, $i18next, numberFormatter, detailConsumptionZoom) {

    var subscriber = $appScope.get('user');
    // Enum that are required by template only
    $scope.MODES = ref.CHARTS_DISPLAY_MODES;
    $scope.CHART_DATA_TYPES = ref.CHART_DATA_TYPES;
    $scope.MAX_VALUE_BOX_DIGIT = 4;

    $scope.detailedConsumption = {};
    $scope.detailedConsumption.loadingData = true;
    $scope.detailedConsumption.loadingPieChartData = true;
    $scope.detailedConsumption.loadingChartData = true;
    $scope.detailedConsumption.getNumberWithMaximumDigit = numberFormatter.getNumberWithMaximumDigit;
    $scope.detailedConsumption.mode = null; // MODES.DETAIL or MODES.TOTAL
    $scope.detailedConsumption.chartDataType = null; // CHART_DATA_TYPES[key]
    $scope.detailedConsumption.isCompared = null; // Boolean toString because angular do not provide select with boolean values

    $scope.detailedConsumption.period = {}; // wrapper for moment startDate & endDate
    $scope.detailedConsumption.period.startDate = null;
    $scope.detailedConsumption.period.endDate = null;

    $scope.detailedConsumption.compareDate = null; // one date to compare, endDate will be determined server side

    $scope.detailedConsumption.hasWattChart = null; // tell if the watt button should be enabled or disabled

    $scope.detailedConsumption.updateLegendState = updateLegendState; // allow to hide/display the legends of the charts
    $scope.detailedConsumption.getVisibleSeries = getVisibleSeries; // getter for the visible series of the charts
    $scope.detailedConsumption.isCompared = isCompared; // $$$DGL : Déjà défini au dessus. De plus isCompared n'est pas défini
    $scope.detailedConsumption.isTotalMode = isTotalMode;
    $scope.detailedConsumption.isComparedOrTotalMode = isComparedOrTotalMode;
    $scope.hasProd = hasProd;
    $scope.chart = {};
    $scope.chart.price = price;
    $scope.detailedConsumption.isTotalModeAndNotCompared = isTotalModeAndNotCompared;
    $scope.detailedConsumption.userHasContractForSelectedSite = userHasContractForSelectedSite; //to know if the user has a contract or not

    // boite de conso, boite de cout, boite de pic
    $scope.detailedConsumption.sums = {};
    $scope.detailedConsumption.sums.left = {};
    $scope.detailedConsumption.sums.left.kwh = null;
    $scope.detailedConsumption.sums.left.euro = null;
    $scope.detailedConsumption.sums.left.pic = null;
    $scope.detailedConsumption.sums.left.prod = null;
    $scope.detailedConsumption.sums.right = {};
    $scope.detailedConsumption.sums.right.kwh = null;
    $scope.detailedConsumption.sums.right.euro = null;
    $scope.detailedConsumption.sums.right.pic = null;
    $scope.detailedConsumption.sums.right.prod = null;

    $scope.detailedConsumption.currentDetailedChart = null; // current chart sent by detailedConsumptionChartDirective

    var debounceFetch = _.debounce(function() {
        $scope.detailedConsumption.loadingData = true;
        $scope.$broadcast("detailedConsumptionPieChartFetch");
        $scope.$broadcast("detailedConsumptionChartFetch");
    }, 300).bind(this)
    initialize();

    function initialize(){
        logger.debug('detailedConsumptionController', 'init');
        $scope.$on("detailedConsumptionUpdateRequested", debounceFetch);
        if(detailConsumptionZoom)
            logger.debug('init with detailConsumptionZoom', detailConsumptionZoom);

        $scope.detailedConsumption.mode = ref.CHARTS_DISPLAY_MODES.DETAIL;

        $scope.detailedConsumption.chartDataType = detailConsumptionZoom ? detailConsumptionZoom.chartDataType : ref.CHART_DATA_TYPES.KWH; // default chart kwh

        var startDate = detailConsumptionZoom ? detailConsumptionZoom.startDate : moment().subtract(1, 'w').startOf('day');
        $scope.detailedConsumption.period.startDate = startDate;
        $scope.detailedConsumption.period.endDate = detailConsumptionZoom ? detailConsumptionZoom.endDate : moment().subtract(1, 'd').startOf('day');

        $scope.detailedConsumption.isCompared = false.toString(); // compared mode disabled by default
        $scope.detailedConsumption.compareDate = moment(startDate).subtract(1, 'w').startOf('day');

        $scope.$on('detailedConsumptionChartHasWattChart', function(event, hasWattChart){
            logger.debug('detailedConsumptionController', 'receiving event', 'hasWattChart', hasWattChart);
            $scope.detailedConsumption.hasWattChart = !!hasWattChart;
            if( !hasWattChart && $scope.detailedConsumption.chartDataType.name === ref.CHART_DATA_TYPES.WATT.name ){
                $scope.detailedConsumption.chartDataType = ref.CHART_DATA_TYPES.KWH;
            }
        });

        $scope.$on('valueBoxesHasChanged', function(event, values){
            logger.debug('detailedConsumptionController', 'receiving', 'valueBoxesHasChanged');
            var side = ( !values.isCompared ) ? 'left' : 'right';
            $scope.detailedConsumption.sums[side].kwh = values.kwh;
            $scope.detailedConsumption.sums[side].euro = values.euro;
            $scope.detailedConsumption.sums[side].prod = values.prod;
            $scope.detailedConsumption.sums[side].pic = {};
            if(values.pic){
                _.extend($scope.detailedConsumption.sums[side].pic, {
                    date: values.pic.date,
                    power: values.pic.power / 1000, // convert watt to kW
                    dateMeter: values.pic.dateMeter
                });
            }
        });
        $scope.$on('onDetailedChartsChange', function(event, chart){
            logger.debug('detailedConsumptionController', 'receiving', 'onDetailedChartsChange');
            $scope.detailedConsumption.currentDetailedChart = chart;
        });

        $scope.$on('onDetailedPieChartsChange', function(event, chart){
            logger.debug('detailedConsumptionController', 'receiving', 'onDetailedPieChartsChange');
            $scope.detailedConsumption.currentDetailedPieChart = chart;
        });

        $scope.$on('onLoadingPieChartDataChange', function(event, loadingData) {
            $scope.detailedConsumption.loadingPieChartData = loadingData;
            if ( !loadingData && !$scope.detailedConsumption.loadingChartDataChange ) {
                $scope.detailedConsumption.loadingData = false;
            }
        });

        $scope.$on('onLoadingChartDataChange', function(event, loadingData) {
            $scope.detailedConsumption.loadingChartDataChange = loadingData;
            if ( !loadingData && !$scope.detailedConsumption.loadingPieChartData ) {
                $scope.detailedConsumption.loadingData = false;
            }
        });
    }

    /**
     * reload legend with default parameters if idLegend = null => update cost and consumption values
     * @param idLegend|null
     */
    function updateLegendState(idLegend){
        var $pieChartSerieList = angular.element('.detailedConsumptionPieChart'),
            $detailedConsumptionChart = angular.element('.detailedConsumptionChart');

        if ( $detailedConsumptionChart == null || $pieChartSerieList == null) {
            return
        }

        const detailedConsumptionChart = $detailedConsumptionChart.highcharts();
        if ( detailedConsumptionChart == null )
            return;
        const  id = idLegend.split("_", 1)[0]; //split car idChart: "15094_0_total" et idPieChart: "15094";
        $scope.$broadcast('visibilitySeriesHasChanged', id, $scope.detailedConsumption.chartDataType);


        if ( detailedConsumptionChart ) {
            detailedConsumptionChart.series.forEach( serie => {
                if (serie.options.id == idLegend) {
                    serie.visible ? serie.hide() : serie.show();
                }
            })
        }
    }

    function getVisibleSeries(){
        var currentChart = $scope.detailedConsumption.currentDetailedChart;
        var currentPieChart = $scope.detailedConsumption.currentDetailedPieChart;

        var visibleSeries = [], i = 0;

        if(currentChart){
            _.each(currentChart.series, function(serie){
                if(serie.showInLegend != false){
                    serie.index = i;
                    visibleSeries.push(serie);
                }
                i++;            
            });
        }

        //It's possible to have series in the pieCharts that aren't in the chart.
        //So we try to determine them in this part of the function
        if(currentPieChart){ 
            _.each(currentPieChart.series[0].data, function(serie){
                var isInVisibleSeries = false;
                _.each(visibleSeries, function(visibleSerie){
                    var id = null;
                    if(visibleSerie.id) 
                        id = visibleSerie.id.split("_", 1); //split car idChart: "15094_0_total" et idPieChart: "15094"
                    if(serie.id ==  id){
                        isInVisibleSeries = true;
                    }
                });

                if(isInVisibleSeries == false){
                    visibleSeries.push(serie);
                }
            });
        }
        return visibleSeries;
    }

    function isTotalModeAndNotCompared()
    {
        return isTotalMode() && !isCompared();
    }

    function isCompared(){
        return $scope.detailedConsumption.isCompared == "true";
    }

    function isTotalMode(){
        return $scope.detailedConsumption.mode == ref.CHARTS_DISPLAY_MODES.TOTAL;
    }

    function isComparedOrTotalMode(){
        return isCompared() || isTotalMode();
    }

    function price(){
        return ref.CHART_DATA_TYPES.EURO.name === $scope.detailedConsumption.chartDataType.name ;
    }

    function hasProd() {
        return subscriber.hasProductionOnSelectedSite()
    }

    function userHasContractForSelectedSite(){
        if(!subscriber.hasContractForSelectedSite()){
            //If we observe a chart in euro, and change the site which doesn't have a contract,
            // the chartDataType will still be euro and we don't want that.
            if($scope.detailedConsumption.chartDataType.name == ref.CHART_DATA_TYPES.EURO.name){
                $scope.detailedConsumption.chartDataType = ref.CHART_DATA_TYPES.KWH;
            }
        }
        return subscriber.hasContractForSelectedSite();
    }
}];

