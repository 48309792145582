const _ = require('underscore');
const logger = require('logger');
const moment = require('moment');
const ref = require('enum');

module.exports = ['$scope', '$http', '$q', function ($scope, $http, $q) {
    $scope.myBudget = {};

    $scope.myBudget.myObjective = {
        isBudgetThresholdActivated: true,
    };

    $scope.myBudget.getVisibleSeries = getVisibleSeries;
    $scope.myBudget.updateLegendState = updateLegendState;

    $scope.myBudget.cap = {}; // wrapper for the monthly and yearly data
    $scope.myBudget.cap.monthly = {};
    $scope.myBudget.cap.monthly.objective = null;
    $scope.myBudget.cap.monthly.consumption = null;
    $scope.myBudget.cap.monthly.startDate = null;

    $scope.myBudget.cap.yearly = {};
    $scope.myBudget.cap.yearly.objective = null;
    $scope.myBudget.cap.yearly.consumption = null;
    $scope.myBudget.cap.yearly.startDate = null;

    initialize();

    function initialize() {
        logger.debug('myBudgetController', 'init');

        if ($scope.user.hasAccessPermission(ref.ACCESS.WIDGET.BUDGET_NOTIFICATION_ALERT)) {
            $scope.myBudget.myObjective.isBudgetThresholdActivatedPromise = loadBudgetThreshold()
                .then((res) => {
                    $scope.myBudget.myObjective.isBudgetThresholdActivated = res.optIn && Boolean(res.optIn.isSiteNotified);
                })
            ;
        }

        const capDeferred = $q.defer();
        $scope.myBudget.cap.yearly.promise = capDeferred.promise;
        $scope.myBudget.cap.monthly.promise = capDeferred.promise;

        $scope.$on('onObjectiveChartChange', function(event, data) {
            logger.debug('myBudgetController', 'receiving', 'onObjectiveChartChange');
            $scope.myBudget.chart = data.chart;

            if (data.chart) {
                $scope.myBudget.cap.yearly.startDate = data.chart.xAxis[0].min;
                $scope.myBudget.cap.yearly.yearly = moment($scope.myBudget.cap.yearly.startDate).format("DD/MM/YYYY");
            }

            capDeferred.resolve();
            $scope.myBudget.cap.monthly.objective = data.monthlyObjective;
            $scope.myBudget.cap.monthly.consumption = data.lastMonthCost;
            $scope.myBudget.cap.monthly.startDate = moment().startOf('month');
            $scope.myBudget.cap.monthly.monthly = moment($scope.myBudget.cap.monthly.startDate).format("MMMM YYYY");

            $scope.myBudget.cap.yearly.objective = data.yearlyObjective;
            $scope.myBudget.cap.yearly.consumption = data.yearlyConsumption;
        });

        $scope.$on('onObjectiveChartError', () => {
            capDeferred.reject();
        });
    }

    // TODO move in a service
    function loadBudgetThreshold() {
        return $http.get('/site/getConsumptionAlertOptInFromSiteId.json')
            .then(res => res.data)
        ;
    }

    function getVisibleSeries() {
        var currentChart = $scope.myBudget.chart;
        if(!currentChart) return;
        var visibleSeries = [], i = 0;

        _.each(currentChart.series, function(serie){
            if(serie.showInLegend != false){
                serie.index = i;
                visibleSeries.push(serie);
            }
            i++;
        });

        return visibleSeries;
    }

    function updateLegendState(idLegend) {
        var $annualObjectiveChart = angular.element('.annualObjectiveChart'),
            annualObjectiveChart = $annualObjectiveChart.highcharts();

        for(var i = 0, max = annualObjectiveChart.series.length; i < max; i++ ){
            var chartSerie = annualObjectiveChart.series[i];
            if(chartSerie.options.id == idLegend){
                chartSerie.visible ? chartSerie.hide() : chartSerie.show();
            }
        }

    }
}];
