'use strict';

var moment = require('moment');
var _ = require('underscore');

module.exports = {
    isInteger: isInteger,
    isNumber: isNumber,
    isArray: isArray,
    isString: isString,
    isValidDateString: isValidDateString,
    getkWhFromW: getkWhFromW,
    getPercent: getPercent,
    getkWhFromWAndDateMeter : getkWhFromWAndDateMeter,
    isProgrammable: isProgrammable,
    formatNumberDecimals: formatNumberDecimals
};

// DKA : la plupart de ses fonction existe déjà dans underscore, pourquoi sont t'elle ici ?
// isArray = _.isArray
// !isNaN = isInteger
// isNumber = _.isNumber
// isString = _.isString
// Pour isValidDateString, il est préférable d'instancier une date avec moment , ensuite nous avont l'information dans la date crée pour savoir si celle ci est valide
// TODO: ne pas utiliser ces fonctions, vérifier les infos si dessus et supprimer

// Integer as string are considered valid
function isInteger( x ){
    return !isNaN( x ) && parseInt( Number( x ) ) == x && !isNaN( parseInt( x, 10 ) );
}

// From http://stackoverflow.com/questions/18082/validate-decimal-numbers-in-javascript-isnumeric
function isNumber( n ) {
    return !isNaN( parseFloat( n ) ) && isFinite( n );
}

function isArray( a ){
    return Object.prototype.toString.call( a ) === '[object Array]'
}

// From http://stackoverflow.com/questions/4059147/check-if-a-variable-is-a-string
function isString( s ){
    return (typeof s == 'string' || s instanceof String)
}

// TODO : à remplacer par quelques chose de mieux
// Format should be DD/MM/YYYY, limit to 19xx and 20xx years
function isValidDateString( dateStr ){
    return null != dateStr.match( /[0-3][0-9]\/[0-1][0-9]\/(19|20)[0-9][0-9]/ );
}

function getkWhFromW( watt, step, unit, pointTimestamp ){
    var durationInS = moment( pointTimestamp ).add( step, unit).diff( moment( pointTimestamp ) ) / 1000;
    var nbSecondsInOneHour = 3600;
    var kwh = watt * durationInS / nbSecondsInOneHour / 1000;
    return kwh;
}


function getkWhFromWAndDateMeter( watt, date_meter, step, unit, pointTimestamp ){
    if(unit == 'months'){

        var starOfMonth = moment().startOf('month');
        if(starOfMonth.valueOf() == moment(date_meter).valueOf()){
            step = moment().diff(moment(date_meter), 'days');
            unit = 'days';
        }
    }
    return getkWhFromW( watt, step, unit, pointTimestamp );
}

function getPercent(total, value){
    return value * 100 / total;
}

/**
 * Returns true if the given link is programmable. A link is programmable each of its appliances are programmable, and
 * it has at least one appliance.
 * @param {Object} csLink
 * @returns {boolean}
 */
function isProgrammable(csLink){
    if (csLink ==null || csLink.appliances == null || csLink.appliances.length === 0) {
        return false;
    }
    var isProgrammable = true;
    _.each(csLink.appliances, function(appliance){
        if(appliance.isProgrammable === 0){
            isProgrammable = false
        }
    });
    return isProgrammable;
}

/**
 * Return string displayed as local format (with '.' in english and ',' in french...)
 * If maxDigits is not defined, min = max
 * @param number
 * @param minDigits
 * @param maxDigits
 * @returns {string}
 */
function formatNumberDecimals (number, minDigits, maxDigits) {
    let stringNumber = number == null ? 0 : number;
    return stringNumber.toLocaleString([],{
        minimumFractionDigits: minDigits,
        maximumFractionDigits: maxDigits == null ? minDigits : maxDigits
    })
}