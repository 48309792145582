module.exports = {
    BASE: {
        color: '#3fb9e2',
        lightColor: "rgba( 63, 185, 226, 0.4 )"
    },
    OFFPEAK: {
        color: '#9bcd1c',
        lightColor: "rgba( 155, 205, 28, 0.4 )",
        background: '#f1fada',

    },
    PEAK: {
        color: '#3fb9e2',
        lightColor: "rgba( 63, 185, 226, 0.4 )",
        background: '#e2f5fb',
    },
    subscription: {
        color: '#bbbbbb',
        lightColor: "rgba(187, 187, 187, 0.4)"
    },
    temperatures: {
        color: '#99241b',
        lightColor: "rgba(153, 36, 27, 0.4)"
    },
    department: {
        color: '#888888'
    },
    production: {
        color: "#f8ce0a"
    },
    otherConsumption: {
        color: "#BC8F8F"
    },
    waterHeater: {
        color: "#FA000C"
    },
    heating: {
        color: "#ea584a"
    },
    cooling: {
        color: "#4cb849"
    },
    WE: {
        background: "#eeeeee",
        label: '#cccccc'
    }
};