import _ from 'underscore';

module.exports = {
    getEvolution,
};

function getEvolution(value) {
    if (!value || !_.isNumber(value)) {
        return '';
    }

    const baseClass = 'fa-external-link-square';
    return `${baseClass} ${value > 0 ? 'v-fa-danger' : 'fa-rotate-90  v-fa-success'}`;
}
